@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      no-repeat center right 1.75rem/1.125rem 1.125rem;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      no-repeat center right 1.75rem/1.125rem 1.125rem;
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dropdown-item:last-child {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(2.875rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.8125rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion .card {
  overflow: hidden;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion .card .card-header {
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-3by4::before {
  padding-top: 133.333333%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */


/**  =====================
     Google Font
==========================  **/
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600"); */
/* 
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import 
@import url("../fonts/feather/css/feather.css");
@import url("../plugins/jquery-scrollbar/css/perfect-scrollbar.css");
@import url("../fonts/datta/datta-icon.css"); */

/* @import "Custome.css"; */

/**  =====================
      Custom css start
==========================  **/
body{
  font-family: Arial, Helvetica, sans-serif
}
.img-radius {
  border-radius: 50%;
}

.page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;
}
.page-header-title + .breadcrumb > .breadcrumb-item a {
  color: #888;
}
.page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
  color: #111;
  font-weight: 600;
}

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.theme-bg2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

/* ==========  card css start  =========== */
.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
          animation: anim-rotate 1s linear infinite;
}

@-webkit-keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
          animation: anim-close-card 1.4s linear;
}

@-webkit-keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
p.text-muted {
  font-size: 13px;
}

.col-md-9-card .card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}
.card .card-header h5 {
  margin-bottom: 0;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}
.card .card-header h5:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: -25px;
  top: 0;
  width: 4px;
  height: 20px;
}
.card .card-header.borderless {
  border-bottom: none;
}
.card .card-header.borderless h5:after {
  display: none;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  /* float: left; */
  padding: 0;
  position: absolute;
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.card .card-footer {
  border-top: 1px solid #f1f1f1;
  background: transparent;
  padding: 25px;
}
.card .card-block,
.card .card-body {
  padding: 0;
}
.card.card-load {
  position: relative;
  overflow: hidden;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: #04a9f5;
  font-size: 24px;
  align-items: center;
  display: flex;
}
.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

/* ==========  scrollbar End  =========== */
.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */
.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;
}
.dropdown-menu.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: 15px;
  top: -5px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu.show.dropdown-menu-right:before {
  left: auto;
  right: 5px;
}
.dropdown-menu[x-placement="top-start"] {
  margin-bottom: 15px;
  margin-top: 0;
}
.dropdown-menu[x-placement="top-start"].show:before {
  content: "\64";
  bottom: -5px;
  top: auto;
  text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu > li {
  padding-left: 15px;
  padding-right: 15px;
}
.dropdown-menu > li > a {
  padding: 5px;
  color: #888;
}
.dropdown-menu > li > a i {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
/* ========================================================
 ===============     document      ======================
 ========================================================


/* Example modals */
.modal {
  z-index: 1072;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}
.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}


.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */

/* Modal */
.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;
}
.datta-example-modal-opened .datta-example-modal {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;
}
.datta-example-modal-content > pre {
  overflow: hidden;
  width: -webkit-fit-content;
  width: fit-content;
}
.datta-example-modal-content > pre > code {
  padding: 0;
  background: none;
  font-size: 16px;
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #04a9f5;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
}
.md-datta-example-modal-copy:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}
.md-datta-example-modal-copy.copied::before {
  content: "Copied to Clipboard Successfully ! . . .";
  position: absolute;
  display: block;
  right: 100%;
  margin-right: 10px;
  font-size: 14px;
  background: #1de9b6;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 6px;
  top: 50%;
  margin-top: -12px;
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.datta-example-modal-close:hover {
  color: #fff;
  opacity: 0.9;
}

/* ================================    range slider Start  ===================== */
.tooltip.in {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px;
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */
.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: #f0f3f6;
}
.bootstrap-tagsinput .tag {
  padding: 5px 12px;
  border-radius: 2px;
  line-height: 37px;
  margin-top: 5px;
  margin-right: 5px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-right: -5px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\e8f6";
  padding: 0 2px;
  font-family: 'feather' !important;
}

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */
.ms-container {
  width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #eaeaea;
  padding: 0.5rem 1rem;
}
.blockquote.text-right {
  border-left: none;
  border-right: 0.25rem solid #eaeaea;
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning h1 {
  color: #fff;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

/* ================================    browser  warning  End  ===================== */
/* material icon for material datetime picker */
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
}

/* material icon for material datetime picker */
/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-c-blue {
  background: #04a9f5;
}

.text-c-blue {
  color: #04a9f5;
}

.bg-c-red {
  background: #f44236;
}

.text-c-red {
  color: #f44236;
}

.bg-c-green {
  background: #1de9b6;
}

.text-c-green {
  color: #1de9b6;
}

.bg-c-yellow {
  background: #f4c22b;
}

.text-c-yellow {
  color: #f4c22b;
}

.bg-c-purple {
  background: #a389d4;
}

.text-c-purple {
  color: #a389d4;
}

.navbar-collapsed .b-brand .b-title {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed .mobile-menu {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed:hover .b-brand .b-title {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.navbar-collapsed:hover .mobile-menu {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}

/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
  content: '';
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}
.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

/* Pre-loader css end */
/* header css start */
.pcoded-header {
  z-index: 1028;
  position: relative;
  display: flex;
  min-height: 70px;
  padding: 0;
  position: relative;
  top: 0;
  background: transparent;
  color: #3f4d67;
  width: calc(100% - 264px);
  margin-left: 264px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .m-header {
  display: none;
}
.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: #3f4d67;
}
.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #04a9f5;
}
.pcoded-header #mobile-header {
  display: none;
}
.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}
.pcoded-header .navbar-nav > li {
  line-height: 70px;
  display: inline-block;
  padding: 0 12px;
}
.pcoded-header .navbar-nav > li .nav-link {
  padding: 0;
}
.pcoded-header .navbar-nav > li:first-child {
  padding-left: 25px;
}
.pcoded-header .navbar-nav > li:last-child {
  padding-right: 40px;
}
.pcoded-header .mr-auto .dropdown-menu {
  margin-left: -20px;
}
.pcoded-header .ml-auto {
  float: right;
}
.pcoded-header .ml-auto .dropdown-menu {
  margin-right: -20px;
}
.pcoded-header .main-search .input-group {
  border-radius: 20px;
  padding: 0;
}
.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.pcoded-header .main-search .input-group .search-close {
  display: none;
}
.pcoded-header .main-search .input-group .search-btn {
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.pcoded-header .main-search .input-group .form-control {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
  font-size: 14px;
}
.pcoded-header .main-search .input-group .form-control:active, .pcoded-header .main-search .input-group .form-control:focus, .pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .main-search.open .input-group {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
  padding: 5px 5px 5px 20px;
}
.pcoded-header .main-search.open .input-group .search-close {
  display: flex;
}
.pcoded-header .main-search.open .input-group .search-btn {
  padding: 5px;
  margin-left: 5px;
  background: #04a9f5;
  border-color: #04a9f5;
}
.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header .main-search.open .input-group .form-control {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
}
.pcoded-header .main-search.open .input-group .form-control:active, .pcoded-header .main-search.open .input-group .form-control:focus, .pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .dropdown .dropdown-toggle {
  line-height: 70px;
  display: inline-block;
  padding-right: 15px;
}
.pcoded-header .dropdown .dropdown-toggle:after {
  content: "\e842";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}
.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  border: none;
  margin-top: -6px;
  min-width: 290px;
}
.pcoded-header .dropdown .dropdown-menu.show:before {
  display: none;
}
.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}
.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}

.pcoded-header .dropdown.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: -5px;
  top: 60px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12);
}
.pcoded-header .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .notification .noti-head {
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;
}
.pcoded-header .dropdown .notification .noti-head a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
}
.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}
.pcoded-header .dropdown .notification .noti-body li {
  padding: 15px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}
.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}
.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}
.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}
.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
}
.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}
.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #04a9f5;
}
.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #888;
  font-size: 14px;
  padding: 10px 20px;
}
.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-body li.active, .pcoded-header .dropdown .profile-notification .pro-body li:active, .pcoded-header .dropdown .profile-notification .pro-body li:focus, .pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .profile-notification .pro-body li.active > a, .pcoded-header .dropdown .profile-notification .pro-body li:active > a, .pcoded-header .dropdown .profile-notification .pro-body li:focus > a, .pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
  background: transparent;
}
.pcoded-header .dropdown.drp-user.show:before {
  color: #04a9f5;
}

/* header css end */
/* menu[ vartical ] css start */
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
}
.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after, .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.mobile-menu.on span {
  background-color: transparent;
}
.mobile-menu.on span:after, .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.mobile-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
          transform: rotate(45deg) translate(4px, 4px);
}
.mobile-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
          transform: rotate(-45deg) translate(3px, -3px);
}

.navbar-brand {
  background: #3f4d67;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1029;
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 0 20px 0 #3f4d67;
  width: 264px;
  height: 100vh;
  top: 0;
  background: #3f4d67;
  color: #a9b7d0;
}
.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.pcoded-navbar .scroll-div.navbar-content {
  height: calc(100vh - 70px);
}
.pcoded-navbar .header-logo {
  position: relative;
  align-items: center;
  display: inline-flex;
  float: left;
  height: 70px;
  text-align: center;
  width: 264px;
  margin-right: 0;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar .header-logo .logo-thumb {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  -webkit-transition: unset;
  transition: unset;
}
.pcoded-navbar .header-logo + .scroll-div {
  /* float: left; */
  display: inline-block;
}
.pcoded-navbar .mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #a9b7d0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after, .pcoded-navbar .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #a9b7d0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.pcoded-navbar .mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.pcoded-navbar .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar .mobile-menu.on span:after, .pcoded-navbar .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.pcoded-navbar .mobile-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
          transform: rotate(45deg) translate(4px, 4px);
}
.pcoded-navbar .mobile-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
          transform: rotate(-45deg) translate(3px, -3px);
}
.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: absolute;
  right: 30px;
  top: 12px;
  padding: 2px 7px;
  border-radius: 2px;
}
.pcoded-navbar .pcoded-inner-navbar {
  flex-direction: column;
}
.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 7px 15px;
  margin: 5px 0 0;
  display: block;
  border-radius: 0;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
  font-size: 1rem;
  padding: 4px 7px;
  margin-right: 7px;
  border-radius: 4px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  text-align: center;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
  position: absolute;
  top: 11px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e844";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 11px;
  right: 20px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > a {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 7px 7px 7px 60px;
  margin: 0;
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 35px;
  width: 5px;
  height: 5px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 10px;
  font-weight: 600;
  padding: 25px 20px 5px;
  text-transform: uppercase;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.disabled > a {
  cursor: default;
  opacity: 0.5;
}
.pcoded-navbar .pcoded-submenu {
  background: #39465e;
  padding: 15px 0;
}
.pcoded-navbar a {
  color: #a9b7d0;
}
.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}
.pcoded-navbar.navbar-collapsed {
  width: 80px;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .header-logo {
  width: 80px;
}
.pcoded-navbar.navbar-collapsed .header-logo img {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: unset;
  transition: unset;
}
.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 1;
  left: calc((80px / 2) - 20px);
}
.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  z-index: 1026;
  padding: 7px 25px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  margin-left: 264px;
  min-height: 100vh;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


body.layout-1 .pcoded-navbar .toggle-sidemenu {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #2c3547;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 0 0;
}
body.layout-1 .pcoded-navbar.navbar-collapsed .toggle-sidemenu {
  display: none;
}
body.layout-1 .pcoded-navbar .header-logo {
  padding: 10px 20px;
}
body.layout-1 .pcoded-navbar .sidemenu {
  left: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: inset -1px 0 0 0 rgba(169, 183, 208, 0.2);
}
body.layout-1 .pcoded-navbar .sidemenu li {
  position: relative;
}
body.layout-1 .pcoded-navbar .sidemenu li a {
  font-size: 22px;
  padding: 10px 29px;
  position: relative;
  cursor: pointer;
}
body.layout-1 .pcoded-navbar .sidemenu li a:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 60%;
  left: 20%;
  bottom: 0;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}
body.layout-1 .pcoded-navbar .sidemenu li:last-child a:after {
  display: none;
}
body.layout-1 .pcoded-navbar .sidemenu li.active {
  color: #04a9f5;
}
body.layout-1 .pcoded-navbar .sidemenu li.active:after {
  content: "";
  background-color: #04a9f5;
  z-index: 1027;
  position: absolute;
  right: 0;
  top: 7px;
  width: 3px;
  height: calc(100% - 14px);
  border-radius: 3px 0 0 3px;
}
body.layout-1 .pcoded-navbar .layout1-nav {
  display: flex;
  align-items: stretch;
  height: auto;
  min-height: 100%;
}
body.layout-1 .pcoded-navbar .side-content {
  flex: 1 1;
}
body.layout-1 .pcoded-navbar .side-content .sidelink {
  display: none;
}
body.layout-1 .pcoded-navbar .side-content .sidelink.active {
  display: block;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 16px;
  padding: 16px 15px 16px 18px;
  font-weight: 400;
  text-transform: capitalize;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 40px;
  left: 18px;
  bottom: 13px;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  padding: 7px 7px 7px 40px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  left: 20px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a {
  padding: 7px 7px 7px 60px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a:before {
  left: 40px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a {
  padding: 12px 10px 12px 20px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-micon {
  display: none;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-mtext {
  position: relative;
  top: 0;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.active:after, body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.pcoded-trigger:after {
  display: none;
}
body.layout-1 .pcoded-navbar.hide-sidemenu .sidemenu {
  position: absolute;
  left: -80px;
}
body.layout-1 .pcoded-navbar.hide-sidemenu .toggle-sidemenu i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.navbar-content {
  position: relative;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent;
}

.ps__thumb-y {
  background: #2a3446;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #222a38;
}

.gradientcolor .btn-round {
  border-radius: 30px;
}

.ChartShadow {
  -webkit-filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
}

.chart-activity,
.chart-sale,
.chart-statistics,
.device-chart {
  margin: 0 auto;
}

#Statistics-sale .amcharts-cursor-fill {
  -webkit-filter: url(#shadow);
          filter: url(#shadow);
  fill: rgba(255, 255, 255, 0.3);
  stroke-width: 0;
}

.amcharts-zoom-out-bg,
.amcharts-zoom-out-image {
  display: none;
}

/* statistics section */
.card-command .card-icon {
  opacity: 0.5;
}

.mat-drp .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #d8d8d8;
}
.mat-drp .btn.dropdown-toggle:after {
  display: none;
}
.mat-drp .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.table-card .row-table i {
  padding: 50px 20px;
}
.table-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.table-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}

.rides-bar i.rides-icon {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 15px;
}

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.profit-bar i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 13px;
}

.assets-value .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center 103%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.assets-value .card-block {
  position: relative;
  z-index: 5;
}



@media screen and (max-width: 1199px) {
  .assets-value .bg-img {
    background: none;
  }
}

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%);
}
.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative;
}
.chat-sanders .scroll-wrapper {
  height: 305px;
}
.chat-sanders .received-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -12px;
  bottom: 18px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  border: 6px solid transparent;
  border-bottom-color: #fff;
}
.chat-sanders .send-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .send-chat .msg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .send-chat .msg:after {
  content: "";
  position: absolute;
  right: -11px;
  bottom: 18px;
  -webkit-transform: rotate(450deg);
          transform: rotate(450deg);
  border: 6px solid transparent;
  border-bottom-color: #1de9b6;
}
.chat-sanders .btn {
  background: none;
  opacity: 0.4;
}
.chat-sanders .form-control {
  background: #f4f7fa;
}
.chat-sanders .input-group {
  background: transparent;
}

.widget-menu {
  background: linear-gradient(-135deg, #88d3ce 0%, #6e45e2 100%);
}
.widget-menu .widget-title {
  border-top: 1px solid #676fb9;
}
.widget-menu i {
  opacity: 0.5;
}

.to-do .to-do-button {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.to-do .to-do-button .btn {
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 50px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}

.lazy-dog i {
  opacity: 0.5;
}

.widget-content .widget-lorem .media h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #e3e3e3;
}
.note-bar .friendlist-box:first-child {
  border: 0;
}
.note-bar .friendlist-box h6 {
  display: inline-block;
}
.note-bar .friendlist-box i {
  opacity: 0.2;
  color: #888;
}

.to-do-list h6 {
  display: inline-block;
}
.to-do-list .done-task {
  opacity: 0.7;
}
.to-do-list .done-task > div {
  -webkit-filter: grayscale(0.8);
          filter: grayscale(0.8);
}
.to-do-list .done-task h6,
.to-do-list .done-task p,
.to-do-list .done-task span {
  text-decoration: line-through;
}
.to-do-list .checkbox-fade .check-task {
  display: block;
}
.to-do-list .checkbox-fade .to-content {
  display: inline-block;
}
.to-do-list .checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  /* float: right; */
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin: 0;
  top: 5px;
}
.to-do-list .checkbox-fade .cr .cr-icon {
  color: #fff;
  font-size: 0.8em;
  left: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}
.to-do-list .checkbox-fade label input[type=checkbox] {
  display: none;
}
.to-do-list .checkbox-fade label input[type=checkbox] + .cr > .cr-icon {
  -webkit-transform: scale(3) rotateZ(-20deg);
          transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon {
  -webkit-transform: scale(1) rotateZ(0deg);
          transform: scale(1) rotateZ(0deg);
  opacity: 1;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border: 0;
}

.User-Activity .table td,
.user-list .table td {
  vertical-align: middle;
}
.User-Activity .table thead th,
.user-list .table thead th {
  border: 0;
}

.Application-list .table td,
.code-table .table td {
  vertical-align: middle;
}
.Application-list .table .label,
.code-table .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.Application-list thead th,
.code-table thead th {
  border: 0;
}

.Recent-Users .table tr:first-child td,
.User-Lists .table tr:first-child td {
  border-top: 0;
}
.Recent-Users .table td,
.User-Lists .table td {
  vertical-align: middle;
}
.Recent-Users .table .label,
.User-Lists .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center;
}
.social-media .progress h5 {
  position: relative;
  top: -2px;
}

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none;
}
.post-emoticon li {
  display: inline-block;
}
.post-emoticon i {
  position: relative;
  top: 4px;
}

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.loction-user .row {
  padding: 35px 30px;
}
.loction-user i {
  opacity: 0.5;
}
.loction-user .loction-progress {
  padding: 35px 30px;
}
.loction-user .loction-progress .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
}
.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}
.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3;
}

.ux-designer {
  position: relative;
  padding: 35px 30px;
}
.ux-designer .btn {
  border-radius: 50px;
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: -20px;
  padding: 0;
  margin: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0;
}
.task-list:after {
  content: "";
  position: absolute;
  background: #ecedef;
  height: 100%;
  width: 2px;
  top: 0;
  left: 30px;
  z-index: 1;
}
.task-list li {
  margin-bottom: 30px;
  padding-left: 55px;
  position: relative;
}
.task-list li:last-child {
  margin-bottom: 0;
}
.task-list li .task-icon {
  position: absolute;
  left: 22px;
  top: 13px;
  border-radius: 50%;
  padding: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-main {
  padding: 30px 25px;
}
.project-main i {
  opacity: 0.4;
}

.user-chart i {
  opacity: 0.3;
}

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.user-card .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.Active-visitor .progress {
  height: 7px;
}
.Active-visitor .card-active > div + div {
  border-left: 1px solid #eaeaea;
}
@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px;
  }
}

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px;
}
.Invoice-bar i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .location-sale .card-icon {
    display: none;
  }
}

.card-Impression i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.card-Revenue i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-customer i {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 375px) {
  .card-customer i {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}

.ticket-customer i {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px;
}

.ticket-visitor .progress {
  border-radius: 0;
  height: 13px;
}

.customer-visitor i {
  opacity: 0.2;
  font-size: 118px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-social:hover i {
  font-size: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.card-social .progress {
  height: 6px;
}
.card-social .card-active > div + div {
  border-left: 1px solid #eaeaea;
}

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px;
}

.team-leader .slide {
  margin-bottom: 32px;
}
.team-leader .slide li {
  width: 10px;
  height: 10px;
  background: #e3e3e3;
  margin: 0 6px;
  border-radius: 50px;
}
.team-leader .slide .carousel-indicators {
  bottom: -44px;
}
.team-leader .slide .carousel-indicators .active {
  background: #869791;
}

.affilate-offers .card-icon {
  opacity: 0.5;
}
.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px;
}

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0;
}
.earning-date .bd-example .nav-pills {
  background: transparent;
  padding: 0;
  box-shadow: none;
}
.earning-date .bd-example .nav-pills .nav-link {
  min-width: auto;
  padding: 6px 20px;
  color: #fff;
}
.earning-date .bd-example .nav-pills .nav-link.active {
  background: #fff;
  box-shadow: none;
  color: #000;
}
.earning-date .bd-example .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 11px;
}
.earning-date .bd-example .tab-content {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.sale-view i {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-task i {
  opacity: 0.5;
}
.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px;
}
.project-task .progress {
  height: 6px;
}

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px;
}
.card-event i {
  position: absolute;
  bottom: 36px;
  right: 27px;
}

.bitcoin-wallet i {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.5;
}

.summary-box .card-icon {
  opacity: 0.4;
}

.feature-card-box .feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  padding: 10px;
  font-size: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feature-card-box:hover .feature-icon {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
  border-radius: 3px;
  padding: 0 3px;
}

@media screen and (max-width: 767px) {
  .user-designer [class*=col-]:last-child {
    margin-top: 15px;
  }

  div.dataTables_wrapper div.dataTables_filter label {
    float: none !important;
    text-align: center;
  }
}
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

/* ===================== to do page ============================= */
#task-container ul {
  overflow: hidden;
  padding: 0;
}
#task-container li {
  /* float: left; */
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  color: #666;
  border-top: 5px solid #04a9f5;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
#task-container li:nth-child(2n) {
  margin-right: 0;
}
#task-container li.complete {
  opacity: 1;
  border-top: 9px solid #f44236;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}

#task-container li.complete p {
  text-decoration: line-through;
}
@media screen and (max-width: 580px) {
  #task-container li {
    width: 100%;
  }
  
  .auth-wrapper .auth-content {
    width: 100%;
  }
  .programcode-card-new {
    width: 90%;
    height: auto;
    margin: auto;
}
.auth-content .card .card-block, .auth-content .card .card-body {
  padding: 25px !important;
}
.auth-wrapper .card {
  margin-bottom: 0;
  width: 90%;
  margin: auto;
}
}

.new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px;
}
.new-task label input[type=checkbox] {
  display: none;
}

.checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  /* float: left; */
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin-right: 16px;
  top: 0;
}

.task-panel .to-do-label {
  margin-bottom: 15px;
}
.task-panel .to-do-label:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding: 0;
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0;
}
.to-do-list:hover i {
  opacity: 1;
  -webkit-transition: opacity ease-in 0.3s;
  transition: opacity ease-in 0.3s;
}

.note-card .note-box-aside {
  border-right: 1px solid #ddd;
}
.note-card .Note-header {
  padding: 20px 0;
}
.note-card #Note-pad {
  border: none;
  resize: none;
  background: 0 0;
  padding: 0 20px 0 50px;
  line-height: 35px;
}
.note-card .note-write {
  position: relative;
  background: -webkit-linear-gradient(top, #98dcfa 0%, #e1f5fe 5%) 0 0;
  background-size: 100% 35px;
}
.note-card .note-write:before {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 32px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .note-write:after {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 34px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
.note-card #Note-list li:hover .Note-delete {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

/* ===================== Gallery page ============================= */
.gallery-masonry .card-columns {
  -webkit-column-count: 4;
          column-count: 4;
}

@media screen and (max-width: 1400px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (max-width: 992px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (max-width: 575px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}
.job-meta-data i {
  margin-right: 5px;
  color: #04a9f5;
}

/* ===================== Task-list page ============================= */
.task-data img {
  width: 40px;
  box-shadow: none;
}
.task-data i {
  color: #111;
}
.task-data .dropdown-toggle:after {
  color: #111;
}

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
.task-board-left .taskboard-right-progress,
.task-board-left .taskboard-right-revision,
.task-board-left .taskboard-right-users {
  padding-top: 10px;
}
.task-board-left .taskboard-right-progress .progress {
  height: 9px;
  margin-bottom: 25px;
}
.task-board-left .user-box .media-object {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.task-board-left .user-box .media-left {
  position: relative;
}
.task-board-left .user-box .btn.btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-board-left .live-status {
  height: 8px;
  width: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  top: 5px;
}

.filter-bar .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  padding: 0.5rem 1rem;
}
@media screen and (max-width: 557px) {
  .filter-bar .navbar .f-text {
    display: block;
    width: 100%;
  }
  .filter-bar .navbar .f-view {
    padding-left: 24px;
  }
  .filter-bar .navbar .f-view span {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
.filter-bar .label {
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 7px;
}
.filter-bar .task-detail {
  margin-bottom: 5px;
}
.filter-bar .card-task .task-list-table {
  display: inline-block;
}
.filter-bar .card-task .task-list-table img {
  width: 40px;
  box-shadow: none;
}
.filter-bar .card-task .task-list-table i {
  color: #111;
}
.filter-bar .card-task .task-board {
  float: right;
  margin-top: 5px;
}
.filter-bar .card-task .task-board .dropdown {
  display: inline-block;
}
.filter-bar .card-task .task-board .btn {
  padding: 4px 10px;
  font-size: 10px;
  margin: 0;
}

.assign-user img,
.task-comment img {
  width: 45px;
  box-shadow: none;
}

/* ===================== Invoice page ============================= */
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
}

.invoice-table.table {
  padding-left: 20px;
}
.invoice-table.table td {
  border: 0;
  padding: 4px 0;
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;
}
.invoive-info h6 {
  margin-bottom: 20px;
  text-transform: uppercase;
}
.invoive-info .invoice-order.table {
  padding-left: 0;
}
.invoive-info .invoice-order.table th {
  border: 0;
  padding: 4px 0;
}
.invoive-info .invoice-order.table th:first-child {
  padding-left: 0;
  width: 80px;
}

.invoice-total.table {
  background: #f3f3f3;
  padding: 30px 0;
}
.invoice-total.table th {
  border: 0;
  padding: 4px 0;
  text-align: right;
}
.invoice-total.table td {
  text-align: right;
}
.invoice-total.table tbody {
  padding-right: 20px;
  float: right;
}

.invoice-summary .label {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px;
}

.invoice-list .btn {
  padding: 5px 10px;
  font-size: 12px;
}
.invoice-list .task-list-table {
  display: inline-block;
}

/*  ================ new css =================== */
.datepicker-dropdown {
  padding: 20px;
  color: #fff;
  background: #3f4d67;
  font-size: 14px;
}
.datepicker-dropdown:after {
  border-bottom: 6px solid #3f4d67;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #3f4d67;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active, .datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active, .datepicker table tr td.highlighted,
.datepicker table tr td.today, .datepicker table tr td.day:hover, .datepicker table tr td.focused, .datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background-color: #333f54;
  color: #fff;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover,
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #ffffff94;
}

.syntax-output {
  border-radius: 0.25rem;
  border: 1px solid #eaeaea;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px !important;
  background-color: #04a9f5;
  color: #fff;
}
.owl-carousel button:focus {
  outline: none;
  color: #fff;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #04a9f5;
  color: #fff;
}

.counter b {
  font-size: 24px;
}

.tour-mobile .error-block {
  display: none;
}

.message-mobile span {
  font-size: 16px;
}
.message-mobile .task-right-header-status {
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .message-mobile .task-right-header-status {
    display: none;
  }
}

.fullcalendar-card .fc-button {
  background-color: #04a9f5;
  border-color: #fff;
  color: #fff;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  height: 37px;
  padding: 0 15px;
}
.fullcalendar-card h2 {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .fullcalendar-card .fc .fc-toolbar > * > * {
    float: none;
  }
  .fullcalendar-card .fc-toolbar .fc-left, .fullcalendar-card .fc-toolbar .fc-right, .fullcalendar-card .fc-toolbar .fc-center {
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ck-content strong {
  font-weight: 600;
}

div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  box-shadow: none !important;
}

pre[class*=language-]:after, pre[class*=language-]:before {
  display: none;
}

#chat-scroll {
  height: 280px;
  position: relative;
}

.msg-user-list.scroll-div {
  height: calc(100vh - 300px);
  position: relative;
}

.msg-user-chat.scroll-div {
  height: calc(100vh - 330px);
  position: relative;
}

@media screen and (max-width: 1199px) {
  .note-card .note-box-aside {
    border: none !important;
  }
}
@media screen and (max-width: 991px) {
  .ch-block {
    display: none;
  }

  .msg-block.dis-chat .taskboard-right-progress {
    display: none;
  }
  .msg-block.dis-chat .ch-block {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .tour-mobile .error-block {
    display: block;
  }

  .tour-mobile .page-wrapper {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .horizontal-mobile {
    display: none;
  }
}
/**  =====================
      Form Componant css start
==========================  **/
.custom-select,
.form-control {
  background: #f4f7fa;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
}
/* .custom-select:focus,
.form-control:focus {
  background: #f4f7fa;
} */
.custom-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #aaaeb3;
  opacity: 1;
}
.custom-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #aaaeb3;
}
.custom-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #aaaeb3;
}

/* input group start */
.input-group {
  background: #f4f7fa;
}
.input-group .input-group-text {
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
}
.input-group .input-group-text i {
  font-size: 20px;
}
.input-group .custom-file-label {
  margin-bottom: 0;
}
.input-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5;
}
.cust-file-button .custom-file-label::after {
  padding: 0.775rem 0.75rem;
  height: 2.65rem;
}

.custom-select {
  height: calc(2.25rem + 9px);
}

/* input group End */
/**====== Form Componant css end ======**/
select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors .form-control {
  padding: 6px 12px 5px 44px;
}

/* form-select */
.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.checkbox input[type=checkbox] {
  margin: 0;
  display: none;
  width: 22px;
}
.checkbox input[type=checkbox] + .cr {
  padding-left: 0;
}
.checkbox input[type=checkbox] + .cr:before {
  content: "\e83f";
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'feather';
  font-weight: 400;
  line-height: 19px;
  vertical-align: bottom;
  text-align: center;
  background: #ffffff;
  color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox input[type=checkbox]:checked + .cr:before {
  background: #1dd5d2;
  border-color: #1dd5d2;
  color: #ffffff;
}
.checkbox input[type=checkbox].disabled + .cr, .checkbox input[type=checkbox]:disabled + .cr {
  opacity: 0.5;
}
.checkbox input[type=checkbox].disabled + .cr:before, .checkbox input[type=checkbox]:disabled + .cr:before {
  cursor: not-allowed;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:after {
  content: "";
  width: 22.5px;
  height: 22.5px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 2px;
  vertical-align: bottom;
  text-align: center;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 8.5px;
  left: 3px;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:before {
  opacity: 0;
  content: "\e840";
  font-size: 27px;
  background: transparent;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:after {
  opacity: 0;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:before {
  opacity: 1;
  background: transparent;
  color: #1dd5d2;
  border-color: transparent;
}
.checkbox.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: #04a9f5;
  border-color: #04a9f5;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #04a9f5;
  border-color: transparent;
}
.checkbox.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: #f44236;
  border-color: #f44236;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f44236;
  border-color: transparent;
}
.checkbox.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: #1de9b6;
  border-color: #1de9b6;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #1de9b6;
  border-color: transparent;
}
.checkbox.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: #f4c22b;
  border-color: #f4c22b;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f4c22b;
  border-color: transparent;
}
.checkbox.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: #3ebfea;
  border-color: #3ebfea;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #3ebfea;
  border-color: transparent;
}
.checkbox .cr {
  cursor: pointer;
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.radio input[type=radio] {
  margin: 0;
  display: none;
  width: 22px;
}
.radio input[type=radio] + .cr {
  padding-left: 0;
}
.radio input[type=radio] + .cr:after, .radio input[type=radio] + .cr:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: bottom;
  background: #fff;
  color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.radio input[type=radio] + .cr:before {
  width: 22px;
  height: 22px;
  border: 2px solid #e9eaec;
}
.radio input[type=radio] + .cr:after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 13px;
  left: 5px;
}
.radio input[type=radio]:checked + .cr:before {
  border-color: #1dd5d2;
}
.radio input[type=radio]:checked + .cr:after {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.radio input[type=radio]:disabled + .cr {
  opacity: 0.5;
  cursor: not-allowed;
}
.radio input[type=radio]:disabled + .cr:after, .radio input[type=radio]:disabled + .cr:before {
  cursor: not-allowed;
}
.radio.radio-fill input[type=radio] + .cr:after {
  width: 18px;
  height: 18px;
  top: 10px;
  left: 2px;
}
.radio.radio-primary input[type=radio]:checked + .cr:before {
  border-color: #04a9f5;
}
.radio.radio-primary input[type=radio]:checked + .cr:after {
  background: #04a9f5;
}
.radio.radio-danger input[type=radio]:checked + .cr:before {
  border-color: #f44236;
}
.radio.radio-danger input[type=radio]:checked + .cr:after {
  background: #f44236;
}
.radio.radio-success input[type=radio]:checked + .cr:before {
  border-color: #1de9b6;
}
.radio.radio-success input[type=radio]:checked + .cr:after {
  background: #1de9b6;
}
.radio.radio-warning input[type=radio]:checked + .cr:before {
  border-color: #f4c22b;
}
.radio.radio-warning input[type=radio]:checked + .cr:after {
  background: #f4c22b;
}
.radio.radio-info input[type=radio]:checked + .cr:before {
  border-color: #3ebfea;
}
.radio.radio-info input[type=radio]:checked + .cr:after {
  background: #3ebfea;
}
.radio .cr {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .radio input[type="radio"] + .cr::after {
    top: 14px;
  }
}
.custom-controls-stacked .radio input[type=radio] + .cr:after {
  top: 15px;
}

/**====== Radio & Checked css end ======**/
/**  =====================
      Label & Badges css start
==========================  **/
.label {
  padding: 4px 10px;
  min-height: auto;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}
.label.label-primary {
  background: #04a9f5;
  color: #ffffff;
}
.label.label-danger {
  background: #f44236;
  color: #ffffff;
}
.label.label-success {
  background: #1de9b6;
  color: #ffffff;
}
.label.label-warning {
  background: #f4c22b;
  color: #ffffff;
}
.label.label-info {
  background: #3ebfea;
  color: #ffffff;
}

/**====== Label & Badges css end ======**/
/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem 0.75rem;
}
.table thead th {
  border-bottom: 1px solid #eaeaea;
}
.table tbody + tbody {
  border-top: 2px solid #eaeaea;
}

/* Border versions */
.table-bordered {
  border: 1px solid #eaeaea;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #eaeaea;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #37474f;
  border-color: #222c31;
}

.table-dark {
  color: #fff;
  background-color: #37474f;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #222c31;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #334249;
}
.table-dark.table-hover tbody tr:hover {
  background-color: #2d3940;
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important;
}
@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222;
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0;
}
table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0;
}

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #eaeaea;
}
.table-columned > tbody > tr > th {
  border: 0;
}

/**====== Foo-table css end ======**/
/**====== Data Tables css end ======**/
/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: #F5F8F9 !important;
}
.auth-wrapper a,
.auth-wrapper p > a {
  color: #111;
  font-weight: 600;
}
.auth-wrapper .btn-auth-gen .btn-icon {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
}
.auth-wrapper .btn-auth-gen .btn-icon small {
  font-size: 15px;
}
.auth-wrapper .input-group {
  background: transparent;
}
.auth-wrapper .card {
  margin-bottom: 0;
}
.auth-wrapper > div {
  z-index: 5;
}
.auth-wrapper .auth-content {
  position: relative;
  width: 430px;
  padding: 15px;
  z-index: 5;
}
.auth-wrapper .auth-content.multyform, .auth-wrapper .auth-content.subscribe {
  width: 750px;
}
@media only screen and (max-width: 768px) {
  .auth-wrapper {
    max-width: 360px;
  }
}
@media only screen and (max-width: 575px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}
.auth-wrapper .auth-icon {
  font-size: 30px;
}
.auth-wrapper .auth-icon:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auth-wrapper.offline {
  background-image: none;
}
.auth-wrapper.offline:before {
  display: none;
}
.auth-wrapper .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.auth-wrapper .auth-bg .r:first-child {
  top: -100px;
  right: -100px;
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.auth-wrapper .auth-bg .r:last-child {
  left: -100px;
  bottom: -100px;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.auth-wrapper .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
.auth-wrapper .auth-bg .r.s:nth-child(2) {
  top: 150px;
  right: -150px;
  background: #04a9f5;
}
.auth-wrapper .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #1de9b6;
}
.auth-wrapper .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
          animation: floating 7s infinite;
}
.auth-wrapper .auth-bg .r:nth-child(even) {
  -webkit-animation: floating 9s infinite;
          animation: floating 9s infinite;
}



.aut-bg-img-side p {
  line-height: 2;
}

.aut-bg-img .custom-select, .aut-bg-img .form-control,
.aut-bg-img-side .custom-select,
.aut-bg-img-side .form-control {
  background: transparent;
}

.auth-tabs .tab-content {
  overflow: hidden;
  position: relative;
}
.auth-tabs .tab-content .tab-pane > * {
  position: relative;
  z-index: 5;
}
.auth-tabs .tab-content .auth-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 180px;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  z-index: 1;
  opacity: 0.2;
}

/* image varient start */
@-webkit-keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
            transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
            transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
@keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
            transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
            transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
/**====== Authentication css end ======**/
/* Button variants
 Easily pump out default styles, as well as :hover, :focus, :active,
 and disabled options for all buttons */
/**  =====================
      Button css start
==========================  **/
.btn-theme,
a.btn-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-color: #1de1c2;
  border-radius: 0.25rem;
  padding: 11px 25px;
}
.btn-theme:active, .btn-theme:focus, .btn-theme:not(:disabled):not(.disabled):active,
a.btn-theme:active,
a.btn-theme:focus,
a.btn-theme:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: none;
}
.btn-theme.active,
a.btn-theme.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}

.btn-outline-theme {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme:active, .btn-outline-theme:focus, .btn-outline-theme:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme.active {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%) !important;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 25px;
}
.btn-theme2:active, .btn-theme2:focus, .btn-theme2:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  box-shadow: none;
}
.btn-theme2.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}

.btn-outline-theme2 {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme2:active, .btn-outline-theme2:focus, .btn-outline-theme2:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme2.active {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%) !important;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: -25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn > i {
  margin-right: 12px;
}
.btn.btn-icon, .btn.drp-icon {
  width: 45px;
  height: 45px;
  padding: 10px 12px;
}
.btn.btn-icon > i, .btn.drp-icon > i {
  margin-right: 0;
}
.btn.drp-icon.dropdown-toggle:after {
  display: none;
}
.btn.drp-icon + .dropdown-menu {
  margin-left: -10px;
}
.btn:active, .btn:focus {
  box-shadow: none;
}
.btn-square {
  border-radius: 0;
}
.btn.disabled {
  cursor: not-allowed;
  opacity: 0.55;
}
.btn-rounded {
  border-radius: 30px;
}

.btn-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 6px 14px;
  font-size: 13px;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 13px 23px;
  font-size: 16px;
}

/* Alternate buttons */
.btn-primary {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:hover {
  color: #fff;
  background-color: #038fcf;
  border-color: #0386c3;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0386c3;
  border-color: #037eb6;
}

.btn-secondary {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #62747d;
  border-color: #5d6e76;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6e76;
  border-color: #57676f;
}

.btn-success {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:hover {
  color: #fff;
  background-color: #14cc9e;
  border-color: #13c095;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #13c095;
  border-color: #12b58c;
}

.btn-info {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:hover {
  color: #fff;
  background-color: #1cb4e6;
  border-color: #18acdd;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #18acdd;
  border-color: #17a3d1;
}

.btn-warning {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ecb50c;
  border-color: #e0ab0c;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #e0ab0c;
  border-color: #d4a20b;
}

.btn-danger {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22012;
  border-color: #ea1b0d;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1b0d;
  border-color: #de190c;
}

.btn-light {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #37474f;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light.disabled, .btn-light:disabled {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #37474f;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}

.btn-dark {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:hover {
  color: #fff;
  background-color: #273338;
  border-color: #222c31;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #222c31;
  border-color: #1d2529;
}

/* outline buttons */
.btn-outline-primary {
  color: #04a9f5;
  background-color: transparent;
  background-image: none;
  border-color: #04a9f5;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #04a9f5;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}

.btn-outline-secondary {
  color: #748892;
  background-color: transparent;
  background-image: none;
  border-color: #748892;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #748892;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-outline-success {
  color: #1de9b6;
  background-color: transparent;
  background-image: none;
  border-color: #1de9b6;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #1de9b6;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}

.btn-outline-info {
  color: #3ebfea;
  background-color: transparent;
  background-image: none;
  border-color: #3ebfea;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3ebfea;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}

.btn-outline-warning {
  color: #f4c22b;
  background-color: transparent;
  background-image: none;
  border-color: #f4c22b;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f4c22b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}

.btn-outline-danger {
  color: #f44236;
  background-color: transparent;
  background-image: none;
  border-color: #f44236;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f44236;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-light {
  color: #f2f2f2;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.btn-outline-dark {
  color: #37474f;
  background-color: transparent;
  background-image: none;
  border-color: #37474f;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #37474f;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}

/* glow buttons */
.btn-glow-primary {
  box-shadow: 0 1px 6px 2px rgba(4, 169, 245, 0.56), 0 6px 11px 2px rgba(4, 169, 245, 0.2);
}
.btn-glow-primary:hover {
  box-shadow: 0 1px 4px 2px rgba(4, 169, 245, 0.56), 0 4px 9px 2px rgba(4, 169, 245, 0.1);
}
.btn-glow-primary:not(:disabled):not(.disabled).active, .btn-glow-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-primary.dropdown-toggle, .btn-glow-primary.active, .btn-glow-primary:focus {
  box-shadow: none;
}

.btn-glow-secondary {
  box-shadow: 0 1px 6px 2px rgba(116, 136, 146, 0.56), 0 6px 11px 2px rgba(116, 136, 146, 0.2);
}
.btn-glow-secondary:hover {
  box-shadow: 0 1px 4px 2px rgba(116, 136, 146, 0.56), 0 4px 9px 2px rgba(116, 136, 146, 0.1);
}
.btn-glow-secondary:not(:disabled):not(.disabled).active, .btn-glow-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-secondary.dropdown-toggle, .btn-glow-secondary.active, .btn-glow-secondary:focus {
  box-shadow: none;
}

.btn-glow-success {
  box-shadow: 0 1px 6px 2px rgba(29, 233, 182, 0.56), 0 6px 11px 2px rgba(29, 233, 182, 0.2);
}
.btn-glow-success:hover {
  box-shadow: 0 1px 4px 2px rgba(29, 233, 182, 0.56), 0 4px 9px 2px rgba(29, 233, 182, 0.1);
}
.btn-glow-success:not(:disabled):not(.disabled).active, .btn-glow-success:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-success.dropdown-toggle, .btn-glow-success.active, .btn-glow-success:focus {
  box-shadow: none;
}

.btn-glow-info {
  box-shadow: 0 1px 6px 2px rgba(62, 191, 234, 0.56), 0 6px 11px 2px rgba(62, 191, 234, 0.2);
}
.btn-glow-info:hover {
  box-shadow: 0 1px 4px 2px rgba(62, 191, 234, 0.56), 0 4px 9px 2px rgba(62, 191, 234, 0.1);
}
.btn-glow-info:not(:disabled):not(.disabled).active, .btn-glow-info:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-info.dropdown-toggle, .btn-glow-info.active, .btn-glow-info:focus {
  box-shadow: none;
}

.btn-glow-warning {
  box-shadow: 0 1px 6px 2px rgba(244, 194, 43, 0.56), 0 6px 11px 2px rgba(244, 194, 43, 0.2);
}
.btn-glow-warning:hover {
  box-shadow: 0 1px 4px 2px rgba(244, 194, 43, 0.56), 0 4px 9px 2px rgba(244, 194, 43, 0.1);
}
.btn-glow-warning:not(:disabled):not(.disabled).active, .btn-glow-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-warning.dropdown-toggle, .btn-glow-warning.active, .btn-glow-warning:focus {
  box-shadow: none;
}

.btn-glow-danger {
  box-shadow: 0 1px 6px 2px rgba(244, 66, 54, 0.56), 0 6px 11px 2px rgba(244, 66, 54, 0.2);
}
.btn-glow-danger:hover {
  box-shadow: 0 1px 4px 2px rgba(244, 66, 54, 0.56), 0 4px 9px 2px rgba(244, 66, 54, 0.1);
}
.btn-glow-danger:not(:disabled):not(.disabled).active, .btn-glow-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-danger.dropdown-toggle, .btn-glow-danger.active, .btn-glow-danger:focus {
  box-shadow: none;
}

.btn-glow-light {
  box-shadow: 0 1px 6px 2px rgba(242, 242, 242, 0.56), 0 6px 11px 2px rgba(242, 242, 242, 0.2);
}
.btn-glow-light:hover {
  box-shadow: 0 1px 4px 2px rgba(242, 242, 242, 0.56), 0 4px 9px 2px rgba(242, 242, 242, 0.1);
}
.btn-glow-light:not(:disabled):not(.disabled).active, .btn-glow-light:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-light.dropdown-toggle, .btn-glow-light.active, .btn-glow-light:focus {
  box-shadow: none;
}

.btn-glow-dark {
  box-shadow: 0 1px 6px 2px rgba(55, 71, 79, 0.56), 0 6px 11px 2px rgba(55, 71, 79, 0.2);
}
.btn-glow-dark:hover {
  box-shadow: 0 1px 4px 2px rgba(55, 71, 79, 0.56), 0 4px 9px 2px rgba(55, 71, 79, 0.1);
}
.btn-glow-dark:not(:disabled):not(.disabled).active, .btn-glow-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-dark.dropdown-toggle, .btn-glow-dark.active, .btn-glow-dark:focus {
  box-shadow: none;
}

.custom-camp-table .cust-name {
  color: #2561a8;
  white-space: nowrap;
  font-weight: bolder;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}

/* ----------------------------Store Master page-------------------  */
/* ---------------------------Date : 13-10-2019-------------------- */

.breadcrumbs-row {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.settings-ticketing {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

/* chetan settings starts */
.setting-title {
  background-color: #ecf2f4;
  padding: 18px 36px !important;
}
.store-settings-cntr table {
  border-radius: 0;
}
.setting-title p {
  font-size: 20px;
  color: #000;
}
.setting-tabs {
  padding: 15px 20px;
}
.setting-tabs ul {
  border: none;
}
.setting-tabs .nav-tabs .nav-item {
  margin-right: 34px;
}
.setting-tabs .nav-tabs .nav-link {
  font-weight: 400;
  padding: 7px 0px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
.setting-tabs .nav-tabs .nav-link.active {
  border: none;
  background-color: #2561a8;
  color: #fff;
  padding: 7px 30px;
  font-weight: 500;
  font-size: 14px;
}
.setting-tabs .es .nav-link.active {
  border: none;
  background-color: #dbe4e7;
  color: #2561a8;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 14px;
}
.setting-tabs .tab-content {
  padding: 25px 0;
}
.setting-tabs .tab-content .tab-pane .row {
  margin-bottom: 20px;
}
.setting-tabs .tab-content .tab-pane .row:last-child {
  margin-bottom: 0px;
}
.setting-tabs .row > div {
  padding-right: 10px;
  padding-left: 10px;
}
.setting-tabs .row {
  margin-right: -10px;
  margin-left: -10px;
}
.setting-cntr {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
}
.setting-cntr p {
  font-size: 12px;
  margin-top: 2px;
  color: #4a4a4a;
  min-height: 72px;
}
.setting-cntr strong {
  color: #2561a8;
  font-size: 16px;
}
.setting-box {
  display: flex;
  margin-bottom: 10px;
  padding: 20px 20px 15px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.setting-box:hover {
  background-color: #f4f7fa;
}
.setting-cntr .setting-box:last-child {
  margin-bottom: 0px;
}
.setting-desc {
  padding-left: 17px;
}
.setting-icons {
  flex: 0 0 22px;
}
.icon-small {
  flex: 0 0 18px;
}
.big-icon {
  flex: 0 0 25px;
}
.setting-icons img {
  width: 100%;
}
/* chetan settings ends */

.storemaster-text {
  color: rgba(54, 54, 54, 0.85);
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

.breadcrumbs-row-padding {
  padding: 15px;
  padding-left: 35px;
}

.store-col-1 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 827px;
  height: auto;
  margin-left: 50px;
}

.store-col-2 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: auto;
  /* margin-left: 25px; */
}

/* .row{
    margin-right: 0px;
  } */

.table-data-text {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
}
.store-col-1 table tr td:last-child {
  text-align: center;
}
.store-col-1 table tr th:last-child {
  text-align: center;
}

.store-col-1 table tr td:first-child {
  text-align: center;
}
.store-col-1 table tr th:first-child {
  text-align: center;
}

.Table-action-edit-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 57px;
  height: 27px;
  min-width: auto;
  cursor: pointer;
}
.store-master table th {
  padding: 20px 16px;
}
.store-master table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 18px 20px;
}
.category-master table th {
  padding: 20px 21px;
}
.category-master table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 18px 25px;
}
.Table-action-edit-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  cursor: pointer;
}
.store-col-1 button {
  min-width: 0;
  padding: 0;
  font-size: 0;
}

.Create-store-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  /* margin-left: -20px; */
  padding-top: 20px;
  padding-bottom: 15px;
}

.store-col-2 .row {
  margin-left: 40px;
}
.store-col-2 .ro {
  margin-left: 20px;
}
.store-create-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding-left: 10px;
  color: #999;
  font-weight: 600;
  border-color: #ecf2f4;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  /* margin-left: -10px; */
  cursor: pointer;
  border: none;
}

.store-create-lable-text {
  color: #757373;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  /* margin-left: -10px; */
  margin-top: 15px;
}

.store-create-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 265px;
  height: 43px;
  margin-top: 5px;
  padding-left: 10px;
  border: none;
}
.store-create-margin {
  margin-top: none;
  /* margin-top: 20px; */
  margin-bottom: 10px;
}

.store-create-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  padding-left: 10px;
  border: none;
  width: 100%;
}
.ticketMSGCmt-textarea {
  background-color: #ffff;
  /* border-radius: 2px; */
  border: none;
  width: 100%;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  margin: 20px 0;
  padding: 10px;
  height: 150px;
}

.store-create-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 305px;
  height: 43px;
  margin-left: -20px;
  /* margin-top: 18px; */
}

.store-create-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.storeCRM-create-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 288px;
  height: 43px;
  margin-left: -10px;
  /* margin-top: 18px; */
}
.crm-margin-div {
  margin-bottom: 8px;
  margin-left: -30px;
}
.crm-padding-div {
  padding-right: 45px;
}
.cr-float-auto {
  text-align: right;
  float: right;
  margin-left: auto !important;
}
.rectangle-upload {
  background-color: #ffffff;
  border: 1px solid #99afc9;
  border-radius: 8px;
  width: 266px;
  height: 150px;
}

.upload-icon {
  width: 40px;
  height: 40px;
}

.upload-add-text {
  color: #2561a8;
}
.upload-add-text1 {
  color: #4a4a4a;
}
.store-create-oval {
  background-color: #4a4a4a;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-top: 10px;
}
.store-icons-8-delete {
  width: 18px;
  height: 18px;
}

.store-upload-details-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.store-upload-details-div {
  margin-left: 15px;
  margin-bottom: 8px;
}
.store-upload-details-div-2 {
  margin-left: 63px;
}
.store-upload-details-div-3 {
  margin-left: 25px;
}
.store-upload-details-div-4 {
  margin-left: 75px;
}

.upload-file-memory {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.file-upload-faild-text {
  color: #fa6400;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.file-upload-retry-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.file-upload-progress {
  background-color: #f5f8f9;
  border-radius: 3.5px;
  width: 215px;
  height: 7px;
}
.file-upload-progress-status {
  background-color: #2561a8;
  border-radius: 3.5px;
  width: 132px;
  height: 7px;
}
.bottom-margin-class {
  margin-bottom: 15px;
  margin-top: 15px;
}
.upload-icon-center {
  padding-left: 110px;
  padding-top: 34px;
}
.store-create-cross-icon {
  width: 13px;
  height: 10px;
}

.red-delete-icon-size {
  width: 20px;
  height: 20px;
}
.list-edit-button-margin {
  margin-left: 10px;
}

/* chetan priority settings starts */
.setting-breadcrumb a,
.setting-breadcrumb {
  color: #2561a8;
}
.setting-breadcrumb a.active {
  color: rgba(54, 54, 54, 0.85);
  cursor: context-menu;
}
.setting-breadcrumb span {
  display: inline-block;
  margin: 0 7px;
}
/* chetan priority settings ends */

/* chetan hierarchy master settings starts */
.store-settings-cntr {
  padding: 15px 20px;
}
.store-settings-cntr table th {
  font-size: 13px;
}
.store-settings-cntr table td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.store-settings-cntr table tr td:last-child,
.store-settings-cntr table tr th:last-child {
  padding-right: 50px;
}
.store-settings-cntr .butn {
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.5px;
  padding: 6px 11px;
  min-width: auto;
  cursor: pointer;
}
.del-btn {
  width: 14px;
  display: inline-block;
  margin-right: 9px;
  cursor: pointer;
  /* padding: 6px 8px; */
  line-height: 1;
  box-sizing: content-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.del-btn:hover {
  background-color: #fdeeee;
}
.del-btn img {
  width: 100%;
}
.store-settings-cntr .pagi ul li {
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 28px;
}
.store-settings-cntr .pagi {
  padding-top: 35px;
  padding-bottom: 35px;
}
.item-selection select {
  color: #4a4a4a;
  font-size: 12px;
  border: 1px solid #e7eaec;
  border-radius: 4px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASxJREFUeNq8lUFqwkAUQEdxVbpzW7B05aqXKd6goTsX7V66FIorVyUb7+BGD1APUIQKSlGkF5BSMkRM38CfkIZmGEM08DJhMvNf8v8nqSVJos5xNIIguGJsm+sTOfawMME13MEtXFYs+YZ36FnRB7SgCRcVSX7gS2LrOqcdjGACS4grkMQSy8QchWG4s3Uxsld4gxe4gXpJyQE28AxzJCZjf4KZiTncw1o2lJGsJUYqUf88tZa8Psh4OFKS7s1KVEF6tDxVV8bYsybpnrxEOepg09iHT4gckkjW9PPp8hFZ2QzGsCqQRXLPrJkVSZRHZ2Vbf5NLYyxzaQs7P0Ee+betb2rwCNcyv4UhTF1vcozIpnEqXTWQuSdXTcqKsg3Sybyp9t1cO9dv4leAAQCnZXX+jQEPIwAAAABJRU5ErkJggg==) no-repeat right 6px center;
  background-size: 9px;
  padding: 6px 14px 6px 10px;
  cursor: pointer;
}
.item-selection p {
  font-size: 12px;
  display: inline-block;
  margin-left: 7px;
}
.item-selection {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px;
}
.general-popover .popover {
  border: 0;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  max-width: none;
  max-width: initial;
}
.general-popover .popover .arrow {
  width: 26px;
  height: 18px;
  margin: 0;
}
.general-popover .popover-body {
  color: #4a4a4a;
  font-family: "Lato", sans-serif;
  padding: 25px;
}
.general-popover h4 {
  font-weight: 700;
  color: #2561a8;
  font-size: 14px;
  text-transform: uppercase;
}
.sla-popover {
  padding: 15px 16px;
  padding-right: 0;
  padding-bottom: 7px;
  min-width: 350px;
  max-height: 450px;
  overflow: auto;
}
.sla-popover::-webkit-scrollbar {
  width: 7px;
}
.sla-popover::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.sla-popover::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.sla-popover::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.sla-popover .slatargettext-1 {
  margin-bottom: 15px;
}
.sla-popover .createhead-text-1 {
  color: #2561a8;
  padding-right: 20px;
  padding-left: 0px;
  margin-bottom: 10px;
  width: 20%;
}
.sla-popover .slatemp-textpopup-1 {
  font-size: 12px;
  margin-bottom: 12px;
  padding-right: 20px;
  width: 20%;
}
.sla-popover div:not(:first-child) label:first-child,
.sla-popover div:not(:first-child) label:nth-child(2) {
  width: 30%;
}
.sla-popover-store div:not(:first-child) label:first-child {
  width: 50%;
}
.sla-popover-store div:not(:first-child) label:nth-child(2) {
  width: 20%;
}
.sla-popover-store div:not(:first-child) label:nth-child(3) {
  width: 30%;
}
.created-popover .popover-body {
  padding: 25px 35px;
}
.created-popover .popover-inner {
  pointer-events: none;
}
.created-popover .title {
  font-weight: 700;
  font-size: 14px;
}
.created-popover .sub-title {
  font-size: 13px;
  color: #9b9b9b;
  margin-top: 2px;
}
.created-popover .popover-body div {
  margin-bottom: 10px;
}
.created-popover .popover-body div:last-child {
  margin-bottom: 0px;
}
.edit-popover label {
  font-size: 12px;
  font-weight: 700;
}
.edit-popover input,
.edit-popover select {
  background-color: #ecf2f4;
  border-radius: 4px;
  min-width: 200px;
  color: #000;
  font-size: 12px;
  display: block;
  border: 0;
  padding: 8px 15px;
  margin-top: 3px;
}
.edit-popover select {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px
    center;
  background-size: 11px;
  padding-right: 40px;
}
.edit-popover .div-cntr {
  margin-top: 12px;
}
.del-big-icon {
  background-color: #f3f3f3;
  border-radius: 50%;
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 15px;
}
.del-can button {
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 100px;
}
.del-can {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.del-can a {
  color: #2561a8;
  font-size: 12px;
}
.right-sect-div {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 25px 35px;
  margin-bottom: 25px;
}
.right-sect-div-edit {
  box-shadow: none;
  box-shadow: initial;
  padding: 0;
}
.right-sect-div-edit label {
  font-size: 12px !important;
}
.right-sect-div:last-child {
  margin-bottom: 0;
}
.right-sect-div h3 {
  color: #2561a8;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 7px;
  text-transform: uppercase;
}
.right-sect-div label {
  font-weight: 700;
  font-size: 16px;
}
.right-sect-div input,
.right-sect-div select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  color: #000;
  display: block;
  border: 0;
  padding: 11px 15px;
  margin-top: 5px;
}
.right-sect-div select {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 25px
    center;
  background-size: 13px;
  padding-right: 50px;
  cursor: pointer;
}
.right-sect-div .div-cntr {
  margin-top: 12px;
}
.right-sect-div .butn {
  font-weight: 400;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  margin-top: 30px;
}
.file-upload + label {
  font-weight: 400;
  border: 1px solid #99afc9;
  border-radius: 8px;
  width: 100%;
  padding: 40px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
.file-upload + label span {
  font-weight: 700;
  color: #2561a8;
}
.file-icon {
  width: 37px;
  margin: 0 auto 13px;
}
.file-icon img {
  width: 100%;
}
.del-file {
  flex: 0 0 14px;
  line-height: 1;
  cursor: pointer;
}
.del-file img {
  width: 100%;
}
.file-dtls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.file-dtls:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #4a4a4a;
  border-radius: 50%;
  left: -20px;
  top: 8px;
}
.file-cntr {
  padding-left: 20px;
  font-size: 14px;
  margin-top: 12px;
}
.file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}
.user-profile-file-dtls {
  padding: 0 15px;
}
.user-profile-file-dtls::before {
  display: none;
}
.file-size {
  color: #9b9b9b;
}
.file-retry {
  color: #2561a8 !important;
  font-weight: 700;
}
.file-failed {
  color: #fa6400;
}
.file-progress {
  background-color: #f5f8f9;
  border-radius: 5px;
  height: 8px;
  overflow: hidden;
  width: 100%;
}
.file-progress .progress-bar {
  background-color: #2561a8;
}
.cancel-upload {
  flex: 0 0 13px;
  margin-left: 36px;
  line-height: 1;
  cursor: pointer;
}
.cancel-upload img {
  width: 100%;
}
.file-info {
  padding-bottom: 10px;
  padding-top: 13px;
}
.table-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.general-popover .bs-popover-auto[x-placement^="bottom"] .arrow::after,
.general-popover .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.general-popover .bs-popover-bottom .arrow::after,
.general-popover .bs-popover-bottom .arrow::before {
  border-width: 0 13px 18px 13px;
}
.general-popover .bs-popover-auto[x-placement^="top"] .arrow::after,
.general-popover .bs-popover-auto[x-placement^="top"] .arrow::before,
.general-popover .bs-popover-top .arrow::after,
.general-popover .bs-popover-top .arrow::before {
  border-width: 18px 13px 0;
}
.general-popover .bs-popover-auto[x-placement^="left"] .arrow::after,
.general-popover .bs-popover-auto[x-placement^="left"] .arrow::before,
.general-popover .bs-popover-left .arrow::after,
.general-popover .bs-popover-left .arrow::before {
  border-width: 13px 0 13px 18px;
}
.general-popover .bs-popover-auto[x-placement^="right"] .arrow::after,
.general-popover .bs-popover-auto[x-placement^="right"] .arrow::before,
.general-popover .bs-popover-right .arrow::after,
.general-popover .bs-popover-right .arrow::before {
  border-width: 13px 18px 13px 0;
}
.general-popover .bs-popover-auto[x-placement^="bottom"] .arrow,
.general-popover .bs-popover-bottom .arrow {
  top: -19px;
}
.general-popover .bs-popover-auto[x-placement^="top"] .arrow,
.general-popover .bs-popover-top .arrow {
  bottom: -19px;
}
.general-popover .bs-popover-auto[x-placement^="left"] .arrow,
.general-popover .bs-popover-left .arrow {
  right: -19px;
}
.general-popover .bs-popover-auto[x-placement^="right"] .arrow,
.general-popover .bs-popover-right .arrow {
  left: -19px;
}
.general-popover .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.general-popover .bs-popover-bottom .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.general-popover .bs-popover-auto[x-placement^="top"] .arrow::before,
.general-popover .bs-popover-top .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.general-popover .bs-popover-auto[x-placement^="left"] .arrow::before,
.general-popover .bs-popover-left .arrow::before {
  border-left-color: rgba(0, 0, 0, 0.1);
}
.general-popover .bs-popover-auto[x-placement^="right"] .arrow::before,
.general-popover .bs-popover-right .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.1);
}
.general-popover .bs-popover-auto[x-placement^="bottom"],
.general-popover .bs-popover-bottom {
  margin-top: 24px;
}
.general-popover .bs-popover-auto[x-placement^="top"],
.general-popover .bs-popover-top {
  margin-bottom: 24px;
}
.general-popover .bs-popover-auto[x-placement^="left"],
.general-popover .bs-popover-left {
  margin-right: 24px;
}
.general-popover .bs-popover-auto[x-placement^="right"],
.general-popover .bs-popover-right {
  margin-left: 24px;
}
/* chetan hierarchy master settings ends */

/* chetan item master settings starts */
.item-settings-cntr table th,
.item-settings-cntr table td {
  padding-right: 8px;
  padding-left: 8px;
}
.item-settings-cntr table tr td:last-child,
.item-settings-cntr table tr th:last-child {
  padding-right: 20px;
  text-align: left;
}
.item-settings-cntr table tr td:first-child,
.item-settings-cntr table tr th:first-child {
  padding-left: 20px;
}
.down-excel a {
  line-height: 1;
}
.down-excel p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.down-excel {
  display: flex;
  align-items: flex-end;
}
/* chetan item master settings ends */

/* chetan users settings starts */
.users-popover .sub-title {
  color: #4a4a4a;
  margin: 0;
  flex: 0 0 65%;
}
.users-popover .title {
  color: #000;
  flex: 0 0 35%;
}
.users-popover .popover-body {
  max-width: 580px;
  display: flex;
  flex-wrap: wrap;
}
.users-popover .popover-body div {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 0;
}
.users-popover .popover-body div:nth-child(odd) {
  padding-right: 35px;
}
.users-popover .popover-body div:nth-child(even) {
  padding-left: 35px;
}
.collapse-title {
  font-weight: 700;
  color: #4a4a4a;
  font-size: 16px;
  padding: 11px 20px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 40px center;
  background-size: 13px;
  padding-right: 65px;
  margin: 0 25px;
  position: relative;
}
.collapse-title:hover {
  color: inherit;
}
.collapse-title::after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #f4f4f4;
  left: -25px;
  right: 0;
  bottom: 0;
  width: calc(100% + 50px);
}
.collapse-title[aria-expanded="true"] {
  background-color: #f3f3f3;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO9JREFUeNq8lVELgjAUhZtX56yetHqpRyH6///L6KEopNYdHGONdJuJFw4TlPtt5x6m0Fov5igx4vsEzy9W8C4pEpKzTqwd68J6Tg0ykDWrZm1YS1bBurHaqUDGqop1wErW6SQsvPtspABICatKNLbfZVi9MPLYtcJJSpzArRRws16HbCTP4GvYJQc2ZCAK6g0I9UAypKuKmGMBBxpYOQgS2GEHSSLjr5DIxp0X/bDryNpGQuweBWBfNpIT4T0g9Odto2C/7tJIToR9gw+tBH0+0U+tGEvEs53wLpXofe6Gn4+4YEPL2PcQc/0m3gIMADqhKR/uzSU8AAAAAElFTkSuQmCC);
  font-size: 16px;
}
.collapse-title[aria-expanded="true"]::after {
  background-color: transparent;
}
.right-sect-collapse .div-cntr {
  padding: 0 45px;
}
.right-sect-collapse {
  padding-right: 0;
  padding-left: 0;
}
.right-sect-collapse h3 {
  padding-left: 45px;
  margin-bottom: 10px;
}
.btn-coll {
  padding: 0 45px 25px;
}
.mapped-cate-extra {
  border-top: 1px solid #f4f4f4;
  margin-top: 20px;
}
.right-sect-collapse .collapse-cntr:last-child .collapse-title::after {
  display: none;
}
.right-sect-collapse .collapse-cntr:last-child .btn-coll {
  padding-bottom: 5px;
}
.escalation-options input {
  display: none;
}
.escalation-options .filter-checkbox input + label::before {
  border: 2px solid #1a499a;
}
.escalation-options .filter-checkbox input:checked + label:before {
  background-color: #1a499a;
}
.escalation-options .filter-checkbox input + label::after {
  left: 5px;
  width: 6px;
  height: 10px;
}
.escalation-options .filter-checkbox input + label {
  padding-left: 27px;
  color: #202020;
  cursor: pointer;
}
.escalation-options .filter-checkbox {
  padding-top: 10px;
}
.escalation-options {
  padding-top: 5px !important;
}
.escalation-options .logout-label {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  text-transform: initial;
  color: #5a5a5a;
}
.escalation-options .status-options input + label::before {
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-color: #5a5a5a;
}
.escalation-options .status-options input + label {
  padding-left: 25px;
  cursor: pointer;
}
.sup-agent-cntr .status-options {
  display: inline-block;
  margin-left: 35px;
}
.sup-agent-cntr .status-options:first-child {
  margin-left: 0px;
}
.sup-agent-cntr {
  padding-left: 25px;
  padding-top: 15px;
}
.escalation-options .status-options input:checked + label::before {
  border-color: #2561a8;
  background-color: #fff;
}
.escalation-options .status-options input:checked + label::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #2561a8;
  left: 3px;
  top: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.escalation-options .status-options input:checked + label {
  color: #2561a8;
}
.user-desig-pop {
  min-width: 650px;
  padding: 10px 20px;
}
.user-desig-pop .col-md-6 {
  display: flex;
  margin-bottom: 15px;
}
.user-desig-pop .row:last-child .col-md-6 {
  margin-bottom: 0px;
}
.user-desig-pop .col-md-6 p {
  flex: 0 0 160px;
}
/* chetan users settings ends */

.breadcrumbs-row-padding {
  padding: 15px;
  padding-left: 35px;
}
/* .row{
  margin-right: 0px;
} */
/* --------------------------------------------------------------- */

/* ------------------------Shlok Ticket Hierarchy css start------------ */
/* --------------------------Date : 13-09-2019 ----------------------- */
.mainDivPadding {
  padding-bottom: 25px;
}
.lblHierarchy {
  color: rgba(54, 54, 54, 0.85);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.maskTableHierarchy {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 827px;
  height: auto;
  margin-left: 50px;
}
.maskTableHierarchy table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 0px 30px;
}
/* .trash-button-hierarchy{
  margin-top: -15px;
} */
.createHierarchyMask {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  /* margin-left: 25px; */
}
.rectangle-copy-3 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  /* margin-left: 25px; */
}
.hod-manager-team-lea {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
}
.root-hod-manager-tea {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
}
.admin-admin-admin-ad {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
}
.enabled-enabled-enab {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 66px;
  text-align: left !important;
}
.heightpagination {
  text-align: center;
  padding: 25px;
  margin-top: 250px;
}
.pagination {
  margin-left: 214px;
  padding: 25px;
  margin-top: 380px;
}
.pagination ul {
  border: 1px solid #eceff0;
  display: inline-block;
}
.pagination ul li {
  font-size: 16px;
  width: 37px;
  height: 37px;
  line-height: 35px;
  border: 1px solid #eceff0;
  text-align: center;
}
.pagination ul li a {
  color: #747779;
}
.pagination ul li.active {
  border-color: #d3d3d3;
  box-shadow: inset 0px 0px 5px 0px #d3d3d3;
}
.deleteImg {
  opacity: 0.8718843005952381;
  width: 16px;
  height: 18px;
  margin-right: 15px;
}
.btnEditAction {
  background-color: #2561a8;
  border-radius: 4px;
  width: 57px;
  height: 27px;
  min-width: 0px !important;
  padding-top: 0px;
  cursor: pointer;
}
.edit {
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}
.maskTableHierarchy button {
  min-width: 0;
  padding: 0;
  font-size: 0;
}
.create-department {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 750;
  line-height: 22px;
  text-align: left;
  /* margin-left: -15px; */
  padding-top: 20px;
  padding-bottom: 15px;
}
.designation-name {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-bottom: 10px;
}
.reports-to {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-bottom: 10px;
}
.reports-dis {
  display: block;
}
.txt-1 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
}
.createSpace {
  padding: 0px 40px 40px;
}
.divSpace {
  padding-bottom: 10px;
}
.divSpace-3 {
  padding-bottom: 8px;
  padding-top: 5px;
}
.div-padding-1 {
  padding-bottom: 10px;
}
.dropdown-setting {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  border: none;
  cursor: pointer;
}
.dropdown-setting1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding: 10px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 5px center;
  border: none;
  cursor: pointer;
}
.CreateADDBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  cursor: pointer;
  color: #ffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.addBtn-ticket-hierarchy {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.addLable {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.btnSpace {
  margin-top: 20px;
}
.bulk-upload {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 15px;
}
.rectangleBox-1 {
  background-color: #ffffff;
  border: 1px solid #99afc9;
  border-radius: 8px;
  width: 266px;
  height: 150px;
}
.clipImgMargin {
  margin-top: 40px;
  margin-left: 108px;
}
.add-file-or-drop-fil {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-left: 40px;
}
.span-DropFile {
  color: #575a5d;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.blackDot {
  background-color: #4a4a4a;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.chat-agent-user-type {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 8px;
}
.rectangle-copy-3 .row {
  margin-top: 15px;
  margin-left: 0px;
}
.lbl122-6-kb {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 15px;
}
.icons-8-delete {
  width: 18px;
  height: 18px;
  margin-left: 75px;
}
.failed {
  color: #fa6400;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 15px;
}
.retry {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 85px;
}
.ProgressBar {
  background-color: #2561a8;
  border-radius: 3.5px;
  width: 100px;
  height: 7px;
}
.mainProgress {
  background-color: #f5f8f9;
  width: 70%;
  border-radius: 6px;
  margin-top: 10px;
  margin-left: 3px;
}
.cancelIcon {
  margin-left: 250px;
  margin-top: -35px;
}
/* --------------Css end-------------------------- */

/* ---------------------------------Category Master ------------------------- */
.category-master-table table tr td:first-child {
  padding-right: 60px !important;
}
/* .delete-combined-shape{
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  width: 277px;
  height: 170px;
} */

.bottom-margin-class .row {
  margin-right: 0;
}

.oval-delete {
  background-color: #f3f3f3;
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.oval-delete-icons {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  margin-top: 10px;
}
.delete-file-popover {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: 77px;
  text-align: left;
}

.delete-details-popovel {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 165px;
  text-align: left;
}
.popver-delete-mar {
  margin-top: 20px;
  margin-left: 20px;
}
.popover-delete-btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 98px;
  height: 30px;
  margin-right: 21px;
  padding: 0;
}
.popover-delete-btn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.popover-delete-cancel {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: 10px;
  margin-right: 5px;
}
.btn-del-pop button {
  padding: 0;
}
.mrg-can-del-row {
  margin-top: 10px;
  float: right;
}

.deletepopover .popover {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  -webkit-transform: translate3d(603px, 42px, 0px);
          transform: translate3d(603px, 42px, 0px);
  max-width: none;
  max-width: initial;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  width: 277px;
  height: 170px;
  border: none;
}

.abc .popover {
  max-width: none;
  max-width: initial;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  -webkit-transform: translate3d(603px, 42px, 0px);
          transform: translate3d(603px, 42px, 0px);
  max-width: initial;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  width: 250px;
  height: 340px;
  border: none;
}
/* .abc .popover-body {
  width: 500px;
} */

.pop-edit-category-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.popover-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 200px;
  height: 35px;
}
.popover-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.popover-header-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-bottom: 5px;
}

.popover-lable-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
  margin-top: 10px;
  margin-bottom: 7px;
  margin-left: 5px;
}

.popover-select-cate {
  border-radius: 4px;
  width: 200px;
  height: 35px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}

.bottom-margin-class .store-col-1-1 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 827px;
  height: 898px;
  margin-left: 50px;
}

.info-icon-cp {
  position: relative;
  left: 5px;
  bottom: 1px;
  width: 16px;
  height: 16px;
}

.cp-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 285px;
  height: 43px;
  border: none;
  margin-top: 5px;
  padding-left: 10px;
  margin-left: -10px;
}
.cp-textbox::-webkit-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.cp-textbox:-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.cp-textbox::-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.cp-textbox::placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.crm-pagi .pagi {
  padding-top: 70%;
}

.roll-radio [type="radio"]:checked,
.roll-radio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.roll-radio [type="radio"]:checked + label,
.roll-radio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;

  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 5px;
}
.roll-radio [type="radio"]:checked + label:before,
.roll-radio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #2561a8;
  border-radius: 100%;
  background: #fff;
}
.roll-radio [type="radio"]:checked + label:after,
.roll-radio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #2561a8;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #2561a8;
}

.roll-radio [type="radio"]:checked + label {
  color: #2561a8;
}
.roll-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.roll-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.rolle-name-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  width: 135px;
  display: inline-block;
  margin-left: 40px;
}
.storeRole-name-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  display: inline-block !important;
  margin-left: 32px;
}
.ordSttd-store {
  width: auto !important;
}
.Create-role-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}
.StoreCRM-role-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: -10px;
}

.store-col-1-1 .pagi {
  padding-top: 55%;
}

.ctr-mst .pagi {
  padding-top: 72%;
}

/* ------------------------------------end------------------------------------ */
/* Shlok File Upload Logs css start */
.fileUploadTable {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  height: auto;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: -10px;
}
.lblFileUpload {
  color: #6b6c6d;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.downloadBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 76px;
  height: 30px;
  padding: 0px !important;
  min-width: 85px;
  cursor: pointer;
  color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.thRowSpace {
  width: 20%;
}
.created-by-Date {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-bottom: 15px;
}
.subCreated-Date {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.popover-main {
  padding: 20px;
  padding-left: 25px;
  min-width: -8%;
  width: 400px;
  height: 110px;
}
/* File Upload Logs Css End */

/* Shlok Css start for Ticket Heirarchy */
.edit-user-action {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-bottom: 15px;
}
.edit-label-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 5px;
}
.qaus .edit-label-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
}
.edit-dropDwon {
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  font-size: 14px;
  height: 43px;
  padding: 10px;
  padding-left: 15px;
}
.txt-edit-popover {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border: none;
  padding: 7px 10px !important;
}
.txt-edit-popoverchat {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border: none;
  padding: 7px 10px !important;
  margin-bottom: 15px;
}
.cancle-action {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.action-drop-down-status {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 200px;
  height: 35px;
}
.position-relative1 {
  position: relative !important;
  margin-top: 100px;
}
/* Css End */
/* ----------------------------------------------edit category----------------------------- */

.pop-over-lbl-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-bottom: 7px;
  display: block;
  margin-top: 10px;
}
.pop-over-div {
  display: block;
}

.pop-over-text {
  border-radius: 4px;
  width: 200px;
  height: 35px;
  border: none;
  padding: 15px;
  background: #ecf2f4;
}
.pop-over-select {
  border-radius: 4px;
  width: 200px;
  height: 35px;
  border: none;
  padding: 7px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  cursor: pointer;
}

.pop-over-cancle {
  color: #2561a8 !important;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  margin: 12px 0 0 0;
  cursor: pointer;
  display: inline;
}

.pop-over-button {
  background-color: #2561a8;
  border-radius: 4px;
  min-width: 75px;
  height: 30px;
  float: right;
  margin-left: 20px;
  line-height: 0;
  padding: 5px 10px;
  cursor: pointer;
  color: #ffffff;
}

.pop-over-btnsave-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
}
.btn-rm-p button {
  min-width: 0;
  padding: 0;
}

.cp-table table tr td:first-child {
  width: 50px;
  text-align: center;
  padding-right: 0px !important;
}
.braille-icon {
  padding-top: 10px;
}
.list-edit-button-margin-1 {
  margin-left: 15px;
  display: inline-block;
}

.cp-table table tr td:last-child,
.cp-table table tr th:last-child {
  padding-right: 50px;
}

.item-selection {
  position: absolute;
  top: 47%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px;
}

.store-col-1-1 .item-selection {
  position: absolute;
  top: 91%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px;
}

.store-col-1 .item-selection {
  position: absolute;
  top: 93%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px;
}

.pop-over-text ::-webkit-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.pop-over-text :-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.pop-over-text ::-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.pop-over-text ::placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.crm-pagi table tr td:first-child {
  text-align: left !important;
}

/* .crm-pagi table tr td:last-child, .crm-pagi table tr th:last-child {
  padding-right: 50px;
} */

.new-table table thead th {
  border-bottom: 1px solid #eaeaea;
  padding-right: 30px;
  padding-left: 50px;
}
.new-table table td {
  padding-left: 50px;
}
.new-table table tr th:first-child {
  text-align: left !important;
  padding-right: 0 !important;
}
.new-table table tr td:first-child {
  text-align: left !important;
}

.new-table .pagi {
  padding-top: 71%;
}

.rolle-name-text-popover {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  width: 135px;
  display: inline-block;
}

.pop-over-select-crm {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border: none;
  padding-left: 5px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}

.pop-over-text-crm {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border: none;
  padding-left: 5px;
  background: #ecf2f4;
}

.pop-over-lbl-text-pop {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-bottom: 7px;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
}

.rvmmargin {
  margin-bottom: -10px !important;
}

.store-crm-roles .rvmmargin {
  margin: 12px 0 !important;
  display: flex;
  align-items: baseline;
}
.store-crm-roles .pop-over-lbl-text-pop {
  margin: 0;
  margin-left: 15px;
}

.role-info .popover-body {
  margin-bottom: 10px;
}
.sla-table table tr td:first-child,
.sla-table table tr th:first-child {
  padding-left: 50px !important;
  padding-right: 0px !important;
  text-align: left;
}

.store-col-2 .right-sect-div {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 5px 40px 25px;
}
.sla-table .pagi {
  padding-top: 105%;
}

.sla-table .item-selection {
  position: absolute;
  top: 95%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px;
}

.slatargetRow {
  /* margin-left: 20px; */
  margin-bottom: 20px;
}

.slatargettext {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.slatargetRow .row {
  margin-left: 20px;
}

.slatargetRow-1 {
  /* margin-left: 20px; */
  margin-top: 10px;
}
.slatargetRow-1 .row {
  margin-left: 5px;
}
.slatargetRow-3 {
  margin-left: 24px;
  margin-top: 10px;
}
.ma {
  margin-left: 0px;
}
.createhead-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  width: 25%;
  display: inline-block;
  vertical-align: top;
}

.createhead-text-new {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  /* width: 20%;
  display: inline-block;
  vertical-align: top; */
}
.createhead-cus {
  width: 20%;
}
.createhead-text-5 {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  width: 25%;
  display: inline-block;
  margin-top: 20px;
  margin-left: 80px;
}
.createhead-text-6 {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  width: 25%;
  display: inline-block;
  margin-top: 20px;
  margin-left: 5px;
}
.createhead-text-new-1 {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  width: 20%;
  display: inline-block;
}
.createhead-text-1-1 {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding-right: 13px;
  display: inline-block;
}

.createhead-text-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  /* padding-right:8px; */
  padding-left: 8px;
  display: inline-block;
}

.text-box-crt-sla {
  width: 100%;
  border: none;
  border: 0;
  /* font-size: 12px; */
  /* width: 40px; */
  text-align: left;
  /* padding: 5px 2px; */
  padding: 5px;
  border: 1px solid #9b9b9b;
  border-radius: 5px 0 0 5px;
  color: #4a4a4a;
  font-weight: 700;
}
.text-box-crt-sla::-webkit-input-placeholder {
  font-size: 10px;
  color: #ccc;
  padding: 0;
}
.text-box-crt-sla:-ms-input-placeholder {
  font-size: 10px;
  color: #ccc;
  padding: 0;
}
.text-box-crt-sla::-ms-input-placeholder {
  font-size: 10px;
  color: #ccc;
  padding: 0;
}
.text-box-crt-sla::placeholder {
  font-size: 10px;
  color: #ccc;
  padding: 0;
}

.sla-div {
  width: 20%;
  display: inline-block;
}

.slabreach {
  display: flex;
  border: 1px solid;
  /* border-radius: 0px; */
  display: inline-block;
}

.sla-div-1 {
  border: 0px solid #9b9b9b;
  display: inline-block;
  width: 23%;
  /* margin: 0 20px; */
}
.sla-div-3 {
  border: 0px solid #9b9b9b;
  display: inline-block;
  width: 25%;
  margin: 5px 15px;
  margin-left: 32px;
}
.sla-div-4 {
  border: 0px solid #9b9b9b;
  display: inline-block;
  width: 25%;
  margin: 6px 20px;
  margin-left: 60px;
}
.sla-div-5 {
  /* display: flex; */
  border: 0px solid #9b9b9b;
  /* border-radius: 0px; */
  display: inline-block;
  width: 25%;
  margin: 0px 0px;
}

.inner-div {
  font-size: 10px;
  width: 32px;
  display: inline-block;
  border: 0;
}
.inner-div-2 {
  font-size: 10px;
  display: inline-block;
  width: 35px;
}

.inner-div-2-1 {
  font-size: 10px;
  display: inline-block;
  width: 32px;
}
.store-SLATemplate-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-top: 5px;
  padding-left: 10px;
  margin-left: -18px;
  border-color: #ecf2f4;
  border: none;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}

.createhead-text-2 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 20%;
}

.slatargettext-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.pagin-sort .pagi {
  padding-top: 137%;
}
.pagin-sort .item-selection {
  padding-top: 10%;
}

.pers-lable {
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  font-weight: 700;
  border: 1px solid #9b9b9b;
  border-left: 0;
  border-radius: 0px 5px 5px 0px;
  color: #4a4a4a;
}

.pers-lable-select {
  padding: 5px 5px;
  display: inline-block;
  width: 100%;
  border: 1px solid #9b9b9b;
  border-left: 0;
  border-radius: 0px 5px 5px 0px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 5px center;
  background-size: 11px;
  color: #4a4a4a;
}

.category-multiple-cm {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  width: 265px;
  height: 335px;
}
.searchbox-cat-cm {
  background-color: #f9f9f9;
  border: 1px solid #dfdfdf;
  width: 245px;
  height: 44px;
  margin-left: 10px;
  margin-top: 5px;
}
.searchtext-cm {
  background-color: #f9f9f9;
  border: none;
  padding: 10px;
  width: 80%;
  height: 88%;
  margin-top: 2px;
  margin-left: 5px;
}
.searchtext-cm ::-webkit-input-placeholder {
  color: #898989;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 48px;
  text-align: left;
}
.searchtext-cm :-ms-input-placeholder {
  color: #898989;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 48px;
  text-align: left;
}
.searchtext-cm ::-ms-input-placeholder {
  color: #898989;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 48px;
  text-align: left;
}
.searchtext-cm ::placeholder {
  color: #898989;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 48px;
  text-align: left;
}
.search-add {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  width: 37px;
  text-align: left;
}
.store-create-button1 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-left: -17px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.admin-create-lable-text {
  color: #b3b3b3;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-left: -15px;
}
.claim-create-lable-text {
  color: #6b6969;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.table-priority table th {
  /* font-size: 12px; */
  padding: 20px 25px;
}
.table-priority {
  overflow: auto;
  max-height: 444px;
}
.table-priority::-webkit-scrollbar {
  width: 7px;
}
.table-priority::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.table-priority::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.table-priority::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.claim-tableData table th {
  font-size: 12px;
  padding: 20px 18px;
}
.claim-tableData table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 18px 20px;
}
.esc {
  padding: 9px 10px;
}
.esc1 .nav-tabs .nav-link {
  margin-right: -10px;
}
.mlistbtn {
  float: right;
  display: flex;
}
.mlistbtn button {
  margin-left: 5px;
  margin-bottom: 5px;
}
.mtictab table th {
  padding: 16px 25px;
  font-size: 13px;
}
.mtictab table span {
  font-size: 10px !important;
}
.claim-tableData .rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  color: #a0a0a0 !important;
}
/* template React Table css start by Shlok */
.template-table .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 26px !important;
}
/*  template React Table css end*/
/* Claim category master page css start by shlok */
.claim-padding {
  padding: 12px;
}
.header-path {
  font-weight: 600;
  font-size: 14px;
}
/* Claim category master page css end by shlok */

/* StoreModule css Start by Shlok */
.Store-paddmodule {
  background-color: #f5f8f9;
  /* padding: 30px 35px; */
  padding: 5px 25px;
}
.claimtab-lbl {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #2561a8;
  margin-bottom: 15px;
}
.claimTab-DDl {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  line-height: 30px;
  display: grid;
}
.claim-mgn {
  margin-top: -18px;
}
.storeModule .module-tabs .tab-content {
  padding: 22px 0px 0;
  box-shadow: none;
  background: #fff;
  /* height: 100vh; */
}
.store-mdl {
  margin-top: -22px;
}
.tab-content .backNone {
  background: #f5f8f9;
}
.btn-store {
  margin-bottom: 30px;
}
.store-popDiv {
  padding: 15px;
}
.storePop-lbl {
  font-weight: 500;
  max-width: 250px;
}
.campaign-script-less {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.stort-textArea {
  display: flex;
  background: #ecf2f4;
  border: none;
  border-radius: 5px;
  resize: none;
  width: 100%;
  padding: 10px 0px 0px 15px;
  font-weight: 600;
}
.storeImg-dwn {
  margin-top: -32px;
}
/* StoreModule css End by Shlok */
/* Module Css Start Kalpesh */
.module-tabs .nav-tabs .nav-link.active {
  color: #2561a8;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: none;
  padding: 10px 30px;
  font-size: 16px;
  font-family: lato;
  text-align: center;
}
.module-tabs .nav-tabs .nav-link {
  color: #8e8e8e;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 30px;
  border: 1px solid #ededed;
  background-color: #f3f3f3;
  font-family: lato;
  /* width: 180px; */
  /* width: 190px; */
  /* width: 260px; */
  text-align: center;
  white-space: nowrap;
  min-width: 150px;
}
.cmpaign-channel-table {
  margin-top: 20px;
}
.cmpaign-channel-table select {
  background-color: #fff;
  border: 1px solid #9b9b9b;
  padding: 5px 7px;
  font-size: 12px;
  background-position: center right 5px;
  background-size: 10px;
  margin: 0;
  padding-right: 18px;
}
.cmpaign-channel-table input {
  background-color: #fff;
  border: 1px solid #9b9b9b;
  padding: 5px 7px;
  margin: 0;
  width: 50px;
}
.cmpaign-channel-table td {
  padding: 5px 0;
  color: #4a4a4a;
  vertical-align: top;
  padding-right: 15px;
}
.cmpaign-channel-table tr {
  border: 0;
}
.cmpaign-channel-table tr td:last-child {
  min-width: 65px;
  padding-right: 0;
}
.edit-slot .right-sect-div {
  box-shadow: none;
  padding: 0;
  display: block;
  margin-top: -5px;
}
.edit-slot .right-sect-div label {
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 7px;
}
.slotEditModal {
  width: 800px;
  min-width: 800px;
  box-shadow: 0 0 15px 0px #ddd;
  border-radius: 20px;
}
.slotEditModal .modal-content {
  border-radius: 20px;
}
.slotEditModal .edtpadding {
  padding: 25px 50px;
}
.slotEditModal .popover-header-text {
  color: #333 !important;
  font-size: 20px;
  margin-bottom: 15px;
}
.slotEditModal .edit-slot li {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
}
.slotEditModal .edit-slot li label {
  color: #888;
  width: 160px;
}
.slotEditModal .edit-slot label {
  color: #888;
}
.slotEditModal select {
  background: #ecf2f4
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=)
    no-repeat right 25px center;
  background-size: 13px;
  padding-right: 50px;
  cursor: pointer;
}
.slotEditModal .edit-slot li span {
  color: #333;
}
.slotEditModal .edittabs {
  margin-bottom: 15px;
}
.slotEditModal .edittabs input {
  width: 50px;
  height: 30px;
}
.shipping-template-edit .slot-hour {
  width: 80px !important;
}
.edit-slot .slot-timings {
  margin: 0;
}
.edit-slot .slot-setting-options input {
  width: 100%;
}
.slot-setting-options {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.slot-setting-options input {
  width: 75px;
  font-size: 13px;
}
.slot-setting-options input::-webkit-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
}
.slot-setting-options input:-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
}
.slot-setting-options input::-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
}
.slot-setting-options input::placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
}
.slot-timings {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
.slot-hour {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.slot-shift {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 50px !important;
}
.slot-to {
  font-size: 12px;
  margin: 0 10px;
}
.module-switch-cntr .module-switch {
  margin-top: 10px;
}
.module-tabs .nav-tabs {
  border: none;
}
.module-tabs .tab-content {
  padding: 22px 0px 0px;
  box-shadow: none;
  background: #fff;
}
.paddmodule {
  background-color: #f5f8f9;
  /* background-color: #f5f8f9; */
  padding: 40px 35px;
}
.moduleswitchtext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  line-height: 42px;
  width: 200px;
  display: inline-block;
  margin-left: 65px;
}
.moduleswitchtext-main {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  line-height: 42px;
  width: 200px;
  display: inline-block;
  margin-left: 65px;
}
.moduleswitchtext-main1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  width: 180px;
  display: inline-block;
  margin-left: 95px;
}
.chatparent-tabs .nav-tabs .nav-link.active {
  color: #2561a8;
  background-color: #fff;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  padding: 10px 0px;
  font-size: 16px;
  font-family: lato;
  text-align: center;
}
.chatparent-tabs .nav-tabs .nav-link {
  color: #343434;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0px;
  background-color: #fff;
  font-family: lato;
  /* width: 165px; */
  text-align: center;
  border: none;
}
.modulereact .modulereactbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 90px;
  height: 27px;
  min-width: auto;
  padding: 0px !important;
}
.modulereact .ReactTable .rt-tbody .rt-td {
  color: #9b9b9b !important;
}
.modulereact .ReactTable {
  border: none !important;
}
.modulereact .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.chatallowed {
  margin-left: 30px;
}
.chatallowed select {
  border-radius: 4px;
  width: 270px;
  height: 40px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  margin-bottom: 25px;
}
.chatallowedStore select {
  margin-bottom: 0;
}
.chatallowedStore .row {
  margin-bottom: 25px;
}
.chatagent {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  line-height: 30px;
}
.modulereactShortcut .ReactTable .rt-tbody .rt-td {
  color: #9b9b9b !important;
}
.modulereactShortcut .ReactTable {
  border: none !important;
}
.status-shortcut input + label {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.logout-label1 {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.shortcut-option input + label::before {
  content: "";
  position: absolute;
  background-color: #fff;
  border: 1px solid #2561a8;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 0;
  top: 7px;
}
.shortcut-option input:checked + label::before {
  background-color: #2561a8;
  border: double 3px #fff;
}

.shortcut-option input + label {
  position: relative;
  padding: 5px 20px;
}
.notificationmodule {
  margin-left: 30px;
}
.noti {
  font-size: 20px;
  color: #2561a8;
  font-weight: 600;
  font-family: lato;
  text-align: left;
  border-bottom: 1px solid #2561a8;
  width: 100%;
}
.noti-text {
  font-family: lato;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-top: 25px;
  line-height: 20px;
}
.noti-text1 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  font-family: lato;
  text-align: left;
  margin-top: 15px;
}
.noti-text2 {
  font-family: lato;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 20px;
}
.noti-button1 {
  background-color: transparent;
  border-radius: 4px;
  width: 160px;
  height: 43px;
  cursor: pointer;
  margin-right: 35px;
}
.noti-button-text1 {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.noti-button2 {
  background-color: transparent;
  border-radius: 4px;
  width: 160px;
  height: 43px;
  cursor: pointer;
  margin-right: 35px;
}
.noti-button-text2 {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.noti-button3 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 160px;
  height: 43px;
  cursor: pointer;
}
.noti-button-text3 {
  color: #fff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.mess1 input + label {
  padding-left: 22px !important;
  margin-left: -8px !important;
}
.shortcutarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 140px;
  border: none;
  padding: 10px;
}
.insertshortcut1 {
  margin-bottom: 40px;
  padding-right: 45px;
}
.insertshortcut {
  color: #2561a8;
  font-family: lato;
  font-weight: 500;
  font-size: 16px;
  float: right;
  cursor: pointer;
}
.dropDrownSpace1 {
  padding-right: 50px;
}
.txt-12 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
  margin-bottom: 15px;
}
.backnonemodule {
  background-color: #f5f8f9;
}
.minuscircleoption {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -22px;
  margin-top: 12px;
}
.pluscircleoption {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -50px;
  margin-top: 12px;
}
.insertpop {
  display: grid;
  margin-bottom: 20px;
}
.insertpop1 {
  padding: 20px;
}
.new-insertpop1 {
  padding: 10px;
}
.ticketreport .ticketreportdat input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  border: 0;
  background: #ecf2f4 url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
  color: #4a4a4a;
}
.ticketreport .react-datepicker-wrapper {
  width: 100%;
}
.ticketstrReport .ticketreportdat input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  border: 0;
  background: #ecf2f4 url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
  color: #4a4a4a;
}
.ticketstrReport .react-datepicker-wrapper {
  width: 100%;
}
.ticketstrReport .react-datepicker__navigation--previous {
  min-width: auto;
}
.ticketstrReport .react-datepicker__navigation--next {
  min-width: auto;
}
.ticketreport .react-datepicker__navigation--previous {
  min-width: auto;
}
.ticketreport .react-datepicker__navigation--next {
  min-width: auto;
}
.reportpad .borderbottom {
  border-bottom: 1px solid #ecf2f4;
  margin: 10px -40px;
}
.setmainscreen {
  font-weight: bold !important;
  font-size: 19px;
  color: #000;
}
.QaAudit .tab-content {
  padding-top: 0;
}

.alignradio .status-options input + label::before {
  content: "";
  position: absolute;
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  left: 0;
  top: 6px;
}
.alignradio .status-options input + label {
  position: relative;
  padding-left: 15px;
  margin-top: 15px;
}
.popgrid ul li {
  display: block;
}
.dropDrownSpace .ant-select-selection--single {
  background-color: #ecf2f4;
}
.tickhierpad table th {
  padding: 15px 20px;
}
/* Module Css End */
/* Setting ReactTable Css Start Kalpesh */
.TicketStoreReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 8px !important;
}
/* .TicketStoreReact .ReactTable .rt-tbody .rt-td {
  padding: 18px 8px !important;
} */
.TicketStoreReact .ReactTable .rt-table::-webkit-scrollbar {
  height: 7px;
}
.TicketStoreReact .ReactTable .rt-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.TicketStoreReact .ReactTable .rt-table::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.TicketStoreReact .ReactTable .rt-table::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.TicketCategoyMasReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 10px !important;
}
/* .TicketCategoyMasReact .ReactTable .rt-tbody .rt-td {
  padding: 18px 10px !important;
} */
.StoreItemMasterReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 5px !important;
}
/* .StoreItemMasterReact .ReactTable .rt-tbody .rt-td {
  padding: 18px 5px !important;
} */
.StoreItemMasterReact .ReactTable .rt-thead .rt-th {
  text-align: left;
}
.align-table .ReactTable .rt-thead .rt-th {
  text-align: left;
}
.StoreUserReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 7px !important;
}
.TicketBrandReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.TicketCrmRoleReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.reactreport svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.modulereact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.StorCrmRoleReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.StoreHierarchyReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.TicketFileUploadReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.deptMaster svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.StoreUserReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.slatempreact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.StoreItemMasterReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.modulereactShortcut .goodby {
  background-color: #f3f3f3;
  padding: 3px 5px;
}
.modulereactShortcut svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.StoreRaiseReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.editre {
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
}
.EditButtonUSer {
  width: 180px;
}
.EditButtonUSer .collapse-title {
  font-weight: 700;
  color: hsl(0, 0%, 29%);
  font-size: 14px;
  padding: 8px 5px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 8px center;
  background-size: 11px;
  position: relative;
  margin: 0 !important;
}
.EditButtonUSer .collapse-title:hover {
  color: inherit;
}
.EditButtonUSer .collapse-title::after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #f4f4f4;
  left: -25px;
  right: 0;
  bottom: 0;
  width: calc(100% + 50px);
}
.EditButtonUSer .collapse-title[aria-expanded="true"] {
  background-color: #f3f3f3;
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO9JREFUeNq8lVELgjAUhZtX56yetHqpRyH6///L6KEopNYdHGONdJuJFw4TlPtt5x6m0Fov5igx4vsEzy9W8C4pEpKzTqwd68J6Tg0ykDWrZm1YS1bBurHaqUDGqop1wErW6SQsvPtspABICatKNLbfZVi9MPLYtcJJSpzArRRws16HbCTP4GvYJQc2ZCAK6g0I9UAypKuKmGMBBxpYOQgS2GEHSSLjr5DIxp0X/bDryNpGQuweBWBfNpIT4T0g9Odto2C/7tJIToR9gw+tBH0+0U+tGEvEs53wLpXofe6Gn4+4YEPL2PcQc/0m3gIMADqhKR/uzSU8AAAAAElFTkSuQmCC) no-repeat right 8px
    center;
  background-size: 11px;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 5px;
}
.EditButtonUSer .collapse-title[aria-expanded="true"]::after {
  background-color: transparent;
}
.EditButtonUSer .right-sect-collapse .div-cntr {
  padding: 0 45px;
}
.EditButtonUSer .right-sect-collapse {
  padding-right: 0;
  padding-left: 0;
}
.EditButtonUSer .right-sect-collapse h3 {
  padding-left: 45px;
  margin-bottom: 10px;
}
.EditButtonUSer .right-sect-div {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  padding: 0;
  margin-bottom: 0;
}
.EditButtonUSer .sup-agent-cntr {
  padding-left: 0;
  padding-top: 15px;
}
.EditButtonUSer .sup-agent-cntr .status-options {
  display: block;
  margin: 0;
}
.EditButtonUSer .right-sect-div select {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 15px
    center;
  background-size: 13px;
  padding-right: 50px;
  cursor: pointer;
}
.EditButtonUSer .right-sect-div .butn {
  font-weight: 400;
  height: 30px;
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
  line-height: 0px;
  padding: 5px 5px;
}
/* Setting ReactTable Css Start Kalpesh */

/* Akasha css start */

.cus-cs .ant-select-selection {
  border: none !important;
}
.dropDrownSpace .ant-select-selection--single {
  background-color: #ecf2f4;
}
.cus-cs .ant-select-selection--single {
  background: #ecf2f4
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==)
    no-repeat right 12px center;
}
.cus-cs .ant-select-selection--single {
  height: 43px !important;
}
.cus-cs .ant-select-selection__rendered {
  line-height: 43px !important;
}
.cus-cs .ant-select-selection-selected-value {
  font-size: 15px;
  color: #9b9b9b;
  font-weight: 700;
}
.ant-select-arrow {
  display: none !important;
}
.chatpadtoggle {
  padding-right: 90px;
}
.canblue {
  color: #2561a8 !important;
}
.Save-Use {
  background-color: #2561a8;
  border-radius: 4px;
  min-width: 75px;
  height: 30px;
  line-height: 1;
  cursor: pointer;
  color: #fff;
  margin: 0 5px;
}
#UsEdit-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
}
#UsEdit-popup .tab-content {
  padding: 15px 35px;
}
/* Akasha css end */

.drag-handle {
  cursor: move !important;
}

.dragging-container tbody {
  xposition: relative;
  xoverflow: hidden;
}

.dragging-container tbody tr {
  background: #fff !important;
  xtransition: none !important;
}

.dragging-container tbody tr td {
  position: relative;
}

.dragging-container .drag-target-line {
  bottom: -1px;
  left: -500%;
  width: 2000%;
  xleft: 0;
  xwidth: 100%;
  height: 0;
  display: block;
  position: absolute;
  border-bottom: dashed 2px #2561a8;
  z-index: 2;
}

.dragging-container .drag-target-line.drag-target-top {
  bottom: auto;
  top: -1px;
}

.dragging-container .ant-table-tbody > tr.ant-table-row-hover > td,
.dragging-container
  .ant-table-tbody
  > tr
  > td
  .dragging-container
  .ant-table-tbody
  > tr:hover
  > td {
  background: transparent;
}

.store-pop-over-div {
  display: block;
  margin-top: 65px;
}
.storecancel {
  float: right;
  margin: 0;
}
.ReNewBtn {
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
}
.FlNone {
  float: none;
}
.dropDrownSpace .btn-primary {
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  min-width: 75px;
  height: 30px;
  line-height: 0;
  padding: 5px 10px;
}
.sweet-alert {
  margin-left: -170px !important;
}
.EditModa .modal-dialog {
  width: 30%;
}
.EditModa .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}

.FTypeScroll {
  height: 175px;
  overflow-x: hidden;
  overflow-y: auto;
}
.FTypeScroll::-webkit-scrollbar {
  width: 7px;
}
.FTypeScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.FTypeScroll::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.FTypeScroll::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.mainfileUpload {
  font-weight: 400;
  border: 1px solid #99afc9;
  border-radius: 8px;
  width: 100%;
  padding: 40px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
.fileupload-span {
  font-weight: 700;
  color: #2561a8;
}
#Raised-popup {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 80px;
  margin: 0 auto;
  width: 100%;
}
.progress-sect {
  display: inherit;
}
.depatselect .ant-select-search--inline .ant-select-search__field {
  padding: 0;
  margin: 0;
}

#departmenteditmodal {
  width: 25%;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
}

.setting-table-des .ReactTable .rt-tbody .rt-td {
  font-weight: 700;
  font-size: 13px;
}
.setting-table-des .ReactTable .rt-thead .rt-resizable-header-content,
.setting-table-des .ReactTable .rt-thead .rt-th {
  font-weight: 700;
  font-size: 12px;
}
.setting-table-des-antd th span {
  font-size: 12px !important;
  font-weight: 700;
  color: #4a4a4a;
}
.chat-drop-down {
  display: inline-flex;
}
.chat-drop-down .ant-select-arrow {
  display: block !important;
}
.chatsetngtxt {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  color: #000;
  display: block;
  border: 0;
  padding: 11px 15px;
  margin-bottom: 10px;
}
.chattxtdiv {
  display: inline-block;
  /* margin: 0px 30px 0px 30px; */
  width: 100%;
}

.chat-storemng {
  /* color: #eb4d4b; */
  font-weight: bold !important;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 11px;
  line-height: 13px;
  text-align: left;
  font-weight: 600 !important;
}

.chat-tabslst .tab-content {
  padding: 0 !important;
}

.chattab-card {
  width: 100%;
  padding: 15px 15px;
  margin-left: 0;
  margin-right: 0;
}
.chatsettingload {
  margin-left: 10px !important;
}

.slotstorelbl {
  width: 70%;
  text-align: right;
  margin-right: 10px;
}

.strdrp-dwn {
  padding: 10px;
  margin-top: 25px;
}
.pd-10 {
  padding: 10px;
}
.mr-10 {
  margin: 10px;
}
.slt-time-lbl {
  margin: 10px;
  margin-left: 0;
}
.mx-slt-div {
  padding: 10px;
  margin: 10px;
}

.mr-0 {
  margin: 0;
}
.lbl-fnt-w-400 label {
  font-weight: 400 !important;
}
.mx-slt-div input {
  width: 50% !important;
  margin-top: 7px !important;
}
.full-wid-inp input {
  width: 100% !important;
}
.full-wid-inp select {
  margin-top: 7px;
  font-size: 14px;
  width: 70px !important;
}
.ap-select {
  background: none !important;
  padding: 5px 7px !important;
  cursor: auto !important;
  border-radius: 0px 5px 5px 0px !important;
  background: #fff;
}

.slt-non-op-hr-lbl {
  margin: 10px;
  margin-left: 40px;
  margin-bottom: 0;
  margin-top: 0;
}

.edit-slot-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 5px;
}
.edit-slot-lbl1 {
  margin-top: 0 !important;
  margin-left: 106px;
}
.edit-slot-lbl2 {
  margin-top: 0 !important;
  margin-left: 30px;
}
.chrdioclr.switch input[type="checkbox"] + .cr:before {
  background: #c8c8c8;
}
.chrdioclr.switch.switch-primary input[type="checkbox"]:checked + .cr:before {
  background: #50d462;
}

.snlbl-nlbl {
  color: #2561a8;
  font-size: x-large;
  font-weight: 700;
}
.sn-hr {
  margin-top: 0;
  /* margin-bottom:0 ; */
  border-top: 1px solid #2561a8;
}
.sn-p {
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 10px;
}

.sns-lbl {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sncheck .ant-checkbox + span {
  font-weight: 600 !important;
}
.sncheck .ant-checkbox-wrapper {
  width: 31%;
  margin-bottom: 10px;
}
.vlm-ctrl .rangeslider-horizontal {
  border-radius: 0px !important;
  height: 5px;
}

.vlm-ctrl .rangeslider-horizontal .rangeslider__fill {
  border-radius: 0px !important;
  background-color: #e6e6e6;
}
.vlm-ctrl .rangeslider .rangeslider__handle {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4), 0 0px 0px rgba(0, 0, 0, 0.4);
}
.vlm-ctrl .rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
.vlm-ctrl .rangeslider-horizontal .rangeslider__handle {
  border-radius: 0px !important;
  width: 8px !important;
  height: 25px !important;
}
.sn-btn-mr {
  margin-right: 20px;
  outline: none !important;
}
.sn-btn-inactive {
  background-color: #fff !important;
  color: #2561a8 !important;
  margin-right: 20px;
  outline: none !important;
}
.cr-float-right {
  margin-left: 30px !important;
}

/* Slot new design */

.slot-newd .nav-tabs {
  border-bottom: 1px solid #ececec;
  margin: auto;
  display: block;
  text-align: center;
}
.slot-newd .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.slot-newd .nav-tabs .nav-link.active {
  border-bottom: 3px solid #2561a8;
}
.slot-newd .nav-tabs .nav-link {
  background: none;
  border: none;
}
.manualbox ul {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.manualbox label {
  font-weight: normal;
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 3px;
}
.manualbox input {
  margin-top: 0;
}
.manualbox select {
  margin-top: 0;
}
.manualbox .input-group-append img {
  height: 30px;
  padding: 6px 11px;
  width: 40px;
  margin-top: 5px;
}
.manualbox ul li {
  width: 100%;
  margin-right: 30px;
  margin-top: 15px;
}
.manualbox ul li:last-child {
  width: auto;
  margin-right: 0;
}
.manualbox .form-control {
  background-color: #f9f9f9;
  border: 1px solid #dadada;
  height: calc(2.55rem + 2px);
}
.manualbox .input-group-append {
  margin-left: 0;
  background-color: #dadada;
}
.manualbox a {
  color: #2561a8 !important;
  float: right;
}
.manualbox .butn {
  background-color: #fff;
  color: #2561a8;
  padding: 11px 0;
  margin-top: 24px;
}
.bulkuploadbox .addfilebox {
  background-color: #f9f9f9;
  border: 1px dashed #8e8e8e;
  width: 55%;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
}
.bulkuploadbox .addfilebox h3 {
  display: inline;
  color: #8e8e8e;
  text-transform: none;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
}
.bulkuploadbox .addfilebox h3 span {
  color: #2561a8;
  font-weight: 600;
}
.bulkuploadbox .addfilebox img {
  height: 14px;
  margin-right: 7px;
}
.nextbox li:last-child {
  width: 100% !important;
}
.nextbox .del-can a {
  color: #9b9b9b !important;
  margin-right: 15px;
  padding-top: 25px;
  padding-bottom: 0;
}
.nextbox .del-can .butn {
  width: 100px;
  background-color: #2561a8;
  color: #fff;
}
.nextbox .del-can {
  display: flex;
  justify-content: flex-end;
}
.slot-newd {
  box-shadow: none;
}
.statuscheckbox {
  display: flex;
}
.statuscheckbox input {
  width: auto;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 4px;
}
.statuscheckbox label {
  vertical-align: middle;
}
.nextbox .value {
  width: 50px;
  text-align: center;
  height: calc(1.55rem + 2px);
}
.chooseslot-box {
  padding: 25px 50px;
}
.chooseslot-box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
}
.chooseslot-box .statuscheckbox {
  display: flex;
  margin-top: 10px;
}
.chooseslot-box .statuscheckbox input {
  width: auto;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 0;
  margin-top: 4px;
}
.chooseslot-box .statuscheckbox label {
  vertical-align: middle;
  margin-right: 15px;
  margin-top: 0;
}
.chooseslot-box .form-control {
  background-color: #f9f9f9;
  border: 1px solid #dadada;
  height: calc(2.55rem + 4px) !important;
}
.chooseslot-box label {
  font-weight: normal;
  font-size: 14px;
  color: #9b9b9b;
  margin-top: 10px;
  margin-bottom: 3px;
}
.chooseslot-box select {
  margin-top: 0;
}
.chooseslot-box a {
  color: #2561a8 !important;
  margin-right: 10px;
}
.chooseslot-box .butn {
  background-color: #2561a8;
  color: #fff;
  padding: 11px 0;
}
.chooseslot-box .del-can {
  display: block;
  float: right;
}
.chooseslot-box select {
  background: #ecf2f4
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=)
    no-repeat right 25px center;
  background-size: 13px;
  padding-right: 50px;
  cursor: pointer;
}
.chooseslot-table {
  margin: 25px 0 0;
}
.selectstores-box {
  padding: 20px;
}
.selectstores-box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.selectstores-box ul {
  margin-bottom: 0px;
}
.selectstores-box ul li {
  margin-right: 7px;
  margin-bottom: 10px;
}
.selectstores-box .input-group {
  background: #f3f3f3;
  padding: 5px 5px 5px 10px;
  border-radius: 50px;
}
.selectstores-box .cust-ic {
  height: 20px;
  background-color: #dadada;
  padding: 5px;
  border-radius: 50%;
}
.selectstores-box label {
  font-size: 12px;
  margin-right: 5px;
  font-weight: 600;
}
.chooseslot-box .tabbutn {
  background-color: #fff;
  color: #2561a8;
  padding: 11px 0;
  margin-top: 34px;
}
.chooseslot-main {
  width: 800px;
  min-width: 800px;
  box-shadow: 0 0 15px 0px #ddd;
  border-radius: 20px;
}
.chooseslot-main .modal-content {
  border-radius: 20px;
}
.choosestorebox {
  width: 800px;
  max-width: 800px;
  top: 125px;
  right: 175px;
  box-shadow: 0 0 15px 0px #ddd;
  border-radius: 20px;
}
.choosestorebox .modal-content {
  border-radius: 20px;
}
.selectstores-main .modal-content {
  border-radius: 20px;
  top: 335px;
  right: 275px;
  box-shadow: 0 0 15px 0px #ddd;
  border-radius: 20px;
}
.choosestore {
  padding: 25px 50px;
}
.cust-icon2 {
  position: absolute;
  top: 20px;
  right: 10px;
  height: 14px;
  cursor: pointer;
}
.choosestorebox label {
  font-weight: normal;
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 3px;
}
.choosestorebox input {
  margin-top: 0;
}
.choosestorebox select {
  margin-top: 0;
}
.choosestorebox .input-group-append {
  margin-left: 0;
  background-color: #dadada;
}
.choosestorebox .input-group-append img {
  height: 30px;
  padding: 6px 11px;
  width: 40px;
  margin-top: 5px;
}
.choosestorebox .form-control {
  background-color: #f9f9f9;
  border: 1px solid #dadada;
  height: calc(2.55rem + 2px);
}
.butn-selectall {
  border-color: #ccc;
  background-color: #fff;
  height: 28px;
  padding: 0;
  font-size: 13px;
  cursor: pointer;
}
.atoz li {
  margin-right: 9px;
  cursor: pointer;
}
.storetabl .form-control {
  height: 15px;
  width: 15px;
  display: inline;
  cursor: pointer;
}

.storetabl input {
  margin: 4px 8px 0 0;
  vertical-align: middle;
}
.storetabl label {
  vertical-align: middle;
  margin: 0;
  text-overflow: ellipsis;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  color: #666;
}
.storetabl tr {
  border-bottom: none;
}
.storetabl .table td {
  border: none;
  padding: 5px 0;
}
.storetabl {
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  max-height: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 15px;
}
.storetabl::-webkit-scrollbar {
  width: 7px;
}
.storetabl::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.storetabl::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.storetabl::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.operationaldays {
  padding: 8px;
}
.operationaldays h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  font-weight: bold;
}
.operationaldays ul {
  margin-bottom: 0px;
}
.operationaldays ul li {
  margin-right: 7px;
  margin-bottom: 10px;
}
.operationaldays label {
  font-size: 12px;
  margin-right: 0;
  font-weight: 600;
  background-color: #ececec;
  text-align: center;
  border-radius: 50px;
  padding: 6px 15px;
}
.appointmentdays {
  padding: 10px 30px;
}
.appointmentdays h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}
.slotchckhceck {
  display: flex;
  margin-bottom: 10px;
}
.cls1-hide {
  display: none;
}
.slotFrm .react-datepicker-wrapper {
  display: block;
}
.tabactive {
  background-color: #eef4f4;
  text-align: center;
  border-radius: 50px;
  padding: 6px 0;
  color: #08b593;
}
.select-oper .css-yk16xz-control {
  height: calc(2.55rem + 2px);
  background-color: #f9f9f9;
  border: 1px solid #dadada;
  color: #000;
}
.select-oper .css-1pahdxg-control {
  height: calc(2.55rem + 2px);
  border: 1px solid #dadada;
}
.select-oper .css-1pahdxg-control:hover {
  border: #dadada;
}
.operational-select .css-yk16xz-control {
  min-height: 45px;
  height: auto;
}
.operational-select .css-1hwfws3 {
  z-index: 2;
}
.modal-backdrop {
  background-color: transparent;
}
.operational-select .select-oper .css-1pahdxg-control {
  height: auto;
  min-height: calc(2.55rem + 2px);
}
/* .operational-select .css-1hwfws3 {
  max-height: 40px;
  overflow: auto;
} */
.operational-select .css-1g48xl4-IndicatorsContainer {
  max-height: 40px;
}
.slotFrm .react-datepicker-popper {
  z-index: 999999999999;
}
.slotFrm .react-datepicker__time-container {
  width: 120px;
}
.slotFrm
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 120px;
}
.appointmentDiv {
  width: 100%;
  display: inline-flex;
  overflow: hidden;
}
.applicateDiv.react-datepicker-wrapper {
  display: block;
}
.react-datepicker-wrapper {
  width: 100%;
}
.nextbox .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  top: 90%;
}
.slot-down-excel {
  margin-left: 26rem;
  margin-top: 10px;
  display: flex;
}
.appoint-txtarea {
  width: 100% !important;
  margin-top: 10px;
}
.appoint-conf-dv {
  margin: 35px 0px 0px 35px;
}
.dv-placeholders {
  margin-top: 20px;
}
.dv-placeholders label {
  margin-top: 10px !important;
}
.dv-placeholders select {
  width: 77%;
  float: right;
}
.sltsave-btn {
  padding-right: 5px;
  color: #fff !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.slottbl .ant-table-placeholder {
  z-index: 0;
}

.claimtextoverflow {
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tbl-loading .ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 55% !important;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.rt-noData.ant-empty-normal {
  margin: 0;
}
.tbl-loading.rt-noData.ant-empty-normal {
  margin: 0;
}

/* Shlok css start */
/* Header */
.store-nav-tab3 {
  margin-top: -41px;
  margin-left: 77px;
  position: relative;
  z-index: 1;
}
.store-dashboardImg1 {
  width: 20px;
  height: 19px;
}
.store-myTicket {
  width: 18px;
  height: 17px;
  margin-right: 6px;
  margin-top: -2px;
}
.store-claim-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: -2px;
}
.store-nav-tab1 {
  margin-top: -3%;
  margin-left: 27%;
  position: relative;
  z-index: 1;
}
.store-claim-space {
  margin-top: -2.9%;
  margin-left: 39%;
}
.storeHeader1 {
  width: 26px;
  height: 26px;
  margin-top: 13px;
  margin-left: 40px;
}
.store-chatImg {
  width: 25px;
  height: 25px;
  margin-top: -4px;
}
.store-setting {
  width: 24px;
  height: 24px;
}
.store-notifi {
  width: 24px;
  height: 24px;
}
.store-main-nav {
  margin-left: 18.5%;
}
.store-bitmap5 {
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: 155px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 10px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}
/* End header */
/* ***************************** */
/* Campaign tab css start */
.closebtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 90px;
  height: 31px;
  min-width: 68px !important;
  cursor: pointer;
}
.hdrcloselabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-transform: none;
  text-transform: initial;
  cursor: pointer;
}
.store button {
  padding: 0px;
}
.table-cntr-card .card-body {
  padding: 0;
}
.table-cntr-card {
  height: 0;
}
.table-cntr-card table thead tr th {
  color: #989898;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  width: 113px;
  text-align: left;
}
.contactBtnGreen {
  background-color: #9cc541;
  border-radius: 4px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  width: 105px;
  height: 31px;
}
.notConnectedBtnRed {
  background-color: #c0505f;
  border-radius: 4px;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
  width: 105px;
  height: 31px;
  text-transform: none;
  text-transform: initial;
}
.followUpBtnYellow {
  background-color: #f5a623;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5); */
  width: 105px;
  height: 31px;
  text-transform: none;
  text-transform: initial;
}
.followUpBtnBlue {
  background-color: #5c53ee;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5); */
  width: 105px;
  height: 31px;
  text-transform: none;
  text-transform: initial;
}
.table-btnlabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
  text-transform: initial;
  /* line-height: 16px;
  text-align: left; */
  line-height: 31px;
  text-align: center;
  margin-right: 2px;
  opacity: 0.65;
}
.campaign-status-btn:checked + label {
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
}
.saveBtn {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 59px;
  height: 31px;
}
.saveLabel {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: none;
  text-transform: initial;
}
.raisedticket-Btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 101px;
  height: 31px;
  text-transform: none;
  text-transform: initial;
}
.raise-ticketLbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  line-height: 16px;
  text-align: left;
}
.txtStore {
  padding-top: 0px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.dateTimeStore {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 183px;
  height: 31px;
  border: none;
  padding-left: 10px;
}
.dateTimeStore-highlight {
  box-shadow: 0 0 0 1.5px rgba(247, 0, 0, 0.6);
}
.responceDrop-down {
  /* background-color: #ecf2f4; */
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAFC/+zoAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAADgAAAAAuXTW1AAAB7UlEQVQ4EaVSPU8CQRTkLgihseMkGkJsbCCxoFYvIXz2xFj7GaWy8TfYUKHxszaGHgiGiFrTGCg0JobQIPwAckjAGeJe1gM06hX39r03b2Z3dm026VO4jsfjt4qi6FJ93JIwuZ5IJAZybhspfOmKBKgjsWbUdd3j9/sdw1osFjvnIhQKzZjFYQc/bOBULiqgWhgMBpf5fH5JgMZFsjmdzurwtASQCWEegxF54BPYrNfrzlqt1jUHBAj7y8CqAPIkYksARf/XkWdoG4YRKJVKb99NY8sPUFxXITmHwzTp26QBXhXAj7lc7nl4Btrm8/kgZHisSgAXQfQKM7ZJaB563BCZYflLoVDYFOrmAAtiCCANaRaxCnBKgBlVOaHPtJF2ov5kBZvYaDS6GA6HZ83CPxfkIidpFJxrGvEQu9jBlsudTmetXC43/6LBm3G5XFfg0sF1Ao4D0yP64/V606qq7qF51+12V603MkmUz9PhcFyDeKXf7x81Go192ke8KSCGg8HglKZpfK5bqN33er1ksVikxyNfJBLR7HZ7Fo1lbOqs1WqlKpXKuwwcERBNWMfLSyPftVonW4H+Mfr7eGeGmJXjRAEBohAIMrBuA/GGdQiHYcUFYmoSsZj/UUAAaZ3b7T5m3m63d61WCJw1fgDTJeP2n8EQjQAAAABJRU5ErkJggg==) no-repeat center right
    10px !important;
  background-size: 12px !important;
  padding-right: 32px !important;
  border-radius: 4px;
  width: 190px;
  height: 31px;
  padding-left: 12px;
}
.raise-ticket-popup {
  padding: 10px 20px;
}
.not-contacted-count {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #ff7c21;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #ff7c21;
  bottom: calc(100% + 4px);
  right: 5px;
}
.not-contacted-count:before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 18px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-top: 4px solid #ff7c21;
  border-right: 3px solid transparent;
}
.cncl-icn {
  margin-left: auto;
  display: block;
  margin-right: 5px;
  cursor: pointer;
}
.raise-ticket-popup label {
  display: block;
  color: #9b9b9b;
}
.raise-ticket-popup input,
.raise-ticket-popup textarea,
.raise-ticket-popup select {
  background: #f0f0f0;
  border: 1px solid #d8d0d0;
  border-radius: 3px;
  padding: 3px 10px;
  margin-top: 5px;
  width: 100%;
}
.raise-ticket-popup select {
  background: #f0f0f0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}
/* Compaign css End */
/* Shlok css end */
/* --------------------------------------------- */

/* chetan css starts */

/* store task starts */
.store-task-tabs ul {
  border-bottom: 0;
}
.store-task-tabs {
  background-color: #ecf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.store-task-tabs .nav-tabs .nav-link {
  font-weight: 400;
  padding: 17px 15px;
  font-size: 16px;
  font-weight: 600;
}
.store-task-tabs .nav-tabs .nav-link.active {
  font-weight: 700;
  border-bottom: 0;
  background-color: transparent;
}
.store-task-tabs button {
  font-size: 14px;
  padding: 10px 40px;
  margin-right: 15px;
}
.store-task-tab-cont {
  padding-right: 15px;
  padding-left: 15px;
}

.store-task-tab-cont table th {
  font-size: 12px;
  padding: 15px 25px;
}
/* store task ends */

/* store task view starts */
.store-task-view-top {
  padding: 10px 20px;
  background-color: #ecf2f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.str-img {
  width: 30px;
  height: 30px;
  background-color: #f8e71c;
  border-radius: 50%;
  overflow: hidden;
  line-height: 25px;
  text-align: center;
}
.str-img img {
  width: 18px;
}
.str-owner p {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  font-weight: 700;
}
.str-owner {
  display: flex;
  align-items: center;
}
.str-dtls select {
  background: #2561a8 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAgUlEQVR4nJXOsQ3CQBBE0b8X0IWLoQ7LFhGd2HIJJJao00b6JBcgxHF3m2wwo9EL9Q4cEfGk89QbcEnAAOzq3DkwAzswoIa6qC91ahyYcn9R4zPYcjBWBsbc20qFVT1Loiw41bVG/SmqCmqiZkFBdKiP/NsEBZHdgq+RpF7V9K/3BnxOqa7xB6aiAAAAAElFTkSuQmCC) no-repeat right 20px
    center;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-size: 14px;
  padding: 11px 45px 11px 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 25px;
  min-width: 205px;
}
.str-dtls select option {
  color: #2561a8;
  background-color: #fff;
  text-transform: none;
  text-transform: initial;
  font-weight: 700;
}
.task-view-cntr {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 40px;
  height: 100%;
}
.task-view-cntr label {
  color: #9b9b9b;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}
.task-view-cntr input,
.task-view-cntr select,
.task-view-cntr textarea {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  border: 0;
  padding: 12px 15px;
  background-color: #ecf2f4;
  border-radius: 4px;
}
.task-view-cntr select {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 20px center;
  background-size: 18px;
  padding-right: 50px;
}
.task-view-cntr textarea {
  height: 150px;
  resize: none;
}
.task-view-cntr .row > div {
  padding-right: 10px;
  padding-left: 10px;
}
.task-view-cntr .row {
  margin-right: -10px;
  margin-left: -10px;
}
.store-cmnts {
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}
.store-cmnts::-webkit-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.store-cmnts:-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.store-cmnts::-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.store-cmnts::placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.add-cmnts p {
  color: #9b9b9b;
  font-weight: 700;
}
.add-cmnts a {
  color: #fff;
  background-color: #2561a8;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 25px;
  display: inline-block;
}
.add-cmnts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cmnts span {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 700;
}
.cmnts p {
  color: #505050;
  font-size: 13px;
  font-weight: 700;
}
.cmnts-sctn {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 10px;
}
.outer-cmnts .cmnts-sctn:last-child {
  border-bottom: 0;
}
.task-view-right p {
  color: #4a4a4a;
  font-weight: 700;
  font-size: 16px;
}
.task-closure-progress {
  height: 10px;
  background-color: #ecf2f4;
  border-radius: 5px;
  margin-left: 12px;
}
.task-closure-progress .progress-bar {
  background: -webkit-gradient(linear, left top, right top, from(#f9c957), to(#f66c1c));
  background: linear-gradient(to right, #f9c957 0%, #f66c1c 100%);
  box-shadow: none;
}
.task-closure .dte {
  white-space: nowrap;
  font-size: 15px;
  line-height: 1;
  margin-top: -2px;
}
.nos-days {
  font-size: 14px !important;
  text-align: right;
  width: 65%;
  position: relative;
  left: 17px;
  margin-top: 2px;
}
/* store task view ends */

/* chetan css ends */

/* ------------Shlok css---------------------------- */
/* ----------------Store Manager css--------------------- */
.table-expandable-sctn1 {
  background: #ecf2f4;
}
.table-expandable-sctn1 button {
  cursor: pointer;
}
.table-expandable-sctn1 .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn1 .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  /*background: #e3e9eb;*/
}
.table-expandable-sctn1 .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn1 input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  color: #4a4a4a;
}
.table-expandable-sctn1 input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn1 input::-webkit-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 input:-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 input::-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.table-expandable-sctn1 .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn1 .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn1 button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn1 button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.table-expandable-sctn1 button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
  border-color: rgba(37, 97, 168, 0.4);
  color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn1 .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn1 .tab-content button:last-child {
  margin-right: 0;
}
.table-expandable-sctn1 .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn1 .tab-content .row.all-row > div {
  margin-bottom: 25px;
}
.table-expandable-sctn1 .react-datepicker-wrapper input[type="text"] {
  background: #fff url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.table-expandable-sctn1 .react-datepicker-popper {
  z-index: 2;
}
.table-expandable-sctn1 .react-datepicker button {
  min-width: auto;
}
.table-expandable-sctn1 .react-datepicker-wrapper {
  width: 100%;
}
.bill-graph-list {
  font-weight: 300;
  text-align: left;
  margin-left: 25px;
  margin-top: 30px;
}
.bill-graph-list li {
  padding: 4px 0;
  white-space: nowrap;
  display: block;
  font-size: 15px;
}
.bill-graph-list li b {
  font-weight: 700;
}
.tic-bill-graph .nv-x {
  font-size: 12px;
  font-weight: 700;
}
.tic-bill-graph .nv-y {
  font-size: 8px;
  color: #4e4e4e;
}
.tic-bill-graph .nv-chart,
.tic-bill-graph .nvd3-svg {
  width: 100%;
}
.apexcharts-toolbar {
  display: none !important;
}
.opendonutChart .apexcharts-canvas .position-right {
  right: 40px !important;
}
/* Task by ticket css start */
.taskByTable table th,
.taskByTable table td {
  font-size: 12px;
  padding: 20px 20px;
}
/* css end */
/* Edit Store Task page css start by Shlok */
.edit-storeTask-header {
  background-color: #ecf2f4;
  width: 100%;
  height: 57px;
  padding: 15px 20px 0px;
}
.store-header-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}
.btnstore-last {
  display: block;
  float: right;
  margin-top: -8px;
}
.submitAs-reopen {
  background-color: #2561a8;
  border-radius: 4px;
  width: 210px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.store-Edit-lbl {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.store-card-padding {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  /* width: 780px; */
}
.store-edit-txt {
  background-color: #ecf2f4;
  border-radius: 4px;
  height: 43px;
  border: none;
  padding: 10px;
}
.store-mrg {
  margin-top: 20px;
}
.store-crmrole-Btn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 10px;
}
.textarea-store {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 148px;
  resize: none;
  border: none;
  padding: 10px;
}
.textarea-store-comments {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 129px;
  resize: none;
  border: none;
  padding: 10px;
}
.butn-store {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  /* height: 40px; */
  /* width: 155px; */
  float: right;
  text-transform: uppercase;
}
.store-mrg-1 {
  margin-top: 25px;
}
.store-hrLbl {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  float: right;
}
.naman-r-store {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
}
.store-comment {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-cmt-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  width: 100%;
}
.store-card-2 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 69vw;
}
.store-card-3 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: auto;
}
.naman-r-store-2 {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.store-edit-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: left;
}
.store-edit-data-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 7px;
}
.store-mrg-3 {
  margin-top: 25px;
}
.task-clouserDate {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
.store-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.progressbar-2 {
  width: 70%;
  margin-left: 6px;
  margin-bottom: 3px;
}
.dis-btns {
  opacity: 0.5;
  pointer-events: none;
}
#dis-sms,
#dis-msngr,
#dis-bot,
#dis-share {
  pointer-events: none;
}
.progressbar-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  /* float: right; */
  /* margin-right: 90px; */
}
.store-hdrtMdal {
  padding: 7px 113px 10px 20px;
}
.modal-lbl {
  color: #2561a8;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.modal-lbl-1 {
  color: #16cba3;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.modal-lbl-2 {
  color: #696969;
  font-size: 15px;
  font-weight: 600;
}
#SubmitReopn-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 25px;
  margin-top: 45px;
  /* margin-bottom: 4px; */
  display: inline-block;
}
.storeImg-11 {
  height: 18px;
  width: 19px;
  margin: 7px;
}
.oval-5-1-new-store {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-block;
}
/*  css End edit Store Task*/
/* Store Task by tikcet css start by Shlok */
.store-header-task {
  margin-top: -20px;
  margin-left: -288px;
}
.headerBtn-store {
  margin-top: -41px;
  float: right;
  margin-right: 20px;
}
.raisedClaim-storeBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 141px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.raisedClaim-lbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.btn-store-resolved {
  background-color: #2561a8;
  border-radius: 4px;
  height: 43px;
  margin-left: 15px;
  cursor: pointer;
}
.ImgBlue-lbl {
  width: 20px;
  height: 14px;
  padding: 3px;
  margin-left: 7px;
}
.noEditImg {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -30px;
}
.noEditImg-1 {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -252px;
}
.store-card-padding-ticket {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 780px;
  height: 100vh;
}
.hrMargin {
  margin-top: 20px;
  margin-bottom: 15px;
}
.newtab {
  width: 100%;
  background: none;
  /* padding-top: 45px;
    padding-bottom: 15px; */
  padding-left: 0;
  padding-right: 0;
}
/*  Store Task by tikcet css end*/
/* Claim page by shlok */
.main-Claimdiv {
  padding: 5px 15px 15px;
}
.claimTbl table tr td:last-child,
table tr th:last-child {
  text-align: right;
  float: left;
}
.claim-headPhoneImg {
  width: 18px;
  height: 19px;
  margin-right: 7px;
}
.claimTbl .claim-span {
  font-size: 10px !important;
}
.claim-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.claim-bataShoes {
  width: 350px;
  height: 197px;
  margin-left: 0px;
}
.closure-date {
  font-size: 12px;
  font-weight: 700;
  color: #777;
  margin: 0;
  text-align: center;
}
.col-md-10-card {
  margin-left: 5px;
  flex: 0 0 75%;
  max-width: 70%;
}
.claim-customer-padding {
  padding: 60px;
  padding-left: 6%;
  padding-top: 5px;
}
/* Claim page end */
/* StoreAddTask css start by kalpesh */
.card1 {
  width: 100%;
  padding: 42px 20px;
  margin-left: 0;
  margin-right: 0;
}
.store-Edit-lblcre {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.store-Edit-lbl1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.cardmargin {
  margin-left: -30px;
  height: 100%;
  /* height: 49.6vw; */
}
/* StoreAddTask css end */
/* ClaimApproveReject css start for header button and bottom page by Kalpesh */
.claim-title1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 550;
  line-height: 22px;
  text-align: left;
  padding: 15px 0 0 0;
}
.btn-approrej {
  float: right;
  margin-top: 4px;
}
.btn-approrej1 {
  background-color: #2561a8;
  border-radius: 4px;
  border-color: #2561a8;
  width: 155px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  margin: 3px 0px 8px 12px;
}
.oval-approve {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  float: left;
  padding: 4px;
  margin: 9px 15px 9px 35px;
}
.card-radius2 b {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}
.card-radius2 label {
  font-size: 16px;
  font-weight: 400;
}
.a {
  border-radius: 50%;
  background-color: #2561a8;
  color: white;
  padding: 3px 10px 5px 11px;
  font-weight: 500;
  margin-right: 10px;
}
.cardbor {
  border: 1px solid #e0e0e0 !important;
}
.alankrit {
  margin-bottom: 20px;
  display: grid;
}
/* ClaimApproveReject css end */
/* Raiseclaim search collapse css start kalpesh */
.Searchline {
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.uploadsearch-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
.uploadsearchbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 154px;
  height: 43px;
  cursor: pointer;
}
.uploadsearchbtn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.searchdownload {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}
.uploadsechmargin {
  margin-bottom: 15px;
}
.uploadsearch {
  text-align: center;
  margin-top: 70px;
}
.uploadsearch-space {
  margin-bottom: 60px;
}
.allign-reassign {
  margin-left: 35px !important;
  margin-top: -10px;
}
.searchtext {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 299px;
  height: 43px;
  float: right;
  padding: 10px;
}
.searchImg-raise {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -280px;
  margin-top: 12px;
}
.oderDetails-table .ReactTable .rt-thead.-header {
  background-color: #fff;
}
.orderdetailtext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
/* Raiseclaim search collapse css start kalpesh */

/* Department-Master Css Start Kalpesh */
.deptMaster .ReactTable .rt-thead .rt-tr {
  font-size: 12px;
}
.deptMaster .ReactTable {
  border: none;
}
.right-sect-collapse .div-cntrdept {
  padding: 0 10px;
}
.editdeptpopover {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  padding: 25px 10px;
  /* margin-bottom: 25px; */
}
.editdeptpopover input,
.editdeptpopover select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 250px;
  color: #000;
  display: block;
  border: 0;
  padding: 11px 15px;
  margin-top: 5px;
}
.delcan-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -25px;
}
/* Department-Master Css end */
/* SLA Template Department Start by Kalpesh */
.slatemp-text-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 33%;
}
.slatempreact .ReactTable {
  border-left: none;
  border-right: none;
}
.slatempreact {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}
/* SLA Template Department End */
/* Config add pop-up css start by Kalpesh */
.config-button {
  float: right;
  margin: 10px 38px 10px 0;
}
.config-buttontext {
  width: 140px;
  height: 40px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
}
.config-tab {
  width: 100%;
}
.cancelImg-config {
  float: right;
  width: 25px;
  height: 25px;
  margin-top: 15px;
  cursor: pointer;
}
.templateName {
  text-align: left;
  padding: 25px 0px 20px 55px;
}
.template-text {
  font-size: 20px;
  font-weight: 600;
  color: #2561a8;
  font-family: lato;
}
.template-editor {
  width: 92% !important;
  margin-left: 40px !important;
}
.template-editor .cke_bottom {
  display: none;
}
/* Config add pop-up css End by Kalpesh */
.stinlitab {
  display: inline-table;
}
.StoreRaiseReact .hope {
  display: block;
  font-size: 10px;
}
.camp-datepick .react-datepicker__navigation--previous {
  min-width: auto;
}
.camp-datepick .react-datepicker__navigation--next {
  min-width: auto;
}
.camp-datepick span {
  display: grid;
}
.camp-datepick td {
  padding-top: 7px;
  padding-bottom: 7px;
}
.sml-fnt {
  font-size: 11px !important;
}
.reactstoreclaim .ReactTable {
  background-color: #f8f8f8;
}
.reacttableordermodal .ReactTable .rt-thead.-header {
  background-color: #f8f8f8 !important;
}
.raisereactTable svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.storeplusline-12 {
  cursor: pointer;
  float: right;
  padding: 15px 0 0 0;
  height: 54px;
  pointer-events: none;
}
/* ----------------------------------------------- */
/* ------Change password btn css start-------- */
.userChangePW {
  text-align: center;
  font-size: 18px;
  color: #1555a2;
  cursor: pointer;
}
/* -------Change password btn css end------- */
.creation-date-range .ant-calendar-picker-input {
  height: 41px !important;
  background-color: #fff !important;
}
.creation-date-range .ant-calendar-range-picker-input,
.creation-date-range .ant-calendar-range-picker-separator {
  color: #a7a7a7 !important;
}
.creation-date-range div {
  margin-bottom: 0 !important;
}
/* ------------------Campain Css Start 29.04.2020------------------------- */
.custom-tableak {
  padding: 20px;
}
.custom-tableak
  .custom-antd-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-title {
  font-size: 13px !important;
}
.button-red {
  color: #ee6261 !important;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #ee6261;
  padding: 6px 20px 9px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
}
.button-blue {
  color: #2196f3 !important;
  font-weight: 600;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #2196f3;
  padding: 6px 20px 9px;
  margin-right: 10px;
}
.button-red .ico {
  margin-right: 5px;
  vertical-align: bottom;
}
.button-blue .ico {
  margin-right: 5px;
}
.custom-tableak .sml-fnt {
  font-size: 12px !important;
}
.custom-tableak .cust-name {
  color: #2561a8;
  white-space: nowrap;
  font-weight: bolder;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}
.custom-tableak .table-btnlabel {
  box-shadow: none;
}
.custom-tableak
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:first-child
  > td {
  padding-top: 10px !important;
}
.custom-tableak
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #fcfcfc !important;
}
.custom-tableak .saveBtn {
  background-color: #fff;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 75px;
  height: 30px;
  min-width: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.custom-tableak .saveLabel {
  text-align: center;
}
.custom-tableak .raisedticket-Btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  color: #fff;
  text-transform: none;
  text-transform: initial;
}
.custom-tableak .txtStore {
  /* padding-top: 0px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px center */
  background-size: 14px;
}
.antcustom
  .ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  display: none;
}
.antcustom .ant-popover-inner {
  border-radius: 20px !important;
}
.antcustom .ant-popover-placement-bottom,
.antcustom .ant-popover-placement-bottomLeft,
.antcustom .ant-popover-placement-bottomRight {
  padding-top: 1px !important;
}
.antcustom .ant-popover-arrow {
  display: none !important;
}
.ant-prodesc .ant-popover-arrow {
  display: inherit !important;
}
.custompop .poptitle {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 15px;
}
.broadcast-icon {
  display: inline;
  float: left;
}
.broadcast-icon img {
  height: 18px;
  cursor: pointer;
}
.antbroadcast {
  left: 890px !important;
  /* width: 330px; */
  width: 320px;
}
#customername-popup {
  border-radius: 25px;
  position: relative;
  padding: 30px;
  width: 800px;
}
.cust-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  cursor: pointer;
}
.nr-initials {
  float: left;
  width: 7%;
  background: #0080ff;
  border-radius: 8px;
  padding: 12px 8px;
  text-align: center;
  color: #fff;
  font-size: 17px;
}
.nr-name {
  float: right;
  padding-left: 5px;
  width: 92%;
}
.nr-name h3 {
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 600;
}
.nr-name span {
  font-size: 18px;
  color: #0080ff;
}
.nr-name p {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #cf6d0f;
}
.lifetimevalue {
  background-color: #f2f5f8;
  border-radius: 10px;
  /* margin: 20px 0 15px 0; */
  margin: 20px 0 0px 0;
}
.lifetimevalue h4 {
  color: #8694a0;
  font-size: 16px;
  font-weight: bold;
}
.lifetimevalue label {
  color: #000;
  font-size: 26px;
  font-weight: normal;
  margin-top: 1px;
}
.keyinsights {
  background-color: #f2f5f8;
  border-radius: 10px;
  margin: 0;
  padding: 18px 23px;
  height: calc(100% - 128px);
  margin-top: 15px;
}
.keyinsights h4 {
  color: #8694a0;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: normal;
}
.keyinsights p {
  color: #828282;
  font-size: 13px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  margin-bottom: 0px;
  max-height: 120px;
  overflow: auto;
  padding-right: 10px;
}
.keyinsights-big p {
  max-height: 150px;
}
.keyinsights p::-webkit-scrollbar {
  width: 4px;
}
.keyinsights p::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.keyinsights p::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.keyinsights p::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ki-al {
  margin: 20px 0 15px 0;
  height: calc(100% - 20px);
}
.productbox {
  background-color: #f2f5f8;
  border-radius: 10px;
  margin: 20px 0 0 0;
  padding: 18px 15px;
  /* height: calc(100% - 33px); */
  height: calc(100% - 18px);
}
.productbox .tab-content {
  padding: 24px 0px 0;
}
.productbox .prodscro {
  /* max-height: 210px; */
  max-height: 200px;
  overflow: auto;
  padding: 0 15px;
}
.productbox .nav-tabs .nav-link.active {
  color: #8694a0;
  border-bottom: 2px solid #8694a0;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  padding-bottom: 3px;
  display: inline;
}
.productbox .nav-tabs .nav-link {
  color: #8694a0;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;
  padding: 0;
}
.productbox .nav-item {
  display: block;
  width: 50%;
}
.broadChannel {
  display: "block";
  height: "30px";
  line-height: 30px;
}
/* .pro-slidercam td:first-child {
  padding-right: 10px;
}
.pro-slidercam td:last-child {
  padding-left: 10px;
} */
.pro-slidercam .shoeimg {
  height: 100%;
  width: auto;
  display: block;
  margin: auto;
}
.pro-slidercam .imgbox {
  height: 75px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}
.pro-slidercam h4 {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  color: #333;
}
.pro-slidercam td {
  padding: 0 5px 10px 0;
  width: 50%;
  display: inline-block;
}
.pro-slidercam table td:nth-child(2) {
  padding: 0 0 10px 5px;
}
.pro-slidercam table td:nth-child(3) {
  padding: 0 5px 10px 0;
}
.pro-slidercam table td:nth-child(4) {
  padding: 0 0px 10px 5px;
}
.whatsappico {
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
}
.transactionbox {
  padding: 0 15px;
}
.transactionbox td {
  padding: 0 0 10px 0;
}
.transactionbox h5 {
  color: #8694a0;
  font-size: 13px;
  padding: 0 0 1px 0;
}
.transactionbox label {
  color: #666;
  font-size: 14px;
}
.trasactablist {
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 10px;
  /* padding: 15px; */
}
.trasactablist tr:last-child {
  margin-bottom: 15px;
}
.trasactablist th {
  padding: 0 0 5px;
  color: #999;
  font-size: 12.5px;
}
.transactionbox table thead tr,
.transactionbox table tbody tr {
  background-color: transparent !important;
  border: none;
}
.lifetimevalue table tbody tr,
.pro-slidercam table tbody tr {
  border: none;
}
.trasactablist td {
  padding: 5px 0 3px;
  font-size: 12.5px;
  color: #777;
  font-weight: 600;
}
.trasactablist .tabscrol {
  max-height: 90px;
  overflow: auto;
}
.trasactablist .tabscrol::-webkit-scrollbar {
  width: 4px;
}
.trasactablist .tabscrol::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.trasactablist .tabscrol::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.trasactablist .tabscrol::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.productbox .prodscro::-webkit-scrollbar {
  width: 4px;
}
.productbox .prodscro::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.productbox .prodscro::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.productbox .prodscro::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sharecamp {
  margin-top: 25px;
}
.sharecamp h4 {
  font-size: 17px;
  font-weight: bolder;
  color: #666;
  margin-bottom: 8px;
}
.sharecamp ul {
  margin-bottom: 0px;
  float: right;
}
.sharecamp li {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 7px;
  margin-right: 20px;
  min-width: 160px;
  text-align: center;
  color: #888;
  font-size: 15px;
  cursor: pointer;
}
.sharecamp li:last-child {
  margin-right: 0px;
}
.sharecamp .ico {
  margin-right: 7px;
  height: 24px;
}
.sharecamp .emailico {
  margin-right: 7px;
  height: 18px;
}
.cust-name .ico {
  margin-left: 10px;
}
.sharecampmob {
  display: none;
}
.keyingsightdrp {
  display: none;
}
.productbox .nav-tabs {
  overflow: inherit;
  border: none;
  padding: 0 15px;
}
.productdesc {
  padding: 15px;
  width: 250px;
}
.productdesc h4 {
  color: #333;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 16px;
}
.productdesc p {
  color: #999;
  font-size: 13px;
}
.productdesc table {
  margin: 10px 0;
}
.productdesc table tbody tr {
  border-bottom: none;
}
.productdesc td {
  padding: 7px 0;
}
.productdesc ul {
  margin: 0;
}
.productdesc li {
  line-height: 0;
  display: inline;
}
.productdesc label {
  color: #666;
  font-size: 13px;
}
.productdesc span {
  display: inherit;
  visibility: hidden;
}
.productdesc .colorblue {
  background-color: #2a72c7;
  padding: 2px 6px;
  border-radius: 50px;
  display: inline;
  margin-right: 5px;
}
.productdesc .colorblack {
  background-color: #3e3e3e;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorgrey {
  background-color: #bbbaba;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorRed {
  background-color: #ef1634;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorYellow {
  background-color: #ffff00;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}

.productdesc .colorGreen {
  background-color: #008000;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .sizes a {
  color: #333;
  font-size: 13px;
  margin-right: 12px;
}
.productdesc .sizes a:hover,
.productdesc .sizes a:focus,
.productdesc .sizes a:active {
  color: #bbbaba;
}
.productdesc .sizes .active {
  color: #bbbaba;
}
.productdesc h3 {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding-top: 5px;
}
.custom-tableak .dropdown-label {
  font-size: 12px;
}
.custom-tableak .table-btnlabel {
  font-size: 11px;
}

.custom-tableak .txtStore,
.custom-tableak .txtStore::-webkit-input-placeholder {
  font-size: 13px;
}

.custom-tableak .txtStore,
.custom-tableak .txtStore:-ms-input-placeholder {
  font-size: 13px;
}

.custom-tableak .txtStore,
.custom-tableak .txtStore::-ms-input-placeholder {
  font-size: 13px;
}

.custom-tableak .txtStore,
.custom-tableak .txtStore::placeholder {
  font-size: 13px;
}
.chatbotwid {
  min-width: 300px;
}
/* .ant-table-pagination {
  padding: 0 20px;
}
.ant-table-pagination .anticon {
  vertical-align: text-bottom;
}
.ant-table-pagination.ant-pagination {
  float: right !important;
  margin: 5px 16px !important;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  min-width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  font-size: 10px !important;
}
.ant-pagination-item {
  min-width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}
.ant-pagination-item a {
  font-size: 11px !important;
} */
/* .broadcastimg {
  display: block;
} */
.broadcastpop .broadcasttitle {
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: 10px;
}
.broadcastpop .broadcastsubtitle {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}
.broadcastpop .ant-radio-checked .ant-radio-inner {
  border-color: #2561a8;
}
.broadcastpop .ant-radio-inner::after {
  background-color: #2561a8;
}
.broadcastpop .ant-radio-wrapper {
  display: block;
  margin-bottom: 10px;
}
.executebtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  min-width: 68px !important;
  cursor: pointer;
  margin-top: 15px;
  color: #fff;
  text-align: center;
  line-height: 0;
}
.innertabcollapse td {
  padding: 7px !important;
  font-size: 11px;
}
.lt-single td {
  display: block;
  padding: 43px 20px;
  text-align: center;
}
.productbox .nav {
  margin-bottom: 25px;
}
.productbox .store-nav-tabs .nav-link {
  color: #82909d !important;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.productbox .store-nav-tabs .nav-link.active {
  font-weight: 700;
  color: #82909d !important;
  border-bottom: 2px solid #82909d;
}
.tab-single .nav-item {
  width: 100%;
  text-align: center;
}
/* .tab-single .nav-link.active {
  display: block !important;
  text-align: center;
} */
.productbox .nav-link:focus,
.productbox .nav-link:hover:focus {
  outline: 0;
}
#sharecamp-popupmob {
  display: none;
}
#logout-ovrly-none {
  background-color: transparent;
  background: none;
}
.custom-tableak .react-pagination-js-bootstrap {
  background: #f9f9f9;
}
.custom-tableak .react-pagination-js-bootstrap ul {
  margin: auto;
  display: table;
  padding: 10px 0;
  box-shadow: none;
}
.custom-tableak .react-pagination-js-bootstrap ul li {
  border: none;
}
.custom-tableak .react-pagination-js-bootstrap .is-active {
  float: left;
  padding: 0px 10px;
  line-height: 25px;
  color: #fff;
  background-color: #2561a8;
  cursor: default;
  border-radius: 5px;
}
.custom-tableak .react-pagination-js-bootstrap .page,
.custom-tableak .react-pagination-js-bootstrap .pageElli {
  line-height: 25px !important;
  color: #333;
}
.border-red {
  border: 3px solid red;
}
/* ------------------Campain Css End ------------------------- */
.broadcastpop label {
  display: block;
}
.channelScript {
  display: block;
}
.broadcastpop .ant-radio-wrapper {
  display: block;
}
.hidedesk {
  display: none;
}
.status-btn-camp {
  display: contents;
}
.midalResponseAction .ant-table-row-collapsed,
.midalResponseAction .ant-table-row-expanded {
  display: none;
}
.custpagination {
  margin-top: 0 !important;
}
.Camp-pagination {
  margin-top: -22px;
}
.Camp-pagination select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAFC/+zoAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAADgAAAAAuXTW1AAAB7UlEQVQ4EaVSPU8CQRTkLgihseMkGkJsbCCxoFYvIXz2xFj7GaWy8TfYUKHxszaGHgiGiFrTGCg0JobQIPwAckjAGeJe1gM06hX39r03b2Z3dm026VO4jsfjt4qi6FJ93JIwuZ5IJAZybhspfOmKBKgjsWbUdd3j9/sdw1osFjvnIhQKzZjFYQc/bOBULiqgWhgMBpf5fH5JgMZFsjmdzurwtASQCWEegxF54BPYrNfrzlqt1jUHBAj7y8CqAPIkYksARf/XkWdoG4YRKJVKb99NY8sPUFxXITmHwzTp26QBXhXAj7lc7nl4Btrm8/kgZHisSgAXQfQKM7ZJaB563BCZYflLoVDYFOrmAAtiCCANaRaxCnBKgBlVOaHPtJF2ov5kBZvYaDS6GA6HZ83CPxfkIidpFJxrGvEQu9jBlsudTmetXC43/6LBm3G5XFfg0sF1Ao4D0yP64/V606qq7qF51+12V603MkmUz9PhcFyDeKXf7x81Go192ke8KSCGg8HglKZpfK5bqN33er1ksVikxyNfJBLR7HZ7Fo1lbOqs1WqlKpXKuwwcERBNWMfLSyPftVonW4H+Mfr7eGeGmJXjRAEBohAIMrBuA/GGdQiHYcUFYmoSsZj/UUAAaZ3b7T5m3m63d61WCJw1fgDTJeP2n8EQjQAAAABJRU5ErkJggg==) no-repeat right 4px center;
  background-size: 8px;
  padding-right: 17px;
  padding-left: 7px;
}

.campaign-status-drpdwn span {
  color: #4a4a4a;
}
.campaign-status-drpdwn li {
  display: block;
  margin-bottom: 8px;
}
.campaign-status-drpdwn li:last-child {
  margin-bottom: 0;
}
.custom-tableak .ant-table-filter-dropdown {
  border-radius: 10px;
}
.campaign-status-drpdwn {
  padding: 20px 35px;
  /* border-radius: 10px; */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.cust-name-drpdwn {
  padding: 17px 25px 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.cust-name-drpdwn label {
  margin-bottom: 8px;
}
.cust-name-drpdwn input::-webkit-input-placeholder {
  font-weight: 400;
  padding: 0;
}
.cust-name-drpdwn input:-ms-input-placeholder {
  font-weight: 400;
  padding: 0;
}
.cust-name-drpdwn input::-ms-input-placeholder {
  font-weight: 400;
  padding: 0;
}
.cust-name-drpdwn input::placeholder {
  font-weight: 400;
  padding: 0;
}
.campaign-status-drpdwn ul {
  margin: 0;
}
.campaign-status-drpdwn input {
  display: none;
}
.campaign-status-drpdwn label {
  position: relative;
  padding-left: 23px;
}
.campaign-status-drpdwn label::before {
  content: "";
  width: 14px;
  height: 14px;
  border: 1px solid #9a9a9a;
  position: absolute;
  border-radius: 2px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.campaign-status-drpdwn input:checked + label::after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid #9a9a9a;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
  position: absolute;
  left: 2px;
  top: 42%;
}
.camp-status-header {
  position: relative;
}
.camp-status-header .ant-table-filter-icon {
  position: absolute !important;
  left: 65px;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 12px !important;
}
.camp-status-header-cust-name .ant-table-filter-icon {
  left: 122px;
}
.camp-status-header-statusFilter .ant-table-filter-icon {
  left: 65px;
}
.camp-status-header .ant-table-filter-icon::before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAFC/+zoAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAADgAAAAAuXTW1AAAB7UlEQVQ4EaVSPU8CQRTkLgihseMkGkJsbCCxoFYvIXz2xFj7GaWy8TfYUKHxszaGHgiGiFrTGCg0JobQIPwAckjAGeJe1gM06hX39r03b2Z3dm026VO4jsfjt4qi6FJ93JIwuZ5IJAZybhspfOmKBKgjsWbUdd3j9/sdw1osFjvnIhQKzZjFYQc/bOBULiqgWhgMBpf5fH5JgMZFsjmdzurwtASQCWEegxF54BPYrNfrzlqt1jUHBAj7y8CqAPIkYksARf/XkWdoG4YRKJVKb99NY8sPUFxXITmHwzTp26QBXhXAj7lc7nl4Btrm8/kgZHisSgAXQfQKM7ZJaB563BCZYflLoVDYFOrmAAtiCCANaRaxCnBKgBlVOaHPtJF2ov5kBZvYaDS6GA6HZ83CPxfkIidpFJxrGvEQu9jBlsudTmetXC43/6LBm3G5XFfg0sF1Ao4D0yP64/V606qq7qF51+12V603MkmUz9PhcFyDeKXf7x81Go192ke8KSCGg8HglKZpfK5bqN33er1ksVikxyNfJBLR7HZ7Fo1lbOqs1WqlKpXKuwwcERBNWMfLSyPftVonW4H+Mfr7eGeGmJXjRAEBohAIMrBuA/GGdQiHYcUFYmoSsZj/UUAAaZ3b7T5m3m63d61WCJw1fgDTJeP2n8EQjQAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 12px;
  display: block;
  height: 6px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 12px;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header
  .anticon-filter.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header
  .ant-table-filter-icon.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header:hover
  .anticon-filter:hover,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header:hover
  .ant-table-filter-icon:hover {
  background: transparent;
}

.last-trans-table .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding: 11px 15px 6px !important;
  font-size: 12px;
  color: #7b7b7b;
}
.last-trans-table .ReactTable .rt-tbody .rt-td {
  padding: 4px 15px !important;
  font-size: 12px;
  color: #4a4a4a;
}
.last-trans-table .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: transparent;
}
.last-trans-table {
  padding-bottom: 10px;
}
#custMobileFilter {
  margin: 0 auto;
  width: 100%;
  max-width: 260px;
  right: 420px;
  top: 174px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}
.ChecknoDataCamp {
  /* text-align: center;
  display: block; */
  font-weight: bold;
  /* margin-top: 103px; */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 11px;
}
.keyingsightdrp-invert {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.full-data-insight {
  white-space: normal !important;
  white-space: initial !important;
}
.storeTickeTtl {
  color: #4934f6;
  font-family: inherit;
  font-size: 19px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 3px;
}
.storelabel-3 {
  margin-top: 0;
  font-size: 16px;
  padding-left: 3px;
  color: #5c5c5c;
}

/* store campaign tab starts */
.store-campaign-inner-table .ant-table-row-expand-icon,
.store-campaign-script-info {
  display: none;
}
.campaign-cust-popup .lifetimevalue {
  height: calc(100% - 18px);
}
/* store campaign tab ends */

/* ------------------Campain Media Queries ------------------------- */

@media (min-width: 768px) and (max-width: 850px) {
  #customername-popup {
    width: 730px;
  }
  .sharecamp li {
    min-width: 135px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  #customername-popup {
    width: 730px;
  }
  .sharecamp li {
    min-width: 135px;
  }
  .sharecamp {
    margin-top: 25px;
    display: none;
  }
  .sharecampmob {
    display: block;
  }
  .shareviabtn {
    background-color: #00b894;
    border-radius: 5px;
    padding: 7px;
    color: #ffff;
    margin-top: 15px;
    font-size: 12px;
    display: block;
    text-align: center;
  }
  .shareviabtn .shareviaimg {
    height: 13px;
    margin-right: 5px;
  }
  .headers-menu a {
    font-size: 13px !important;
  }
  .ChecknoDataCamp {
    /* margin: 30px 0; */
    margin: 0;
  }
  .lt-single td {
    display: table-cell;
    padding: 18px 20px;
    text-align: left;
  }
  .productbox {
    margin-top: 0;
  }
  .keyinsights {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .cusheade {
    display: none !important;
  }
  .headers-menu .single-menu:first-child {
    margin-left: 10px !important;
  }
  .single-menu {
    /* margin-left: 5px !important; */
    margin-left: 0 !important;
    color: #4a4a4a;
  }
  .single-menu.active {
    color: #2561a8;
    font-weight: 700;
  }
  /* .lowersec .header-icons-cntr img:first-child {
    display: block;
  }
  .lowersec .active .header-icons-cntr img:last-child {
    display: block;
  } */
  .headers-menu {
    display: flex;
    align-items: center;
    max-width: calc(100vw - 235px) !important;
  }
  .chatImg {
    width: 20px !important;
    cursor: pointer;
  }
  .notifi {
    width: 15px !important;
    cursor: pointer;
  }
  .setting {
    width: 18px !important;
    cursor: pointer;
  }
  .header-right-icons a {
    margin-right: 12px !important;
  }
  .custompop {
    right: 0 !important;
    width: 100% !important;
  }
  .antcustom .ant-popover-placement-bottom,
  .antcustom .ant-popover-placement-bottomLeft,
  .antcustom .ant-popover-placement-bottomRight {
    padding: 20px !important;
    left: 0 !important;
  }
  .insertpop1 {
    padding: 0px;
  }
  .antcustom .ant-popover-inner-content {
    padding: 20px;
  }
  .table-coloum-hide {
    display: none !important;
  }

  .particular-hide span {
    visibility: hidden !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-thead
    > tr
    > th {
    background: #e5e9ed !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak
    .custom-antd-table
    .ant-table-thead
    > tr
    > th
    .ant-table-column-title {
    font-size: 11px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-thead > tr > th,
  .custom-react-table .rt-thead .rt-th span {
    padding: 10px 7px 4px !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-tbody > tr > td,
  .custom-tableak .custom-react-table .rt-tbody .rt-td {
    padding: 7px !important;
    font-size: 11px;
  }
  .custom-tableak .closebtn {
    width: 60px;
    height: 20px;
    min-width: 42px !important;
  }
  .custom-tableak .hdrcloselabel {
    font-size: 10px;
    vertical-align: super;
  }
  .custom-tableak {
    padding: 5px 0;
  }
  .broadcast-icon img {
    height: 15px;
    cursor: pointer;
  }
  .hidedesk {
    display: inline-block;
  }
  .custom-tableak .cust-name {
    font-size: 12px;
  }
  .custom-tableak .sml-fnt {
    font-size: 10px !important;
  }
  .table-btnlabel {
    font-size: 10px;
    line-height: 20px;
  }
  .notConnectedBtnRed {
    width: 95px;
    height: 22px;
    line-height: 2;
    font-weight: normal;
  }
  .contactBtnGreen {
    width: 95px;
    line-height: 2;
    font-weight: normal;
    height: 22px;
  }
  .followUpBtnYellow {
    width: 95px;
    font-weight: normal;
    line-height: 2;
    height: 22px;
  }
  .followUpBtnBlue {
    width: 95px;
    height: 22px;
    line-height: 2;
    font-weight: 400;
  }
  .custom-tableak .antd-table-campaign .ant-table-row-collapsed::after {
    background-size: 17px 17px;
    width: 17px;
    top: 49%;
    height: 17px;
  }
  .custom-tableak .antd-table-campaign .ant-table-row-expanded::after {
    background-size: 17px 17px;
    width: 17px;
    top: 49%;
    height: 17px;
  }
  .info-icon-cp {
    width: 17px;
    height: 17px;
  }
  .antcustom .ant-popover-placement-bottom,
  .antcustom .ant-popover-placement-bottomLeft,
  .antcustom .ant-popover-placement-bottomRight {
    padding: 20px !important;
    left: 0 !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-thead
    > tr
    > th {
    background: #e5e9ed !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak
    .custom-antd-table
    .ant-table-thead
    > tr
    > th
    .ant-table-column-title {
    font-size: 11px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-thead > tr > th,
  .custom-react-table .rt-thead .rt-th span {
    padding: 10px 7px 4px !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-tbody > tr > td,
  .custom-tableak .custom-react-table .rt-tbody .rt-td {
    padding: 7px !important;
    font-size: 11px;
  }
  .cust-icon {
    height: 12px;
  }
  .nr-initials {
    width: 18%;
    font-size: 14px;
  }
  .nr-name {
    float: right;
    padding-left: 5px;
    width: 79%;
  }
  .nr-name h3 {
    font-size: 20px;
    letter-spacing: 0;
  }
  .nr-name span {
    font-size: 14px;
  }
  .nr-name p {
    font-size: 14px;
  }
  .lifetimevalue h4 {
    font-size: 13px;
  }
  .lifetimevalue label {
    font-size: 20px;
  }
  .keyinsights h4 {
    font-size: 13px;
  }
  .keyinsights p {
    font-size: 12px;
    margin-bottom: 5px;
    padding-right: 0;
    max-height: none;
    max-height: initial;
  }
  .productbox .nav-tabs .nav-link.active {
    font-size: 13px;
  }
  .productbox .nav-tabs .nav-link {
    font-size: 13px;
    display: inline;
  }
  .pro-slidercam h4 {
    font-size: 12px;
    margin-top: 3px;
  }
  .sharecamp {
    margin-top: 25px;
  }
  .cust-name .ico {
    margin-left: 4px;
  }
  .keyingsightdrp {
    display: block;
    height: 7px;
    width: 14px;
    margin: auto;
  }
  .productbox {
    padding: 18px 20px;
    height: auto;
    min-height: 80px;
  }
  .lt-single {
    margin-bottom: 15px;
  }
  .keyinsights {
    padding: 18px 20px;
    height: auto;
  }
  .trasactablist th {
    font-size: 12px;
  }
  .trasactablist td {
    font-size: 12px;
  }
  .transactionbox label {
    font-size: 13px;
  }
  .info-icon-cp {
    bottom: 4px;
  }
  .customernamecam {
    max-width: 90px;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  #customername-popup {
    width: 90%;
    padding: 30px 20px 20px;
  }
  .customername-popupmob .nav-tabs {
    border: none;
    margin: auto;
    display: block;
    text-align: center;
  }
  .customername-popupmob .nav-tabs .nav-link.active {
    color: inherit;
    border: none;
    background: none;
  }
  .customername-popupmob .nav-item:first-child .nav-link {
    color: #ee6261 !important;
    font-weight: 600;
    border-radius: 50px;
    border: 1px solid #ee6261;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 12px;
    min-width: 110px;
    margin-right: 10px;
  }
  .customername-popupmob .nav-item:last-child .nav-link {
    color: #2196f3 !important;
    font-weight: 600;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    min-width: 110px;
    border: 1px solid #2196f3;
    padding: 6px 15px;
  }
  .customername-popupmob .tab-content {
    padding-top: 18px;
    padding-bottom: 0;
  }
  .customername-popupmob .poptitle {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 15px;
  }
  .channelScript {
    display: block;
    font-size: 11px;
  }
  .camperiod {
    border-top: 1px solid #ddd;
    margin-top: 15px;
    padding-top: 15px;
  }
  .camperiod h4 {
    font-size: 11px;
    font-weight: 600;
    color: #888;
  }
  .camperiod h4 span {
    color: #333;
    margin-left: 15px;
  }
  #sharecamp-popup {
    width: 90%;
    padding: 20px 10px 20px;
    position: relative;
    border-radius: 20px;
  }
  #sharecamp-popup h4 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  #sharecamp-popup table tbody tr {
    border: none;
  }
  #sharecamp-popup table td {
    width: 50%;
    padding: 0px;
  }
  #sharecamp-popup .chatbox {
    padding: 10px;
    background: #f2f5f8;
    margin: 0 5px 10px 0;
    position: relative;
    border-radius: 10px;
  }
  #sharecamp-popup table td:nth-child(2) .chatbox {
    margin: 0 0 10px 5px;
  }
  #sharecamp-popup table td:nth-child(3) .chatbox {
    margin: 0 5px 0px 0;
  }
  #sharecamp-popup table td:nth-child(4) .chatbox {
    margin: 0 0px 0px 5px;
  }
  #sharecamp-popup .ico {
    display: block;
    height: 30px;
    margin: auto;
    margin-bottom: 6px;
  }
  #sharecamp-popup a {
    text-align: center;
    display: block;
    font-size: 11px;
    font-weight: normal;
    color: #333;
    outline: none;
  }
  #sharecamp-popup .tick {
    height: 13px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-prodesc .ant-popover-arrow {
    display: none !important;
  }
  .ant-prodesc {
    left: 15px !important;
  }
  /* .broadcastimg {
  display: none;
} */
  .broadcastpop .broadcasttitle {
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
  }
  .broadcastpop .broadcastsubtitle {
    color: #999;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .broadcastpop .ant-radio-checked .ant-radio-inner {
    border-color: #2561a8;
  }
  .broadcastpop .ant-radio-inner::after {
    background-color: #2561a8;
  }
  .broadcastpop .ant-radio-wrapper {
    display: block;
    margin-bottom: 10px;
  }
  .executebtn {
    background-color: #2561a8;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    min-width: 68px !important;
    cursor: pointer;
    margin-top: 15px;
    color: #fff;
    text-align: center;
    line-height: 0;
  }
  .midalResponseAction .ant-table-row-collapsed,
  .midalResponseAction .ant-table-row-expanded {
    display: block;
  }
  .custom-tableak .txtStore,
  .custom-tableak .txtStore::-webkit-input-placeholder {
    font-size: 12px;
  }
  .custom-tableak .txtStore,
  .custom-tableak .txtStore:-ms-input-placeholder {
    font-size: 12px;
  }
  .custom-tableak .txtStore,
  .custom-tableak .txtStore::-ms-input-placeholder {
    font-size: 12px;
  }
  .custom-tableak .txtStore,
  .custom-tableak .txtStore::placeholder {
    font-size: 12px;
  }
  .innertabcollapse .dateTimeStore {
    width: 160px;
    height: 28px;
  }
  .innertabcollapse .responceDrop-down {
    width: 160px;
    height: 28px;
  }
  .responceDrop-down {
    background-size: 10px !important;
    padding-right: 30px !important;
  }
  .custom-tableak .saveLabel {
    text-align: center;
    font-size: 11px;
    width: 60px;
    height: 28px;
  }
  #sharecamp-popupmob {
    display: block;
    border-radius: 20px;
  }
  .productbox .nav-tabs {
    padding: 0;
  }
  .productbox .prodscro {
    padding: 0;
    max-height: 200px;
  }
  #sharesuccesfullpopup .tick {
    height: 40px;
    margin: auto;
    display: block;
    margin-bottom: 15px;
  }
  #sharesuccesfullpopup {
    border-radius: 20px;
    width: 100%;
  }
  #sharesuccesfullpopup h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }
  #sharesuccesfullpopup p {
    font-size: 11px;
    text-align: center;
    margin-bottom: 15px;
  }

  .custom-tableak .react-pagination-js-bootstrap .page,
  .custom-tableak .react-pagination-js-bootstrap .pageElli,
  .custom-tableak .react-pagination-js-bootstrap .is-active {
    padding: 0 7px;
    font-size: 11px;
  }
  .custom-tableak .react-pagination-js-bootstrap ul {
    margin: 0;
    margin: initial;
    margin-left: auto;
    margin-right: 4px;
  }
  .Camp-pagination p {
    display: none;
  }
  .Camp-pagination select {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .Camp-pagination {
    left: 12px;
  }
  .transactionbox {
    padding: 0;
  }

  .campaign-status-drpdwn,
  .cust-name-drpdwn {
    padding: 15px 20px;
    font-size: 12px;
  }
  .cust-name-drpdwn {
    padding-bottom: 20px;
    max-width: 200px;
  }
  .cust-name-drpdwn label {
    margin-bottom: 5px;
  }
  .cust-name-drpdwn input {
    height: 35px;
  }
  .cust-name-drpdwn input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .cust-name-drpdwn input:-ms-input-placeholder {
    font-size: 12px;
  }
  .cust-name-drpdwn input::-ms-input-placeholder {
    font-size: 12px;
  }
  .cust-name-drpdwn input::placeholder {
    font-size: 12px;
  }
  .campaign-status-drpdwn label::before {
    width: 13px;
    height: 13px;
  }
  .camp-status-header .ant-table-filter-icon::before {
    background-size: 10px;
    width: 10px;
    height: 5px;
  }
  .camp-status-header .ant-table-filter-icon {
    left: 45px;
    top: 48% !important;
  }
  .camp-status-header-cust-name .ant-table-filter-icon {
    left: 95px;
  }
  .camp-status-header-statusFilter .ant-table-filter-icon {
    left: 43px;
  }
  .campaign-status-drpdwn input:checked + label::after {
    left: 1.5px;
  }
  .table-drpdwn {
    width: 10px;
  }
  .productbox .store-nav-tabs .nav-link {
    margin-left: 0;
  }
  .productbox .nav {
    margin-bottom: 20px;
  }
  #insight-data {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
/* ------------------Campain Css End ------------------------- */

/* ------------------Appointment Css Start ------------------------- */
.custom-tabcount {
  background: #2561a8;
  display: inline-block;
  width: 100px;
  padding: 4px 8px 4px 12px;
  border-radius: 3px;
  /* margin-left: 25px; */
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-title {
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.tab-count {
  width: 26px;
  height: 26px;
  line-height: 25px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 2px;
  color: #2561a8;
  font-size: 14px;
  font-weight: 700;
}
.mid-divider {
  width: 0.5px;
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  display: none;
  margin: 0 10px;
}
.custom-tabs {
  /* margin-bottom: 15px; */
  /* margin-left: -5px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background: #ecf2f4;
  padding: 12px 20px;
}
.custom-tabcount1 {
  background-color: rgba(37, 97, 168, 0.1);
  display: inline-block;
  width: 100px;
  padding: 4px 8px 4px 12px;
  border-radius: 3px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-title1 {
  display: inline-block;
  /* width: 20px;
  height: 30px; */
  /* margin-right: 48px; */
  color: #4a4a4a;
  font-size: 15px;
  font-weight: 700;
}
.tab-count1 {
  width: 26px;
  height: 26px;
  line-height: 25px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  border-radius: 2px;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
}
.custom-tableak .statusBtn {
  background-color: #00b894;
  border: #00b894;
  border-radius: 4px;
  width: 60px;
  height: 30px;
}
.statusLabel {
  color: white;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: none;
  text-transform: initial;
}
.appointment-mobile {
  display: none;
}
.appointment-desktop {
  display: table-cell;
}
.appt-status .ant-select-focused .ant-select-selection,
.appt-status .ant-select-selection:focus,
.appt-status .ant-select-selection:active {
  box-shadow: none;
  border-color: #e4e4e4;
}
.appt-status .ant-select {
  width: 140px;
}
.appt-status .ant-select-selection {
  border-radius: 3px;
  border-color: #e4e4e4;
}
.appt-status .ant-select-selection:hover {
  border-color: #e4e4e4;
}
.appt-status .ant-select-selection-selected-value {
  font-weight: 400;
  color: #4a4a4a;
}
.appt-status-dropdown {
  border-radius: 10px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.appt-status-dropdown ul .ant-select-dropdown-menu-item:last-child {
  background: #fff !important;
}
.appt-status-dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #e6f7ff !important;
}
.appt-status-dropdown .ant-select-dropdown-menu-item {
  color: #7b7b7b;
}
.appt-status .ant-select-selection__placeholder {
  font-weight: 400;
  color: #7b7b7b;
}
.appt-status .ant-select-selection::after {
  content: "";
  position: absolute;
  border: solid #979797;
  border-width: 0 1px 1px 0;
  padding: 3px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 12px;
  top: 10px;
}

/* appointment starts chetan */
.appoint-outer-pad {
  padding: 0 15px;
}
.customer-count-block {
  background-color: #ffffff;
  border: 1px solid #e4eff3;
  border-radius: 8px;
  width: 373px;
  padding: 6px 15px;
  margin: 10px auto;
}
.vacancy-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tot-all {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.tot-cnt {
  color: #2561a8;
  font-size: 12px;
  letter-spacing: 0px;
}
.cust-store {
  font-size: 11px;
  color: #dc6d1f;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.cust-prog-outer {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: #e4e4e4;
  position: relative;
  margin: 4px 0;
}
.cust-prog-fill {
  position: absolute;
  top: 0;
  left: 0;
  background: #ff9d58;
  height: 100%;
  border-radius: 3px;
}
.cust-prog-circ {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  border-radius: 50%;
  background: #ff9d58;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  line-height: 14px;
}
.empty-filled-info {
  display: flex;
}
.empty-filled-info div {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.empty-info {
  background: #00b894;
}
.filled-info {
  background: #ff9d58;
  cursor: pointer;
}
.total-info {
  width: 100%;
  margin-right: 5px;
}
.slot-info p {
  font-size: 11px;
  font-weight: 700;
}
.slot-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
}
.appointment-top-right {
  display: flex;
}
.appointment-top-right .butn {
  padding: 8px 11px;
  color: #2561a8;
  background: #fff;
  border: 1px solid #2561a8;
  border-radius: 3px;
  margin-left: 10px;
  display: inline-block;
}
.appointment-top-right .butn:first-child {
  margin-left: 0;
}
.appoint-search {
  flex: 0 0 16px;
  width: 16px;
  display: inline-block;
  line-height: 1;
}
.appoint-search img {
  width: 100%;
}
.appoint-input {
  border: 0;
  height: 20px;
  width: 0;
  margin-right: 0;
  padding: 0;
}
.appoint-input::-webkit-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.appoint-input:-ms-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.appoint-input::-ms-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.appoint-input::placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.appoint-input-full {
  width: 245px;
  margin-right: 16px;
  margin-left: 6px;
}
.back-butn {
  background-color: #2561a8;
  border-radius: 3px;
  position: relative;
  padding: 7px 15px;
  width: 100px;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.back-butn:hover {
  color: #fff;
}
.back-butn .arrow-left {
  left: 15px;
}
.appoint-search-res {
  font-weight: 700;
  font-size: 12px;
  display: block;
  margin: 10px 0;
}

#create-appoint-popup {
  max-width: 340px !important;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.appnt-top-blue {
  background: #2561a8;
  padding: 15px 20px 20px 20px;
  color: #fff;
}
.appnt-top-blue h5 {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.arrow-left {
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(135deg) translateY(50%);
          transform: rotate(135deg) translateY(50%);
  position: absolute;
  top: 50%;
  left: 5px;
  cursor: pointer;
}
.create-appnt-details h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-top: 25px;
}
.appnt-info-cntr {
  display: flex;
  margin-top: 10px;
}
.appnt-info-cntr p {
  font-size: 12px;
}
.appnt-img-cntr {
  line-height: 1;
  margin-right: 8px;
  flex: 0 0 13px;
  text-align: center;
}
.appnt-bottom-white {
  padding: 20px 25px;
}
.appnt-input-group {
  margin-bottom: 15px;
}
.appnt-input-group label {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  display: block;
}
.appnt-input-group input,
.appnt-input-group select {
  font-size: 12px;
  color: #2561a8;
  border: 0;
  border-bottom: 0.5px solid #000000;
  width: 100%;
  padding: 2px 0;
  margin-top: 3px;
}
.appnt-input-group input::-webkit-input-placeholder {
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.appnt-input-group input:-ms-input-placeholder {
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.appnt-input-group input::-ms-input-placeholder {
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.appnt-input-group input::placeholder {
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.appnt-input-group .react-datepicker-wrapper {
  width: 100%;
}
.appnt-input-group .react-datepicker-wrapper + div button {
  min-width: auto;
}
.appoint-date {
  background: url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 5px
    center;
  background-size: 12px;
  padding-right: 22px !important;
}
.appoint-time {
  color: rgba(0, 0, 0, 0.4) !important;
}
.appnt-input-group select option {
  color: #000;
}
.appnt-input-group select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 5px center;
  background-size: 10px;
  padding-right: 20px;
}
.time-date-sep {
  position: relative;
}
.time-date-sep::before {
  content: "/";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.appnt-input-group input::-webkit-outer-spin-button,
.appnt-input-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.appnt-input-group input[type="number"] {
  -moz-appearance: textfield;
}
.appoint-butn {
  background: #07a287;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  padding: 15px 24px;
  margin: 15px auto 0;
  min-width: 185px;
}
.appoint-butn-blue {
  background: #2561a8;
}
.appoint-butn-red {
  background: #e02020;
}
.appoint-butn-orange {
  background: #f37b2b;
}
.appoint-butn-grey {
  background: #aeaeae;
}
.appoint-cancel {
  font-size: 12px;
  color: #000;
  margin-top: 8px;
  display: inline-block;
}
.appoint-cancel:hover {
  color: #000;
}
.ticket-cut {
  position: relative;
}
.ticket-cut::before,
.ticket-cut::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  left: -10px;
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.8); */
  background: #909aa9;
  /* background: url("./../Images/half-circ.png") no-repeat center center; */
}
.ticket-cut::after {
  left: auto;
  left: initial;
  right: -10px;
}
.promotional-sms input {
  display: none;
}
.promotional-sms label {
  font-size: 12px;
  color: #2561a8;
  padding: 2px 0;
  margin-top: 3px;
  font-weight: 400;
  position: relative;
  padding-left: 25px;
}
.promotional-sms label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #2561a8;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.promotional-sms input:checked + label::after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid #2561a8;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
  position: absolute;
  left: 3px;
  top: 42%;
}
.verify-img {
  width: 18px;
  line-height: 1;
}
.verify-img img {
  width: 100%;
}
.number-verified {
  display: flex;
  align-items: center;
  margin-left: 2px;
}
.number-verified span {
  color: #9c9c9c;
  font-size: 12px;
  margin-left: 4px;
}

.people-img {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 17px;
}
.people-img img {
  width: 100%;
}
.appoint-update-det .col-md-7 {
  flex: 0 0 54%;
  max-width: 54%;
  padding-right: 5px;
}
.appoint-update-det .col-md-5 {
  flex: 0 0 46%;
  max-width: 46%;
  padding-left: 5px;
}
.appnt-bottom-white-update .appnt-input-group label {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.people-selection span {
  background-color: #daddec;
  border-radius: 8px;
  width: 26px;
  height: 26px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.people-selection span.active {
  background: #2561a8;
  color: #fff;
}
.people-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 10px;
  z-index: 0;
  margin-top: 15px;
}
.people-selection::before {
  content: "";
  background-color: #daddec;
  border-radius: 2px;
  width: 100%;
  height: 9px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  z-index: -1;
}
.appnt-bottom-white-update .ticket-cut {
  height: 1px;
  margin-top: 30px;
}
.appnt-bottom-white-update .ticket-cut::before {
  left: -35px;
}
.appnt-bottom-white-update .ticket-cut::after {
  right: -35px;
}
.appnt-bottom-white-update .ticket-cut span {
  border: 0.5px dashed #000;
  display: block;
  position: absolute;
  left: -15px;
  width: calc(100% + 30px);
}
.appoint-code {
  background-color: #f4f6f9;
  border-radius: 5px;
  margin: 0 10px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}
.appoint-code p {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 3px;
}
.appoint-code span {
  font-size: 20px;
  color: #2561a8;
  font-weight: 700;
}
.otp-appoint input {
  font-size: 16px;
}
.otp-appoint-input {
  width: 36px !important;
  margin-right: 10px !important;
}
.resend-otp p {
  color: #9c9c9c;
  font-size: 12px;
}
.resend-otp a {
  color: #2561a8;
  font-size: 12px;
  display: block;
}
.resend-otp a:hover {
  color: #2561a8;
}
.resend-otp {
  margin: 10px 0 15px;
}
.edit-num,
.edit-num:hover {
  color: #2561a8;
}
.otp-appoint-height {
  min-height: 219px;
}
.add-appoint-mob {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #2561a8;
  border-radius: 15px 15px 0 0;
  padding: 15px;
  display: none;
}
.broadcastpop {
  max-height: 350px;
  overflow: auto;
}
.broadcastpop::-webkit-scrollbar {
  width: 4px;
}
.broadcastpop::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.broadcastpop::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.broadcastpop::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#promo-sms + label {
  white-space: nowrap;
}
.mobile-appoint-search {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 20px 15px;
  align-items: center;
  display: none;
}
.mobile-appoint-search .appoint-input {
  width: 100%;
  margin-right: 11px;
}

.custom-tableak .visitedBtn {
  background-color: #ff9d58;
  border: #ff9d58;
}

.custom-tableak .partialBtn {
  background-color: #eb4d4b;
  border: #eb4d4b;
}

.custom-tableak .endVisitBtn {
  background-color: #00b894;
  border: #00b894;
}

.check-svr {
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  width: 35%;
  padding: 25px 0px;
  background: #ffff;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
}
.check-svcBtn {
  width: 180px;
  height: 40px;
  background-color: #2561a8;
  color: #fff;
  margin-top: 25px;
  cursor: pointer;
}
#check-service-tab {
  text-align: center;
}
.main-pincodeDiv {
  text-align: center;
  margin-top: 20px;
}
.mobileblock {
  display: none;
}
@media screen and (max-width: 600px) {
  .customer-count-block {
    background: #f5f6fc;
    border: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 11px 0 0;
  }
  .total-dis {
    display: none;
  }
  .slot-info {
    background: #f1f1f1;
    margin-top: 11px;
    padding: 2px 15px;
  }
  .vacancy-info {
    padding: 0 15px;
  }
}
/* appointment ends chetan */

@media screen and (max-width: 600px) {
  .tab-count,
  .tab-count1 {
    width: auto;
    height: auto;
    line-height: normal;
    line-height: initial;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
  }
  .tab-count {
    color: #2561a8;
  }
  .mid-divider {
    display: block;
  }
  .tab-title1,
  .tab-title {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6);
  }
  .tab-title {
    color: #2561a8;
  }
  .custom-tabs {
    padding-right: 15px;
    padding-left: 15px;
    background: #2561a8;
  }
  .appoint-outer-pad {
    padding: 0;
  }
  /* .custom-table-ck .table-cntr {
    padding-top: 11px;
  } */
  .custom-table-ck {
    padding-top: 0;
  }
  .custom-tabcount1,
  .custom-tabcount {
    margin-left: 0;
    margin-right: 20px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    padding: 15px 12px;
    /* min-width: 100px;
    width: auto; */
    flex-grow: 1;
  }
  .custom-tabcount {
    background: rgba(255, 255, 255, 1);
  }
  .custom-tabcount .mid-divider {
    background: #2561a8;
  }
  .custom-tabs .appnt-date-cntr div:last-child {
    margin-right: 0;
  }
  .appointment-mobile {
    display: table-cell;
  }
  .appointment-desktop {
    display: none;
  }
  .appt-cust-name {
    font-size: 12px;
    color: #4a4a4a;
  }
  .appt-cust-mob {
    font-weight: 400;
    color: #7b7b7b;
  }
  .custom-table-ck
    .custom-antd-table.antd-table-campaign
    .ant-table-expanded-row
    .ant-table-tbody
    > tr:first-child
    > td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .custom-table-ck .statusBtn,
  .custom-table-ck .saveBtn {
    width: 95px;
    height: 22px;
    font-weight: 400;
    margin-right: 0 !important;
  }
  .custom-table-ck .saveBtn {
    line-height: 1.3;
  }
  .custom-table-ck .statusBtn {
    line-height: 1;
  }
  .custom-table-ck .statusLabel {
    font-size: 12px;
  }
  .custom-table-ck .saveLabel {
    font-weight: 400;
  }
  .appt-status .ant-select {
    width: 105px;
  }
  .appt-status .ant-select-selection--single .ant-select-selection__rendered {
    margin-left: 8px;
    margin-right: 18px;
    font-size: 12px;
  }
  .appt-status-dropdown .ant-select-dropdown-menu-item {
    font-size: 12px;
    line-height: 20px;
  }
  .appt-status .ant-select-selection::after {
    right: 7px;
  }
  .appointment-top-right {
    display: none;
  }
  .appnt-date-cntr {
    justify-content: space-between;
    width: 100%;
  }
  .add-appoint-mob {
    display: block;
  }
  .antd-table-appoint {
    padding-bottom: 65px;
  }
  .mobile-appoint-search {
    display: flex;
  }
  .appoint-search-res {
    margin: 0;
    background: #fff;
    font-size: 11px;
    padding: 5px 15px;
  }

  /* ant pagination starts */
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px !important;
  }
  .ant-pagination-item a {
    font-size: 10px !important;
    padding: 0 4px !important;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 22px !important;
    height: 22px !important;
    line-height: 17px !important;
    margin-right: 5px !important;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 17px !important;
    height: 17px !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    font-size: 10px;
  }
  .ant-pagination-item {
    line-height: 20px !important;
  }
  .ant-select-selection--single {
    font-size: 10px !important;
    height: 22px !important;
  }
  .ant-select-selection__rendered {
    line-height: 20px !important;
    margin-left: 8px !important;
  }
  .ant-pagination-options {
    margin-left: 5px !important;
  }
  .ant-select-dropdown-menu-item {
    padding: 0 7px !important;
    font-size: 10px !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 10px 0 !important;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 10px !important;
  }
  /* ant pagination ends */
}

@media screen and (max-width: 576px) {
  .ant-pagination-options {
    display: inline-block !important;
  }
  .create-appoint-popup {
    padding: 15px !important;
  }

  /* check service starts */
  .check-svr {
    width: calc(100% - 30px);
    margin-top: 5px;
  }
  /* check service ends */

  /* store campaign tab starts */
  .store-task-tab-cont {
    padding: 0 !important;
  }
  .store-campaign-table .ant-table table {
    border-radius: 0;
  }
  .custom-antd-table.antd-table-campaign.store-campaign-table
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding: 14px 5px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px;
    padding-left: 15px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0px;
    padding-right: 15px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 15px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 15px !important;
    position: relative;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table
    .ant-table-row-indent
    + .ant-table-row-expand-icon {
    position: static;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr.ant-table-expanded-row
    > td {
    padding: 0 !important;
    /* background: #f0f0f0 !important; */
    background: #dbdbdb !important;
  }
  .store-campaign-inner-table .ant-table-row-expand-icon {
    display: block;
  }
  .store-campaign-inner-table .saveBtn {
    display: none;
  }
  .store-campaign-inner-cntr .saveBtn {
    display: block;
    margin: auto !important;
    margin-top: 20px !important;
    width: 80px;
  }
  .store-campaign-table .cust-name {
    white-space: normal;
    white-space: initial;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td {
    padding: 12px 5px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 15px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 15px !important;
  }
  .store-campaign-inner-title {
    color: #4a4a4a;
    margin-bottom: 6px;
  }
  .store-campaign-inner-cntr > div {
    margin-bottom: 15px;
  }
  .store-campaign-inner-cntr > div:last-child {
    margin-bottom: 5px;
  }
  .store-campaign-inner-cntr .table-btnlabel {
    font-size: 12px;
    font-weight: 700;
    line-height: 31px;
    width: 103px;
    height: 31px;
    margin-right: 10px;
  }
  .not-contacted-count {
    right: 12px;
  }
  .store-campaign-inner-cntr .followUpBtnYellow {
    margin-right: 0;
  }
  .store-campaign-script-info {
    display: block;
    padding: 12px 15px;
  }
  .store-campaign-inner-cntr .dateTimeStore {
    width: 190px;
  }
  .mobileblock {
    display: block;
  }
  .campaign-cust-popup .lifetimevalue {
    height: auto;
  }
  .store-task-tabs-mobile {
    display: none;
  }
  /* store campaign tab ends */
}

@media screen and (max-width: 400px) {
  .custom-tabcount1,
  .custom-tabcount {
    margin-right: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .mid-divider {
    margin: 0 7px;
  }
}
@media screen and (max-width: 360px) {
  .custom-tabcount1,
  .custom-tabcount {
    margin-right: 12px;
  }
  .tab-count,
  .tab-count1 {
    font-size: 16px;
  }
  .appoint-update-det .col-md-7,
  .appoint-update-det .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .appnt-bottom-white-update .appnt-input-group .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 5px;
  }
  .appnt-bottom-white-update {
    margin-top: -5px;
  }
  .broadcastpop {
    padding: 0;
  }
  #sharecamp-popupmob {
    padding: 20px 14px 5px;
  }
}

/* ------------------Appointment Css End ------------------------- */

/* Datepicker responsive starts */

@media screen and (max-width: 600px) {
  .date-time-resp .react-datepicker__day-name,
  .date-time-resp .react-datepicker__day,
  .date-time-resp .react-datepicker__time-name {
    width: 1.5rem !important;
    line-height: 1.5rem !important;
  }
  .date-time-resp .react-datepicker__current-month,
  .date-time-resp .react-datepicker-time__header,
  .date-time-resp .react-datepicker-year-header {
    font-size: 14px !important;
  }
  .date-time-resp .react-datepicker-popper {
    margin-left: -119px;
  }
  .date-time-resp .react-datepicker__triangle {
    margin-left: 90px !important;
  }
}

/* Datepicker responsive ends */

@media screen and (max-width: 359px) {
  .trasactablist {
    margin: 0 -16px;
  }

  /* check service starts */
  .check-svr {
    width: calc(100% - 20px);
  }
  /* check service ends */

  /* store campaign tab starts */
  .store-campaign-table .ant-table-thead > tr:first-child > th:first-child {
    padding-left: 10px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    font-size: 12px;
  }
  .store-campaign-table .closebtn {
    width: 70px;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr.ant-table-expanded-row
    > td {
    padding: 0 !important;
  }
  .store-campaign-table .cust-name {
    font-size: 13px;
  }
  .store-campaign-inner-cntr .responceDrop-down {
    font-size: 12px;
    line-height: 19px;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 10px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-inner-cntr .table-btnlabel {
    width: 93px;
  }
  .store-campaign-inner-cntr .react-datepicker__month {
    margin-right: 0;
    margin-left: 0;
  }
  .store-campaign-inner-cntr .react-datepicker__time-container,
  .store-campaign-inner-cntr
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 75px;
  }
  .store-campaign-script-info {
    padding: 12px 10px;
  }
  /* store campaign tab ends */
}

p {
  margin-bottom: 0;
}
.cr-pnt {
  cursor: pointer;
}
a {
  cursor: pointer;
}
.card-Nav {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  /* margin-bottom: 30px; */
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: white;
}
.rectangle-nav-header {
  background-color: #ffffff;
  width: 1260px;
  height: 57px;
}
.dashboards {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.btnClickDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.cls-hide {
  display: none;
}
.my-tickets {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.claim-space {
  margin-top: -2.9%;
  margin-left: 41%;
}
.claim-label {
  margin-left: 333%;
}
.claim-logo {
  width: 16px;
}
.knowledge-base {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.bitmap {
  background-color: #4a4a4a;
  width: 26px;
  height: 26px;
}
.bitmap {
  width: 25px;
  height: 25px;
}
.oval-4 {
  background-color: #ffb629;
  border-radius: 100%;
  width: 19px;
  height: 19px;
}
.card .card-header1 {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
  /* padding: 3px 0px; */
}
.main-header {
  width: 5%;
  background: "#2561A8";
  color: "#FFFFFF";
  /* padding-left: '1.5%' */
}
.rectangle-2 {
  background-color: #2561a8;
  width: 63px;
  height: 57px;
}
.rectangle-1 {
  background-color: #ffffff;
  width: auto;
  height: 57px;
  margin: 20px;
}
.card-row {
  height: 55px;
}
.er {
  height: 58px; /* 60px; */
  flex: 0 0 60px;
  background: #2561a8;
  color: #fff;
  line-height: 58px;
  text-align: center;
}
.bell-icon {
  flex: 0 0 85px;
  background: #fff;
  border-right: 1px solid #e9e9e9;
  line-height: 53px;
}
.bell-icon img {
  width: 50px;
  cursor: pointer;
}
.hamb-menu {
  width: 20px;
  margin-top: 17px;
  float: none;
  display: none;
}
.hamb-menu img {
  width: 100%;
}
.er-label {
  font-size: 17px;
  font-weight: 700;
}
.headers-menu .active {
  font-weight: 700;
  color: #2561a8;
}
.header-icons-cntr img:first-child {
  display: block;
}
.header-icons-cntr img:last-child {
  display: none;
}
.headers-menu .active .header-icons-cntr img:first-child {
  display: none;
}
.headers-menu .active .header-icons-cntr img:last-child {
  display: block;
}
.headers-menu {
  display: flex;
  align-items: center;
  max-width: calc(100vw - 324px);
  overflow: auto;
  white-space: nowrap;
  flex: 1 0 100%;
}
/* .ordermainrow .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  width: auto !important;
}
.ordermainrow .ReactTable .rt-tbody .rt-td {
  width: auto !important;
} */

.ordermainrow .rt-table::-webkit-scrollbar {
  height: 5px;
}
.ordermainrow .rt-table::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.ordermainrow .rt-table::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.ordermainrow .rt-table::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.headers-menu::-webkit-scrollbar {
  height: 5px;
}
.headers-menu::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.headers-menu::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.headers-menu::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.headers-menu a {
  color: #4a4a4a;
  line-height: 1;
  font-size: 16px;
}
.header-icons-cntr {
  line-height: 1;
  margin-right: 8px;
}
.single-menu {
  display: flex;
  align-items: center;
  margin-left: 45px;
}
.headers-menu .single-menu:first-child {
  margin-left: 30px;
}
.rectangle-2 {
  background-color: #2561a8;
  width: 63px;
  height: 57px;
}
.dashboards-header {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  width: 11%;
}
.campaign-logo {
  width: 18px;
}
.my-tickets {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  width: 10%;
}
.knowledge-base {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.header-right-icons {
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.header-right-icons a {
  display: inline-block;
  color: #4a4a4a;
  margin-right: 20px;
  line-height: 1;
}
.icon-fullname {
  margin-left: 6px;
  margin-right: 25px;
}
.bitmap {
  background-color: #2561a8;
  width: 21px;
  height: 21px;
}
.bitmap1 {
  width: 26px;
  height: 26px;
  margin-top: 15px;
}
.bitmap2 {
  width: 26px;
  height: 26px;
  margin-top: -23px;
  margin-left: 40px;
}
.bitmap3 {
  width: 24px;
  height: 24px;
  margin-top: -29px;
  margin-left: 80px;
}
.bitmap4 {
  width: 24px;
  height: 24px;
  margin-top: -23px;
  margin-left: 116px;
}
.bitmap5 {
  width: 30px;
  height: 30px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 10px;
  line-height: 27px !important;
  text-align: center;
  font-weight: 600;
}
.main-nav {
  margin-left: 17.5%;
}
.sign-in {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  padding-top: 10px;
  display: block;
  margin-bottom: 15px;
  /* margin-bottom: 30px; */
}
.dashboardImg1 {
  width: 21px;
}
.stores-icon {
  width: 24px;
}
.myTicket {
  width: 19px;
}
.order-blue {
  width: 18px;
}
.knowledgeNav {
  width: 19px;
}
.calImg {
  width: 19px;
}
.chatImg {
  width: 26px;
  cursor: pointer;
}
.notifi {
  width: 18px;
  cursor: pointer;
}
.setting {
  width: 21px;
  cursor: pointer;
}
.dashboards-label1 {
  font-size: 16px;
  vertical-align: middle;
  padding-left: 3px;
  /* margin-top: -117%;
  margin-left: 100px; */
}
.myTiket-label {
  font-size: 16px;
}
.nav-tab1 {
  margin-top: -3%;
  margin-left: 25%;
  position: relative;
  z-index: 1;
}
.nav-tab2 {
  margin-top: -3.5%;
  margin-left: 455px;
}
.nav-tab3 {
  margin-top: -41px;
  margin-left: 68px;
  position: relative;
  z-index: 1;
}
.ticket-label {
  font-size: 18px;
  vertical-align: -webkit-baseline-middle;
  margin-top: -47px;
  margin-left: 23px;
}
.knowledge-label {
  font-size: 18px;
  vertical-align: -webkit-baseline-middle;
  margin-top: -5px;
}
/***** For Model *****/
.Model {
  position: absolute;
  top: 30%;
  left: 65%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 536px;
  height: 220px;
}
.new-tickets-assigned {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 50px;
  width: 243px;
  text-align: left;
  padding-left: 15px;
}
.view-tickets {
  color: #2561a8;
  font-family: Lato;
  /* font-size: 16px; */
  font-size: 14px;
  /* font-weight: 600; */
  line-height: 50px;
  width: 121px;
  text-align: left;
  cursor: pointer;
}
.upper-noti-count {
  position: absolute;
  background-color: #ffb629;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  top: -13px;
  right: -10px;
}
.rectangle-2 {
  background-color: #2561a8;
  width: 33px;
  height: 27px;
}
.lable05 {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-top: 12px;
}
.rowpadding {
  padding-left: 25px;
}
.viewticketspeadding {
  padding-left: 30px;
}
.labledata {
  padding-left: 9px;
  padding-top: 5px;
}
/************My Ticket Header******************/
.headphone {
  width: 25px;
  height: 27px;
  margin-right: 9px;
  vertical-align: baseline;
  vertical-align: initial;
}
.headphonered {
  width: 23px;
  height: 23px;
  margin-right: 9px;
  cursor: pointer;
}
.loading-rectangle {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 6px;
  width: 23px;
  height: 25px;
  padding: 5px;
  margin-top: 7px;
  margin-left: 7px;
  vertical-align: top;
  cursor: pointer;
}
.loading-rectangle-cntr {
  display: inline-block;
  position: relative;
  top: -15px;
  margin-left: 10px;
  cursor: pointer;
}
.oval-55 {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-top: -3px;
}
.naman {
  margin-top: 8px;
}
.oval-head {
  margin-top: -7px;
  margin-left: 700px;
}
.naman-r {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
}
.down-header {
  width: 20px;
  height: 7px;
  padding-left: 8px;
  cursor: pointer;
}
.head-header {
  background-color: #ecf2f4;
  width: auto;
  height: auto;
}
.head-header-1 {
  padding: 7px 15px;
}
.id-abc-1234 {
  color: #4a4a4a;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
}
.updated-2-d-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
}
.lable-name {
  font-weight: bold;
  color: black;
  padding-left: 4px;
}
#HeadePhone-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 10px;
  margin-top: 45px;
  /* margin-bottom: 4px; */
  display: inline-block;
}
#labelmodel-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 150px;
  margin-top: 45px;
  width: 450px;
  /* width: 400px; */
  /* margin-bottom: 4px; */
  display: inline-block;
}
.myTicket-table table td {
  font-size: 16px;
  color: #5f5d5d;
  padding: 10px 15px;
}
.myTicket-table table th {
  font-size: 13px;
  padding: 10px 15px;
  color: #9b9b9b;
}
.remov table th {
  background-color: white !important;
}
.myTicket-table table thead tr {
  background-color: #ffffff;
  border-bottom: none;
}
.myTicket-table table tbody tr {
  border-bottom: 1px solid #eeeeee;
  border: none;
}
.btn-outline-primary {
  color: #2561a8;
  background-color: transparent;
  background-image: none;
  border-color: #2561a8;
  width: 190px;
}
.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: -25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.button-margin {
  /* margin-left: 32px; */
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
.myTicket-table table tr td:last-child,
table tr th:last-child {
  text-align: right;
  /* float: left; */
}
/****************My Ticket********************/

.right-icon {
  width: 19px;
  height: 19px;
  margin-left: 5px;
  margin-top: 3px;
}
.new-top-bottom-margin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.myticket-submit-solve-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 222px;
  height: 43px;
  margin-left: 35px;
  cursor: pointer;
  color: #ffff;
}

.myticket-submit-solve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 12px;
  cursor: pointer;
}
.rectangle-3 .top-margin {
  margin-top: 20px;
}

.rectangle-3 .message-header {
  margin-top: 15px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.main-content1 {
  padding: 0px 20px;
}
.rectangle-box {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: auto;
  height: auto;
  margin: 15px;
}
.rectangle2 {
  background-color: #ecf2f4;
  width: 1280px;
  height: 57px;
}
.rectangle-3 {
  background-color: #ffffff;
  border-radius: 4px;
  width: auto;
  height: auto;
  /* margin: 32px;
  padding: 30px; */
}
.text-editor .row {
  margin: 20px 0 0 0;
}
.card-rectangle {
  background: #f5f8f9;
  padding: 1px;
}
.ticket-title-where {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 3px;
}
.ticket-details-wher {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: 1227px;
  text-align: left;
}
.card .card-header-1 {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}
.messages-04 {
  color: #2561a8;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  cursor: pointer;
}
.msg-row {
  padding-top: 23px;
}
.notes-00 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -46px;
  cursor: pointer;
}
.task-03 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -122px;
  cursor: pointer;
}
.claim-00 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
  cursor: pointer;
}

.claim-00-1 {
  color: #2561a8;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
  cursor: pointer;
}
.line-4 {
  text-align: center;
  display: block;
}
/***************ToDAY 02*******************/
.oval-5 {
  background-color: #7ed321;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.solved-by-naman-r {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;

  margin-top: 5px;
}
.i-have-solved-this-i {
  color: #4a4a4a;
  font-family: unset;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.smg-Img1 {
  width: 22px;
  height: 16px;
  /* margin: 0 0 0 88px; */
  margin-left: auto;
  margin-right: 20px !important;
}
.up {
  width: 18px;
  height: 18px;
}
.up-1 {
  width: 22px;
  height: 18px;
  margin: 0px;
  margin-left: 11%;
  padding: 4px;
  cursor: pointer;
}
.comment {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.rectangle-4 {
  background-color: #fafafa;
  width: 693px;
  height: 253px;
}
.rectangle-44 {
  background-color: #ffffff;
  width: 563px;
  height: 206px;
}
.btn-submit {
  margin-top: -6px;
  padding-left: 8px;
}
.btn-primary1 {
  color: #fff;
  background-color: #2561a8;
  border-color: #2561a8;
  margin-left: 3.7px;
}
.dropdown-menu.show {
  display: block;
}
.mobile-number {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 2px;
  text-align: left;
}
.mobile-no {
  color: #000000;
  font-family: inherit;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  padding-right: 10px;
}
.eyeImg {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: auto;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.eyeImg1 {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: 22px;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.searchImg-2 {
  width: 20px;
  height: 20px;
  margin-left: -32px;
}
.billImg {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: auto;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.bill-1 {
  margin-top: -25px;
  padding-left: 155px;
}
.target-closure-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.Date-target {
  color: #4a4a4a;
  font-family: unset;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.vl {
  border-left: 2px solid #f1f1f1;
  height: 158px;
  margin-top: -1.3%;
  margin-left: 10px;
}
.v2 {
  border-left: 2px solid #f1f1f1;
  height: 158px;
  margin-top: -137px;
  margin-left: 556px;
}
.v3 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
}
.v4 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
  margin-left: -8.5%;
}
.v5 {
  border: 1px solid #dfdfdf;
  width: 104%;
  margin-top: 2.6%;
}
.v6 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
  margin-left: -8.5%;
}
.oval-loading {
  background-color: #f5a623;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  vertical-align: top;
  padding: 7px;
}
.reassign-to-naman-r {
  color: #4a4a4a;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.today-02 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 70px; */
}
.yesterday-02 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 67px; */
}
.rashmi-c {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 7px;
}
.label-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.card-space-1 {
  padding-top: 20px;
  padding-bottom: 5px;
}
.hi-naman-please-hel {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.hi-diwakar-i-really2 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 650px;
  text-align: left;
  display: contents;
}
.comment-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.comment-text1 {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.label-2 {
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 15px;
  padding-left: 3px;
  color: #5c5c5c;
}
.label-3 {
  margin-top: 0;
  font-size: 16px;
  padding-left: 3px;
  line-height: normal;
  line-height: initial;
  padding-bottom: 22px;
}
.mail-card {
  background-color: #ecf2f4;
  width: auto;
  height: 54px;
}
.mask1 {
  background-color: #ecf2f4;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  height: auto;
}
.mail-mask {
  border-radius: 5px;
  height: auto;
  margin: 4px;
  position: relative;
  width: 100%;
  display: inline-block;
}
.email {
  color: #000000;
  font-family: inherit;
  font-size: 16px;
  padding-right: 46px;
}
.down-1 {
  width: 16px;
  height: 22px;
}
.arrow-img {
  width: 15px;
  height: 15px;
  margin: 5px 15px 0;
}
.plush-img {
  width: 15px;
  height: 15px;
  margin: 5px 15px 0;
  cursor: pointer;
}
.minus-img {
  width: 15px;
  height: 2px;
  margin: 5px 15px 0;
  cursor: pointer;
}
.line-1 {
  border: 1px solid #cad4d7;
  width: 1px;
  height: 40px;
  position: absolute;
  top: -4px;
  right: 42px;
}
.label-4 {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: inherit;
  margin-bottom: 5px;
  text-align: left;
}
.rectangle-9 {
  border-radius: 4px;
  width: 100%;
  height: 31px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  background-size: 13px;
  cursor: pointer;
  border: none;
}
.mid-sec {
  padding: 30px;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  height: 100%;
}
.mid-secnew {
  padding: 15px 30px 0px 30px;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  height: 100%;
}
.drop-card {
  margin-top: -15px;
  padding-left: 20px;
}
.data-store {
  margin-top: -24.4%;
  margin-left: 97%;
}
.bata-rajouri-garden {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  white-space: normal;
  cursor: pointer;
}
.storeSpacing {
  padding-left: 0;
  padding-top: 0;
  display: inherit;
}
.pencilImg {
  width: 13px;
  height: 13px;
  margin-top: -10px;
  cursor: pointer;
}
.card-details {
  background-color: #fafafa;
  width: auto;
  /* height: 253px; */
}
.card-details-1 {
  background-color: #ffffff;
  width: auto;
  /* height: 195px; */
  margin: 29px;
  margin-left: 58px;
  padding: 23px;
  padding-left: 47px;
  padding-right: 39px;
}
.card-op-out .card .card-block,
.card-op-out .card .card-body {
  padding: 0;
}
.card-op-out .collapse {
  width: 100%;
}
.card-op-out .card-details {
  overflow: auto;
}
.label-5 {
  color: #a5a49b;
  font-size: 14px;
}
.oval-56 {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 6px;
}
.row-spacing {
  margin-top: 22px;
}
.facebook {
  width: 25px;
  height: 25px;
  float: right;
}
.clip {
  width: 22px;
  height: 22px;
}
.oval-6 {
  background-color: #50e3c2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 4px;
}
.oval-7 {
  background-color: #e63939;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 4px;
}
.headphone1 {
  width: 25px;
  height: 28px;
  float: right;
}
.need-to-change-my-sh {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.reply-comment {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  /* float: right; */
  display: block;
  cursor: pointer;
}
.inlineGridTicket {
  display: inline-grid;
  float: right;
}
.btn-primary1 {
  background-color: #2561a8;
  margin-left: 15px;
  margin-top: -9px;
}
.btn-primary2 {
  background-color: #2561a8;
  margin-left: 119px;
  margin-top: -48px;
  color: white;
}
.down-white {
  width: 20px;
  height: 14px;
  padding: 3px;
  margin-left: 15px;
}
/*****************Ticket System (Deepak)************************/
.rectanglesystem {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.tablepadding {
  margin-top: 15px;
}

.mask {
  background-color: #ffffff;
  border-radius: 4.5px;
  width: auto;
  margin: 20px;
}
.bitmapback {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.bitmapheadpone {
  width: 30px;
  height: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.source {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 12px;
}

.a91-9873470074 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 10px;
}
.b91-9873470074 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.save-as-a-draft {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-right: 22px;
  cursor: pointer;
  border: none;
}

.rectanglecreateticket {
  background-color: #2561a8;
  border-radius: 4px;
  width: 145px;
  height: 43px;
  cursor: pointer;
}

.create-ticket {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: auto;
  min-width: 160px;
  text-align: center;
}

.btnMdlSubmit {
  background: #fff;
  border: none;
  height: 0px;
  padding: 0px;
  cursor: pointer;
}

.tdtext {
  padding-top: 15px;
  padding-left: 10px;
}
.tdicon {
  padding-top: 8px;
  padding-left: 10px;
}

.tdtextnew {
  padding: 5px;
  float: right;
  text-align: right;
}

table {
  width: auto;
}
.rectangletd {
  background-color: #fbfbfb;
  width: 591px;
  height: 792px;
}
.ticket-tittle {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 15px;
}

.ticket-tittle-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 43px;
  border: none;
  padding: 10px;
}

.rowmargin {
  margin-left: 10px;
  margin-bottom: 10px;
}

.ticket-details {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
}

.ticket-details-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}

.category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.sub-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 20px;
}

.category-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 245px;
  height: 43px;
  padding-left: 10px;
  border: none;
}
.sub-category-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 245px;
  height: 43px;
  padding-left: 10px;
  border: none;
  margin-left: 10px;
}

.select-category-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.select-sub-category-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

/* .high-button {
  background-color: #ffffff;
  border: 1px solid #d02139;
  border-radius: 4px;
  width: 58px;
  height: 43px;
} */

/* .high-button-text {
  color: #ba505d;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
} */

/* .medium-button {
  background-color: #f5a623;
  border: 1px solid #f5a623;
  border-radius: 4px;
  width: 78px;
  height: 43px;
} */

/* .medium-button-text {
  color: #f5a623;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */

/* .low-button {
  background-color: #ffffff;
  border: 1px solid #5aa805;
  border-radius: 4px;
  width: 58px;
  height: 43px;
} */

/* .low-button-text {
  color: #61ad0f;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */

.screen-shot-2018-04-17-at-4-39-16-pm {
  width: 18px;
  height: 23px;
}

.sort-column {
  color: #0066cc !important;
  font-weight: bold;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.add-note {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.email-id {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px !important;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.phone-number {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.customer-name {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.alankrit-kumar {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.alankrit-easyrewardz {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.edit-button {
  background-color: #fbfbfb;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 93px;
  height: 31.32px;
  margin-left: 13px;
  margin-top: 10px;
  cursor: pointer;
}

.rectangletd .row button {
  padding: 0px;
}

.mask-ticket-system {
  background-color: #ffffff;
  border-radius: 4.5px;
  width: auto;
  height: auto;
  margin: 20px;
}

.mask-ticket-system .table td {
  border: none;
}

.margin-ticket-system-td {
  margin-top: 15px;
}
.edit-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  line-height: 12px;
  /* width: 23.07px; */
  text-align: left;
  padding-bottom: 5px;
  cursor: pointer;
}
.ticketsystem-line {
  margin-top: 30px;
  border: 1px solid #ededed;
}
.right-rectangle {
  background-color: #ecf2f4;
  width: 77px;
  height: 792px;
}

.customer-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.customer-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  padding-top: 10px;
  cursor: pointer;
}
.order-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.order-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}
.store-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.store-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}

.task-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.task-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}

:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
ticket-details-textarea ::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
ticket-details-textarea :-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
ticket-details-textarea ::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
ticket-details-textarea ::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
.addNote-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 123px;
  border: none;
  resize: none;
}

.addNote-textarea::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.colmarigin-tab {
  margin-left: 20px;
  margin-right: 40px;
}
.colmarigin-tab .row {
  margin-bottom: 5px;
}

/********************************************/

/* chetan css starts */

p,
label,
h4,
h3 {
  margin: 0;
  padding: 0;
}
ul,
li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
  display: inline-block;
}
a:hover {
  text-decoration: none;
}
input[type="radio"] {
  display: none;
}
/* input:focus {
  outline: none;
} */
/* input[type="text"]:focus {
  border: 1px solid #1890ff;
} */
/* button:focus {
  outline: none;
} */
/* button:focus,
select:focus,
textarea:focus {
  outline: none;
} */
a:focus,
a:hover:focus,
button:focus {
  outline: 1.5px #7aacfe solid;
  /* outline: 1.5px #e5efff solid; */
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
body {
  font-family: "Lato", sans-serif !important;
  background-color: #f5f8f9 !important;
  color: #4a4a4a !important;
}
.chat-left-height::-webkit-scrollbar,
.chat-user-det::-webkit-scrollbar {
  width: 6px;
}
.chat-left-height::-webkit-scrollbar-track,
.chat-user-det::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chat-left-height::-webkit-scrollbar-thumb,
.chat-user-det::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chat-left-height::-webkit-scrollbar-thumb:hover,
.chat-user-det::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.red-clr {
  color: #f2726f;
}
.blue-clr {
  color: #2561a8;
  cursor: pointer;
}
.black-clr {
  color: #4a4a4a;
}
.blak-clr {
  color: #000;
}
.green-clr {
  color: #46780b;
}
.pink-bg {
  background-color: #ffdfdf;
}
.orange-bg {
  background-color: #fff3df;
}
.blue-bg {
  background-color: #def3ff;
}
.fs-14 {
  font-size: 14px;
  font-weight: 500;
}
.fs-12 {
  font-size: 12px;
}
button,
.csv-button {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  color: #2561a8;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 15px;
  min-width: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.butn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.butn-inv {
  background-color: #fff;
  color: #2561a8;
  font-size: 12px;
  padding: 8px 25px;
  border-radius: 50px;
  cursor: pointer;
}

/* dashboard starts */

.dash-cntr .card {
  position: static;
  display: inline;
  display: initial;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0;
  border-radius: 0;
}
.dash-cntr .card .card-block,
.dash-cntr .card .card-body {
  padding: 0;
}
.dashboard-collapse-icon {
  width: 36px;
  height: 36px;
  background-color: #c8d8dd;
  border-radius: 50%;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 2px;
  z-index: 1;
}
.dashboard-collapse-icon-inv {
  background-color: #2561a8;
}
.dash-popup {
  position: absolute;
  background-color: #fff;
  right: -12px;
  top: 28px;
  padding: 25px 30px 35px;
  color: #4a4a4a;
  z-index: 1;
  display: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}
.dash-creation-popup {
  width: 370px;
}
.dash-creation-popup-cntr {
  position: relative;
  display: inline-block;
}
.dash-creation-popup-cntr:hover .dash-popup {
  display: block;
}
.dash-creation-popup li {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15px; */
  margin-top: 20px;
}
.dash-creation-popup li.title {
  /* font-size: 12px; */
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  padding-bottom: 5px;
}
.dash-creation-popup li p:first-child {
  flex: 0 0 70%;
}
.dash-creation-popup li p:last-child {
  flex: 0 0 30%;
}
.dash-creation-popup li p {
  /* font-size: 13px; */
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  line-height: 0.8;
}
.dash-category-popup {
  padding: 20px 30px;
  font-size: 14px;
  border-radius: 8px;
  white-space: normal;
  white-space: initial;
}
.dash-category-popup li p:first-child {
  color: #9b9b9b;
}
.dash-category-popup li p {
  white-space: nowrap;
}
.dash-category-popup li {
  margin-bottom: 15px;
  display: block;
}
.dash-category-popup li:last-child {
  margin-bottom: 0px;
}
.dash-task-popup {
  width: 220px;
  padding: 20px 20px 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  top: auto !important;
  top: initial !important;
  bottom: 34px;
}
.dash-task-popup span {
  display: inline;
  font-size: 14px;
}
.task-progress {
  margin-top: 12px;
  background-color: #e6e6e6;
  border-radius: 5px;
  height: 10px;
  overflow: hidden;
}
.task-progress .progress-bar {
  background-color: #b0d288;
}
.task-red-clr {
  color: #d20c24;
}
.nw-chat {
  width: 18px;
  margin-left: 9px;
}
.nw-chat img {
  width: 100%;
}
.dash-top-cards {
  padding: 12px 0px 15px; /*15px 0px 15px;*/
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  min-height: 140px;
}
.dash-top-cards small {
  white-space: nowrap;
}
.card-head {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 20px;
}
.card-value {
  font-size: 40px;
  font-weight: 300;
  display: block;
}
small {
  font-size: 12px;
  font-weight: 700;
  margin-top: -5px;
  display: block;
}
big {
  font-size: 56px;
}
.dash-cntr {
  padding: 15px 0;
  position: relative;
}
.resp-success .card-head {
  text-align: center;
  font-weight: 300;
  margin-bottom: 0px;
}
.aside-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 63px); /* 24 */
}
.aside-cont > div {
  margin: 0 25px;
}
.btm-mar > .row > div {
  margin-bottom: 30px;
}
.table-cntr {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}
table {
  width: 100%;
  font-weight: 700;
  overflow: hidden;
  border-radius: 5px 5px 0px 0;
}
table th {
  font-size: 13px;
  padding: 15px 25px; /*20px 30px;*/
}
table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 18px 23px;
}
table thead img {
  width: 12px;
  margin-left: 3px;
}
table tbody tr {
  border-bottom: 1px solid #eeeeee;
}
table thead tr {
  background-color: #e5e9ed;
  border-bottom: 1px solid #eeeeee;
}
table span {
  font-size: 10px;
}
table td span {
  display: block;
}
.scroll-table {
  overflow: auto;
}
.mobile-table {
  /* border-radius: 5px 5px 0 0;
  overflow: hidden; */
  display: none;
}
.table-cont {
  display: flex;
  font-size: 13px;
  color: #9b9b9b;
  font-weight: 700;
  padding-bottom: 15px;
}
.table-row .table-cont:last-child {
  padding-bottom: 0;
}
.tab-subhead {
  display: block;
  font-size: 10px;
}
.table-cont > p:first-child {
  font-size: 12px;
  color: #4a4a4a;
  flex: 0 0 100px;
}
.table-row {
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
}
.scroll-table table {
  white-space: nowrap;
}
.scroll-table::-webkit-scrollbar {
  height: 7px;
}
.scroll-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.scroll-table::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.scroll-table::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.one-liner label {
  max-width: calc(100% - 20px);
}
.one-liner {
  display: flex;
  align-items: flex-start;
}
.one-liner .info-icon {
  margin-top: 4px;
}
.one-liner .info-icon-cp {
  margin-top: 5px;
}
.priority-one-liner span {
  display: flex;
  align-items: flex-start;
}
.priority-one-liner .info-icon-cp {
  margin-top: 4px;
}
.store-one-liner .info-icon-cp {
  margin-top: 3px;
}
.info-icon {
  width: 17px;
  position: relative;
  left: 2px;
  bottom: 1px;
  margin-left: 3px;
  cursor: pointer;
}
.info-iconcus {
  float: right;
  margin-top: 3px;
}
.dashboard-popover .ant-popover-arrow {
  display: none;
}
.dashboard-popover .ant-popover-inner {
  position: relative;
  left: -30px;
}
.table-btn {
  border-radius: 15px;
  min-width: 100px;
  width: auto;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.table-blue-btn {
  background-color: #cbe0f1;
  border: 1px solid #0076ff;
  color: #1775e2;
  font-size: 10px !important;
}
.table-yellow-btn {
  background-color: #ffe4b2;
  border: 1px solid #ffa400;
  color: #dc6530;
  font-size: 10px !important;
}
.table-green-btn {
  background-color: #cbf1e9;
  border: 1px solid #2d8f7b;
  color: #2d8f7b;
  font-size: 10px !important;
}
.task-icon-1 {
  width: 20px;
}
.claim-icon {
  width: 16px;
  margin-right: 6px;
}
.claim-icon-1 {
  width: 17px;
}
.table-img-cntr {
  width: 44px;
}
.table-img-cntr {
  width: 44px;
  padding: 0;
  text-align: right;
}
table tr td:last-child,
table tr th:last-child {
  text-align: none; /*text-align: right;*/
}
.pagi {
  text-align: center;
  padding: 25px;
}
.pagi ul {
  border: 1px solid #eceff0;
  display: inline-block;
}
.pagi ul li {
  font-size: 16px;
  width: 37px;
  height: 37px;
  line-height: 35px;
  border: 1px solid #eceff0;
  text-align: center;
}
.pagi ul li a {
  color: #747779;
}
.pagi ul li.active {
  border-color: #d3d3d3;
  box-shadow: inset 0px 0px 5px 0px #d3d3d3;
}
.float-search small {
  display: inline-block;
}
.float-search {
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  top: -17px;
  left: 50%;
  font-size: 16px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #fff;
  padding: 4px 17px 6px;
  cursor: pointer;
  color: #4a4a4a;
  z-index: 1;
}
.float-search:hover {
  color: #4a4a4a;
}
.search-icon {
  width: 15px;
  margin-left: 7px;
}
.dash-dropdowns {
  background-color: #ecf2f4;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
}
.dash-dropdowns div div:last-child select {
  margin-right: 0;
}
.dash-dropdowns span {
  font-weight: 300;
  font-size: 16px;
  color: lightslategray;
}
.dash-dropdowns select {
  color: #4a4a4a;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 55%;
  border: 0;
  padding-right: 20px;
  background-size: 13px;
  padding-left: 5px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.assign-collapse-btn {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 121px;
  height: 43px;
}
.table-expandable-sctn {
  background: #ecf2f4;
}
.table-expandable-sctn button {
  cursor: pointer;
}
.table-expandable-sctn .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px
    center;
  color: #4a4a4a;
}
.table-expandable-sctn input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn input::-webkit-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn input:-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn input::-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.table-expandable-sctn .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.save-view-search button {
  padding-right: 25px;
  padding-left: 25px;
}
.save-view-search button.btn-inv {
  padding-right: 45px;
  padding-left: 45px;
}
.table-expandable-sctn button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
  border-color: rgba(37, 97, 168, 0.4);
  color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn .tab-content button:last-child {
  margin-right: 0;
}
.save-view-search {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-51%);
          transform: translateY(-51%);
}
.table-expandable-sctn .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn .tab-content .row.all-row div {
  margin-bottom: 25px;
}
.mar-comp {
  margin-right: 20px;
}

/* status dropdown starts */
.status-drop-down {
  position: absolute;
  background-color: #fff;
  right: -25px;
  top: 25px;
  width: 250px;
}
.sort-sctn {
  padding: 13px 35px;
  border-bottom: 1px solid #eeeeee;
}
.sort-sctn div {
  padding: 5px 0 0 0;
}
.sort-sctn p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
}
.sorting-icon {
  width: 11px;
  margin-right: 14px;
}
.sorting-icon img {
  width: 100%;
  margin: 0;
}
.filter-type {
  padding: 5px 25px 20px 25px;
  border-bottom: 1px solid #eeeeee;
}
.filter-type p {
  margin: 0;
  padding: -2px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
}
.filter-type input {
  display: none;
}
.filter-checkbox input + label {
  position: relative;
  padding-left: 35px;
}
.filter-checkbox input + label::before {
  content: "";
  width: 17px;
  height: 17px;
  position: absolute;
  background-color: #fefefe;
  border: 1px solid #e0ddd9;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-51%);
          transform: translateY(-51%);
  border-radius: 3px;
}
.filter-checkbox input + label::after {
  content: "";
  position: absolute;
  left: 6px;
  width: 5px;
  height: 7px;
  border: solid #fefefe;
  border-width: 0 1.5px 1.5px 0;
  top: 50%;
  -webkit-transform: translateY(-65%) rotate(45deg);
          transform: translateY(-65%) rotate(45deg);
}
.filter-checkbox input:checked + label:before {
  background-color: #2d8f7b;
}
.filter-type .filter-checkbox {
  margin-top: 18px;
}
.filter-color {
  border: 0;
}
.fil-color-bg {
  width: 100px;
  height: 25px;
  display: block;
  border: 1px solid #e8e8e8;
}
.fil-color-red {
  background-color: #ffdfdf;
}
.fil-color-orange {
  background-color: #fff3df;
}
.fil-color-white {
  background-color: #fff;
}
.fil-color-green {
  background-color: #def3ff;
}
.filter-color .filter-checkbox {
  margin-top: 10px;
}
/* status dropdown ends */

/* tabs starts */
.nav-tabs {
  border-bottom: 1px solid #ececec;
}
.nav-tabs .nav-link {
  color: #4a4a4a;
  font-weight: 700;
  padding: 12px 20px;
  border: 0;
}
.nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  /*background: #e3e9eb;*/
}
.tab-content {
  padding: 12px 0px;
  box-shadow: none;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
}
.alert-p1 {
  padding: 35px !important;
}
/* tabs ends */

/* logout block starts */
.logout-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 10px;
}
.logout-block {
  color: #000;
  /* width: 380px; */
  min-width: 380px;
  background-color: #fff;
  border-radius: 8px;
}
.logout-block > div {
  padding: 22px 22px 20px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e4e4e4;
}
.logout-block > div:first-child {
  border-top: none;
}
#logout-popup .logout {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: 100px;
  height: 38px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #2561a8;
  cursor: pointer;
  margin-left: 10px;
}
.mail-id {
  color: #6e6e6e;
  font-size: 12px;
  margin-top: 0px;
  word-break: break-all;
}
.user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.user-img a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout-label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.logout-block p {
  margin-bottom: 0;
}
.logout-status {
  width: 19px;
}
.logout-status img {
  width: 100%;
}
.status-options input + label {
  position: relative;
  padding-left: 15px;
}
.status-options input + label::before {
  content: "";
  position: absolute;
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  left: 0;
  top: 3px;
}
.status-options input:checked + label::before {
  background-color: #2561a8;
}
.status-sctn {
  padding-top: 0 !important;
  border-top: none !important;
  padding-bottom: 10px !important;
  margin-top: -12px;
}
.chat-status {
  margin-left: 5px;
  position: relative;
  top: 7px;
}
.logout-progress {
  background-color: #d8d8d8;
  border-radius: 5px;
  height: 10px;
  margin-top: 10px;
  overflow: hidden;
}
.logout-progress .progress-bar {
  background: -webkit-gradient(linear, left top, right top, color-stop(42%, #ff8363), color-stop(50%, #ff631d));
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  box-shadow: none;
}
.prog-indi {
  text-align: right;
  /* width: 60%; */
  position: relative;
  left: 22px;
  margin-top: 5px;
  white-space: nowrap;
}
#logout-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  max-width: 500px;
}
#logout-ovrly {
  padding: 70px 10px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#Pincode-ovrly {
  padding: 70px 10px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
  z-index: 10000;
}
#logout-popup .styles_closeButton__20ID4 {
  display: none;
}
.dis-btn {
  background-color: #2561a8 !important;
  color: #fff;
  opacity: 0.4;
}
/* logout block ends */

/* save search popup starts */
#save-search-ovrly {
  background-color: rgba(77, 92, 116, 0.2);
  padding: 0;
}
#save-search-popup {
  margin: 0 auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 0;
  width: 335px;
}
#save-search-popup .save-search {
  font-size: 16px;
  font-weight: 600;
  color: #2561a8;
  padding: 20px 25px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #eeeeee;
}
.search-name {
  padding: 20px 15px;
  border-bottom: 1px solid #eeeeee;
}
.search-name input {
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  padding: 10px 18px;
  width: 100%;
}
.search-name input::-webkit-input-placeholder {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
}
.search-name input:-ms-input-placeholder {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
}
.search-name input::-ms-input-placeholder {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
}
.search-name input::placeholder {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
}
.search-name .butn {
  padding: 11px 25px;
  font-size: 14px;
  letter-spacing: 0.5px;
  width: 100%;
  margin-top: 15px;
}
.search-names {
  padding: 15px 20px;
  padding-right: 7px;
}
.names-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.names-title p {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 700;
}
.search-names ul p {
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 700;
  flex: 0 0 45%;
  line-height: 1.3;
}
.search-names ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f2f2f2;
  margin-right: 11px;
}
.search-names ul li:first-child {
  padding-top: 0px;
}
.search-names ul li:last-child {
  border-bottom: 0;
}
.search-names ul a {
  font-size: 14px;
  font-weight: 700;
  color: #2561a8;
  margin-right: 12px;
}
.search-names ul {
  max-height: 190px;
  overflow: auto;
}
.search-names ul::-webkit-scrollbar {
  width: 7px;
}
.search-names ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.search-names ul::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.search-names ul::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
#save-search-popup .styles_closeButton__20ID4 {
  display: block;
  top: 19px;
  right: 25px;
  min-width: auto;
  cursor: pointer;
}
#save-search-popup .styles_closeButton__20ID4 .styles_closeIcon__1QwbI {
  width: 24px;
  height: 24px;
}
/* save search popup ends */

.prio-pie-chart .nv-legend {
  display: none;
}
.prio-pie-chart {
  display: inline-block;
  margin-top: -10px;
}
.pie-chart-count {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  margin-left: 0px;
  padding: 0 !important;
  margin-top: -2px;
}
.pie-chart-count span {
  font-weight: 700;
  font-size: 16px;
  display: block;
}
.prio-pie-chart .nv-label text {
  fill: #4a4a4a !important;
  font-size: 12px;
}
.prio-pie-cntr {
  padding-right: 0;
  padding-left: 0;
}
.prio-pie-cntr p {
  padding-right: 20px;
  padding-left: 20px;
}
.dash-timepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box,
.dash-timepicker .react-datepicker__time-container {
  width: 185px !important;
}
.dash-timepicker .react-datepicker-wrapper,
.dash-timepicker input {
  width: 100% !important;
}
.dash-timepicker {
  margin-bottom: 12px;
}
.collapse-icon {
  width: 15px;
}
.black-twitter {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}
.dash-res {
  display: block;
  font-size: 11px;
  margin-top: 3px;
  opacity: 0;
  font-weight: 300;
}
.dash-res-opac {
  opacity: 1;
}
/* dashboard ends */

/* chatbot starts */

/* chatbot left starts */
.chatbot-left {
  background-color: #fffef7;
  height: calc(100vh - 55px);
  position: relative;
}
.chatbot-right {
  background-color: #ecf2f4;
  height: calc(100vh - 55px);
  position: relative;
}
.chat-left-height {
  max-height: 200px;
  overflow: auto;
}
.chat-cntr {
  padding: 15px 0px;
  border-bottom: 1px solid #e8e8e8;
}
.chat-cntr .chat-info:last-child {
  border-bottom: none;
}
.chat-cntr .ongng {
  font-size: 16px;
  font-weight: 600;
}
.chat-info {
  /* padding: 17px; 
  border-bottom: 1px solid #e4e4e4;*/
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.chat-info.active {
  /* background-color: #ecf2f4; */
  background-color: #cde4ff;
}
.dark-blue-ini {
  background-color: #ece8fd;
  color: #5b35ff;
}
.light-blue-ini {
  background-color: #ddf6fc;
  color: #11b4db;
}
.yellow-ini {
  background-color: #ffefcb;
  color: #ffb40a;
}
.green-ini {
  background-color: #daf3c0;
  color: #65ac13;
}
.orange-ini {
  background-color: #ffdee2;
  color: #ff631d;
}
.initial {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  margin-top: -10px;
  background-color: #ddf6fc;
  border: 1px solid #64c5b4;
}
.name-num p {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  line-height: 0.7;
}
.name-num .num {
  font-size: 11px;
  font-weight: 400;
  color: #4a4a4a;
  margin-top: -2px;
}
.mess-time p {
  font-size: 11px;
  line-height: 0.7;
  font-weight: 600;
}
.chatbot-left button {
  font-weight: 700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50px;
}
.hist-btn {
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
}
/* chatbot left ends */

/* chatbot right ends */
.top-tab-act {
  background-color: #ecf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}
.top-tab-act .nav-tabs,
.top-tab-act .nav-tabs .nav-link.active {
  border-bottom: none;
  background-color: transparent;
}
.action-part select {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 42px;
  cursor: pointer;
  background: #2561a8 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAgUlEQVR4nJXOsQ3CQBBE0b8X0IWLoQ7LFhGd2HIJJJao00b6JBcgxHF3m2wwo9EL9Q4cEfGk89QbcEnAAOzq3DkwAzswoIa6qC91ahyYcn9R4zPYcjBWBsbc20qFVT1Loiw41bVG/SmqCmqiZkFBdKiP/NsEBZHdgq+RpF7V9K/3BnxOqa7xB6aiAAAAAElFTkSuQmCC) no-repeat right 20px
    center;
  background-size: 13px;
}
/* .sel-arr::after {
    content: '';
    position: absolute;
    display: inline-block;
    font-size: 12px;
    width: 11px;
    height: 11px;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    transform: rotate(-144deg) skew(-20deg);
  } */
.action-part .cross {
  min-width: auto;
  width: 43px;
  height: 43px;
  padding: 0;
  margin-left: 8px;
  font-size: 30px;
}
.top-tab-act .nav-tabs .nav-link {
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
}
.chat-user-det {
  background-color: #fff;
  padding: 10px 25px;
  height: calc(100vh - 118px);
  overflow: auto;
}
.chat-user-det > div {
  padding: 15px 0;
  border-bottom: 1px solid #e4e4e4;
}
.chat-user-det > div:last-child {
  border-bottom: none;
}
.chat-user-num a,
.chat-user-num span {
  font-size: 11px;
  color: #4a4a4a;
}
.chat-user-num span {
  margin: 0 5px;
}
.chat-user-name {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.chat-user-det textarea {
  background-color: #ecf2f4;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 100px;
  width: 100%;
  resize: none;
  font-size: 11px;
  color: #4a4a4a;
  padding: 8px 12px;
  margin-top: 10px;
}
.chat-user-det textarea::-webkit-input-placeholder {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0px;
}
.chat-user-det textarea:-ms-input-placeholder {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0px;
}
.chat-user-det textarea::-ms-input-placeholder {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0px;
}
.chat-user-det textarea::placeholder {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0px;
}
.price-tic div {
  flex: 0 0 50%;
}
.chat-extra-info {
  color: #000;
  font-size: 11px;
}
.chat-extra-info div {
  margin-top: 15px;
}
.chat-extra-info label {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 15px;
}
/* chatbot right ends */

/* chatbot ends */

/* store dashboard starts */
.store-dash-top small {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 0;
}
.store-dash-top .card-value {
  font-size: 28px;
}
.store-dash-top .aside-cont > div {
  margin: 0 20px;
  display: inline-block;
}
.store-dash-top .aside-cont {
  height: auto;
  display: block;
}
.store-table-cntr table {
  border-radius: 0;
}
.store-table-cntr {
  position: relative;
}
.store-dash-tabs a {
  color: #4a4a4a;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 10px;
}
.store-dash-tabs li:last-child a {
  margin-right: 0;
}
.store-dash-tabs a span {
  /* width: 30px; */
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  position: relative;
  top: 1px;
}
.store-dash-tabs a.active {
  background-color: #e3ebed;
  color: #2561a8;
  font-weight: 700;
}
.store-dash-tabs a.active span {
  background-color: #2561a8;
  color: #fff;
  font-weight: 400;
  width: 30px;
}
.store-dash-tabs {
  background-color: #ecf2f4;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
/* store dashboard ends */

/* login and password extra css starts */
.custom-select,
.form-control {
  background-color: #ecf2f4;
  padding: 12px;
}
.auth-content .card .card-block,
.auth-content .card .card-body {
  padding: 25px 60px 25px;
  /* padding: 35px 60px 50px; */
  /*box-shadow: 0 0 4px 0px #ddd;*/
}
.auth-wrapper .auth-content {
  padding: 0;
}
.auth-wrapper {
  display: block;
}
/* .box-center {
  display: flex;
  height: 100%;
} */
.settingtable .ReactTable .rt-thead {
  text-align: left;
}
.settingtable .ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.auth-wrapper .auth-content {
  /* margin: 20px auto; */
  margin: 50px auto;
}
.change-password-auth-wrapper .auth-content {
  margin: 20px auto;
}
.auth-wrapper input {
  border: 0;
}
/* login and password extra css ends */

/* extra css starts */
.my-tic-email {
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  padding-right: 35px;
  width: 151px;
  font-size: 14px;
  background: #dbe4e7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  background-size: 16px;
  text-align: left;
}
.my-tic-email::after {
  display: none;
}
.my-tic-email1 {
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  padding-right: 35px;
  width: 151px;
  font-size: 14px;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  background-size: 16px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ecf2f4;
}
.my-tic-email1::after {
  display: none;
}
.mail-mask .dropdown-menu.show:before {
  display: none;
}
.mail-mask svg {
  text-align: left;
  color: #212529 !important;
  float: left;
  margin: 1px 5px 1px 0 !important;
}
.mail-mask ul li {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.mail-mask ul {
  padding: 15px 0;
}
/* extra css end */

/* my ticket list starts */
.normal-dropdown .css-yk16xz-control,
.normal-dropdown .css-1pahdxg-control {
  min-height: 41px;
  border-color: transparent;
  box-shadow: none;
}
.normal-dropdown .css-1pahdxg-control {
  border-color: #7aacfe;
}
.normal-dropdown .css-yk16xz-control:hover,
.normal-dropdown .css-1pahdxg-control:hover {
  border-color: transparent;
  box-shadow: none;
}
.normal-dropdown .css-1pahdxg-control:hover {
  border-color: #7aacfe;
}
.normal-dropdown .css-1n7v3ny-option,
.normal-dropdown .css-yt9ioa-option,
.normal-dropdown .css-9gakcf-option {
  padding: 6px 19px;
}
.normal-dropdown .css-w8afj7-Input {
  margin: 0;
  padding: 0;
}
.normal-dropdown .css-26l3qy-menu {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #7b9cd3;
}
.normal-dropdown .css-1ml51p6-MenuList {
  padding: 0;
}
.normal-dropdown .css-yt9ioa-option,
.normal-dropdown .css-1uccc91-singleValue,
.normal-dropdown .css-1wa3eu0-placeholder {
  color: #a7a7a7;
}
.normal-dropdown .css-1wa3eu0-placeholder {
  margin: 0;
}
.normal-dropdown .css-1n7v3ny-option {
  color: #fff;
  background-color: #2684ff;
}
.normal-dropdown .css-1g48xl4-IndicatorsContainer,
.normal-dropdown .css-1wy0on6 {
  display: none;
}
.normal-dropdown .css-yt9ioa-option:active {
  background-color: #fff;
}
.normal-dropdown .css-1n7v3ny-option:active {
  background-color: #2684ff;
}
.normal-dropdown .css-1hwfws3 {
  padding-right: 44px;
  padding-left: 19px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px center;
  background-size: 13px;
}
.store-report-add .normal-dropdown .css-1hwfws3 {
  background-color: #ecf2f4;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
  padding-left: 14px;
  padding-right: 40px;
}
.store-report-add .normal-dropdown .css-1wa3eu0-placeholder {
  color: #9b9b9b;
  font-weight: 700;
}
.store-report-add .normal-dropdown .css-yk16xz-control,
.store-report-add .normal-dropdown .css-1pahdxg-control {
  min-height: auto;
}
.store-report-add .normal-dropdown .css-1n7v3ny-option,
.store-report-add .normal-dropdown .css-yt9ioa-option,
.store-report-add .normal-dropdown .css-9gakcf-option {
  padding: 3px 14px;
}
.store-report-add .issuetype-cusdrp .category-box {
  margin-bottom: 0;
}
.common-adv-padd {
  padding: 20px 0;
}
.schedule-multi {
  padding: 0;
  border-radius: 5px;
  margin-top: 15px;
}
.schedule-multi .css-yk16xz-control,
.schedule-multi .css-1pahdxg-control {
  min-height: 43px;
  background: #ecf2f4;
}
.schedule-multi .css-1hwfws3 {
  padding-right: 25px;
  padding-left: 11px;
  background-position: right 4px center;
}
.schedule-multi .css-1n7v3ny-option,
.schedule-multi .css-yt9ioa-option,
.schedule-multi .css-9gakcf-option {
  padding: 6px 12px;
}
.schedule-multi .css-yt9ioa-option,
.schedule-multi .css-1uccc91-singleValue {
  color: #495057;
}
.schedule-multi .css-1ml51p6-MenuList {
  font-weight: 400;
}
.schedule-multi .css-1wa3eu0-placeholder {
  color: #9b9b9b;
}
.schedule-width {
  /* max-width: 283px; */
  max-width: 500px;
  width: 100%;
}
.last-to {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.one-line-outer {
  display: flex;
  align-items: flex-end;
}
.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.my-tic-ck-height iframe {
  height: calc(100% - 50px) !important;
}
.a-mar {
  margin-bottom: 14px;
}
.ReactTable .-pagination input:focus,
.ReactTable .-pagination select:focus,
.ReactTable .-pagination .-btn:focus {
  outline: 1.5px #7aacfe solid;
}
/* my ticket list ends */

/* ticket system starts */
.ticket-title-select .css-yk16xz-control,
.ticket-title-select .css-1pahdxg-control {
  background-color: #ecf2f4;
  min-height: 42px;
  border-color: transparent;
  box-shadow: none;
}
.ticket-title-select .css-yk16xz-control:hover,
.ticket-title-select .css-1pahdxg-control:hover {
  border-color: transparent;
  box-shadow: none;
}
.ticket-title-select .css-1g48xl4-IndicatorsContainer {
  display: none;
}
.ticket-title-select .css-1wa3eu0-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-weight: 700;
}
.ticket-title-select .css-1uccc91-singleValue,
.ticket-title-select .css-w8afj7-Input input {
  color: #000 !important;
  font-family: Lato;
  font-weight: 700;
}
.ticket-title-select .css-1ml51p6-MenuList {
  padding: 12px;
}
.ticket-title-select .css-26l3qy-menu {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  margin: 0;
}
.ticket-title-select .css-1n7v3ny-option,
.ticket-title-select .css-yt9ioa-option {
  color: #9b9b9b;
  font-family: Lato;
}
.ticket-title-select .css-9gakcf-option {
  font-family: Lato;
  background-color: #2561a8;
}
.ticket-title-select .css-1ml51p6-MenuList div {
  display: inline-block;
  width: auto;
  border: 1px solid #2561a8;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 7px;
  cursor: pointer;
}
.priority-butns label,
.action-type-butns label {
  border-radius: 4px;
  font-weight: 700;
  font-family: Lato;
  padding: 10px 15px;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid;
  margin-bottom: 7px;
}
.action-type-butns label {
  border-color: #f4f4f4;
}
.action-type-butns-cntr .action-type-butns input:checked + label {
  border-color: #2561a8;
  color: #2561a8;
}
.priority-butns,
.action-type-butns {
  display: inline-block;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 1) label {
  border-color: #d02139;
  color: #d02139;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 1) input:checked + label {
  background: #d02139;
  color: #fff;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 2) label {
  border-color: #f5a623;
  color: #f5a623;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 2) input:checked + label {
  background: #f5a623;
  color: #fff;
}
.priority-butns-cntr .priority-butns:nth-child(3n) label {
  border-color: #5aa805;
  color: #5aa805;
}
.priority-butns-cntr .priority-butns:nth-child(3n) input:checked + label {
  background: #5aa805;
  color: #fff;
}
.priority-butns-cntr .priority-butns:last-child label,
.action-type-butns-cntr .action-type-butns:last-child label {
  margin-right: 0;
}
.kb-acc-cntr {
  border-bottom: 1px solid #ecf2f4;
}
.kb-acc-cntr .table-details-data-modal {
  cursor: pointer;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right top 6px;
  background-size: 13px;
  padding-right: 25px;
}
.kb-acc-cntr .table-details-data-modal[aria-expanded="true"] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAGoSURBVDhPjZI9qIFRGMcPL5ORbMooMohFhEmZmNiMFpvRYhFGg8lklslkYJCSSCllsFAkihL5/vjf95zOe2/ivff+6iznOc/vPM95jgIi5Bcmkwk5Ho/EaDQSjUbDd2WgMjmm0ykikQisVisqlQoOhwOPfEZWNp/PEY1G4XA4YLfb2apWqzidTvzEOx9ly+USsVgMNpsN5XIZw+EQ4XAYTqcTtVoN5/OZn3zlTbZerxGPx1lrpVIJ2+0Wj8cDo9EIwWAQLpcLjUYDl8uFZ/zwIqOJiUQCFosFxWIRm80Gz+eTxe73OwaDAQKBAHw+H1qtFq7XK4tJfMv2+z2SySQTFQoFVqEkkqDCXq8Hv9/PVqfTwe1241EuE0ePVCoFk8mEfD6P1Wr1JpKgyVTi9XpZlf1+n11CIbvdDplMBmazGblcDovFQlYkQdtrNptwu90IhULodrtsKKTdbrM3SKfTmM1mf4ok6ADq9To8Hg+y2Sz7Sgr6VuPxmBgMBqLVaolSqeTf+W/ECok4ZaLT6YherycKOna6qVariSAI/Nj/EdsjKpVKzBXIF8BbbC8R1h4JAAAAAElFTkSuQmCC);
}
.kb-acc-cntr .copyblue-kbtext {
  display: inline-block;
  margin: 10px 0 12px;
}

.custom-ticket-title {
  position: relative;
}
.custom-ticket-title input {
  background-color: #ecf2f4;
  border: 0;
  width: 100%;
  padding: 11px;
  border-radius: 4px;
}
.custom-ticket-title-suggestions span {
  display: inline-block;
  width: auto;
  border: 1px solid #2561a8;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 7px;
  cursor: pointer;
  color: #9b9b9b;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.custom-ticket-title-suggestions span:hover {
  background: #deebff;
}
.custom-ticket-title-suggestions {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}
.custom-ticket-title-suggestions::-webkit-scrollbar {
  width: 5px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-thumb {
  background: #5c5252;
  border-radius: 10px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}

.custom-antd-table .ant-table-content {
  overflow: auto;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar {
  height: 5px;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar-track {
  background: #f3f0f0;
  border-radius: 10px;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar-thumb {
  background: #a5a3a3;
  border-radius: 10px;
}
.order-input {
  border: 1px solid #eaeaea;
  padding: 4px 10px;
  border-radius: 5px;
  width: 100%;
}
.custom-antd-table .ant-table-thead > tr > th,
.custom-react-table .rt-thead .rt-th span {
  font-weight: 700;
  color: #4a4a4a;
  white-space: nowrap;
  padding: 10px 20px 4px !important;
  background: transparent !important;
  border-bottom: 0;
}
.custom-antd-table.antd-table-campaign .ant-table-thead > tr > th {
  background: #e5e9ed !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.custom-antd-table.antd-table-campaign .ant-table-thead > tr > th:last-child {
  text-align: left;
  float: none;
}
.custom-antd-table.antd-table-campaign .ant-spin-nested-loading,
.custom-antd-table.antd-table-campaign .ant-spin-container,
.custom-antd-table.antd-table-campaign .ant-table {
  position: static;
}
.custom-antd-table.antd-table-campaign .react-datepicker__time-list {
  overflow-x: hidden;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-thead
  > tr
  > th {
  color: #989898;
  background: #f0f0f0 !important;
}
.custom-table-bg
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-thead
  > tr
  > th {
  background: #f5f8f9 !important;
}
.custom-table-bg
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  background: rgb(250, 254, 255) !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  background: #fcfcfc !important;
}
.custom-antd-table.antd-table-campaign.antd-table-campaign-padd
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:first-child
  > td {
  padding-top: 15px !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:last-child
  > td {
  padding-bottom: 15px !important;
}
.antd-table-campaign tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}
.custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.custom-antd-table.antd-table-campaign table {
  padding: 0;
}
.antd-table-campaign .ant-table-row-collapsed::after {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IArs4c6QAAAEVQTFRFM2bMUIC6N26vJWGoZI/B6O72////2uTwj67S/f3+7/P4ZY/BfaDL9vj7ZpDC/v7+ZZDBfqLL9fj7a5TESHq19Pf6m7fXq2XNgQAAAAN0Uk5TBan63hAalwAAAHBJREFUKJHNkssSgCAIRUHTwsCe9v+fWmOPycxdi1jew3AvAwCodFYKAbAyNitTIagXfSMK9Jturf4BqBs6BGrqO3At74S4dcko8ZF03MvDIxJiL5m5+GEczv4klUzzdOlJ3LCEDxb8BhRPW3yG0vusrA0Nd8frMawAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}
.antd-table-campaign .ant-table-row-expanded::after {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IArs4c6QAAAEVQTFRFM2bMUIC6N26vJWGoZI/B6O72////9Pf6m7fX9vj7a5TESHq1ZpDCfqLL9fj7ZZDBfaDL/f3+/v7+7/P4ZY/B2uTwj67SDUE53wAAAAN0Uk5TBan63hAalwAAAG5JREFUKJG90tEOgCAIBVDQpCgrSev/PzXXamuJbxWP9ww2BgBobFEGAbBxVJRrEIySZzFgtZzI/gxt16rA/dCzAuzHaZy5APZBSILnBxw50RKunhNiOnLKPSneYd3kHCHb+sKC30H1tNVnqL3PDvCgDauVlavsAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}
.antd-table-campaign
  .ant-table
  .ant-table-row-indent
  + .ant-table-row-expand-icon {
  margin-right: 0;
  position: relative;
  border: 0;
}
.antd-table-campaign .ant-table-tbody > tr > td {
  border-color: #f1f1f1;
}
.custom-react-table .rt-thead .rt-th span {
  padding: 0 !important;
  font-size: 12px;
}
.custom-antd-table .ant-table-thead > tr {
  background: transparent !important;
}
.custom-antd-table .ant-table-tbody > tr > td,
.custom-react-table .rt-tbody .rt-td {
  /* white-space: nowrap !important; */
  padding: 20px !important;
  font-weight: 700 !important;
  font-size: 13px;
}
.custom-antd-table
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}
.custom-antd-table .ant-table-thead > tr > th .ant-table-column-title {
  font-size: 12px !important;
}
.custom-antd-table .ant-table-expanded-row > td:first-child {
  display: none;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  > td:first-child {
  display: table-cell;
  padding: 0 !important;
}
.custom-antd-table .ant-table-expanded-row,
.inner-custom-react-table .ReactTable {
  background: #fff;
}
.inner-custom-react-table .ReactTable {
  border-radius: 0 !important;
  border-top: 1px solid #e8e8e8 !important;
}
.inner-custom-react-table .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0;
}
.custom-antd-table .ant-table-expanded-row .ant-table-tbody > tr > td {
  border-bottom: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.custom-antd-table .filter-checkbox input + label,
.custom-react-table .filter-checkbox input + label {
  padding-left: 0;
  top: -5px;
}
.custom-antd-table table,
.custom-react-table .ReactTable {
  padding: 0 20px;
}
.custom-antd-table .ant-table-expanded-row table {
  padding: 0;
}
.date-picker-arrows .react-datepicker button,
.antd-table-campaign .react-datepicker button {
  min-width: auto;
}
.date-picker-arrows .react-datepicker span,
.antd-table-campaign .react-datepicker span {
  display: inline-block;
}
.datepicker-overflow table,
.datepicker-overflow .ant-table-content {
  overflow: visible;
}
.datepicker-overflow .react-datepicker__month-read-view span,
.datepicker-overflow .react-datepicker__year-read-view span {
  display: inline-block;
}
.ck-expand {
  width: 15px;
  position: absolute;
  top: 15px;
  right: 25px;
}
.ck-exp-top {
  top: 6px;
}
.ck-exp-width {
  width: 100% !important;
  max-width: 90%;
}
.ck-exp-width .placeholder-dropdown-tktSys {
  top: 45px;
  right: -375px;
  left: inherit;
}
.ck-exp-width .ck-det-cntr .cke_top {
  height: 70px !important;
}
.ck-exp-width .ck-expand {
  top: 25px;
  right: 30px;
}
.ck-exp-width .cke_bottom {
  display: none;
}
.ck-exp-width .ck-det-cntr .cke_inner {
  min-height: auto;
}
.ck-exp-width .ck-det-cntr .cke_contents {
  height: 300px !important;
}
.ck-exp-width iframe {
  height: calc(100% - 60px) !important;
}
.store-date-width {
  width: 115px;
}
/* ticket system ends */

/* add search my ticket starts */
.srv-validation-message {
  color: #f00;
  margin-top: 5px;
}
.circular-loader {
  width: 15px !important;
  margin-right: 8px !important;
  color: #fff !important;
}
.add-cust-butn:disabled {
  opacity: 0.7;
  pointer-events: none;
}
/* add search my ticket ends */

/* my ticket starts */
.my-ticket-notes .my-ticket-notes-row:first-child {
  margin-top: 0;
}
.my-ticket-notes-created {
  margin-top: 7px !important;
}
.my-ticket-notes {
  max-height: 245px;
  overflow: auto;
}
.my-ticket-notes::-webkit-scrollbar {
  width: 5px;
}
.my-ticket-notes::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.my-ticket-notes::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.my-ticket-notes::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.ReactTable.limit-react-table-body .rt-tbody {
  max-height: 300px;
}
.ReactTable.limit-react-table-body .rt-thead {
  margin-right: 4px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar {
  width: 4px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ReactTable.limit-react-table-body .rt-thead .rt-tr {
  text-align: left;
}
.cancel-assign {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
}
.cancel-assign img {
  width: 100%;
}
.my-ticket-temp .kblink {
  top: 90px;
  left: 145px;
  right: auto;
  right: initial;
  border: none;
}
.my-ticket-temp .collapbtn {
  top: 90px;
  left: 0px;
  right: auto;
  right: initial;
  border: none;
}
.my-tic-ckeditor iframe {
  padding-top: 35px;
  height: calc(100% - 70px) !important;
}
.my-tic-ckeditor .cke_inner {
  min-height: 300px;
}
.tic-det-progress .progress {
  height: 8px;
  margin-top: 8px;
}
.ck-edit-mar {
  margin-bottom: 14px !important;
}
.tic-det-ck-user {
  position: absolute;
  right: 230px;
  z-index: 1;
  background: #fff !important;
  top: 5px;
}
.tic-det-ck-user-rply {
  right: 10px;
  top: 42px;
  width: 120px;
}
.tic-det-ck-user select {
  background-color: #ecf2f4 !important;
  height: auto;
  padding: 5px 25px 5px 15px !important;
  background-position: right 10px center !important;
}
.placeholder-dropdown {
  right: 410px;
  width: 180px;
}
.placeholder-dropdown-tktSys {
  top: 45px;
  right: -245px;
  width: 135px;
}
.placeholder-dropdown {
  right: 410px;
}
.placeholder-alert {
  right: 250px !important;
}
.big-modal-placeholder .modal-dialog {
  max-width: 1000px;
}
.tic-det-Freecmd {
  right: 65px;
  top: 15px;
}
.tic-createTic {
  right: -100px;
  top: 45px;
  width: 100px;
}
.template-user {
  right: 80px;
  top: 38px;
  width: 160px;
}

/* my ticket ends */

/* .cke_editable {
  margin: 0px !important;
} */

/* settings starts */
.module-switch .switch input[type="checkbox"]:checked + .cr {
  border: 1px solid #50d462;
}
.module-switch .switch input[type="checkbox"] + .cr:before {
  background: #c9c9c9;
}
.module-switch .switch input[type="checkbox"]:checked + .cr:before {
  background: #50d462 !important;
}
.temp-sub {
  padding: 0 40px 30px 40px;
}
.temp-multi.schedule-multi .css-1hwfws3 {
  padding-right: 35px;
  background-position: right 12px center;
}
.settings-created-by-popover .title {
  color: #000;
}
.settings-created-by-popover .sub-title {
  font-size: 13px;
  margin-top: 2px;
}
.settings-created-by-popover div {
  margin-bottom: 18px;
}
.settings-created-by-popover div:last-child {
  margin-bottom: 0px;
}
.settings-created-by-popover {
  padding: 10px 20px;
}
/* settings ends */

/* sla starts */
.category-box input:checked + label img {
  display: block;
}
.category-box input + label img {
  display: none;
}
.category-box input + label div {
  float: right;
  border: 2px solid #2561a8;
  width: 16px;
  height: 16px;
  margin-top: 5px;
}
.category-box input {
  display: none;
}
#overlaySla,
#overlayDepartment,
#overlayFunction,
#overlayPriority,
#overlayClaimStatus,
#overlayClaimCategory,
#overlayClaimSubCategory,
#overlayClaimIssueType,
#overlayCampaignName,
#overlayCampaignStatus {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
}
#overlaySla.show,
#overlayDepartment.show,
#overlayFunction.show,
#overlayPriority.show,
#overlayClaimStatus.show,
#overlayClaimCategory.show,
#overlayClaimSubCategory.show,
#overlayClaimIssueType.show,
#overlayCampaignName.show,
#overlayCampaignStatus.show {
  display: block;
}
.dropdown-menu-sla {
  z-index: 1001;
}
.sla-cancel-search {
  position: relative;
}
.sla-cancel-search img {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 12px;
}
/* sla ends */

.kb-search-cntr {
  display: inline-block;
  cursor: pointer;
}

.alert-proper {
  margin-top: 0 !important;
  margin-right: 35px;
}

.notification-container {
  text-align: left;
}

/* chetan css end */
/******************************************************/

/*Shlok css start*/

/*******************Claim start *********************/
.claim-header-card {
  background: #ecf2f4;
}
.claim-ticket {
  font-size: 15px;
  padding: 15px;
  padding-left: 0;
  margin-bottom: 0px;
  color: #5e6061;
}
.claim-A22345 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  margin-right: 40px;
}
.claim-title {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding: 14px;
}
.btn-claim {
  background-color: #2561a8;
  border-radius: 4px;
  border-color: #2561a8;
  width: 145px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: white;
}
.btn-margin {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 30px;
}
.submit-claim {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.btn-btn-claim {
  color: #2561a8;
  background-color: transparent;
  background-image: none;
  border-color: #2561a8;
  border-radius: 0.25rem;
  height: 40px;
  line-height: 10px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}
.col-md-9-card {
  margin-left: -5px;
  flex: 0 0 75%;
  max-width: 75%;
}
.oderDetails-table {
  padding-top: 15px;
}
.span-color {
  color: red;
}
.search-customer-padding {
  padding: 30px;
}
.cusrow .row {
  margin: 0 !important;
}
.back-color {
  background-color: #f5f8f9;
  padding-top: 20px;
}
.card-radius {
  border-radius: 6px;
}
.label-color {
  color: #6b6767;
  margin-right: 5px;
}
.add-customer-color {
  color: #4275b1;
  float: right;
  cursor: pointer;
}
.search-mobile-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  border: none;
}
.searchImg {
  width: 16px;
  height: 16px;
}
.raiseclaim .searchImg {
  position: absolute;
  top: 25px;
  right: 15px;
}
.claim-status-card {
  background-color: #ecf2f4;
  width: auto;
  height: 40px;
  border-radius: 4px;
  padding: 9px 15px;
}
.line-2 {
  border: 1px solid #cad4d7;
  height: 53px;
  width: 0px;
  margin-top: -36px;
  margin-left: 753px;
}
.line-3 {
  border: 1px solid #cad4d7;
  height: 53px;
  width: 0px;
  margin-top: -53px;
  margin-left: 810px;
}
.arrow-img-1 {
  width: 18px;
  height: 18px;
  /* float: right; */
  margin-right: 10px;
  margin-top: -5px;
  margin-left: 10px;
}
.plush-img-1 {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  /* margin-top: -9%; */
  float: right;
}
.first-margin {
  margin-left: -13px;
  font-size: 15px;
}
.search-order {
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-left: 27px;
  padding-left: 6px;
}
.searchImg-1 {
  width: 20px;
  height: 20px;
  margin-top: -70px;
  margin-left: 833px;
}
.batashoes {
  width: 150px;
}
.label-6 {
  color: #4a4a4a;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  opacity: 0.5;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 10px;
}
/* .raiseclaim .row {
  margin: 0;
} */
.ticket-comments-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  min-height: 125px;
  border: none;
  height: auto;
  resize: none;
}
.hr-ago {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  padding-top: 15px;
}
.naman-R {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-top: 15px;
  margin-left: -6px;
}
.hr-ago-margin {
  margin-left: 43px;
  padding-left: 70px;
}
.margin-claim {
  margin-left: -28px;
  padding-top: 10px;
}
.comment-02-margin {
  margin-bottom: 9px;
}
.card-radius2 {
  border-radius: 6px;
  padding-top: 35px;
  padding-left: 25px;
  height: auto;
}
.displayNn {
  display: none;
}
.displayShw {
  display: block;
}
/**************************************/

/* -------------Deepka Savani-------------- */

.addNote-textarea::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.addNote-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.card-header1 .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
}

/* ----------------My Ticket List Page--------------------- */

.myticketlist-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.escalation-04-new {
  color: #2561a8;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.Escalation04-box1 {
  background-color: #e3ebed;
  color: #2561a8;
  border-radius: 4px;
  width: 137px;
  height: 43px;
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
}

.Add-ticket-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 148px;
  height: 40px;
  cursor: pointer;
}

.myticketlist-header table td {
  padding: 15px 15px 5px 15px;
  color: #727474;
}
.add-tickets {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
/* .oval04 {
  background-color: #2561a8;
  border-radius: 100%;
  width: 30px;
  height: 30px;
} */

/* ---------------------------------------------------------- */

/**************************************/

/********Shlok css for Historical task popup********/
/**Historical popup css start**/
.historical-model {
  border-radius: 10px;
}
.table-scrolling {
  min-height: 300px;
  overflow: auto;
  max-height: 350px;
  margin-left: 0;
}
#Historical-popup .rt-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#Historical-popup .rt-table::-webkit-scrollbar-track {
  background: yellow;
  border-radius: 10px;
}
#Historical-popup .rt-table::-webkit-scrollbar-thumb {
  background: yellow;
  border-radius: 10px;
}
#Historical-popup .rt-table::-webkit-scrollbar-thumb:hover {
  background: yellow;
}
/**Historical popup css edn**/

/*Progress bar in my tickeyt page*/
progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 290px;
  height: 8px;
  border-radius: 20px;
  /* background-color: #777; */
  color: #ff6d33;
}
.responseProgress {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
}
progress::-webkit-progress-bar {
  background-color: #777;
  border-radius: 20px;
}

.ticket-progress::-webkit-progress-value {
  background: -webkit-gradient(linear, left top, right top, color-stop(42%, #ff8363), color-stop(50%, #ff631d));
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  border-radius: 20px;
}
.ticket-progress::-webkit-progress-bar {
  background-color: #d8d8d8;
  border-radius: 20px;
}

.ticket-progress::-moz-progress-bar {
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  border-radius: 20px;
}
.prog-indi-1 {
  width: 100%;
  text-align: center;
  position: relative;
  margin: 0;
  text-transform: capitalize;
}
.dropdown-label {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 20px center;
  background-size: 15px;
  border: none;
  cursor: pointer;
}
#Historical-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 360px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.historical-popup {
  margin-right: auto !important;
  min-width: 60%;
}
/* ---------------------------Deepak Savani Task:Claim Tab Ticket View-----------------------------   */

.claim-00-1 {
  color: #2561a8;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
}

.messages-04-1 {
  color: #4a4a4a;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.rectanglesystem table tr {
  border-bottom: none !important;
}
.custom-select:focus,
.form-control:focus {
  background-color: #ecf2f4 !important;
  box-shadow: none !important;
}
/* ------------------------------------------------------------------------------------------------- */
/* model close button css */
.styles_closeButton__20ID4 {
  display: none;
}
.ReactModal__Overlay {
  background-color: rgba(77, 92, 116, 0.2) !important;
}

/* .styles_modal__gNwvD
{
  top: 7%;
  left: 14%;
  border-radius: 8px;
  width: 500px;
} */
/* ------------------------------- */

.CliamDetailsModal {
  background-color: #ffffff;
  width: 945px;
  height: 929px;
  float: right;
  position: relative;
}

.black-left-arrow {
  width: 20px;
  height: 18px;
  margin-top: -5px;
  cursor: pointer;
}

.claim-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  padding-top: 3px;
  text-align: left;
  margin-left: 20px;
  text-transform: uppercase;
}

.id-a-22134 {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 70px;
  text-align: left;
}

.claimrowmargin {
  margin-left: 25px;
}
.claimline {
  margin-left: -25px;
  margin-right: -24px;
}
.claim-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.sub-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  padding: 0;
}
.claim-type {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.claim-asked-for {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.attached-image {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.refund {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  display: block;
}

.recent-orders {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.recent {
  margin-bottom: 20px;
}
.customer-name {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.email-id {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}
.phone-number {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.gender {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.Add-Comments-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 359px;
  height: 198px;
  border: none;
  resize: none;
  padding: 10px;
}

/* ----- */

.table-header {
  background-color: white;
}
.claim-status-table {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 876px;
  min-height: 350px;
  height: auto;
}
.my-tic-mob-float {
  position: absolute;
  top: -20px;
  right: -20px;
}
.my-tic-mob-float img {
  width: 12px;
}
.claim-status-table-header {
  background-color: #ecf2f4;
  width: 876px;
  height: 57px;
  border: 1px solid #ecf2f4;
  border-radius: 8px;
}

.claim-status-open {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding: 20px;
}

.order-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 34px;
  padding-top: 20px;
}

.invoice-number {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.invoice-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.item-count {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.item-price {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.price-paid {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-code {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-address {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.claim-SKU-details-table {
  background-color: #f8f8f8;
  width: 802px;
  height: 105px;
  margin-left: 10px;
}
.bb-332398 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.sku {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

/* table.borderless td,table.borderless th{
  border: none !important;
} */

/* ------ */
.oval-5-1 {
  background-color: #7ed321;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* margin-left: 15px; */
}

.varun-nagpal {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.hi-diwakar-i-really {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  width: 343px;
  text-align: left;
}

.add-comment-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 359px;
  height: 43px;
}

.add-comment-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.claim-status-table .table td {
  padding: 0 15px;
}

.claim-status-row {
  background-color: #f3f0f0;
  border-radius: 4.5px;
  width: 851px;
  height: 10px;
  margin: 10px;
}

.ticket-details-textarea::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-details-textarea:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-details-textarea::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-details-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-tittle-textbox::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.ticket-tittle-textbox:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.ticket-tittle-textbox::-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.ticket-tittle-textbox::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.batashoes-icon {
  width: 135px;
  height: 76px;
}
.batashoes-icon img {
  width: 100%;
}
.reacttableclaimdrawer .ReactTable {
  border: none;
}
.reacttableclaimdrawer .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 7px !important;
}
.reacttableclaimdrawer .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable {
  background-color: #f8f8f8;
}
.reacttableclaimdrawe .ReactTable .rt-thead.-header {
  background-color: #f8f8f8;
}
.borderless table tr {
  border-bottom: none !important;
  border: none !important;
  height: 0px;
  padding: 1px;
}

.borderless table td {
  border: none !important;
}

.borderless table tr td:last-child {
  text-align: left;
}

.claim-SKU-details-table table tbody tr {
  height: 28px;
  border: none !important;
}
.claim-SKU-details-table table tbody tr td {
  border: none !important;
}

#Notification-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 25%;
  top: -20px;
}
#Status-popup {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 80px;
  margin: 0 auto;
  padding: 0px;
}
#ScheduleModel {
  border-radius: 10px;
}
.textBox {
  border: none;
}
/* #endregion */
.disabled-input {
  cursor: not-allowed !important;
}
/* Deepak Savani for Program Code Page add CSS  */

.program-code-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  /* margin-right: 10px; */
  cursor: pointer;
  color: #fff;
}
.chpass {
  font-weight: bold;
  color: #a5a5a5;
  /* margin-bottom: 5px; */
}
.program-code-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}

.program-code-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding: 10px;
}

.card {
  border: none !important;
}
.headPhone3 {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 0;
}

.ScheduleDate-to {
  /* width: 245px; */
  margin-bottom: 17px;
  margin-top: 20px;
  width: 100%;
}
.txt1Place {
  padding-left: 10px;
  width: 245px !important;
  border: none;
}
.scheduleBtn {
  background-color: #2561a8;
  border-radius: 4px;
  /* width: 245px; */
  height: 43px;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
}

.obpml {
  margin-left: 0px;
}
.textbhead {
  display: inline;
  font-size: 14px;
  padding: 0 15px;
  font-weight: bold;
}
.textbhead span {
  font-weight: normal;
}
.collapbtn {
  position: absolute;
  right: 100px;
  top: 41px;
  z-index: 1;
  border: 1px solid #d1d1d1;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}
.collapbtn1 {
  margin: 45px 0 0 18px;
  z-index: 1;
  /* border: 1px solid #d1d1d1; */
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}
.collapbtn button {
  background: none;
  width: auto;
}
.kblink {
  position: absolute;
  top: 41px;
  right: 5px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.kblink1 {
  z-index: 1;
  padding: 5px 8px 5px 5px;
  /* border: 1px solid #d1d1d1; */
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
  margin: 45px 0 0 0;
}
.floatingtabs {
  position: absolute;
  bottom: 0;
  right: 0;
}
.action-label {
  float: right;
}

#slaEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  max-width: 30%;
  min-width: 30%;
}

/*------------------------------Knowledge Base start-------------------------*/

.kb-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.header-new-submissions {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 20px;
  padding-top: 17px; */
  cursor: pointer;
}

.header-new-submissions-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 24px;
  padding-top: 17px; */
  cursor: pointer;
}

.kb-Header-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 185px;
  height: 43px;
  float: right;
  margin-right: 20px;
  margin-top: 7px;
  cursor: pointer;
  color: #ffff;
}

.add-new-kb {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
}

.main-content-kb {
  background-color: #ffffff;
  border-radius: 4px;
  width: 97%;
  margin: 20px;
}

.main-content-margin {
  margin: 10px;
}
.main-conenet-point {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.clear-search {
  color: #cd3245;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-left: 20px;
  cursor: pointer;
  display: inline;
}

.searchicon {
  width: 20px;
  height: 20px;
  margin-left: 7px;
  cursor: pointer;
}

.search-KB {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.table-id-data {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.table-details-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: auto;
  text-align: left;
  white-space: normal;
  display: inline-block;
}
.table-type-return {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.table-category {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.table-subcategory {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.kb-table table thead th {
  border: 0;
  background-color: white;
}

.reject-button {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
}

.reject-button-1 {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
}
.reject-button-text {
  color: #d0021b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  padding-bottom: 5px;
}

.aprove-button {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  padding: 0px;
}

.approve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.down-icon-kb {
  width: 21px;
  height: 21px;
  vertical-align: top;
}

/* ------------------------------------end---------------------------------- */

.ReactModal__Overlay {
  overflow: auto;
}
.oval-5-1-new {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

/*------------------------------Knowledge Base start-------------------------*/

.kb-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.header-new-submissions {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 20px;
  padding-top: 17px; */
  cursor: pointer;
}

.header-new-submissions-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 24px;
  padding-top: 17px; */
  cursor: pointer;
}
.kb-header span {
  margin-left: 20px;
  margin-top: 17px;
  display: inline-block;
}

.kb-Header-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 185px;
  height: 43px;
  float: right;
  margin-right: 20px;
  margin-top: 7px;
}

.add-new-kb {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: uppercase;
}

.main-content-kb {
  background-color: #ffffff;
  border-radius: 4px;
  width: 97%;
  margin: 20px;
}

.main-content-margin {
  margin: 10px;
}
.main-conenet-point {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.search-KB {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
}

.table-id-data {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.table-details-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: auto;
  text-align: left;
  white-space: normal;
  display: inline-block;
}

.table-details-data-1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 383px;
  text-align: left;
  white-space: normal;
  display: inline-block;
}
.table-type-return {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.table-category {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.table-subcategory {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.kb-table table thead th {
  border: 0;
  background-color: white;
}
.kb-table table thead th.pad {
  padding-right: 65px;
  float: inherit;
}

.reject-button {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
  min-width: 0;
  cursor: pointer;
}

.reject-button-1 {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
  min-width: 0;
}
.reject-button-text {
  color: #d0021b;
  font-family: Lato;
  font-size: 11px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  padding-bottom: 5px;
  cursor: pointer;
}

.aprove-button {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  padding: 0px;
  min-width: 0;
  cursor: pointer;
}

.approve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  cursor: pointer;
}

.down-icon-kb {
  width: 20px;
  height: 15px;
  padding: 3px;
  vertical-align: top;
  cursor: pointer;
}
.ReactModal__Overlay {
  overflow: auto;
}

/* --------------------------------Search Modal------------------------------- */
#kb-search-popup {
  border-radius: 0px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  height: 100%;
  width: 422px;
}
#kb-search-ovrly {
  padding: 0px 0px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#kb-search-popup .styles_closeButton__20ID4 {
  display: none;
}

.search-modal-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
  margin-left: 8px;
}
.search-button-modal {
  background-color: #2561a8;
  border-radius: 4px;
  width: 105px;
  height: 43px;
  float: right;
  margin-right: 40px;
  cursor: pointer;
}
.search-button-modal-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}

.kb-Model-mp {
  margin: 40px 40px 0px 40px;
}

.back-button-left {
  width: 24px;
  height: 24px;
  margin-top: -6px;
  cursor: pointer;
}

.kb-modal-type-select {
  border-radius: 4px;
  width: 340px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12% center;
  margin-left: 20px;
  background-size: 17px;
  cursor: pointer;
}
.claimTab-drawerModal .ant-drawer-content-wrapper {
  width: auto !important;
}

/* ----------------------------------------------------------- */

/* ---------------------------Add new kb modal-------------------------------- */

#addkb-modal-popup {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-top: 4.5%;
  display: inline-block;
  height: auto;
  width: 600px;
}
#addkb-modal-ovrly {
  padding: 0px 0px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#addkb-modal-popup .styles_closeButton__20ID4 {
  display: none;
}

.add-select-category {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 7% center;
  background-size: 20px;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding-left: 10px;

  cursor: pointer;
}

.add-select-subcategory {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 7% center;
  border-radius: 4px;
  background-size: 20px;
  width: 258px;
  height: 43px;
  border: none;
  padding-left: 10px;
  margin-left: 11px;
  cursor: pointer;
}

.addkb-subject {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  /* margin-left: 17px; */
  margin-top: 15px;
  padding: 10px;
}

.addkb-subject::-webkit-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.addkb-subject:-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.addkb-subject::-ms-input-placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.addkb-subject::placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.rich-text-areas {
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  width: 530px;
  height: 178px;
  resize: none;
  margin-left: 17px;
}

.add-kb-button-modal {
  background-color: #2561a8;
  border-radius: 4px;
  width: 128px;
  height: 43px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}
.add-kb-button-text-modal {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.cancel-button-modalk {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  width: 128px;
  height: 43px;
  cursor: pointer;
  margin-right: 0;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.cancel-button-modal {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.cancel-button-modal-icon {
  width: 17px;
  height: 16px;
  float: right;
  margin-right: 20px;
  margin-top: -20px;
  cursor: pointer;
}
.main-content-kb .pagi {
  padding-top: 43%;
}

.kb-table .card-body {
  padding: 0;
}

/* ----------------------------------------------------------- */
/* ------------------------------------end---------------------------------- */

/* ---------------------------------myticket list----------------- */

.header-list-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.claim-modal-scroll .row {
  margin-right: 0;
}

.forgotpass-card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 406px;
  /* height: 438px; */
}
.forgot-pass-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  /* margin-top: 28px; */
  margin-top: 10px;
}

.recovery-pass-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 282px;
  height: 43px;
}

.programcode-card-new {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 406px;
  height: auto;
}
.mainDivPadding .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: -15px;
}
.lblHistorical {
  margin-left: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 22px;
}
.historyTable {
  font-size: 12px;
  padding: 10px 0px;
  margin-right: 0;
}
.historyTable table thead tr {
  background-color: #ffffff !important;
  border-bottom: none;
}

/* --------------------------------------------------------- */
#profile-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 548px;
  height: 494px;
}
#BillInvoice-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0 16px 0 28px;
  margin: 0 auto;
  display: inline-block;
  min-width: 83%;
  /* height: 550px; */
}
.profilemodal-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  display: block;
}

.profilemodal-textval {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 20px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  display: block;
}

.openticketbox {
  background-color: #43c3a7;
  border-radius: 8px;
  width: 206px;
  height: 138px;
}

.profilemodalrow {
  margin-top: 9.13%;
  margin-left: 3.85%;
}

.profilemodalrow-1 {
  margin-top: 7.13%;
  margin-left: 3.85%;
}

.open-tickets-box-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 38px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  display: block;
  padding-top: 33px;
}
.open-tickets-box-textval {
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  display: block;
  text-align: center;
  margin-top: -15px;
}
.openticketbox-2 {
  background-color: #4a90e2;
  border-radius: 8px;
  width: 206px;
  height: 138px;
  margin-left: 26px;
}
.alternumber {
  border: 0;
  border-radius: 5px;
  padding: 10px;
  width: 171px;
  height: 40px;
  font-size: 14px;
  background: #dbe4e7;
}

.full-profile-view-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  float: right;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 12px;
}

.profilemodal-row-3 {
  float: right;
  margin-right: 25px;
  margin-top: 60px;
}
.profilemodalmaindiv {
  clear: both;
  overflow: hidden;
}
.pro-cross-icn {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

#ticket-store-modal {
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  width: 439px;
  height: auto;
}
#layout-ticket-store-modal {
  padding: 70px 10px 70px 0px;
  background-color: rgba(77, 92, 116, 0.2);
}

.storemodal-row {
  margin-top: 10px;
}
.storemodal-select {
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  cursor: pointer;
}

.storemodal-text {
  background-color: #edf2f4;
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding: 10px;
}
.storemodal-row-1 {
  margin-top: 20px;
}

.storemodal-more-filter {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.storemodal-search-btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 365px;
  height: 43px;
  text-align: center;
  cursor: pointer;
}

.storemodal-search-btn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  /* padding-top: 10px; */
}

.collstore-head-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  float: left;
}
.storemd-icon {
  width: 30px;
  height: 30px;
  float: left;
}

.profilemodalmaindiv .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 25px 25px 15px;
  position: relative;
  padding-left: 25px;
}

.profilemodalmaindiv .card .card-header button {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}
.profilemodalmaindiv .card .card-body {
  padding: 0px 40px;
}

.collstore-head-text-black {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  float: left;
}

.pro-cross-icn-1 {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 92%;
  cursor: pointer;
}
.historyTable table th {
  font-size: 13px;
  padding: 12px 30px;
}
.historyTable table tbody tr {
  border-bottom: none;
}
.historyTable table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 10px 30px;
}

.cancalImg {
  width: 18px;
  height: 18px;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

.datePicketArrow {
  width: 15px;
  height: 10px;
  margin-left: 5px;
  cursor: pointer;
}
.storemodal-row-1 button {
  padding: 0;
}

.profilemodalmaindiv-1 {
  clear: both;
  overflow: hidden;
}

.profilemodalmaindiv-1 .card {
  padding-top: 20px;
}

.profilemodalmaindiv-1 .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 0px;
  position: relative;
}

.profilemodalmaindiv-1 .card-body {
  padding: 0px 23px;
}

.profilemodalmaindiv-1 .card .card-header button {
  margin-left: 10px;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 24px center;
}

.storeproduct-tab-head-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.storeheadsearchdiv {
  margin-left: 20px;
}

.tabhead1 {
  width: 30%;
}

.tabhead2 {
  width: 15%;
  text-align: right;
}

.tabhead3 {
  width: 25%;
  text-align: right;
}

.tabhead4 {
  width: 25%;
  text-align: right;
}

.tabdatadeactive {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
}
.tabdatadeactive-1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.addselected-button {
  background-color: #edf2f4;
  border: 1px solid #3069ac;
  border-radius: 4px;
  width: 175px;
  height: 43px;
  margin-right: 20px;
  cursor: pointer;
}
.addselected-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.bigblue {
  color: DodgerBlue;
  padding: 40px;
  font-family: Arial;
  text-align: center;
}

/* 
.storemodal-select{  
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url("../Images/dropDown.png") no-repeat right 12px center;
} */
/* ----------------------------store and product modal search modal----------------------- */
#storeproductsearchmodal {
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  min-width: 800px;
  width: 1002px;
  height: 694px;
  padding: 0px 5px 0px 31px;
  max-width: 1100px;
}
#layout-storeproductsearchmodal {
  padding: 70px 10px 70px 0px;
  background-color: rgba(77, 92, 116, 0.2);
}

.storeproductdetailsdiv {
  background-color: #edf2f4;
  border-radius: 4px;
  width: 571px;
  height: 694px;
}
.disabled-link {
  pointer-events: none;
}
.text-disabled {
  pointer-events: none;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  line-height: 50px;
  width: 100px;
  text-align: left;
  cursor: pointer;
}

/* ---------------------------------------------- */
/* Css Store_CRM Roles Shlok */
/**  =====================
      Switches css start
     =====================  **/
.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.switch input[type="checkbox"] + .cr {
  position: relative;
  display: inline-block;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  /* top: 10px; */
  top: 2px;
  margin-left: 115px;
}
.cr-tick {
  top: 10px !important;
}
.switch input[type="checkbox"] + .cr:after,
.switch input[type="checkbox"] + .cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
.switch input[type="checkbox"] + .cr:before {
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
  background: #50d462;
}
.switch input[type="checkbox"] + .cr:after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}
.switch input[type="checkbox"]:checked + .cr:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.switch input[type="checkbox"]:checked + .cr:after {
  left: 16px;
}
.switch input[type="checkbox"]:disabled + label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
  cursor: not-allowed;
}
.switch.switch-primary input[type="checkbox"]:checked + .cr:before {
  background: #c9c9c9;
}
.switch.switch-danger input[type="checkbox"]:checked + .cr:before {
  background: #f44236;
}
.switch.switch-success input[type="checkbox"]:checked + .cr:before {
  background: #1de9b6;
}
.switch.switch-warning input[type="checkbox"]:checked + .cr:before {
  background: #f4c22b;
}
.switch.switch-info input[type="checkbox"]:checked + .cr:before {
  background: #3ebfea;
}
.switch.switch-alternative input[type="checkbox"]:checked + .cr:before {
  background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}

/**====== Switches css end ======**/

/* *************New react table start css(SHLOK BAROT)************** */
.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: #f8f8f8;
  /* background-color: #E5E9ED; */
  border-bottom: 1px solid #eeeeee;
}
.ReactTable {
  border-radius: 5px !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}
.rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  color: #9e9e9e !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  float: left !important;
  color: #4a4a4a !important;
  font-weight: 500;
  white-space: normal;
}
.ChatElementReact
  .ReactTable
  .rt-thead
  .rt-resizable-header:last-child
  .rt-resizable-header-content {
  float: right !important;
}
.ChatElementReact .react-tabel-button {
  float: right;
}
.chatRules
  .ReactTable
  .rt-thead
  .rt-resizable-header:last-child
  .rt-resizable-header-content {
  float: right !important;
}
.chatRules .react-tabel-button {
  float: right;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: none !important;
  padding: 20px 25px !important;
  /* color: #393a3a !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px; */
}
.ReactTable .rt-tbody .rt-td {
  padding: 18px 20px !important;
  border-right: none !important;
  font-weight: 500;
}
.settings-align .ReactTable .rt-tbody .rt-td,
.settings-align .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.settings-align-actions {
  display: flex;
  align-items: center;
}
.settings-align-actions .react-tabel-button {
  flex: 0 0 57px;
}
.settings-align .del-btn,
.settings-align .downloadaction {
  margin-right: 20px;
}
.inner-custom-react-table .ReactTable .rt-tbody .rt-td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
#inner-custom-react-table .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding: 15px 20px 9px !important;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 15px !important;
  /* color: #393a3a !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px; */
}
#tic-det-assign .ReactTable .rt-td {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
#tic-det-assign .ReactTable .rt-th {
  font-weight: 700 !important;
  color: #9b9b9b !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.react-tabel-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 57px;
  height: 27px;
  min-width: auto;
  padding: 0px !important;
  color: #ffffff;
}
.rt-tableMoveImge {
  flex: 0 0 auto !important;
  width: 0px !important;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -2px;
}
.btn-padding-rTbale {
  padding-top: 20px !important;
}
/* .rt-resizable-header {
  width: 0px !important;
} */
.headerAdminStyle {
  background: aqua !important;
}
.store-settings-cntr > .rt-td:nth-child(1) {
  width: 50%;
}
/* .rt-th{
  display: none !important;
} */

/* Drag and drop table By Shlok  */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 20px 25px !important;
}
table span {
  font-size: 13px !important;
}
.ant-table-thead > tr > th {
  background: #e5e9ed !important;
}
table thead tr {
  background-color: #e5e9ed !important;
}
/* End css  */
/* Multi-Select Drop Down css start by SHLOK */
.dropDown-multiselect {
  width: 295px !important;
  height: calc(2.55rem + 2px);
}
.add-valueLogo-Dd {
  padding: 4px 8px;
  cursor: pointer;
  float: right;
  color: rgb(42, 112, 193);
}
.sweetAlert-inCategory {
  color: #2561a8;
  float: right;
}
/* Scrolling css start By Shlok*/
.ant-select-dropdown-menu-vertical::-webkit-scrollbar {
  width: 5px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-thumb:hover {
  background: #d2ddeb;
  border-radius: 10px;
}
/* Scrolling css End */
.ant-select-dropdown ul .ant-select-dropdown-menu-item:last-child {
  background: #f9f9f9;
}
/* Multi select drop down css End */
/*********8 Alert page css Start *********/
.alert-tableImge {
  width: 20px;
  height: 18px;
  margin-left: 15px;
}
.communication-labelHeader {
  color: #393a3a !important;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
}
.right-sect-div h4 {
  color: #2561a8;
  font-size: 17px;
  font-weight: 600;
  padding-top: 20px;
}
.ant-checkbox + span {
  padding-right: 18px !important;
  font-weight: 500 !important;
}
.cre-str-alrt .ant-checkbox + span {
  padding-right: 9px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2561a8 !important;
  border-color: #2561a8 !important;
}
.text-areaModel {
  background-color: #ffff;
  padding: 12px;
  height: auto;
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #f1f0f0;
  resize: none;
}
.notification-placeholder {
  position: static;
}
.noti-plchldr-cntr {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
/* Alert page css End */
/* Next pop-up in Alert Page start css */
#Alert-next-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 250px;
  margin-top: -54px;
  display: inline-block;
  width: 780px;
  height: auto;
}
.alert-nav-tabs3 {
  border-bottom: 1px solid #ececec;
  border: none;
  margin-left: 270px;
}
.alert-nav-tabs3 .nav-link {
  color: #4a4a4a;
  font-weight: 700;
  padding: 12px 20px;
  border: 0;
}
.alert-nav-tabs3 .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  /* box-shadow: none; */
}
.container-fluid-alert {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}
.position-relative-alert {
  position: inherit !important;
  background: #f3f3f3;
  padding-bottom: 15px;
  margin-top: -25px;
}
.alert-tabs {
  padding: 15px 20px;
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
}
.margin-Alerttab {
  margin-left: 250px;
}
.cancelImg-alert {
  height: 20px;
  float: right;
  margin-top: -42px;
  margin-right: -13px;
}
.alert-sec-div h3 {
  color: #2561a8;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 7px;
}
.alert-sec-div {
  padding: 25px 45px;
}
.alert-main-popuplbl {
  font-size: 22px;
  font-weight: 500;
  color: #2561a8;
  padding-bottom: 20px;
  margin-top: -10px;
}
/* pop-up End css */
/* Email-test editor */
#cke_32 {
  display: none;
}
#cke_47 {
  display: none;
}
#cke_1_bottom {
  display: none;
}
#cke_editor1 {
  border-radius: 3px;
}
/* #cke_editor1 {
  margin-left: 0 !important;
  width: 100% !important;
} */
#cke_24 {
  display: none;
}
.textbox-email-editor {
  border: 1px #f4f4f4 solid;
  width: 240px;
  margin-left: 60px;
  height: 34px;
  border-radius: 3px;
  padding-left: 10px;
}
.text-box2 {
  margin-left: 57px;
}
.text-box3 {
  margin-left: 49px;
}
.text-box4 {
  margin-left: 28px;
}
.div-padding-alert {
  padding-left: 15px;
}
.m-t1 {
  margin-bottom: -10px;
}
.label-color-alert {
  color: #807e7e;
  padding-top: 5px;
}
.butn-2 {
  background-color: #2561a8;
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}
.div-button1 {
  float: right;
  margin-top: 25px;
  margin-bottom: 20px;
}
/* .sms-mainLabel{
  padding: 25px 40px;
  margin-top: -70px;
  color: #2561a8;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 7px;
} */
/* Template page css Start by Shlok */
.template-table .ReactTable .rt-tbody .rt-td {
  padding: 18px 25px !important;
  border-right: none !important;
  font-weight: 500;
}
.template-table .rt-tr .rt-th:last-child {
  margin-left: 20px !important;
}
.template-table .rt-tr .rt-th:last-child div {
  margin-left: 20px !important;
}
.modal-header {
  border: none !important;
  padding: 0;
}
.modal-body {
  padding: 0 !important;
}
/* Template css End */
/* Add-Search My Ticket page  start css*/
.addSearch-header {
  background-color: #ecf2f4;
  width: auto;
  height: 55px;
  padding: 12px 20px;
}
.mobile-noAddsearch {
  font-weight: 700;
  font-size: 16px;
}
.arrowImg-addSearch {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.label-addsearch {
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}
.paste-addSearch {
  width: 18px;
  margin-left: 12px;
  cursor: pointer;
}
.bck-AddSearch {
  background: #f5f8f9;
  height: auto;
}
.addsearch-div {
  padding: 15px;
}
.addsearch-div .card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.addSearchCard {
  padding: 50px 270px;
  height: 100vh;
}
.label1-AddSearch {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.label2-AddSearch {
  font-size: 16px;
  font-weight: 500;
}
.search-customerAddSrch {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 90%;
  height: 43px;
  padding: 10px;
  border: none;
}
.notFound-addSrch {
  width: 35px;
  height: 30px;
}
.div-notFound {
  margin-top: 60px;
  text-align: center;
}
.div-notFoundaddseacr {
  margin-top: 60px;
  text-align: center;
  width: 90%;
}
.lbl-count-foundData {
  font-size: 24px;
  font-weight: 600;
  margin-top: 15px;
}
.btn-addCustomer {
  color: #2662a9;
  background-color: #fbfbfb;
  border-color: #2662a9;
  font-weight: 600;
  height: 34px;
  padding-top: 5px !important;
  margin-top: 15px;
}

.lbl-popup-title {
  font-size: 18px;
  font-weight: 500;
}
#AddSearchModel {
  position: relative;
  padding: 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: -60px auto;
  width: 670px;
  border-radius: 10px;
}
.pop-upAddSearchPD {
  padding: 0px 15px;
}
.row-margin1 {
  margin-top: 20px;
  margin-bottom: 15px;
}
.radio-btn-margin {
  margin-top: 10px;
}
.datePicker-modal {
  background: #ecf2f4 !important;
  border: none !important;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAmCAMAAACbFsmhAAAAAXNSR0IArs4c6QAAAEtQTFRFAAAASEhISkpKSUlJQEBASUlJRERER0dHSkpKSkpKRERESUlJSkpKSkpKSkpKSkpKSUlJSUlJSUlJQkJCSUlJAAAAAAAAQEBASEhIj7N+mwAAABl0Uk5TACCAcAgOD0v/4R7E4Of8bz84Bx8cAQMQQEovJCIAAAD6SURBVDiNvZNdF4IgDIYXJmBCzjDr///S+JAja0naRc8Vq+fEtjcAIichmjMQzo0Qp+XcyoDSurtIwqXTWsVTC70JWK2vnSF0V61tPPVg9FfMbmlAHPxPKyQom7+JEgKM/uaWTtf6bkcAXKWbc9OdSvfJudsqDVhhONL4LsmaCnaRlKyg1um2QSb5tGeAOWSaCyYt+0ub5ctMyFSEz2VZHJfmEVF6F3Gcc8Ebj6bPzPPYnC4wpf9qw6XiBpeCEvy6otcs8cZ3ScX+ssSXWSSRpa1YIg//cj1PPh3JdCmYRDIlT+fzdLn4USKZpqKa3RuHpH++4L72fBP9C6J4KTfb9cvOAAAAAElFTkSuQmCC) no-repeat right 7%
    center !important;
  background-size: 15px !important;
  height: 40px !important;
  padding: 10px;
}
.btn-float {
  float: right;
  margin-top: 15px;
}
.cancel-btn-A {
  margin-right: 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #2561a8;
  font-family: lato;
  font-weight: 400;
}
/* css End */
/* .KBase .cke_browser_webkit {
  margin-left: 0px !important;
  width: 530px !important;
} */
.collapbtn1 svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -2px 3px;
}
/* .ck-det-cntr iframe {
  padding-top: 15px;
} */
.ck-det-cntr .cke_top {
  height: 120px !important;
}
.ck-det-cntr-custom .cke_top {
  height: 80px !important;
  background: #fff;
  border-color: #f3f3f3;
}
.ck-det-cntr-custom .cke {
  border-color: #f3f3f3;
}
.ck-det-cntr-custom .colladrow label {
  font-size: 14px;
}
.ck-det-cntr-custom .file-upload1 + label {
  margin-right: 0;
}
.ck-det-cntr-custom .inputcc,
.ck-det-cntr-custom .inputcc-one,
.ck-det-cntr-custom .CCdi1 {
  border-color: #f3f3f3;
  font-weight: 400;
}
.ck-det-cntr-custom .CCdi1 {
  border-left: 0;
  padding-right: 0;
  padding-left: 0;
}
.ck-det-cntr .cke_contents {
  height: auto !important;
}
.ck-det-cntr .cke_inner {
  min-height: 380px;
  height: auto;
}
.KBas .cke_contents {
  height: 160px !important;
  width: auto !important;
}
.KBas .cke_bottom {
  display: none !important;
}
.KBase .cke_combo_text {
  padding: 0px !important;
}
.KBase .cke_top {
  padding: 0px !important;
}
/* css start Knowledge base pop-up modal by Shlok */
.myTicket-btn-A {
  min-width: 0px;
  /* padding: 12px; */
  margin-top: 0px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
  height: 40px;
  font-size: 14px;
}
.assign-modal-KBase .modal-lg {
  max-width: 435px;
  margin-right: -20px !important;
  margin-top: 115px !important;
}
.assign-modal-div {
  /* padding: 0px 40px 0px 30px; */
  padding: 0px 30px;
}
.assign-modal-header {
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eae9e9;
}
.assign-modal-body {
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-top: 1px solid #eae9e9;
  margin-top: 18px;
}
.txt-btmSpace {
  margin-bottom: 15px;
}
.srch-mleft-spc {
  /* margin-left: 120px; */
  float: right;
  width: auto;
  margin-top: 5px;
}
.assign-btn {
  height: 43px;
}
.anchorTag-clear {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 15px;
}
.assign-modal-body table thead tr {
  background-color: white !important;
  border-bottom: none !important;
}
.assign-modal-body table th {
  font-size: 14px;
  padding: 10px 5px;
  color: #bbb8b8;
}
.assign-modal-body table tbody tr {
  border-bottom: none;
}
.assign-modal-body table td {
  font-size: 14px;
  color: #9b9b9b;
  padding: 10px 5px;
}
.assign-hdphone {
  margin-right: 8px;
}
.assign-modal-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  /* width: 359px; */
  width: 100%;
  height: 236px;
  border: none;
  padding: 15px;
  resize: none;
  margin-top: 15px;
}
.btn-assign-tikcet {
  height: 43px;
  width: 360px;
  margin-top: 12px;
}
.assign-butn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.width {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Css End Knowledge base */
/* Dashboard Assign pop up modal css start*/
#AssignPop-up {
  /* max-width: 450px; */
  max-width: 520px;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  /* margin-left: 800px; */
  /* margin-left: 815px; */
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
.assign-modal-headerDashboard {
  padding: 0px 20px 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eae9e9;
}
.assign-modal-body-dash {
  padding: 25px 32px 10px 15px;
  margin-bottom: 15px;
  border-top: 1px solid #eae9e9;
  margin-top: 18px;
}
/* Dashboard Assign pop up Css End */
.fo a {
  font-size: 17px;
}
.Add-Notes-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 198px;
  border: none;
  resize: none;
  padding: 10px;
}
.tab {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  text-align: left;
}
.notesbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  cursor: pointer;
}
.notesbtn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  /* width: 100%; */
  cursor: pointer;
}
.commentbt {
  margin-top: -50px;
  text-align: right;
  margin-right: 15px;
}
.commentbtn {
  background: #2561a8;
  border: none;
  cursor: pointer;
}
.txt {
  cursor: pointer;
  color: white;
  font-size: 14px;
}
.plusminus {
  margin-top: -32px;
  float: right;
}
.li-1 {
  border: 1px solid #cad4d7;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.d-block {
  padding: 10px 30px !important;
}
.logou {
  padding-bottom: 10px !important;
}
.pink1 {
  padding: 7px 10px;
  border: 0px;
}
.pink1 .pink2 {
  margin-top: 0px;
}
.pink2 input + label {
  padding-left: 0;
}
.draft td {
  padding: 20px 15px !important;
  color: #9b9b9b !important;
}
.claimplus {
  float: right;
  cursor: pointer;
  height: 35px;
}
.plusline12 {
  border-left: 1px solid #9b9b9b;
  padding-top: 10px;
  padding-bottom: 24px;
}
.plusline1 {
  border-right: 1px solid #9b9b9b;
  padding-top: 17px;
  padding-bottom: 18px;
}
.plusline13 {
  border-right: 1px solid #9b9b9b;
  padding-top: 8px;
  padding-bottom: 27px;
}
.plusline2 {
  border: 1px solid #9b9b9b;
  padding-top: 9px;
  padding-bottom: 9px;
}
/* My Ticket page email Editor css start by Shlok */
.myTicketEmail .cke_chrome {
  display: block;
  padding: 0;
}
.myTicketEmail #cke_editor1 {
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  border: 1px solid #d1d1d1;
  border-top: none;
}
.myTicketEmail .cke_top {
  border-bottom: 1px solid #e6e6e6;
  background: #fffefe;
  padding: 6px 8px 2px;
  white-space: normal;
}
.sendEmail-btn {
  margin-top: -10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
  font-size: 15px;
  width: 128px;
  height: 43px;
}
.sendmail-Img {
  width: 20px;
  height: 17px;
  margin-top: -5px;
  margin-right: 8px;
}
.myTicketEmail #cke_editor1 {
  margin-left: 0;
  width: 100%;
}
/* Email Editor css End*/
/* myTicket css start for comment pop up by Shlok*/
.commentsDwarer .ant-drawer-content-wrapper {
  width: 580px !important;
}
.btn-addMoreTask {
  color: black;
  background-color: #fff;
  font-weight: 600;
  float: right;
  margin-top: -10px;
}
.lblHeader-drawer {
  font-weight: 700;
  font-size: 16px;
}
.commentsDwarer .ant-drawer-body {
  padding: 0px !important;
}
.drawer-header-1 {
  padding: 15px 20px 10px;
  background: #ecf2f4;
}
.drawer-hr {
  margin-top: 0px;
}
.deleteImg {
  height: 16px;
  width: 13px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
  margin-right: 3px;
}
.knowledgeTbl table tr td:last-child,
table tr th:last-child {
  /* text-align: right; */
  float: left;
}
.active .myTciket-tab-span {
  background-color: #2561a8;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  padding: 3px 6px 4px 6px;
}
.comment-padding {
  padding: 30px;
}
.table-column {
  float: left;
}
.cmt-lbl {
  font-weight: 700;
  font-size: 15px;
}
.cmdtxt-2 {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 8px;
  border: 1px solid;
  border-color: #949090;
}
.cmt-textarea {
  background-color: #ffff;
  padding: 8px;
  /* height: auto; */
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #949090;
  resize: none;
  border: 1px solid;
}
.frm-margin {
  padding-top: 15px;
  padding-bottom: 15px;
}
.frm-margin1 {
  padding-bottom: 15px;
}
.cmt-regtangleDDL {
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding-left: 14px;
  padding-right: 40px;
  border-color: #949090;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  background-size: 16px;
  cursor: pointer;
  border: 1px solid;
}
.select-CmtDDl {
  color: #585454;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.cmdtxt-2::-webkit-input-placeholder {
  color: black;
}
.cmdtxt-2:-ms-input-placeholder {
  color: black;
}
.cmdtxt-2::-ms-input-placeholder {
  color: black;
}
.cmdtxt-2::placeholder {
  color: black;
}
.cmt-textarea::-webkit-input-placeholder {
  color: black;
}
.cmt-textarea:-ms-input-placeholder {
  color: black;
}
.cmt-textarea::-ms-input-placeholder {
  color: black;
}
.cmt-textarea::placeholder {
  color: black;
}
/* myTicket css end */
/* Dashboard datepicker css start by kalpesh  */
.react-datepicker button {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
  margin-right: 0;
}
.table-expandable-sctn .react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.55rem solid transparent;
  z-index: 1;
}
.table-expandable-sctn .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.table-expandable-sctn .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.table-expandable-sctn .react-datepicker-wrapper {
  width: 100%;
}
.allspc {
  margin-bottom: 25px;
}
/* dashboard datepicker css end */
/* My Ticket/Eye Button css Start By Kalpesh */
.customerBill {
  padding: 20px 0 0 20px;
  margin-bottom: 20px;
}
#BillInvoice-popup .customer-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
#BillInvoice-popup .cross {
  float: right;
  margin-top: 15px;
  cursor: pointer;
}
#BillInvoice-popup .down-header {
  width: 24px;
  height: 10px;
  padding-left: 10px;
  cursor: pointer;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-top: -15px;
}
#BillInvoice-popup
  .switch.switch-primary
  input[type="checkbox"]:checked
  + .cr:before {
  background: #1ab394;
}
#BillInvoice-popup .usericon {
  width: 22px;
  height: 22px;
  margin-top: -10px;
}
.customer-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.namepad {
  padding: 0;
  /* padding: 0 0 0 35px; */
  margin-bottom: 20px;
  display: grid;
}
.fullna {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}
.namedi {
  color: #000000;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  word-break: break-all;
}
.xyz {
  background-color: #edf2f4;
}
.calnex {
  float: right;
  padding: 0px 28px 18px 0px;
}
.calnexbtn {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  width: 134px;
  height: 43px;
}
.calnexbtn1 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 134px;
  height: 43px;
  margin-left: 10px;
  cursor: pointer;
}
.calnexbtn1-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.calnexbtn-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.search-orderhis {
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-left: 2px;
  border: none;
  cursor: pointer;
  padding: 10px;
}
.histo {
  padding: 20px 0 25px 15px;
}
.tablehistrical {
  margin-top: 25px;
}
.tablehistrical .ReactTable {
  border: none;
  background: #edf2f4;
}
.tablehistrical .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 0px !important;
  background-color: #edf2f4;
  border: none;
}
.historyTable-header {
  float: left;
  padding: 1px 0px 0px 18px;
}
.tablehistrical .rt-thead {
  border: none !important;
  color: #4a4a4a;
  font-size: 13px;
}
.tablehistrical .ReactTable .rt-tbody .rt-td {
  border-bottom: 1px solid #edf2f4;
}
.tablehistricaldetail .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.tablehistrical .rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  color: #000 !important;
}
#hispop-upBck {
  background-color: rgba(77, 92, 116, 0.2);
}
.skipmar {
  margin-top: 38px;
}
.done {
  margin-top: 218px;
}
.skiptext {
  color: #2561a8;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.histOrderHide {
  display: none;
}
.orderswitch {
  margin-top: -27px;
  margin-left: -25px;
}
.switch input[type="checkbox"] + .ord {
  margin-left: 70 !important;
}
.customerOrder-text {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  /*  padding-left: 15px;  */
  /*  padding-right: 15px;  */
}
.customerItem-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 75px;
  padding-right: 15px;
}
.order1checkbox {
  padding: 0;
}
.order1checkbox .order2checkbox {
  margin-top: 0;
}
.order1checkbox input + label {
  padding-left: 22px;
}
/* MyTicket/Eye Css End */
/* MyTicket ProgressBar Response Css Kalpesh Start */
.mobilenumber-resp {
  font-size: 10px;
}
.mobilenumber-resp .respo {
  float: left;
  margin-left: 35px;
}
.mobilenumber-resp .resol {
  float: right;
  color: green;
  margin-right: 35px;
}
.mobilenumber-resp .line-respo {
  border-right: 2px solid orange;
  padding: 3px;
  top: 20px;
  left: -26px;
  position: relative;
}
.mobilenumber-resp .line-resol {
  border-right: 2px solid red;
  padding: 3px;
  top: 20px;
  position: relative;
  right: -20px;
}
/* MyTicket Css End */
/* Tooltip css start by Shlok In Edit Button */
.edtpadding {
  padding: 15px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  margin-top: -5px;
  background: white !important;
}
/* Tooltip css End by Shlok In Edit Button */
/* MyTicketNew Css Start Kalpesh */
.newReact .ReactTable .rt-thead .rt-tr {
  font-size: 13px;
  color: #4a4a4a;
  padding: 0 0 0 13px;
  font-weight: 600;
}
.newReact .table-b {
  border-radius: 15px;
  width: 76px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.newReact .ReactTable .rt-tbody .rt-td {
  padding: 12px 20px !important;
  color: #9b9b9b !important;
  border-bottom: 1px solid #eeee !important;
}
/* MyTicketNew Css end*/
/* React table body color css start by shlok */
.alertsTable .rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  color: #a0a0a0 !important;
}
.assign-ticket-table .rt-tr[style="background: rgb(236, 242, 244);"] .rt-td {
  color: #000 !important;
}
.assign-ticket-table .rt-tbody .rt-tr {
  cursor: pointer;
}
.assign-ticket-table .rt-tbody .rt-tr.-padRow {
  cursor: auto;
  cursor: initial;
}
.assign-ticket-table .rt-tbody .rt-tr:hover {
  background: #ecf2f4;
}
.assign-ticket-table .rt-tbody .rt-tr.-padRow:hover {
  background: #fff;
}
.assign-ticket-table .rt-tbody .rt-tr:hover .rt-td {
  color: #000 !important;
}
/* React table body color css end */
/* StoreUser page css start by shlok */
.storeUsers table th {
  font-size: 13px;
  padding: 20px 16px;
}
/* StoreUser page css end by shlok */
/* Reports Css Start Kalpesh */
.reportbutton {
  float: right;
  margin-top: -10px;
  margin-right: 16px;
}
.addplusbtn {
  width: 120px;
  height: 40px;
  background-color: #2561a8;
  cursor: pointer;
}
.addplusbtntext {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.addplusbtnReport {
  width: 120px;
  height: 40px;
  background-color: #2561a8;
  cursor: pointer;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.downloadaction {
  width: 17px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
  /* padding: 6px 8px; */
  line-height: 1;
  box-sizing: content-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.reactreport .ReactTable .rt-tbody .rt-td {
  color: #9b9b9b !important;
}
.ticketreport {
  margin-bottom: 10px;
}
.reportpad {
  padding: 30px 40px !important;
}
.ticketreport input {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding: 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  /* background-size: 13px; */
  cursor: pointer;
  border: none;
}
.ticketreport select {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding-left: 8px;
  padding-right: 25px;
  border-color: #ecf2f4;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 4px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  height: 39px;
}
.ticketreport label {
  font-size: 13px;
  font-weight: 500;
  font-family: lato;
  text-align: left;
  line-height: 25px;
  display: block;
}
#addreport-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  max-width: 1150px;
}
.entercenter {
  padding: 15px 20px;
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  text-align: center;
}
.nextbutton-text {
  background-color: #2561a8;
  color: #fff;
  font-size: 13px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  width: 150px;
}
.nextbutton1 {
  float: right;
  margin-bottom: 20px;
  margin-right: -1px;
  margin-top: 15px;
}
#nextbuttonpopup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 730px;
  height: 415px;
}
.cancelnextpopup {
  height: 20px;
  float: right;
}
.reportdetail {
  font-size: 20px;
  font-family: lato;
  font-weight: 600;
  text-align: left;
  color: #2561a8;
}
.contpaddre {
  padding: 0;
}
.totalresultcircle {
  background-color: #f3f3f3;
  font-size: 24px;
  width: 190px;
  height: 190px;
  border-radius: 100%;
  text-align: center;
  margin: 65px 0 0 75px;
}
.totalresult {
  display: block;
  font-size: 20px;
  font-weight: 500;
  font-family: lato;
  padding: 65px 0 0 0;
  line-height: 0;
}
.totalresultnumber {
  font-size: 2.5em;
  font-weight: 600;
  color: #2561a8;
  font-family: lato;
}
.Schedulenext {
  width: 270px;
  height: 40px;
  background: #fff;
  color: #2561a8;
}
.Schedulenext1 {
  width: 270px;
  height: 40px;
  background-color: #2561a8;
  color: #fff;
  margin-bottom: 40px;
  cursor: pointer;
}
.buttonschdulesave {
  margin-bottom: 10px;
  margin-top: 75px;
}
.rname input {
  border-radius: 4px;
  width: 270px;
  height: 35px;
  padding: 0 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  cursor: pointer;
  border: none;
}
.ranmetext {
  margin-top: 65px;
  display: grid;
}
.renametext {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  text-align: left;
}
.margin-report {
  margin: auto;
  display: block;
  float: none;
  text-align: center;
}
.reactreport .ReactTable .rt-tbody {
  background: #fff;
}
.report-action {
  /* width: 115px; */
  width: 170px;
  display: flex;
  justify-content: flex-end;
}
.report-action div:first-child,
.report-action div:nth-child(2) {
  flex: 0 0 20%;
}
.report-action div:last-child {
  flex: 0 0 60%;
}
.down-tic-rep input {
  height: 43px;
}
.total-tic-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
/* Reports Css end Kalpesh */
/* storeReports css start by Shlok */
#addStorereport-modal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  min-width: 950px;
}
.ticketstrReport label {
  font-size: 13px;
  font-weight: 500;
  font-family: lato;
  text-align: left;
  line-height: 25px;
  display: block;
}
.ticketstrReport input {
  border-radius: 4px;
  width: 100%;
  height: 37px;
  padding: 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  /* background-size: 13px; */
  cursor: pointer;
  border: none;
}
.ticketstrReport select {
  border-radius: 4px;
  width: 100%;
  height: 37px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
}
.mdl-row {
  margin-bottom: 10px;
}
.store-totalresultcircle {
  background-color: #f3f3f3;
  font-size: 24px;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  text-align: center;
  margin: 65px 0 0 75px;
}
/* storeReports css End by Shlok */
/* My Ticket Claim:03 add tadk modal css start by Shlok */
.claim-addTask-btn {
  float: right;
  margin-top: -55px;
}
#ClaimAdd-taskPopup {
  /* border-radius: 8px; */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 360px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 588px;
}
.ClaimAddTadk-modal-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  border: none;
  padding: 15px;
  resize: none;
  margin-top: 15px;
  margin-bottom: 10px;
}
.claim-AddTask-Mdl {
  background: #ecf2f4;
  height: 50px;
  padding: 12px 28px 0px 16px;
  margin: 12px 0px 0px 0xp;
  margin-top: -19px;
  width: 588px;
  margin-left: -19px;
}
#ClaimAdd-taskPopup .cancel {
  background-color: #fff;
  color: #2561a8;
  font-size: 14px;
  font-family: lato;
  margin-right: 1opx;
  cursor: pointer;
  border: none;
}
.claim-hdrMdl {
  font-weight: 700;
  font-size: 16px;
}
.task-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  padding-top: 3px;
  text-align: left;
  margin-left: 20px;
  text-transform: uppercase;
}
.wifiLbl-drawer {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 15px;
}
.addTasklbl-name {
  font-weight: bold;
  color: black;
  display: flex;
}
.addTask-2-d-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
  padding-left: 7px;
}
.status-opn {
  background-color: #f9f9f9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 14px;
  padding: 6px;
}
.taskTab-drawerModal .ant-drawer-content {
  height: 100%;
  width: 435px !important;
}
.taskTab-drawerModal .ant-drawer-content-wrapper {
  width: auto !important;
}
.tasktasb-para {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  /* width: 356px; */
  text-align: left;
}
.task-drawerv-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 359px;
  height: 166px;
  border: none;
  resize: none;
  padding: 15px;
}
.varunoverflow {
  max-height: 190px;
  overflow-x: hidden;
}
.varunoverflow::-webkit-scrollbar {
  width: 4px;
}
.varunoverflow::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.varunoverflow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.varunoverflow::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar {
  height: 7px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.assign-modal-body-mytick .item-selection {
  position: absolute;
  top: 90%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  left: 0;
  text-align: center;
}
.varun-taskDrawer {
  font-weight: bold;
  color: black;
  display: inline-block;
  line-height: 30px;
  padding: 0px 5px;
}
.addTask-time-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  padding-left: 7px;
}
.task-drawer-lnl {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  width: 343px;
  padding: 0;
  margin-left: 35px;
}
.storeImg-drawer {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* margin-left: 14px; */
  padding: 6px;
}
.storeImg {
  width: 20px;
  height: 18px;
  margin-top: -5px;
}
/* My Ticket Claim:03 add tadk modal css end */
/* TicketSystem Css Start Kalpesh */
.marginsystem {
  padding: 0;
}
.column {
  width: 46%;
}
.column1 {
  width: 7%;
  height: auto;
  background-color: #f5f8f9;
}
.paddingsystem {
  padding: 40px 30px 40px 40px;
  position: relative;
  height: auto;
}
.ticket-details-system {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.ticket-details-textarea-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}
.category-select-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding: 10px;
  border: none;
}
.High button {
  background-color: #ffffff;
  border: 1px solid #d02139;
  border-radius: 4px;
  height: 43px;
  min-width: 60px;
  /* margin-left: 22px; */
  cursor: pointer;
  color: #d02139;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.High button:focus {
  background-color: #d02139;
  border: 1px solid #d02139;
  border-radius: 4px;
  height: 43px;
  min-width: 60px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.high-button-text:focus {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Low button {
  background-color: #ffffff;
  border: 1px solid #5aa805;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  margin-left: -8px;
  cursor: pointer;
  color: #5aa805;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Low button:focus {
  background-color: #5aa805;
  border: 1px solid #5aa805;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  margin-left: -8px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.low-button-text:focus {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Medium button {
  background-color: #fff;
  border: 1px solid #f5a623;
  border-radius: 4px;
  min-width: 68px;
  height: 43px;
  margin-left: -15px;
  cursor: pointer;
  color: #f5a623;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Medium button:focus {
  background-color: #f5a623;
  border: 1px solid #f5a623;
  border-radius: 4px;
  min-width: 68px;
  height: 43px;
  margin-left: -15px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.medium-button-text:focus {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Qc button {
  /* background-color: #ffffff;
  border: 1px solid #2561a8; */
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  cursor: pointer;
  /* color: #2561a8; */
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Qc button:focus {
  border: 1px solid #2561a8;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.Etc button {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  min-width: 60px;
  height: 43px;
  margin-left: -50px;
  cursor: pointer;
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Etc button:focus {
  border: 1px solid #2561a8;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

/* .Qc-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */
/* .Etb-button-text {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */
.addNote-textarea-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}
.myticketlist-header-system {
  background-color: #f5f8f9;
  width: auto;
  /* height: 620px; */
}
.system .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  padding: 20px 0;
  font-size: 16px;
}
.system .nav-tabs .nav-link {
  font-weight: 400;
  padding: 20px 0px;
  border-radius: 3px;
}
.system ul {
  display: grid;
}
.system {
  padding: 15px 10px;
  text-align: center;
}
.system .nav-tabs .nav-item {
  margin-right: 0;
}
.active .system-tab-span {
  background: transparent;
  color: #2561a8;
  display: block;
  font-weight: 600;
  font-family: lato;
  font-size: 10px;
}
.system-tab-span {
  display: block;
  font-size: 10px;
  font-weight: 600;
  font-family: lato;
}
.tabpaddingsystem {
  padding: 0;
  box-shadow: none;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  height: 100%;
}
.category2 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}
.category1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  word-break: break-all;
}
.systemeditbutton {
  background-color: #fbfbfb;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 93px;
  line-height: 0;
  height: 31.32px;
  cursor: pointer;
  text-align: center;
}
.systemeditbutton-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  /* line-height: 12px;
  width: 23.07px;
  text-align: left;
  cursor: pointer; */
}
.collapsetask {
  padding: 0;
  margin-bottom: 0;
  background-color: #ecf2f4;
  border: none;
}
.btn {
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.btn-link {
  font-weight: 500;
  color: #000;
}
.btn-link:hover {
  color: #000;
  text-decoration: none;
}
.createtasksystem {
  background-color: #2561a8;
  border-radius: 4px;
  width: 254px;
  height: 45px;
  cursor: pointer;
}
.createtasksystem-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}
.systemtaskreact {
  padding: 0 10px;
}
.systemtaskreact .ReactTable {
  border: none;
}
.systemtaskreact .ReactTable .rt-thead.-header {
  background-color: transparent;
}
.systemtaskreact .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.systemtaskreact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 15px !important;
}
.systemorder {
  background-color: #ecf2f4;
}
.arrow-imgtask-1 {
  width: 18px;
  height: 18px;
  /* float: right; */
  margin-right: 10px;
  margin-top: 0px;
  margin-left: 15px;
  cursor: pointer;
}
.systemordersearch {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  float: right;
  padding: 10px;
}
.systemorder-imgsearch {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
}
.addmanualbtn {
  text-align: center;
  margin-top: 20px;
}
.addmanual {
  width: 142px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #2561a8;
  color: #2561a8;
  line-height: 0;
  cursor: pointer;
}
.drpdwn-order > div > div {
  position: absolute !important;
  left: 0 !important;
  top: 100% !important;
  z-index: 1;
  max-height: 100px !important;
}
.addmanuallytext1 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
}
.addmanuallytext1::-webkit-input-placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}
.addmanuallytext1:-ms-input-placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}
.addmanuallytext1::-ms-input-placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}
.addmanuallytext1::placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}
.addmanuallytext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
#addOrderTableModal {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  min-width: 80%;
  border-radius: 10px;
}
.reacttableordermodal .ReactTable {
  border: none;
}
.reacttableordermodal .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 12px 15px !important;
}
.reacttableordermodal .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: #fff;
  border: none;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}
.searchtextpopup {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  float: right;
  padding: 10px;
}
.searchtextimgpopup {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
}
.orderdetailpopup {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  color: #4a4a4a;
  margin-top: 8px;
}
.minus-imgorder {
  width: 21px;
  height: 2px;
  /* float: right; */
  margin-right: 5px;
  margin-top: 12px;
  margin-left: 15px;
  cursor: pointer;
}
.orderswitchitem {
  margin-top: 7px;
  margin-left: -106px;
  margin-right: 8px;
}
.storemainrow {
  margin-left: 0;
  margin-right: 0;
  background-color: #ecf2f4;
  border-radius: 5px;
  height: 54px;
}
.storeplusline {
  cursor: pointer;
  float: right;
  padding: 15px 0 0 0;
  height: 54px;
}
.storeplusline13 {
  cursor: pointer;
  float: right;
  padding: 0 0 0 0;
  height: 54px;
}
.systemstoredropdown {
  background-color: #dbe4e7;
  width: 70%;
  height: 30px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
  background: #dbe4e7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}
.systemstoredropdown1 {
  background-color: #dbe4e7;
  width: 35%;
  height: 30px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
  background: #dbe4e7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
}
.systemstordercustomer {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
}
#addStoreTableModal {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  min-width: 83%;
  border-radius: 10px;
}
.newtabstore {
  background: none;
  padding-left: 15px;
  padding-right: 0;
}
.store-nav-tabs {
  border-bottom: 1px solid gray;
  border: none;
  margin-left: 0px;
}
.store-nav-tabs .nav-link.active {
  color: #2561a8 !important;
  border: none;
}
.store-nav-tabs .nav-link {
  color: #4a4a4a !important;
  font-weight: 700;
  padding: 0;
  margin-top: 3px;
  border: 0;
  margin-left: 15px;
}
.tabcontentstore {
  padding: 0px;
}
.linestore1 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
}
.linestore2 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
  margin-bottom: 10px;
}
.linestore3 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
  /* margin-bottom: 10px; */
}
.reactstoreselect .ReactTable {
  border: none;
}
.reactstoreselect .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 20px 4px !important;
  /* padding: 12px 15px !important; */
}
.reactstoreselect .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: transparent;
  border: none;
}
.switchmargin {
  margin-bottom: 8px;
  margin-left: -102px;
}
.ticktSycard {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.reacttableordermodal .ReactTable .rt-tbody {
  overflow: hidden;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}
.storedetailtabsbutton {
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}
.addstoretabsbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 145px;
  height: 43px;
  color: #fff;
  cursor: pointer;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
#CreateTicket1-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 10px;
  margin-top: 45px;
  margin-bottom: 4px;
  width: 184px;
  display: inline-block;
}
/* TicketSystem Css End Kalpesh */
/* UserProfile CSS Start By Kalpesh */
.profile-settings-cntr {
  padding: 35px 300px;
}
.centerprofile {
  padding: 100px 0px 20px;
  margin: auto;
}
.profilemain {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  padding-bottom: 20px;
  overflow: hidden;
}
.forwardpasstext {
  color: #2561a8;
  font-family: lato;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.half-circle {
  position: relative;
}
.half-circle::before {
  position: absolute;
  content: "";
  height: 210px;
  width: 100%;
  background-color: #2561a8;
  border-left: 0;
  box-sizing: border-box;
  border-radius: 50%;
  top: -100px;
}
.profimg {
  width: 60px;
  height: 60px;
  margin-top: 30px;
}
.profimg1 {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}
.uploadtextprofile1 {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: lato;
  color: #9b9b9b;
  padding: 5px 15px;
  cursor: pointer;
  background-color: #ecf2f4;
}
.imguserupload {
  text-align: center;
  width: 150px;
  height: auto;
  background: #fff;
  border-radius: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  position: relative;
  top: 35px;
  box-shadow: 0px 5px 4px 0px #ddd;
  z-index: 1;
}
#ProfileForgot-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.modalforgotpadding {
  padding: 25px 80px;
}
.Changepasswordheader {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  text-align: left;
  padding-bottom: 20px;
}
.file-uploadprofile + label {
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
/* UserProfile CSS End By Kalpesh */
/* MyTicket Comment Collapse Css start Kalpesh */

.commentcollapseTicket .card-body {
  padding: 0px;
}
.commentcollapseTicket .Commentlabel {
  background-color: #ecf2f4;
  padding: 10px 0 10px 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.comment-disp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commentcollapseTicket .commenttextmessage {
  padding: 25px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.Commentlabel1 {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.SendCommentBtn {
  text-align: end;
  /* margin-right: 25px; */
  margin-bottom: 20px;
}
.SendCommentBtn1 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 128px;
  height: 43px;
  color: #fff;
  cursor: pointer;
}
.comment-line {
  border: 1px solid #cad4d7;
  height: 42px;
  margin-top: -42px;
  margin-right: 40px;
  float: right;
}
.CommentMinus-img {
  width: 15px;
  height: 2px;
  margin-left: 0;
  margin-top: 12px;
  cursor: pointer;
  float: right;
  margin-right: 14px;
}
.myTicketCommentCollapse #cke_editor2 {
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
  border: 1px solid #d1d1d1;
}
.myTicketCommentCollapse .card-body {
  padding: 0;
}
.myTicketCommentCollapse #cke_2_bottom {
  display: none;
}
/* MyTicket Comment Collapse Css start Kalpesh */
/* Header Headphone Img Pop-up CSS Start Kalpesh */
.headphoneheader {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
#HeadPhoneHeader-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 300px;
  display: inline-block;
  height: 300px;
}
.logincenter {
  text-align: center;
  background-color: #f3f3f3;
  margin-left: 0;
  margin-right: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 14px 0;
}
.logincenterimg {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  margin-top: 45px;
}
.logintoivr {
  font-family: lato;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.agenttextrow {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  text-align: center;
}
.agenttextrow1 {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 3px;
}
.agenttext {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 85%;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
.agentidtext {
  font-size: 14px;
  font-weight: 600;
  font-family: lato;
  color: #4a4a4a;
  margin-left: 10px;
}
.loginbtnagent {
  width: 228px;
  height: 35px;
  background-color: #2561a8;
  color: #fff;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.easyrewardlogo {
  width: 100px;
  height: 25px;
}
.poweredby {
  font-size: 11px;
  font-family: lato;
  margin-right: 5px;
  color: lightgray;
}
#Login-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 350px;
  display: inline-block;
  height: 370px;
}
.amitsinghlogo {
  width: 60px;
  height: 60px;
}
.amitsinghcenter {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 25px;
}
.amitsinghcenter2 {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 10px;
}
.amitsinghcenter1 {
  margin-left: 15px;
  margin-right: 0;
  text-align: center;
  margin-top: 25px;
}
.amitsinghtext {
  font-size: 15px;
  font-weight: 700;
  font-family: lato;
}
.amitsinghtextonline {
  font-size: 14px;
  font-weight: 600;
  font-family: lato;
  border: 1px solid greenyellow;
  margin-right: 5px;
  padding: 5px;
}
.amitnextrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}
.logouttamitsingh {
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
  color: #9b9b9b;
}
.logoutImg {
  margin-right: 4px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.status1 input:checked + label::before {
  background-color: #6dd400;
  border: double 3px #fff;
}
.status1 input:checked + label {
  border: 1px solid #6dd400;
  border-radius: 5px;
}

.status1 input + label::before {
  content: "";
  position: absolute;
  background-color: #fff;
  border: 1px solid #6dd400;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 2px;
  top: 9px;
}
.status1 input + label {
  position: relative;
  padding-left: 20px;
}
.logout-label {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
}
.logout-label1 {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px;
}
#NextButton-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 410px;
  display: inline-block;
  height: auto;
}
.CallwrapBtn {
  width: 228px;
  height: 35px;
  background-color: #44d7b6;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.SwitchToProgBtn {
  width: 228px;
  height: 35px;
  background-color: #32c5ff;
  color: #fff;
  border: none;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.amitsinghcallrow {
  text-align: center;
  background-color: #f3f3f3;
  margin-left: -10px;
  margin-right: -10px;
  padding: 41px 0;
  margin-top: 25px;
}
.amitsinghwaiting {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 228px;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
.idletimeamit {
  font-size: 14px;
  font-weight: 400;
  font-family: lato;
  color: #44d7b6;
  margin-top: 3px;
  margin-left: 10px;
}
.amitnextbuttonrow1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: 45px;
  text-align: center;
}
.amitnextbuttonrow3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.amitnextbuttonrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 0px;
  text-align: center;
}
.takeabreak {
  border: 1px solid #e5e5e5;
  width: 120px;
  margin: 0 auto;
  padding: 5px;
}
.takebreaktext {
  font-size: 13px;
  font-family: lato;
  font-weight: 400;
  color: #795548;
  cursor: pointer;
}
.backtohomerow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.backtohomelogoutrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
}
.logoutbacktohome {
  font-size: 13px;
  font-family: lato;
  font-weight: 400;
  color: #9b9b9b;
}
.backtohometext {
  font-size: 12px;
  font-family: lato;
  font-weight: 400;
  color: #2561a8;
  cursor: pointer;
}
.CallwrapBtnwaiting {
  width: 228px;
  height: 35px;
  background-color: #e5e5e5;
  border: none;
  color: #9b9b9b;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.holdbtn {
  width: 228px;
  height: 35px;
  background-color: #1cd188;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.tranferbtn {
  width: 228px;
  height: 35px;
  background-color: #3d7aff;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.incomingcalltext {
  font-size: 12px;
  font-weight: 400;
  font-family: lato;
  color: #4a4a4a;
  margin-top: 3px;
  margin-left: 77px;
}
.incomingcallrow {
  margin-left: 0;
  margin-right: 0;
}
.tooltiptransfercallrow {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 3px;
}
.agenttext-tooltip {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
/* Header Headphone Img Pop-up CSS End Kalpesh */
.addcustdate .react-datepicker-wrapper {
  width: 100%;
}
.addcustdate .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.addcustdate .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.colladrow label {
  font-family: lato;
  font-size: 15px;
  color: #9b9b9b;
  margin-right: 10px;
}
.CCdi {
  border: 1px solid #9b9b9b;
  padding: 5px 5px;
  width: 145px;
  height: 30px;
}
.CCdi1 {
  border: 1px solid #9b9b9b;
  padding: 5px 5px;
  width: 145px;
  height: 30px;
  border-right: none;
}
.colladrow .fileup {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  cursor: pointer;
}
.colladrow .send,
.my-tic-send {
  background: #2561a8;
  color: #fff;
  font-size: 14px;
  height: 40px;
  width: 115px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.my-tic-send {
  float: right;
  margin-top: 20px;
  margin-right: 15px;
}
/* .my-tic-ckeditor {
  margin-top: 20px;
} */
.my-tic-ckeditor .cke_bottom {
  display: none;
}
.colladrow .sav {
  background: #fff;
  color: #2561a8;
  font-size: 14px;
  height: 40px;
  width: 115px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.colladrow .send1 {
  background: #2561a8;
  color: #fff;
  font-size: 13px;
  height: 30px;
  padding: 0;
  line-height: 0;
  min-width: 65px;
  border-radius: 5px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.colladrowa .ticsys {
  margin: 0 18px;
}
.colladrow .sav1 {
  background: #fff;
  color: #2561a8;
  font-size: 13px;
  padding: 0;
  line-height: 0;
  height: 30px;
  width: 75px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.EMFCImg {
  width: 20px;
  height: 15px;
}
.EMFCText {
  margin: 0 15px;
  color: #000;
  text-transform: capitalize;
}
.kblink-auto1 {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.kblink-auto2 {
  margin: 45px 0 0 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.myTicketCommentCollapse .cke_bottom {
  display: none;
}
.myTicketEmail .cke_bottom {
  display: none;
}
.file-upload1 + label {
  font-weight: 400;
  cursor: pointer;
}
.colladrow .one {
  color: #2561a8;
  margin-left: -20px;
}
.myTicketEmail {
  position: relative;
}
.colladrowa {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.DashBoarReact .table-b {
  border-radius: 15px;
  width: 76px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.marginimg {
  margin-left: -20px;
}
.DashBoarReact .ReactTable .rt-thead.-header .pinkmargin {
  margin-left: 5px;
}

.pinkmargin input + label {
  padding-left: 25px;
}
.DashBoarReact .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 12px;
}
.MyTicketListReact .ReactTable .rt-thead.-header .pinkmargin {
  margin-left: 5px;
}
.MyTicketListReact .rt-th {
  text-align: left;
}
.newReact .rt-tbody .pinkmargin {
  margin-left: 5px;
}
.newReact .pink1 {
  padding: 0;
}
/* MyTicket KbLink Css Start Kalpesh */
.knoim {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.knoim1 {
  width: 17px;
  height: 17px;
  margin-top: -5px;
  margin-right: 10px;
}
#KbLink-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  min-width: 85%;
  height: 565px;
}
#logout-ovrlykb {
  background-color: rgba(77, 92, 116, 0.2);
}
.kblinkright {
  background-color: #ecf2f4;
  padding: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 565px;
}
.thumbtick {
  border: 1px solid #ecf2f4;
  border-radius: 5px;
  margin-right: 5px;
  height: 45px;
}
.thumbtick-plus {
  border: 1px solid #ecf2f4;
  border-radius: 5px;
  margin-right: 5px;
  margin: auto 0;
  width: 32px;
  height: 35px;
  cursor: pointer;
}
.cancalImg-kb {
  width: 15px;
  height: 15px;
  float: right;
  margin-top: -15px;
  cursor: pointer;
  margin-right: -25px;
}
.knokb {
  padding: 25px 60px;
}
.knokb-a {
  padding: 25px 40px;
}
.table-details-data-modal {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  white-space: normal;
  display: inline-block;
}
.knokb-a h5 {
  margin-bottom: 25px;
  color: #2561a8;
}
.kblink-search {
  background: #2561a8;
  color: #fff;
  font-size: 15px;
  height: 40px;
  width: 125px;
  border: 1px solid #2561a8;
  line-height: 11px;
  float: right;
  cursor: pointer;
}
.down-icon-kb1 {
  width: 20px;
  height: 15px;
  padding: 3px;
  vertical-align: top;
  cursor: pointer;
  float: right;
  margin: 10px 0;
}
.textkb {
  border-bottom: 1px solid #ecf2f4;
}
.select-category-placeholderkblink {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
}
.kblinkrectangle-9 {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #fff;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  background-size: 13px;
  cursor: pointer;
  border: none;
}
.copyblue-kb {
  width: 20px;
  height: 22px;
  margin-right: 3px;
}
.copyblue-kbtext {
  font-weight: 600;
  color: #2561a8;
  font-size: 15px;
  font-family: lato;
}
.viewpolicy-kb {
  margin-left: 5px;
  width: 22px;
  height: 25px;
  margin-top: -5px;
}
.kblink-auto {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.autosavekb {
  width: 15px;
  height: 16px;
  margin-top: -4px;
}
/* MyTicket KbLink Css end Kalpesh */
/* Dashboard ReactTable Css Start Kalpesh */
.dash-task-popup-new {
  width: 220px;
  padding: 20px 20px 25px;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  left: 50%;
  top: auto !important;
  top: initial !important;
  bottom: 34px;
}
.DashBoarReact .pink1 {
  padding: 0;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(1) {
  background-color: #ffdfdf;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(2) {
  background-color: #fff3df;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(3) {
  background-color: #def3ff;
}

.modal-content {
  border: 0;
}

.broembox {
  background-color: #f3f3f3;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.broembox p {
  float: left;
}
.broembox img {
  float: right;
  height: 20px;
  margin-top: 13px;
  cursor: pointer;
}
.broembox label {
  font-size: 16px;
  font-weight: bolder;
}
.broembox span {
  font-size: 12px;
  font-style: italic;
  color: #666;
}
#totalconcount-popup {
  border-radius: 5px;
  position: relative;
  padding: 30px;
  padding-bottom: 5px;
  width: 400px;
}
.totalconcount-popup {
  padding: 15px !important;
}
.counttab td {
  padding: 0 0 25px 0;
  text-align: center;
  font-size: 12px;
}
.counttab label {
  /* font-size: 17px; */
  font-size: 16px;
  color: #2561a8;
}
.counttab span {
  font-size: 12px !important;
  color: #333;
  font-weight: 400;
}

.mob-header {
  display: none !important;
}
.cardasscus .store-task-tabs .nav-tabs .nav-link {
  padding: 0px 30px 5px;
}
.cardasscus .ant-table-row-cell-last {
  text-align: right !important;
}
.cardasscus .card-img {
  width: 140px;
  box-shadow: 0 0 10px 0px #ddd;
  border-radius: 10px;
}
.cardasscus .card-img img {
  height: auto;
  width: 115px;
  margin: auto;
  display: block;
  padding: 20px 10px;
}
.cardasscus .btngreen {
  background-color: #00b894;
  color: #fff;
  padding: 8px 15px !important;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}
.cardasscus .btnred {
  background-color: #ff7163;
  color: #fff;
  padding: 8px 15px !important;
  border: none;
  cursor: pointer;
}
.cardasscus .statusbtnadd {
  background-color: #e5f7f4;
  color: #0ebc9a;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: 600;
}
.cardasscus .statusbtnrej {
  background-color: #ffe3e0;
  color: #ff7171;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: 600;
}
.cardassetspop {
  width: 300px;
}
.cardsresbtn {
  text-align: right !important;
}
.addimg input {
  width: 100%;
}
.pickuppending-table td {
  padding: 10px 10px;
}
.pickuppending-table tr {
  border-bottom: none;
}
.pickuppending-table label {
  display: block;
  margin-bottom: 10px;
}
.pickuppendingcustom .ant-popover-inner-content {
  padding: 10px;
  width: 200px;
}
.pickuppending-table .popbtn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.pickuppending-table .popbtnno {
  background-color: #fff;
  color: #2561a8;
  border: 1px solid #2561a8;
  font-size: 14px;
  padding: 8px 25px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.pickuppending-table .form-control {
  margin: 0 !important;
}
#addressPincode-popup {
  width: auto;
  border-radius: 10px;
}
#article-popup {
  width: 800px;
  border-radius: 10px;
}
#article-popup .heading {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.article-body span {
  text-align: center;
  display: block;
  font-size: 16px;
}
.staffdetailspopup {
  padding: 20px 30px;
}
.staffdetailspopup .popbtn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.staffdetailspopup .popbtnno {
  background-color: #fff;
  color: #2561a8;
  border: 1px solid #2561a8;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.staffdetailspopup label {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 15px;
}
.staffdetailspopup .form-control {
  border: none;
  margin-bottom: 15px;
}
.enterpod {
  border: none;
  width: 125px;
}
.enterpod::-webkit-input-placeholder {
  color: #2561a8;
  text-align: center;
  font-weight: normal;
}
.enterpod:-ms-input-placeholder {
  color: #2561a8;
  text-align: center;
  font-weight: normal;
}
.enterpod::-ms-input-placeholder {
  color: #2561a8;
  text-align: center;
  font-weight: normal;
}
.enterpod::placeholder {
  color: #2561a8;
  text-align: center;
  font-weight: normal;
}
#article-popup .tabs-content {
  padding: 0px;
  margin: 0;
}
#article-popup .step-progress {
  padding: 0;
}
#article-popup .footer-buttons {
  padding: 0 10px;
}
#article-popup .btn-prev {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  border-color: #2561a8;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
#article-popup .btn-next {
  background-color: #fff;
  color: #2561a8;
  font-size: 14px;
  border-color: #2561a8;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.airwaybox {
  background-color: #f6f9fb;
  border-radius: 10px;
  border: 1px solid #ddd;
  min-height: 300px;
}
.airwaycontent {
  margin-top: 8%;
}
.airwaycontent .cardtick {
  height: 50px;
  margin-bottom: 15px;
}
.airwaycontent h2 {
  font-size: 28px;
  margin-bottom: 5px;
}
.airwaycontent p {
  font-size: 17px;
  color: #999;
  margin-bottom: 5px;
}
.airwaycontent ul {
  border-top: 1px solid #ddd;
  display: inline-block;
}
.airwaycontent ul li {
  font-size: 18px;
  color: #666;
  padding-top: 5px;
}
.airwaycontent ul li:first-child {
  margin-right: 30px;
}
#article-popup em {
  display: none;
}
.progtrckr li {
  margin-right: 50px;
  padding-left: 20px;
}
#article-popup .progtrckr-done span {
  color: #2561a8;
  font-weight: bold;
}
#article-popup .progtrckr-doing span {
  color: #2561a8;
  font-weight: bold;
}
#article-popup .progtrckr {
  margin: 20px;
  border-bottom: 1px solid #ddd;
  padding: 0;
  padding-bottom: 12px;
  margin-top: 0;
}
#article-popup .multi-step {
  position: relative;
}
.progtrckr li:first-child {
  position: relative;
}
.progtrckr li:first-child:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  background: #2561a8;
  right: -46px;
  top: 12px;
}
.innertabcollapse label {
  display: block;
}
.innertabcollapse table td {
  border-top: none;
  vertical-align: top;
}
.innertabcollapse label b {
  font-weight: bold;
  color: #333;
  font-size: 12px;
}
#shipment-tab table td span {
  display: inline;
}
#shipment-tab .antd-table-order .ant-table-row-expand-icon {
  display: inline;
  margin-left: 10px;
}
#delivered-tab .antd-table-order .ant-table-row-expand-icon {
  display: inline;
}
.pickuppendingcustom {
  display: inline;
  vertical-align: super;
  float: left;
}
.action-w {
  min-width: 150px;
}

.initial-logo {
  width: 210px;
}

@media (min-width: 768px) {
  .storecards .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .storecards .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/* Dashboard ReactTable Css End Kalpesh */
/* MyTicketList ReactTable Start Kalpesh */
.MyTicketListReact .table-b {
  border-radius: 15px;
  width: 85px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.MyTicketListReact .ticketid {
  font-size: 12px;
}
.MyTicketListReact .pinkmyticket {
  padding: 0 0;
}
.tabpadtick {
  padding-bottom: 60px !important;
}
.slatemp-textpopup-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 25%;
}
.newReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 10px !important;
}
/* MyTicket Bottom Header Css Start Kalpesh */
.TicketTabs {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
  padding: 0 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.TicketTabs li {
  padding: 15px 0;
}
.TicketTabs .active {
  border-top: 3px solid #2561a8;
}
.TicketTabs .Sub {
  margin: 0 20px;
}
.TicketTabs .SubR {
  margin: 0 12px;
}
.TicketTabs .SubL {
  margin: 0 12px;
}
.TicketTabs .Ticket {
  width: 22px;
  height: 22px;
}
.TicketTabs .cancel {
  width: 16px;
  height: 16px;
}
.TicketTabs .Subject {
  margin: 0 15px;
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  opacity: 0.501983;
  text-align: left;
}
.TicketTabs .More {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.TicketTabs .MoreUp {
  width: 14px;
  height: 11px;
}
/* MyTicket Bottom Header Css End Kalpesh */
.dashnewpopup li p {
  margin-bottom: 0;
}
.dashnewpopup {
  padding: 0px 10px;
  font-size: 14px;
  border-radius: 8px;
  white-space: normal;
  white-space: initial;
}
.myticlist-expand-sect .nav-tabs .nav-link {
  padding: 20px;
  background: transparent;
  color: #4a4a4a;
  font-weight: 700;
  border: 0;
  margin-right: 0;
}
.myticlist-expand-sect .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  font-weight: 700;
  background-color: transparent;
  padding: 20px;
  border-radius: 0;
}
.myticlist-expand-sect {
  background: #ecf2f4;
}
.myticlist-expand-sect .nav-tabs {
  border-color: #dae2e5;
  position: relative;
  border-bottom: 1px solid #dae2e5;
}
.myticlist-expand-sect .nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 0;
}
.myticlist-expand-sect .save-view-search {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-51%);
          transform: translateY(-51%);
}
.myticlist-expand-sect .save-view-search button {
  padding-right: 25px;
  padding-left: 25px;
}
.myticlist-expand-sect button,
.csv-button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}
.csv-button {
  padding-top: 15px;
  padding-bottom: 15px;
}
.csv-dis-btn {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}
.myticlist-expand-sect .save-view-search button.btn-inv {
  padding-right: 45px;
  padding-left: 45px;
}
.myticlist-expand-sect button.btn-inv {
  color: #fff;
  background-color: #2561a8;
  cursor: pointer;
}
.myticlist-expand-sect .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
  margin-bottom: 0 !important;
}
.myticlist-expand-sect input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px
    center;
  color: #4a4a4a;
}
.myticlist-expand-sect input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.myticlist-expand-sect input::-webkit-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.myticlist-expand-sect input:-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.myticlist-expand-sect input::-ms-input-placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.myticlist-expand-sect input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.myticlist-expand-sect select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.myticlist-expand-sect .react-datepicker button {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
  margin-right: 0;
}
.myticlist-expand-sect .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.myticlist-expand-sect .react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.55rem solid transparent;
  z-index: 1;
}
.myticlist-expand-sect .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.myticlist-expand-sect .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.myticlist-expand-sect .myticket-text input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff;
  color: #4a4a4a;
}
.dapic .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.dapic .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.dapic .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.diwamargin {
  /* margin-bottom: 8px; */
  margin-bottom: 15px;
}
.ticketsystemeditor .cke_bottom {
  display: none !important;
}
.ticketsystemeditor .kblink {
  position: absolute;
  top: 7px;
  right: 113px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.ticketsystemeditor .kblink-auto1 {
  position: absolute;
  top: 7px;
  right: 16px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.dashallbrand {
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
  cursor: pointer;
  font-weight: 500;
  margin: 0 7px;
  min-width: 65px;
  height: 30px;
  line-height: 1px;
  font-size: 14px;
  background: #dbe4e7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 12px
    center;
  background-size: 14px;
  text-align: left;
}
.dashallbrand::after {
  display: none;
}
.dashallbrand1 .dropdown-menu.show:before {
  display: none;
}
.dashallbrand1 .dropdown {
  background: transparent !important;
  display: inherit;
}
.ticketSycard {
  padding: 0 0 0 10px;
  background-color: #f5f8f9;
  height: 100%;
}
.ticketSycard1 {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}
.marginsystem1 {
  border-radius: 8px;
  height: auto;
}
.addNote-textarea-system-new {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  /* position: absolute; */
  height: 120px;
  border: none;
  resize: none;
  padding: 10px;
}
.paddingsystem .cke_bottom {
  display: none !important;
}
.collapsetask .drop {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAACA0IaCAAAA+klEQVR4nJ3SvyuFURzH8dfz3J8uukX5EcWIlNKVlAwMJpPMJpPBiCwWo81itDEx3TIhspBBZPRfmA3PeUq3nuu591Pf6XzO+3zO5xyIUERVdyqEvXGMEkYwhXKHoBgDmEENxnCAB6x1AIwwjiPcYQkqWMA1HrES0v4HGsU+PnGI/nSxgHk0cY9FSY9ZoCHs4QvH6Gk1FQPkNkwjHNIKGsQuPnCCvqz4JSxLOmhi7g8wkpS9E0CnqGeBUpWxiifcYFryanVs4x1nIWEuVbCOZ1xhFlt4wzmG84JSVbGB1zAvuJB8pa5Uw6bkapeYaGeOcgB7MYkffLcz/gLxDiIT44kXAgAAAABJRU5ErkJggg==) no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
  cursor: pointer;
}
.drop .drop1 {
  width: 100%;
  text-align: left;
}
.DashTimeRange .form-control {
  padding: 3px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  /* width: 120px; */
}
.DashTimeRange .form-control:focus {
  background-color: none !important;
  box-shadow: none !important;
}
.DashTimeRange #DateTimeInput_end {
  width: 120px;
  margin-left: -135px;
  color: #666 !important;
  border: none;
}
.DashTimeRange #DateTimeInput_start {
  width: 120px;
  text-align: right;
  border: none;
  color: #666 !important;
}
.DashTimeRange .timeContainer {
  display: none;
}
.DashTimeRange .dateTimeLabel {
  display: none;
}
.DashTimeRange .activeNotifier {
  display: none;
}
.DashTimeRange .fromDateHourContainer {
  position: absolute;
  bottom: 5px;
  border: none;
}
.DashTimeRange .fromDateTimeContainer {
  height: 295px;
}
.DashTimeRange .buttonContainer {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.DashTimeRange .input-group-addon {
  display: none;
}
.DashTimeRange .applyButton {
  background-color: #2561a8 !important;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  padding: 5px 10px;
  width: 88px;
  cursor: pointer;
  margin-right: 4px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #2561a8;
  text-transform: uppercase;
}
.DashTimeRange .applyButton:hover {
  background-color: #fff !important;
  color: #2561a8 !important;
}
.DashTimeRange .cancelButton:hover {
  background-color: #2561a8 !important;
  color: #fff !important;
}
.DashTimeRange .cancelButton {
  background-color: #fff;
  color: #2561a8;
  font-size: 12px;
  width: 88px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #2561a8;
  text-transform: uppercase;
}
.DashTimeRange .rangecontainer {
  border-right: 1px solid #eeeeee;
}
.DashTimeRange .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.DashTimeRange .glyphicon-chevron-left {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.DashTimeRange .glyphicon-chevron-right {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.dashallbrand1 .ch1 {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}
.dashallbrand1 .ch1-text {
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
  font-family: lato;
  margin: 0 5px;
}
.dashallbrand1 .dropdown-menu.show {
  min-width: 155px;
  display: grid;
}
.DashTimeRange .inputDate form-control {
  margin: 0 0 0 100px;
}
.centerprofile .txt-1 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 47px;
  border: none;
  padding: 15px;
}
.centerprofile .CreateADDBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 47px;
  cursor: pointer;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.im {
  flex: 20 0 auto !important;
  width: 20px !important;
}
#ScheduleModel .scheduleBtncancel {
  background-color: #fff;
  border-radius: 4px;
  /* width: 245px; */
  width: 100%;
  height: 43px;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  border: none;
  cursor: pointer;
}
#ScheduleModel .txt1Time {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAAXNSR0IArs4c6QAAAKhQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALN9IhgAAADh0Uk5TABQiKzERM0tUWVtcWDIQAg88XV47BilXVkk3OD5PNBMENT06BU0cHU4BKkwYGSxVOTADKCFaDh5ya+ZGAAABsElEQVQ4jZWU22KCMAyGg+eWg9hURJ0goFPnPDLd+7/ZWgtoK1wsN23pR5Km+Qvwf7Na7U6n3bKaiW6vPyDUtqkz6LteHTH0R4RhZczh/tBkxsFE7ITTGaeUz6eh4MNgbDAf4u9FFAMso2gpcksW4kOqUeMZIsm0NDxOEGcv1CpFXH+aGWy2wteqXO2+GO6/y6003RTTwxpZsCsW/gRJuQFHxGPli2DoF444Iq+O27btdlUWsZMpVx7BRRfqIOgu0FHH6TEWQT0ECWPuw+cew7gJskLsy0ysE06hCYIpnqSHFsF5M3RGpyXPTJGvmqAVRyqXHRupljhmz+uPKNoXMV4E1HtCIjp1lyZkhIOO+J5cjXBG4gCujSwvI4rEf8QQ6yUAuEY2knyplUAWU2/8YS7yUvUtiymvJdEguOa3o0qzvBbwnNcLVlSsGHnBqlf0VtHiylYp5lrTvZpsunsx3wUM14d3RrZvXrbvQwjbN1+/mhAKSXFNUl1+0yUlqFSKM7F0cY5MCQfhQ+ZnKfOzknluMOK498zRHozsXlsWz+0PnPLp8esIZbF6xOJmotH+AEYyKLcdaVrfAAAAAElFTkSuQmCC) no-repeat right 12px center;
  background-size: 17px;
}
.Every {
  width: 35px;
  height: 40px;
  background-color: #ecf2f4;
  border: none;
  padding: 0 8px;
  margin: 0 8px;
  border-radius: 5px;
  color: #6e6e6e;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
}
.ScheduleDate-to .every1 {
  color: #a7a7a7;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
#ScheduleModel .ant-checkbox + span {
  padding: 0 9px 0 5px !important;
  color: #a7a7a7;
}
#ScheduleModel .ant-checkbox-wrapper {
  width: 60px;
}
#ScheduleModel .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
.pop-over-cancle:hover {
  color: #2561a8 !important;
}
#thumb-modal-popup {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: auto;
  display: inline-block;
  height: auto;
  width: 634px;
}
#thumb-modal-popup .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  margin: -20px 5px 0 0;
}

/* ****************End css ************************/

.autocomp div {
  background: red;
}

.loader-icon-cntr {
  position: relative;
  min-height: 150px;
}
.loader-icon-cntr-above {
  background: #fff;
  margin: 0 15px;
  border-radius: 5px;
  margin-bottom: 40px;
}
.loader-icon {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* height: 100vh; */
  bottom: 0;
  background: transparent url(/static/media/loader.a5be3183.gif) no-repeat center center;
  background-size: 60px;
  z-index: 5;
}
.loader-icon-cntr-ovrlay .loader-icon {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
}
.loader-icon-height {
  height: 200px;
}
.tic-history .ReactTable {
  border: none;
}
.tic-history .ReactTable .rt-thead.-header {
  background-color: transparent;
  border: none;
}
.tic-history .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.tic-history .ReactTable .rt-th {
  font-weight: bold;
  text-align: left;
  color: #888;
}
.tic-history .ReactTable .rt-tbody .rt-td {
  padding: 10px 15px !important;
}
.tic-history .ReactTable .rt-thead .rt-th.-cursor-pointer,
.tic-history .ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 15px !important;
}

.bckHide .rt-th {
  display: none;
}
.tableSrolling {
  max-height: 300px;
  overflow: auto;
}

.datePickertable .ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
  overflow: initial;
}

.datePickertable .ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
}

.datePickertable .ReactTable .rt-tr .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: visible;
  overflow: initial;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;
}

.datePickertable .ReactTable .rt-tbody {
  overflow: visible;
  overflow: initial;
}
.circleCancle {
  height: 18px;
  position: absolute;
  top: -7px;
  right: -2px;
  cursor: pointer;
}

.datePickertable .react-datepicker__input-container input,
.date-picker-arrows .react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #dcd4d4;
  padding: 1px 5px;
  border-radius: 3px;
  font-weight: 500;
}
.date-picker-arrows .react-datepicker__input-container input {
  width: auto;
}
.datePickertable button {
  min-width: 0;
  min-width: initial;
}
#Modalorderitemtable .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}
#Modalorderitemtable .ReactTable {
  border: none;
}
#Modalordertable {
  max-height: 400px;
  overflow: auto;
}
#Modalordertable .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}
#Modalordertable .ReactTable {
  border: none;
}

.reactstoreselect .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}

.applySearch {
  font-size: 14px;
  font-weight: 700;
  color: #2561a8 !important;
  margin-right: 12px;
  cursor: pointer;
}

.tic-noti label {
  line-height: 1.5;
}
.noti-count {
  width: 50px;
  text-align: center;
}
.noti-count label {
  padding-right: 9px;
}
.notification-popover {
  max-width: 150px;
  max-height: 200px;
  overflow: auto;
  padding-right: 10px;
}
.notification-popover p {
  margin-bottom: 0;
  padding: 5px 0;
  font-weight: 700;
}
.notification-popover a {
  margin-left: 10px;
  font-weight: 400;
  color: #2561a8;
}
.notification-popover::-webkit-scrollbar {
  width: 6px;
}
.notification-popover::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}
.notification-popover::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}
.notification-popover::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cus-drp .css-yk16xz-control,
.cus-drp .css-1fhf3k1-control {
  border: none;
  color: #000;
  /* display: block; */
  margin-top: 5px;
}
.cus-drp .css-14jk2my-container {
  pointer-events: initial;
}
.cus-drp .css-14jk2my-container .css-1hwfws3 {
  cursor: not-allowed;
}
.cus-drp .css-14jk2my-container .css-1rhbuit-multiValue {
  pointer-events: none;
}
.cus-drp .css-1hwfws3 {
  background: #ecf2f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 25px
    center;
  background-size: 13px;
  min-height: 43px;
  border-radius: 4px;
  padding-right: 45px;
  padding-top: 4px;
}
.cus-drp .css-1g48xl4-IndicatorsContainer,
.cus-drp .css-1wy0on6 {
  display: none;
}
.cus-drp .css-1pahdxg-control {
  border-color: transparent;
  margin-top: 5px;
  border: none;
  box-shadow: none;
  background-color: #ecf2f4;
}
.cus-drp .css-1pahdxg-control:hover,
.cus-drp .css-1pahdxg-control:focus {
  border-color: transparent;
}
.cus-scroll {
  max-height: 300px;
  overflow: auto;
}
.agentlist .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: #ecf2f4;
  cursor: pointer;
}
.agentlist .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: transparent;
  border-bottom: none;
}
.agentlist .ReactTable {
  border: none;
}
.agentlist .ReactTable .rt-thead .rt-resizable-header-content {
  color: #a0a0a0 !important;
}
.agentlist .ReactTable .rt-td {
  color: #4a4a4a !important;
  padding: 12px 20px !important;
}
.agentlist .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.order-det .ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal !important;
  text-overflow: inherit !important;
  padding: 7px 5px;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.order-det .ReactTable .rt-thead .rt-th,
.order-det .ReactTable .rt-thead .rt-td {
  width: 150px !important;
  text-align: left;
}
.order-det .ReactTable .rt-tbody .rt-td {
  width: 150px !important;
  padding: 12px 15px !important;
}
.order-det .ReactTable .rt-thead .rt-th,
.order-det .ReactTable .rt-thead .rt-td {
  padding: 12px 15px !important;
}
.order-det .ReactTable .rt-tbody .rt-td:first-child {
  width: 50px !important;
}
.order-det .ReactTable .rt-thead .rt-th:first-child,
.order-det .ReactTable .rt-thead .rt-td:first-child {
  width: 50px !important;
}
.order-detc2 .ReactTable .rt-tbody .rt-td:nth-child(2) {
  width: 50px !important;
}
.order-detc2 .ReactTable .rt-thead .rt-th:nth-child(2),
.order-detc2 .ReactTable .rt-thead .rt-td:nth-child(2) {
  width: 50px !important;
}
#daterangepicker .daterangepicker:after {
  top: -6px;
  border-right: 6px solid #fff0;
  border-bottom: 6px solid #fff;
  border-left: 6px solid #fff0;
}
.tic-his {
  max-height: 300px;
  overflow: auto;
}
.cus-head span {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.cus-head .ReactTable .rt-thead.-header {
  background-color: #e5e9ed;
}
.issuetype-cusdrp .btn {
  background-color: #ecf2f4;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  color: #333;
  text-align: left;
  padding: 10px 15px;
}
.store-report-add .ticketstrReport select {
  padding-left: 15px;
}
.store-report-add .issuetype-cusdrp .btn {
  padding: 7px 40px 7px 15px;
  font-size: 14px;
}
.issuetype-cusdrp .dropdown-menu.show {
  margin: 0;
  width: 100%;
  padding: 0px;
}
.cat-mainbox {
  padding: 10px;
  border-bottom: 1px solid #d2ddeb;
}
.issuetype-cusdrp .dropdown-menu.show:before {
  content: "";
}
.issuetype-cusdrp .searchf {
  background-color: #f9f9f9;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  padding: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
}
.issuetype-cusdrp .searchf::-webkit-input-placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.issuetype-cusdrp .searchf:-ms-input-placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.issuetype-cusdrp .searchf::-ms-input-placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.issuetype-cusdrp .searchf::placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.issuetype-cusdrp .filter-checkbox {
  max-height: 70px;
  overflow: auto;
  margin-bottom: 10px;
}
.issuetype-cusdrp .filter-checkbox ul {
  margin-bottom: 0px;
}
.issuetype-cusdrp .filter-checkbox ul li {
  display: block;
}
.issuetype-cusdrp .category-button ul {
  margin-bottom: 0px;
}
.issuetype-cusdrp .category-button li:nth-child(1) {
  float: left;
}
.issuetype-cusdrp .category-button li:nth-child(2) {
  float: right;
}
.issuetype-cusdrp .category-button ul label {
  color: #2561a8;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.5px;
}
.issuetype-cusdrp .category-button {
  margin-bottom: 35px;
  padding: 6px 6px 0 6px;
}
.issuetype-cusdrp .category-box {
  margin-bottom: 15px;
  max-height: 110px;
  overflow: auto;
}
.issuetype-cusdrp .category-box {
  clear: both;
  padding: 0 6px;
}
.issuetype-cusdrp .category-box label {
  display: block;
}
.issuetype-cusdrp .category-box label {
  display: block;
  margin-bottom: 6px;
  color: #666;
  font-size: 16px;
}
.issuetype-cusdrp .category-box span {
  background-color: #f3f3f3;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 12px;
  margin: 2px 0;
  margin-right: 5px;
  display: inline-block;
}
.issuetype-cusdrp .category-box li {
  display: block;
  margin-bottom: 13px;
}
.store-report-add .issuetype-cusdrp .category-box li:last-child {
  margin-bottom: 0;
}
.category-scroll {
  max-height: 190px;
  overflow-x: hidden;
}
.category-scroll::-webkit-scrollbar {
  width: 6px;
}
.category-scroll::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}
.category-scroll::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}
.category-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.category-buttonbtm .cancel {
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}
.category-buttonbtm .done {
  margin-top: 0;
  background: #2561a8;
  border: none;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 40px;
  font-size: 14px;
  cursor: pointer;
}
.category-buttonbtm {
  padding: 12px 15px 15px;
}
.category-buttonbtm ul {
  margin-bottom: 0;
}
.issuesladrop {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 12px center;
  background-size: 12px;
}
.store-report-add .issuesladrop {
  background-position: right 19px center;
}
.issuetype-cusdrp .category-box label img {
  height: auto;
  /* width: 15px; */
  width: 10px;
  /* float: right; */
  /* margin: 5px 10px 0; */
  margin: 2px 1px 0;
}
#storeEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  /* max-width: 50%;
  min-width: 50%; */
}
.commentImg {
  width: 25px;
  height: 25px;
  padding: 5px;
}
.editstorepopover {
  min-width: 700px;
  max-width: 700px;
}
.emshl {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAACDklEQVR4nO3Yv2pUQRTH8c/dvWaFBGLEViEGLcTCzkZQNDZW4huIWqTwAXwDLQRfwAiKbyCCxqDpBRHESrSw0KhgFKPZzR8tZpbs3v1zV3Zzd4v8YODOYZj5zsy5Z2YOI6ak4Xs/9mG8YIZVfI9FEkGu4RwmUS4YaBM/8BTzKa7gKqaHANMINY0kxWyslFBFrWCYsVimMZva3qYanuNFwUBncDZCTaa2t6mGJdwqGCjBqQhULhU8eK7SHez3OI7gAPYIjruBZbzBu6KA9uIELuIkDkXbBtYizP2igEpCPLuNo5oDb11VIfZ17GCQOij8FJ1gcjXoFbqEGc0wf/Fb8CH4hfWigC606fM9HuNLBPmK10UBzWh1g+tY1OMJMGgfmtK8Xet42yvMTgAlGaA/gg/1rH63rJfbQTnTbksXyH6AykIAzNqy9WOa484Kvgl3oIECTeBlTptxPMrYHuCecLNo0TAO18/CKrXVMIA+GTGgZR38h/58qIo7GdscKpk2D/GzwfZqp4DWcCNju9wG6CY+Nthqwq8/cKA6VJ6qPbbDcHyoq3aB8rQLlKddoDyNHFBq+zUwhtP+84aXUaVNfU6XwzSOORa/NxM8ER53JSGs95OOmdD6BFrVfZL1dMwWFlM8w2EhP1PROst+lETIPG3iAxZS3BVmcF7IMQ4jpbeCBcw3Lu9ULENNeo6c/gGctmdXcpsYvAAAAABJRU5ErkJggg==) no-repeat right 15px center;
}
.fileAttchImg {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}

#categoryEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  max-width: 30%;
  min-width: 30%;
}

#tampleteEditModal {
  width: 300px;
  top: 16%;
}

.editcatcnl {
  float: right;
  margin: 0;
  color: #2561a8 !important;
}

.clrFlwUp {
  color: #2561a8;
  width: 18px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  display: inline-table;
}
.dropdown-settings {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  width: 100%;
  border: none;
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.notifi-container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.notifi-container::-webkit-scrollbar {
  width: 5px;
}
.notifi-container::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.notifi-container::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.notifi-container::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}

#BlockEmailModel {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  width: 670px;
  border-radius: 10px;
}

.btn-block {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.block-cancelImg {
  height: 20px;
  float: right;
  margin-top: -11px;
  margin-right: -13px;
}

/* Responsive starts */
@media only screen and (max-width: 1300px) {
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
  .placeholder-dropdown-tktSys {
    right: -275px;
  }
}
@media only screen and (max-width: 1200px) {
  .colladrow label {
    font-size: 12px !important;
  }
  #slaEditModal {
    max-width: 50%;
    min-width: 50%;
  }
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
}
@media only screen and (max-width: 1024px) {
  /* Qa Dashboard */
  .QAtable table th {
    padding: 15px 10px;
  }
  .QAtable table td {
    padding: 18px 10px;
  }
  /* MyTicketClaim */
  .paddcla table th {
    padding: 15px 5px;
  }
  .paddcla table td {
    padding: 15px 5px;
  }
  /*MyTicketList  */
  .esc1 .nav-tabs .nav-link {
    margin-right: -15px;
  }
  .setting-tabs .es .nav-link.active {
    padding: 7px 7px;
    font-weight: 500;
    font-size: 13px;
  }
  .mtictab table th {
    padding: 16px 5px;
    font-size: 13px;
  }
  .myticketlist-header table td {
    padding: 15px 5px 15px 5px;
  }
  /* Setting>Store>Storemaster */
  .store-master table td {
    padding: 18px 5px;
  }
  .store-master table th {
    padding: 20px 5px;
  }
  /* Qa>QaAuditView */
  .removelinespace .mobiotw {
    margin-left: 32px !important;
  }
  /*Store/StoreTask */
  .store-task-tab-cont table th {
    padding: 15px 5px;
  }
  .store-task-tab-cont table td {
    padding: 15px 5px;
  }
  /* Setting/Brand */
  .brandtable table th {
    padding: 15px 10px;
  }
  .brandtable table td {
    padding: 15px 10px;
  }
  /* Setting/CategoryMaster */
  .category-master table td {
    padding: 18px 5px;
  }
  .category-master table th {
    padding: 18px 5px;
  }
  /* HierarchyMaster */
  .hiermas table th {
    padding: 20px 10px;
  }
  .hiermas table td {
    padding: 20px 10px;
  }
  /* Setting/Template */
  .template-table .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    padding: 20px 15px !important;
  }
  .template-table .ReactTable .rt-tbody .rt-td {
    padding: 18px 10px !important;
  }
  /*Store/Claim  */
  .store-task-tab-cont table th {
    padding: 15px 10px;
  }
  .store-task-tab-cont table td {
    padding: 15px 10px;
  }
  /* Setting/Store/CrmRole */
  .crm-padding-div {
    padding-right: 32px;
  }
  /*  */
  .ChatElementReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    padding: 20px 5px !important;
  }
  .ChatElementReact .ReactTable .rt-tbody .rt-td {
    padding: 10px 5px !important;
  }
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
  .setting-tabs .nav-tabs .nav-item {
    margin-right: 25px;
  }
  .Add-ticket-button {
    width: 130px;
  }
  .seacrh-img-addsearch {
    width: 8%;
  }
  .placeholder-dropdown-tktSys {
    top: 83px;
    left: 20px;
    width: 128px;
  }
  .CCdi1 {
    width: 95px;
  }
}
@media only screen and (max-width: 992px) {
  /* dashboard starts */
  body {
    font-size: 14px;
  }
  .dash-dropdowns select {
    background-size: 11px;
    padding-right: 20px;
    margin-right: 25px;
  }
  .card-value {
    font-size: 32px;
  }
  .card-head {
    margin-bottom: 10px;
  }
  .dash-top-cards {
    min-height: 125px;
  }
  .btm-mar > .row > div {
    margin-bottom: 25px;
  }
  big {
    font-size: 42px;
  }
  table th {
    padding: 20px;
  }
  table td {
    padding: 18px 20px;
  }
  .info-icon {
    width: 15px;
  }
  .claim-icon {
    width: 13px;
  }
  .task-icon-1 {
    width: 16px;
  }
  .dash-creation-popup li p {
    font-size: 14px;
  }
  .dash-creation-popup li {
    margin-top: 12px;
  }
  .dash-creation-popup li.title {
    padding-bottom: 0;
  }
  .dash-popup {
    right: -8px;
    top: 24px;
  }
  .dash-task-popup {
    bottom: 30px;
    font-size: 12px;
  }
  .dash-task-popup span {
    font-size: 12px;
  }
  .nw-chat {
    width: 16px;
    margin-left: 7px;
  }
  .task-progress {
    margin-top: 10px;
    height: 8px;
  }
  .pagi ul li {
    font-size: 14px;
    width: 32px;
    height: 32px;
    line-height: 30px;
  }
  .table-expandable-sctn button {
    font-size: 12px;
    padding-top: 7px;
    padding-bottom: 8px;
  }
  .save-view-search button.btn-inv {
    padding-right: 30px;
    padding-left: 30px;
  }
  .save-view-search button {
    padding-right: 20px;
    padding-left: 20px;
  }
  .table-expandable-sctn .nav-tabs .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .table-expandable-sctn .tab-content button img {
    top: -2px;
  }
  .sch-icon {
    width: 17px;
  }
  .csv-icon {
    width: 14px;
  }
  .assign-icon {
    width: 16px;
  }
  .table-expandable-sctn .tab-content .row {
    padding: 15px 0;
  }
  .table-expandable-sctn input[type="text"] {
    background-size: 16px;
  }
  .table-expandable-sctn input[type="text"]::-webkit-input-placeholder {
    font-size: 14px;
  }
  .table-expandable-sctn input[type="text"]:-ms-input-placeholder {
    font-size: 14px;
  }
  .table-expandable-sctn input[type="text"]::-ms-input-placeholder {
    font-size: 14px;
  }
  .table-expandable-sctn input[type="text"]::placeholder {
    font-size: 14px;
  }
  .setting-tabs ul {
    border: none;
    margin-bottom: 25px;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 991px) {
  .target-closure-date {
    font-size: 11px;
  }
  .Date-target {
    font-size: 12px;
  }
  .bata-rajouri-garden {
    line-height: 22px;
  }
  #slaEditModal {
    max-width: 50%;
    min-width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  /* dashboard starts */
  .save-view-search {
    position: static;
    -webkit-transform: none;
            transform: none;
    padding: 15px;
    padding-bottom: 0;
  }
  .save-view-search button {
    width: 48%;
    margin-right: 0;
  }
  .save-view-search {
    display: flex;
    justify-content: space-between;
  }
  /* dashboard ends */
}
@media only screen and (max-width: 768px) {
  .mobilenumber-resp .respo {
    margin-left: 0 !important;
  }
  .mobilenumber-resp .resol {
    margin-right: 0 !important;
  }
  .card-details-1 {
    height: auto;
  }

  #slaEditModal {
    max-width: 70%;
    min-width: 70%;
  }
  #categoryEditModal {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
    padding: 10px;
    margin: auto;
    display: inline-block;
    max-width: 70%;
    min-width: 70%;
  }
  .EditModa .modal-dialog {
    width: 70%;
  }
}
@media only screen and (max-width: 767px) {
  .storebox {
    padding: 0 15px;
  }
  .v3,
  .v4 {
    display: none;
  }
  /* dashboard starts */
  .ticketToTask-chart .apexcharts-canvas {
    width: 100% !important;
    overflow: auto;
  }

  .ticketToTask-chart .apexcharts-canvas svg {
    width: auto !important;
    overflow: auto;
  }
  #bill-graph-tab .col-md-3 {
    order: 1;
  }
  .bill-graph-list {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .bill-graph-list li {
    padding: 0px 15px;
  }
  .table-expandable-sctn .tab-content .row > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .table-expandable-sctn .tab-content .row {
    padding: 10px 0;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .table-expandable-sctn .tab-content .row.all-row div {
    margin-bottom: 0px;
  }
  .table-expandable-sctn .tab-content .row.all-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ticketToTask-chart-1 .apexcharts-canvas {
    width: 100% !important;
    overflow: auto;
  }

  .ticketToTask-chart-1 .apexcharts-canvas svg {
    width: auto !important;
    overflow: auto;
  }
  .sectiontenant select {
    margin-bottom: 10px;
  }
  #Notification-popup {
    left: 5px;
  }
  /* dashboard ends */

  .storeRole-name-text {
    width: 105px;
  }
  .module-switch {
    padding: 0;
  }
  .d-nonemob {
    display: none !important;
  }
  .mob-header {
    display: block !important;
    background-color: #2561a8;
    color: #fff;
    padding: 10px;
  }
  .message-icon-cnt {
    position: absolute;
    background-color: #eb4d4b;
    width: 13px !important;
    height: 13px !important;
    line-height: 11px !important;
    text-align: center;
    border-radius: 10%;
    font-size: 10px !important;
    color: #fff;
    font-weight: 700;
    top: -5px !important;
    right: -5px !important;
  }
  .verticaldots {
    margin: 0 0 0 8px !important;
  }
  .verticaldots img {
    height: 17px;
  }
  .header-right-icons {
    padding: 0;
    float: right;
  }
  .hamb-menu {
    display: inline-block;
    margin: 0 10px 0;
  }
}

@media only screen and (max-width: 580px) {
  /* dashboard starts */
  .rem-table {
    display: none;
  }
  .mobile-table {
    display: block;
  }
  .dash-creation-popup {
    right: -70px;
    width: 300px;
  }
  .dash-popup {
    padding: 20px 15px;
  }
  /* dashboard ends */
  .myticket-submit-solve-button {
    width: 200px;
    height: auto;
    margin-left: 5px;
  }
  .myticket-submit-solve-button-text {
    font-size: 12px;
    margin-left: 0;
  }
  #slaEditModal {
    max-width: 70%;
    min-width: 70%;
  }
  .rolle-name-text {
    width: 90px;
  }

  /* program code starts */
  .auth-wrapper .auth-content {
    width: 360px;
  }
  .sign-in {
    font-size: 24px;
  }
  .programcode-card-new img,
  .main-logo {
    width: 160px !important;
  }
  #totalconcount-popup {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
  .counttab span {
    font-size: 11px !important;
  }
  .cardassetspop {
    width: 90%;
    left: 15px !important;
  }
  .cardasscus .card-img img {
    height: auto;
    width: 90px;
    margin: auto;
    display: block;
    padding: 8px 10px;
  }
  .cardasscus .card-img {
    width: 100px;
    margin: 7px 0;
  }
  .cardasscus td:nth-child(3) {
    white-space: nowrap;
  }
  .cardasscus .statusbtnadd {
    padding: 5px 20px;
  }
  .cardsresbtn {
    white-space: nowrap;
  }
  .cardasscus .btngreen {
    padding: 6px 10px !important;
  }
  .cardasscus .btnred {
    padding: 6px 10px !important;
  }
  .addimg input {
    height: 53px;
  }
  .pickuppendingcustom .deliv-grid-butn {
    width: 110px;
    height: 25px;
    font-size: 11px;
  }
  #article-popup {
    width: 95%;
    border-radius: 5px;
    padding: 0;
  }
  #article-popup .cancalImg {
    display: none;
  }
  .progtrckr li:first-child:before {
    display: none;
  }
  .article-body span {
    font-size: 11px;
    margin: 0;
    padding: 2px;
    margin-bottom: 5px !important;
    white-space: normal;
  }
  #article-popup .footer-buttons #next-button {
    float: right;
    margin: 10px 10px 10px 0;
  }
  #article-popup .footer-buttons #prev-button {
    float: right;
    margin: 10px 10px 10px 0;
  }
  .cus-checkbox {
    display: none;
  }
  #article-popup .progtrckr {
    margin: 0;
  }
  #article-popup .progtrckr li {
    margin-right: 0;
    padding-left: 0;
    font-size: 12px;
  }
  #article-popup .progtrckr li:first-child {
    margin-right: 10px;
  }
  #article-popup .step-progress {
    padding: 0;
  }
  #article-popup .progtrckr {
    padding: 15px;
    margin: 0;
    background: #2561a8;
    border-radius: 5px 5px 0 0;
  }

  #article-popup .progtrckr-done span {
    color: #fff;
    font-weight: normal;
  }
  #article-popup .progtrckr-doing span {
    color: #fff;
    font-weight: normal;
    position: relative;
  }
  #article-popup .progtrckr-todo span {
    color: #7fa9db;
    font-weight: normal;
  }
  #article-popup .progtrckr-doing span::before {
    width: 35px;
    background: #3a91f8;
    height: 2px;
    left: 0;
    bottom: -7px;
    position: absolute;
    content: "";
  }
  #article-popup .tabs-content {
    padding: 0;
    margin: 0;
  }
  .airwaybox {
    border-radius: 5px;
    border: none;
    margin: 10px;
  }
  .airwaycontent .cardtick {
    height: 40px;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .airwaycontent h2 {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .airwaycontent p {
    font-size: 13px;
    color: #999;
    margin-bottom: 5px;
  }
  .airwaycontent ul li:first-child {
    margin-right: 0;
  }
  .airwaycontent ul li {
    font-size: 14px;
    color: #666;
    padding-top: 5px;
  }
  .innertabcollapse label b {
    color: #797979;
  }
  .deliv-grid-butn {
    width: auto;
    height: 22px;
  }
  .assign-grid-btn {
    margin-right: 5px;
  }
  .order-grid-butn {
    min-width: 80px;
  }
  .step-progress .nav-tabs .nav-item {
    background: #2561a8;
    color: #fff;
    width: 100%;
  }
  .nav-tabs .nav-link.active {
    color: #fff;
    background: #2561a8;
    font-weight: normal;
  }
  .step-progress .nav-tabs .nav-link {
    font-size: 12px;
  }
  .step-progress .nav-line::before {
    display: none;
  }
  .step-progress .nav-line {
    font-weight: normal;
    color: #fff !important;
  }
  .airwaycontent ul {
    border-top: 1px solid #ddd;
    display: inline-block;
    margin: 0 30px 0;
  }
  .dv-status {
    margin-top: 15px;
    text-align: right;
    padding: 0 10px;
  }
  .cus-strecth {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  /* program code ends */

  .order-popover .antd-table-order .ant-table-tbody > tr > td {
    padding: 15px 5px !important;
    width: 70px !important;
  }
  .order-popover
    .custom-antd-table.antd-table-campaign.antd-table-order
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
    width: 70px;
  }
  .order-popover .ant-table-thead > tr > th:nth-child(2),
  .order-popover-table .ant-table-tbody > tr > td:nth-child(2) {
    width: 75px !important;
  }
}

@media only screen and (max-width: 480px) {
  .hamb-menu li {
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }
  .hamb-menu .dropdown-menu.show:before {
    display: none;
  }
  .hamb-menu ul {
    padding: 0;
    font-size: 12px;
  }
  /* .active a {
    color: #fff !important;
  } */
  .header-right-icons a:last-child {
    margin-right: 0px;
  }

  .vl {
    border: 0;
    height: auto;
    margin: 15px 0 20px;
    border-bottom: 2px solid #f1f1f1;
    width: 100%;
  }
  .v2 {
    border: 0;
    height: auto;
    margin: 5px 0 15px -15px;
    border-bottom: 2px solid #f1f1f1;
    width: 110%;
  }
  .data-store .col-md-12 {
    padding: 0;
  }
  .rectangle-box {
    height: auto;
  }
  .dropdrown {
    margin-left: 0;
  }
  .mob-pad {
    padding: 0 !important;
  }
  .drop-card {
    padding: 0 !important;
    margin-top: 0;
  }
  .label-4 {
    width: 100px;
  }
  .data-store {
    margin: 0;
  }
  .storeSpacing {
    padding-left: 0;
  }
  .data-store .label-4 {
    width: 45px;
  }
  .rectangle-box .select-category-placeholder {
    width: 100%;
  }
  .notes-00,
  .task-03,
  .claim-00 {
    margin-left: 0;
  }
  .arrow-img,
  .plush-img,
  .line-1 {
    margin-left: 0;
    margin-top: 0;
  }
  .mail-mask {
    width: 100%;
    background-color: transparent;
  }
  .mob-float {
    float: right;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .line-1 {
    display: inline-block;
    margin: 0 15px;
  }
  .label-2 {
    margin-top: 0px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .label-3 {
    margin-top: 2px;
    padding-bottom: 20px;
  }
  .v3,
  .v4 {
    width: 100%;
    margin-left: 0;
  }
  .v4 {
    margin-top: 0;
  }
  .today-02 {
    text-align: center;
    display: block;
    padding: 0;
    margin: 10px 0;
  }
  .rectangle-3 .message-header {
    display: none;
  }
  .i-have-solved-this-i {
    margin-left: 0;
  }
  .mob-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }
  .card-details {
    width: auto;
    height: auto;
    margin: 0;
  }
  .card-details-1 {
    width: auto;
    height: auto;
    margin: 20px;
    padding: 20px 30px;
  }
  .v6 {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
  .yesterday-02 {
    display: block;
    padding-left: 0;
    text-align: center;
    margin: 10px 0;
  }
  .hi-naman-please-hel {
    padding-left: 0;
  }
  .comment {
    margin-left: 0 !important;
    padding-left: 0;
    order: 1;
  }
  .up-1 {
    margin: 0;
    order: 2;
  }
  .card-op-out .card-details {
    margin-top: 15px;
  }
  .comment-text {
    padding-left: 0;
  }
  .hi-diwakar-i-really1 {
    width: 80%;
    display: inline-block;
    padding-left: 10px;
  }
  .comment-text1 {
    padding-left: 15px;
    margin-top: 5px;
  }
  .clip {
    margin-left: 30px;
    vertical-align: top;
    width: 17px;
    height: 17px;
  }
  .reply-comment {
    padding-left: 13px;
    text-align: left;
  }
  .need-to-change-my-sh {
    margin-left: 37px;
  }
  .head-header {
    height: auto;
  }
  .oval-head {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    width: 100%;
  }
  .myticket-submit-solve-button {
    margin-left: 9px;
    width: auto;
    float: right;
    padding: 5px 10px;
  }
  .myticket-submit-solve-button-text {
    margin-left: 0;
  }
  .loading-rectangle {
    margin-top: -183px;
  }
  .head-header-1 {
    padding-bottom: 0;
  }
  .nav-tab3,
  .nav-tab2,
  .nav-tab1,
  .claim-space,
  .main-nav {
    display: none;
  }
  .er {
    padding-left: 0;
    text-align: center;
    width: 60px;
    height: 55px;
    display: inline-block;
  }
  .er-label {
    font-size: 16px;
    margin-top: 0;
  }

  /* dashboard starts */
  .dash-dropdowns {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dash-dropdowns > div {
    padding: 5px 0;
  }
  .btm-mar > .row > div {
    margin-bottom: 20px;
  }
  .task-claim-cont {
    padding-right: 0;
    padding-left: 0;
  }
  .mob-mar-btm button {
    margin-bottom: 15px;
  }
  .mob-mar-btm button:last-child {
    margin-bottom: 0px;
  }

  .grapwid {
    max-width: 100% !important;
    overflow: auto;
  }
  .dash-top-cards1 {
    padding: 12px 20px !important;
  }
  .mid-sec {
    padding: 15px;
  }
  .arrow-img {
    margin: 5px 30px 0;
  }
  #Notification-popup .rowpadding {
    padding-left: 5px;
  }
  #Notification-popup .viewticketspeadding {
    padding-left: 5px;
  }
  #slaEditModal {
    max-width: 90%;
    min-width: 90%;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 400px) {
  /* dashboard starts */
  .pagi {
    padding-right: 15px;
    padding-left: 15px;
  }
  .pagi ul li {
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 28px;
  }
  .save-view-search {
    display: block;
  }
  .save-view-search button {
    width: 100%;
    margin-bottom: 15px;
  }
  .save-view-search button:last-child {
    margin-bottom: 0px;
  }
  .btnSearchQa {
    width: 100%;
    margin-top: 15px;
  }
  #categoryEditModal {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
    padding: 10px;
    margin: auto;
    display: inline-block;
    max-width: 100%;
    min-width: 100%;
  }
  .EditModa .modal-dialog {
    width: 100%;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 360px) {
  /* dashboard starts */
  .dash-tp-card .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .obpml {
    margin-left: 0;
  }
  .mlistbtn {
    float: none;
    text-align: center;
  }
  .setting-tabs ul {
    border: none;
    display: flex !important;
    margin-bottom: 25px;
  }
  #slaEditModal {
    max-width: 98%;
    min-width: 98%;
  }
  #logout-ovrly {
    padding: 70px 0px 0 0;
  }
  /* dashboard ends */

  /* program code starts */
  .auth-wrapper .auth-content {
    width: 100%;
  }
  /* program code ends */
}
#customer-tab {
  position: relative;
}
.alertckuser {
  /* top: 132px !important; */
  top: 82px !important;
}
.alertckuserinter {
  right: 75px;
  top: 37% !important;
}
.placeholder-alert-2 {
  /* top: 37% !important; */
  top: 32% !important;
}

/* Responsive ends */

#DateTimeRangeContainerNoMobileMode .ant-calendar-range-picker-input {
  width: 41%;
  /* width: 40%; */
  height: 99%;
  text-align: left;
  float: left;
  background-color: transparent;
  border: 0;
  outline: 0;
}
#DateTimeRangeContainerNoMobileMode .ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: left;
  float: left;
  vertical-align: top;
  pointer-events: none;
}
#DateTimeRangeContainerNoMobileMode .ant-calendar-picker-clear {
  top: 45%;
}
.seacrh-img-addsearch {
  width: 4%;
  display: inline-block;
  height: 43px;
  padding: 13px 0 0 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background: #ecf2f4 !important;
}
.srch-imge {
  margin-top: -5px;
  cursor: pointer;
}
.csv-button img {
  margin-right: 5px;
}
.myticlist-expand-sect img {
  margin-right: 5px;
}
.dis-btn {
  margin: 0;
}
.DashTimeRange {
  padding: 0 15px 0 0;
}

#BlockEmailModel {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  width: 670px;
  border-radius: 10px;
}

.btn-block {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.block-cancelImg {
  height: 20px;
  float: right;
  margin-top: -11px;
  margin-right: -13px;
}

.myticket-knowpopup {
  margin-right: auto !important;
  min-width: 30%;
}

.knowticketID {
  color: #2561a8 !important;
  cursor: pointer;
}
.iskbticket {
  pointer-events: none;
}
.storeTdetail .ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.autopriority {
  display: none;
}
.plusline1new {
  border-right: 1px solid #9b9b9b;
  padding-top: 12px;
  padding-bottom: 14px;
}

.campaign-end-date .ant-calendar-picker-input {
  height: 37px;
  background: #ecf2f4 url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
  border-color: transparent;
}
.campaign-end-date
  .ant-calendar-picker:hover
  .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: transparent;
}
.campaign-end-date .ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 0;
  pointer-events: none;
}
.campaign-end-date .ant-calendar-picker-icon {
  display: none;
}
.campaign-end-date .ant-calendar-range-picker-input {
  font-weight: 700;
  color: #333;
}
.campaign-end-date .ant-calendar-range-picker-separator {
  color: #333;
  font-weight: 700;
  vertical-align: middle;
}
.tasksearchdiv {
  text-align: right;
  width: 100%;
}
.rejectmodal-popup {
  margin-right: auto !important;
  min-width: 40%;
}
.cusinput {
  position: absolute;
  width: 135px;
  right: -135px;
  top: 50px;
}
.ord-m-t20 {
  margin-top: 20px !important;
}
.ordPayment1 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 148px;
}
.ordcusinput {
  position: absolute;
  width: 135px;
  right: -135px;
  top: 325px;
}
.ordcusinput1 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 52px;
}
.ordcusinput2 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 100px;
}
.ordcusinputStore2 {
  position: absolute;
  width: 250px;
  right: -515px;
  top: 100px;
}
.ordcusinput3 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 147px;
}
.ordcusinput4 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 194px;
}
.ordcusinput5 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 241px;
}
.ordcusinput6 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 287px;
}
.ordcusinput7 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 333px;
}
.ordcusinput8 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 379px;
}
.chatabcus table td {
  color: #333;
}
.chatabcus .custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.chatabcus table tbody tr {
  border-bottom: none;
}
.chatabcus .ant-table-tbody > tr > td {
  border-bottom: none;
}
.chattxtdivcus {
  display: flex;
  width: 100%;
}
.languagebox {
  margin-bottom: 15px;
}
.languagebox .langbtn {
  background-color: #eee;
  border-color: #ccc;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  height: 42px;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}
.languagebox .langbtn.active {
  background-color: #2561a8;
  color: #fff;
}
.skip {
  margin-top: 10px;
  text-transform: uppercase;
  color: rgb(36, 110, 195);
  cursor: pointer;
}
.ant-drawer-content-wrapper {
  width: 230px !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.mobsidebar {
  height: 100vh;
}
.uppersec {
  background-color: #2561a8;
  padding: 50px 20px;
}
.uppersec ul {
  margin-bottom: 0;
}
.uppersec ul li:first-child {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
}
.uppersec ul li:last-child {
  vertical-align: -webkit-baseline-middle;
}
.uppersec ul li img {
  height: 10px;
}
.uppersec h3 {
  font-size: 14px;
  color: #fff;
}
.uppersec p {
  font-size: 12px;
  color: #fff;
}
.lowersec ul {
  padding: 20px;
  margin-bottom: 0;
}
.lowersec ul li {
  display: block;
  margin-bottom: 25px;
}
.lowersec img {
  height: 15px;
  margin-right: 10px;
  max-width: 20px;
}
.lowersec span {
  width: 30px;
  display: inline-block;
}
.logoutbox {
  padding: 20px;
  border-top: 1px solid #ccc;
}
.logoutbox ul {
  margin-bottom: 0;
}
.logoutbox img {
  height: 15px;
  margin-right: 10px;
  max-width: 20px;
}
.headers-menu a[disabled] {
  color: #4a4a4a !important;
}
.lowersec a[disabled] {
  color: #4a4a4a !important;
}

.myticlist-expand-sect .tab-content .row.all-row > div {
  margin-bottom: 25px;
}

* {
  box-sizing: border-box;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1 > span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search > input {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search > input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search > button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search > button > span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}

.notfound .notfound-search > button > span:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  border: 4px solid #c5c5c5;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound-search > button > span:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: 7px;
  top: 17px;
  border-radius: 2px;
  background: #c5c5c5;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search > button:hover > span:after {
  border-color: #00b7ff;
}

.notfound .notfound-search > button:hover > span:before {
  background-color: #00b7ff;
}

@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}
.tktTaskDrw .ant-drawer-body {
  padding: 24px !important;
}
/* ------------------- for chat-dashborad start ----------------------- */

.Chat-main-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.lable-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
}

.text-center-header {
  padding: 15px;
}
.text-center-margin {
  margin-left: 5px;
}

.sort-down-arrow {
  opacity: 0.2647758152173913;
  width: 20px;
  height: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Chat-main-header .row {
  margin: 0px;
}
.team-mamber-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 294px; */
  height: 191px;
  margin-top: 15px;
}
.response-speed-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 610px; */
  height: 194px;
  margin-top: 15px;
}
.chat-status-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 289px; */
  height: 194px;
  margin-top: 15px;
}

.chat-score-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 605px; */
  height: 243px;
}

.chat-live-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 608px; */
  height: 243px;
}

.total-chat-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 929px; */
  height: 243px;
}

.missed-chat-card {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: 289px; */
  height: 243px;
}

.card-titel {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

.aside-cont-new-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 63px);
}
.aside-cont-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 35px);
}

.card-content-number {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  width: 50px;
  text-align: left;
  /* padding-left: 18px; */
}
.card-content-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  width: 78px;
  text-align: left;
  margin-top: 8px;
}

.response-speed-dropdown {
  background-color: #ecf2f4 !important;
  border-radius: 4px;
  width: 140px;
  height: 35px;
  border: none;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 8px center;
  background-size: 15px;
  cursor: pointer;
}

.select-text {
  color: #6e6e6e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 10px;
}

.response-card-content-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  width: auto;
  text-align: left;
  margin-top: 8px;
}

.aside-cont-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 108px);
}

.missed-chat-30 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  width: 117px;
  text-align: left;
}
.missed-chat-small-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  padding: 10px 9px 0;
}

.card-content-number .response-small {
  display: inline;
  font-weight: 200;
  font-size: 18px;
}

.response-row {
  margin: 35px 20px 0px 35px;
}

.team-member-center-text {
  text-align: center;
}
.team-member-30 {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  /* padding-left: 125px; */
  /* padding-top: 28px; */
}
.teamchat-p {
  font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}
.team-member-p {
  display: flex;
  padding: 10px 28px;
}

.float-search-chat {
  border: 1px solid #f0f0f0;
  width: 220px;
  height: 43px;
  top: 97px;
  z-index: 1;
  border-radius: 21.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #fff;
  padding: 4px 17px 6px;
  cursor: pointer;
}

.back-to-main-dashboa {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  padding-top: 10px;
  padding-top: 15px;
  padding-left: 5px;
}
.removeleft-1 .row {
  margin-right: -10px;
  /* margin-right: -12px; */
}

.addmargin .row {
  margin-left: 10px;
}
/* dashboad css start by shlok */
.apexcharts-legend.position-bottom.center,
.apexcharts-legend.position-top.center {
  /* justify-content: center; */
  margin-bottom: 170px !important;
  margin-left: 304px !important;
}
.ticketToTask-chart-1 .apexcharts-legend.position-bottom.center {
  margin-bottom: 150px !important;
  margin-left: 200px !important;
}
.chat-lbl1 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl2 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl3 {
  text-align: left;
  text-align: initial;
  padding-left: 15px;
}
.chat-lbl4 {
  text-align: left;
  padding-left: 18px;
}
.chat-lbl5 {
  text-align: left;
  padding-left: 35px;
}
.chat-lbl6 {
  padding-left: 25px;
  text-align: left;
}
.chat-lbl7 {
  text-align: left;
  padding-left: 20px;
}
.dash-linechart #chart {
  margin-top: -5px !important;
}
/* dashboad css end */
/* ChatBot Create modal css start by shlok */
.mdlcancleImg {
  margin-top: -24px;
  float: right;
}
.mainDiv-crt {
  padding: 0px 15px 0px 15px;
}
.lbl-customerMdl {
  font-size: 15px;
  font-weight: 700;
}
.lbl-sourceChat {
  float: right;
  font-weight: 600;
}
.lbl-mdlHeader {
  margin-top: 10px;
  margin-bottom: 5px;
}
.mdl-lbl {
  font-weight: 500;
  color: #9b9b9b;
  margin-bottom: 4px;
}
.txt-border {
  border: 1px solid;
  color: #d6d4d4;
}
.chat-txt1 {
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  height: 38px;
  padding: 10px;
}
.chat-mdlMargin {
  margin-bottom: 15px;
}
#CreateCustomer-mdl {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 215px;
  display: inline-block;
  min-width: 870px !important;
  margin-top: -62px;
}
.drop-downlist-mdl {
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  height: 37px;
  padding: 10px;
  padding-left: 15px;
}
.dropdown-chat {
  color: #635e5e;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #f9f9f9 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==) no-repeat right 12px center;
  cursor: pointer;
}
.text-areaChatModel {
  background-color: #f9f9f9;
  padding: 12px;
  height: auto;
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #bdbdbd;
  resize: none;
}
.chatbutn-2 {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}
.chatAnchor {
  color: #2561a8;
  margin-top: 10px;
  margin-right: 15px;
}
.chatbtnDiv {
  float: right;
  margin-top: 15px;
}
.curshar-pointer {
  cursor: pointer;
}
#createTicketModal {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  display: inline-block;
  min-width: 610px !important;
  margin-top: 120px;
}
.openTciketLbl {
  font-size: 15px;
  font-weight: 600;
}
.flot-calcel {
  float: right;
}
.modal-lbl1 {
  font-size: 13px;
  font-weight: 400;
}
.span-lbl2 {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}
.modal-lbl2 {
  font-size: 13px;
  font-weight: 700;
}
.opn-ticketDiv {
  margin-top: 6px;
}
.no-mdl {
  font-weight: 700;
  color: #2561a8;
}
.padding-div {
  padding: 10px;
}
/* ChatBot Create modal css end */
/* dashboard Chatbot drawer css start kalpesh*/
.chatdrawer .ant-drawer-mask {
  background: none;
}
.chatdrawer .ant-drawer-content-wrapper {
  margin-top: 58px;
}
.chatdrawer .ant-drawer-wrapper-body {
  width: 325px;
}
.chatdrawer .chatleftdrawer {
  /* max-height: 200px;  */
  overflow: auto;
}
.ongoing {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}
.cancelbtn-chat {
  width: 17px;
  height: 16px;
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  cursor: pointer;
}
.chatpadding {
  padding: 0 10px;
  border-bottom: 1px solid #e8e8e8;
}
.chatdrawer .ant-drawer-body {
  background-color: #fffef7;
}
/* dashboard Chatbot drawer css end kalpesh */
/* Chatbot recent chat and drawer css start kalpesh*/
.table-bchat {
  background-color: #ebebeb;
  border-radius: 4px;
  width: 28px;
  height: 22px;
}
.chatreact .ReactTable {
  margin: 10px;
  border: none;
}
.chatreact .chatnamereact {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  display: inline;
  display: initial;
  margin-left: 5px;
}
.chatreact .ReactTable .rt-tbody {
  background-color: #fff;
  border-radius: 8px !important;
}
.chatreact .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.actionleft {
  float: right;
  margin-top: -51px;
  margin-right: 20px;
}
.actionright {
  float: right;
  margin-top: -62px;
  margin-right: 20px;
}
.searchImg-raisechat {
  width: 20px;
  height: 20px;
  margin-left: -31px;
  margin-right: 8px;
}
.searchtextchat {
  border: none;
  background-color: #ffffff;
  border-radius: 4px;
  width: 260px;
  height: 43px;
  float: right;
  padding: 10px;
  margin-right: 4px;
}
.chatreact .chatthumb {
  width: 20px;
  height: 20px;
}
.drawerchat .ant-drawer-content-wrapper {
  height: 202px !important;
  margin-left: 347px;
  width: 72.5% !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
}
.drawerchat .ant-drawer-mask {
  background: none;
}
.drawerchat .crossdrawer {
  font-size: 28px;
  float: right;
  width: 25px;
  height: 25px;
  font-weight: 700;
  margin-top: -11px;
}
.drawerchat .chaton {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.drawerchat .mohitdrawer {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}
.drawerchat .num {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 15px;
}
.twothous {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}
.twotho {
  display: grid;
  padding-left: 25px;
}
.twothoustext {
  color: #2561a8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}
/* Chatbot recent chat and drawer css end kalpesh*/
/* Chat Elements Css Start Kalpesh */
.chat-elementicon {
  border-radius: 100%;
  background-color: #fa6400;
  width: 30px;
  height: 30px;
  padding: 3px 7px;
}
.chatelementiconimg {
  width: 15px;
  height: 15px;
}
.ChatElementReact .ReactTable {
  border: none;
}
.ChatElementReact .ReactTable .rt-tbody .rt-td {
  color: #4a4a4a !important;
  padding: 10px 20px !important;
}
.chatelementbrowse {
  height: 25px;
  padding: 0;
  float: right;
  border: none;
  background-color: #9b9b9b;
  color: #fff;
}
.custom-file-label {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
}
.custom-file-label::after {
  position: absolute;
  top: 9px;
  right: 20px;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 25px;
  line-height: 12px;
  color: #495057;
  content: "Browse";
  background-color: #d3d9db;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
/* Chat Elements Css End */

/* Chat Theme page Css start By Shlok */
.chat-file-upl {
  font-weight: 400;
  color: #afafaf;
}
/* Chat Theme page Css end */
/* ChatConfigureBrand Css Start Kalpesh */
.setting-chat-config {
  padding: 20px 20px;
  /* background-color: #ecf2f4; */
  }
  .chat-config-center {
  margin: 0 auto;
  }
  .ChatThemeflex {
  display: flex;
  }
  .ChatConfigtheme {
  width: 70px;
  height: 70px;
  margin-right: 40px;
  }
  .ChatConfigthemelast {
  width: 70px;
  height: 70px;
  margin-right: 0px;
  }
  .ChatBataLogo {
  width: 100px;
  height: 50px;
  }
  .bataimgborder {
  border:1px solid #D9DADC;
  text-align: center;
  padding: 10px 0px;
  }
  .chatconfigpadding {
  padding: 10px 90px;
  }
  .chatconfigbtn {
  margin-top: 10px;
  }
  #pluspopup {
  width: 400px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  }
  .chat-config-center .table-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 480px;
}
  /* ChatConfigureBrand Css end Kalpesh */
  /* Chat Historical chat page css start by Shlok */
  .chat-historicalHdr{
    background-color: #ECF2F4;
    width: 100%;
    height: 57px;
  }
  .chatSrchHder{
    float: right;
    margin-top: 7px;
    margin-right: 15px;
  }
  .mychatHistoriLbl{
    color: #4A4A4A;
    font-family: Lato;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-top: 18px;
    margin-left: 25px;
  }
  .HistoChat .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0,0,0,0.05);
    border: none;
}
  /* Chat Historical chat page css End by Shlok */
  .chatconfigpadd {
    padding: 10px 60px;
  }
  .chatconfigpadd .txt-1 {
    background-color: #ecf2f4;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    border: none;
    padding: 9px;
  }
  .chatconfigpadding .txt-1 {
    background-color: #ecf2f4;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    border: none;
    padding: 9px;
}
/* -------------------------------------------------------------------- */

/* Chetan CSS starts */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
html {
  background-color: #fff;
}
#chat-popup-overlay {
  padding: 0px;
  /* background-color: rgba(77, 92, 116, 0.6); */
  background-color: #fff;
  top: 54px;
  overflow-y: hidden;
}
#chat-popup-overlay .styles_modal__gNwvD {
  /* max-width: 100%; */
  max-width: 100%;
  width: 100%;
  padding: 0;
  box-shadow: none;
  height: calc(100vh - 0px);
  /* overflow: hidden; */
  /* height: 100%; */
}
.store-chat-header {
  background-color: #2561a8;
  padding: 9px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.store-chat-header h3 {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
.rounded-cross {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 1;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}
.chats-heading {
  /* margin: 0 18px 7px; */
  margin-bottom: 7px;
  font-size: 14px;
  padding: 0 15px;
}
.name-num p,
.mess-time p {
  line-height: 1.5;
}
.name-num {
  overflow: hidden;
}
.mess-time p {
  font-weight: 400;
}
.mess-time {
  text-align: right;
  white-space: nowrap;
}
.initial {
  margin-top: 0;
  flex: 0 0 28px;
  color: #000;
}
.chat-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chat-info {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.chat-info:hover {
  /* background-color: #ecf2f4; */
  background-color: #cde4ff;
}
.mobile-chat-tabs {
  display: none;
  width: 100%;
  text-align: center;
}
/* .chatbot-left {
  height: calc(100vh - 94px);
} */

/* message suggestions starts */
.suggestions-cntr {
  margin: 8px -7px;
}
.suggestions-cntr span {
  background: #fff;
  opacity: 0.7;
  padding: 5px 15px;
  display: inline-block;
  margin: 4px 7px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 1px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.suggestions-cntr div {
  position: relative;
  display: inline-block;
}
.suggestions-tick span {
  opacity: 1;
}
.suggestions-tick span:after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid #47b04b;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 0.5px;
  right: 6.5px;
}
.suggestions-tick span:before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  border-radius: 50%;
  background: #c7e8c8;
  top: -3px;
  right: 0px;
}
/* message suggestions ends */

/* mobile chats tab starts */
.chat-tabs-mobile {
  display: none;
}
.mobile-arrow {
  display: none;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.chats-count {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.chats-count span {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 7px;
  font-weight: 700;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: -0.5px;
  margin-top: -0.5px;
  color: #fff;
}
.mobile-chat-tabs .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 13px;
  color: rgba(37, 97, 168, 0.4);
  padding: 5px 10px;
  border-radius: 20px;
  line-height: 1;
  min-height: 25px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.mobile-chat-tabs .nav-tabs {
  justify-content: center;
  padding: 10px 0;
  margin: 0 15px;
  border-bottom: 1px solid rgb(37, 97, 168, 0.2);
}
.mobile-chat-tabs .nav-tabs .nav-link.active {
  background: #2561a8;
  border: 0;
  color: #fff;
  padding-right: 20px;
  padding-left: 20px;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count span {
  color: #2561a8;
}
.chat-bubble-white {
  display: none;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count .chat-bubble-white {
  display: block;
}
.chat-bubble-blue {
  display: block;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count .chat-bubble-blue {
  display: none;
}
.mobile-chat-tabs .tab-content {
  padding: 7px 0px 7px 15px;
}
.mobile-chat-header {
  font-weight: 700;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.face-name {
  font-weight: 700;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
.chat-face-cntr {
  width: 50px;
  margin: auto;
  position: relative;
}
.chat-face-inner-cntr {
  border-radius: 6px;
  overflow: hidden;
}
.chat-face-cntr img {
  width: 100%;
}
.chat-detail-outer-cntr {
  display: flex;
  padding-top: 10px;
  overflow: auto;
  min-height: 81px;
  margin-left: -4px;
}
.chat-detail-outer-cntr .chat-detail-middle-cntr:last-child .chat-detail-cntr {
  margin-right: 15px;
}
.chat-detail-outer-cntr .chat-detail-middle-cntr:first-child {
  padding-left: 4px;
}
.chat-initial {
  background: #ddf6fc;
  display: block;
  height: 50px;
  line-height: 50px;
  color: #11b4db;
  text-transform: uppercase;
}
.chat-notification-count {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 9px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: 700;
  margin-top: 1px;
  margin-left: -0.5px;
}
.chat-detail-cntr {
  margin-right: 25px;
  position: relative;
}
.chat-detail-cntr.active .face-name {
  color: #2561a8;
}
.chat-notification-cntr {
  width: 17px;
  position: absolute;
  top: -12px;
  left: -4px;
}
/* .chat-detail-cntr.active::after, */
.online {
  /* content: ""; */
  position: absolute;
  width: 9px;
  height: 9px;
  top: 6px;
  right: -4px;
  border-radius: 50%;
  background: #45ee8e;
  border: 1px solid #fff;
}
.chatcontentdivtab .cke_inner {
  display: flex;
  flex-direction: column-reverse;
}
.mobile-ck-send {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 25px;
  right: 15px;
  top: 15px;
  /* bottom: 55px; */
  cursor: pointer;
  border: 0.5px solid #215faa;
  border-radius: 50%;
  text-align: center;
}

.mobile-ck-send-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 25px;
  right: 15px;
  top: 56px;
  /* bottom: 55px; */
  cursor: pointer;
  border: 0.5px solid #215faa;
  border-radius: 50%;
  text-align: center;
}

.mobile-ck-send img {
  width: 13px;
}
#mobile-tabs-overlay {
  background: rgba(0, 0, 0, 0.65);
  padding: 20px;
}
#mobile-tabs-popup {
  background: #ecf2f4;
  padding: 15px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}
.mobile-chat-popup .search-customerAddSrch {
  font-size: 13px;
  /* padding-left: 15px !important; */
  padding-right: 2px !important;
  margin: 0;
  width: calc(100% - 30px) !important;
  border: 1px solid #d8dfe2 !important;
  border-right: 0 !important;
}
/* .mobile-chat-popup */
.search-customerAddSrch::-webkit-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.search-customerAddSrch:-ms-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.search-customerAddSrch::-ms-input-placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.search-customerAddSrch::placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.search-customerAddSrch-plcholder::-webkit-input-placeholder {
  font-size: 16px;
  color: #4a4a4a;
}
.search-customerAddSrch-plcholder:-ms-input-placeholder {
  font-size: 16px;
  color: #4a4a4a;
}
.search-customerAddSrch-plcholder::-ms-input-placeholder {
  font-size: 16px;
  color: #4a4a4a;
}
.search-customerAddSrch-plcholder::placeholder {
  font-size: 16px;
  color: #4a4a4a;
}
.search-customerAddSrch-plcholder {
  font-size: 16px;
  padding-left: 22px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-customerAddSrch-plcholder + .seacrh-img-addsearch {
  width: 7%;
}
.search-customerAddSrch-plcholder + .seacrh-img-addsearch .srch-imge {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}
.mobile-chat-popup .input-group-addon {
  width: 30px;
  border: 1px solid #d8dfe2 !important;
  border-left: 0 !important;
}
.mobile-card-cntr {
  display: flex;
  align-items: flex-end;
}
.searchtxt-new {
  /* margin-top: 15px; */
  margin-bottom: 10px;
}
.no-record {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  font-size: 12px;
}
/* mobile chats tab ends */

/* chat trail starts */
.chat-trail-img {
  flex: 0 0 50px;
  border-radius: 5px;
  overflow: hidden;
}
.chat-trail-img img {
  width: 100%;
}
.chat-trail-chat {
  padding: 10px 20px;
  border-radius: 5px;
  border-top-left-radius: 0;
  background: #58a4ff;
  color: #fff;
  word-break: break-all;
}
.chat-trail-chat .card-body {
  margin: 0 -10px;
}
.chatcontentDiv::-webkit-scrollbar {
  width: 6px;
}
.chatcontentDiv::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chatcontentDiv::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chatcontentDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat-trail-cntr {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 10px;
  max-width: 80%;
  margin-left: 10px;
  margin-top: 10px;
}
.chatcontentDiv .chat-trail-cntr:last-child {
  margin-bottom: 0px;
}
.chat-trail-cntr-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.bot-mark {
  background-color: #f7b500;
  font-weight: 700;
  font-size: 10px;
  position: absolute;
  top: 9px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  left: -20px;
}
.chat-trail-chat-cntr {
  margin-top: 15px;
  margin-left: 10px;
  white-space: pre-line;
  position: relative;
}
.chat-trail-cntr-right .chat-trail-chat-cntr {
  margin-right: 10px;
  margin-left: 0;
}
.chat-trail-cntr-right .chat-trail-chat {
  border-top-right-radius: 0;
  border-top-left-radius: 5px;
  background: #fff;
  color: #4a4a4a;
}
.chat-trail-time {
  font-size: 12px;
  display: block;
  margin-top: 8px;
}
.chat-trail-cntr-right .chat-trail-time {
  text-align: right;
}
#chat-popup .butn,
#chat-popup .butn-inv {
  font-weight: 700;
  border-radius: 8px;
  font-size: 14px;
  height: 40px;
  padding: 6px 10px;
  padding-left: 15px;
  min-width: 95px;
}
#chat-popup .butn {
  color: rgba(255, 255, 255, 0.85);
}
#chat-popup .butn-inv {
  background: transparent;
}
.send-up {
  width: 24px;
  -webkit-filter: invert(1);
          filter: invert(1);
  /* position: relative;
  top: -1px; */
  margin-left: 4px;
  float: right;
}
.chat-btn-cntr {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 10px;
}
/* chat trail ends */

/* ck editor starts */
/* .cke_toolbox {
  display: flex;
  overflow: auto;
}
.cke_toolgroup {
  display: flex;
}
.cke_top {
  background: #fff !important;
  border-top: 1px solid #f0f0f0 !important;
}
.cke_bottom {
  display: none !important;
} */
/* ck editor ends */

/* mobile search starts */
.mobile-search-img {
  width: 13px;
}
.mobile-search-img img {
  width: 100%;
}
.mobile-search-cntr {
  position: absolute;
  top: 10px;
  padding: 0 15px;
  display: flex;
  width: 100%;
}
.mobile-search-input input {
  width: 100%;
  border: 0;
  padding: 3px 10px;
  font-size: 13px;
  color: #3c3c3c;
}
.mobile-search-input input::-webkit-input-placeholder {
  color: rgba(60, 60, 60, 0.4);
  padding: 0;
  font-weight: 400;
}
.mobile-search-input input:-ms-input-placeholder {
  color: rgba(60, 60, 60, 0.4);
  padding: 0;
  font-weight: 400;
}
.mobile-search-input input::-ms-input-placeholder {
  color: rgba(60, 60, 60, 0.4);
  padding: 0;
  font-weight: 400;
}
.mobile-search-input input::placeholder {
  color: rgba(60, 60, 60, 0.4);
  padding: 0;
  font-weight: 400;
}
/* mobile search ends */

/* recommended list starts */
.recommended-cntr {
  /* margin-left: 20px; */
  /* margin-top: 15px; */
  margin-top: 6px;
}
.recommended-cntr .send-up {
  margin-left: 25px;
}
/* recommended list ends */

/* recommended list mobile starts */
.recommended-list-popup > div {
  height: 100%;
}
.recommended-list-popup {
  padding: 15px !important;
  height: 100%;
}
.recommended-cntr .chat-btn-cntr {
  margin: 0;
  padding: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recommended-cntr .chat-btn-cntr button {
  margin: 10px 0;
}
/* recommended list mobile ends */

/* payment link starts */
.payment-link-butn {
  float: right;
  /* margin-right: 18px; */
  margin-top: 30px;
}
.payment-details {
  /* margin-left: 17px; */
  /* padding-top: 5px; */
}
.payment-details span {
  font-size: 18px;
  font-weight: 700;
}
.payment-details label {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  display: block;
  margin-bottom: -2px;
}
/* payment link ends */

/* Chetan CSS ends */

/* Deepak CSS starts */
.chattitlelbl {
  padding: 15px;
  font-size: 15px;
  /* font-size: 20px; */
  font-weight: bold;
  color: #2561a8;
}
.chatdivtitle {
  height: 80px;
  background-color: #ecf2f4;
  width: 100%;
  height: 20%;
}
.chatdivtitle .nav-tabs .nav-link.active {
  border: 1px solid #2561a8;
  background: #2561a8;
  color: #fff;
}
.chatdivtitle .nav-tabs .nav-link {
  color: #2561a8;
  border: 1px solid #2561a8;
  padding: 7px 18px;
  border-radius: 10px;
}
.chatactionbtn {
  background: #fff;
}
.chatactionbtn label {
  color: #2561a8;
}
.chatcontentDiv {
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;

  /* height: 240px; */
  height: 290px;
  /* background-color: #F5F5F5 !important; */
  overflow: auto;
  /* margin: 20px; */
  margin: 0px;

  /* margin-right: 10px; */
}
.pastcht {
  height: 240px;
}
.chatcontentRow {
  margin: 0px;
  background-color: #f5f5f5;
  height: 40%;
  width: 100%;
  /* overflow: auto; */
}

.chatcontentdivtab {
  margin: 0;
  background-color: #ecf2f4;
  padding: 20px 20px 10px;
  height: 100vh;
  width: 100%;
  /* max-height: 250px; */
  /* max-height: 220px; */
  max-height: calc(100vh - 445px);  
  overflow: hidden;
  /* overflow: auto; */

}
.chatcontentdivtab::-webkit-scrollbar {
  width: 6px;
}
.chatcontentdivtab::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chatcontentdivtab::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chatcontentdivtab::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatcontentdivtab .tab-content {
  padding-top: 18px;
  padding-bottom: 0;
  cursor: pointer;
  /* height: calc(100vh - 491px);  */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
 
}
.chatcontentdivtab .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #2561a8;
}
.chatcontentdivtab .nav-tabs .nav-link {
  color: #000000;
  border: 1px solid #2561a8;
  box-shadow: none;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding: 8px 12px;
}
.chatcontentdivtab .nav-tabs {
  border: none;
}
.chatcontentdivtab .nav-item {
  margin-right: 10px;
}
.chatcontentdivtab ul .nav-item:last-child {
  margin-right: 0px;
}
.message-div {
  /* margin: 20px; */
  position: relative;
}
.chatcontentdivtab .cke_contents {
  height: 100px !important;
}
.message-div textarea {
  height: 110px;
  resize: none;
  width: 100%;
  border: 1px solid #d8dfe2;
  border-radius: 5px;
  padding: 16px 55px 16px 50px;
  color: #4a4a4a;
}
.message-div textarea::-webkit-input-placeholder {
  padding: 0;
  font-weight: 400;
}
.message-div textarea:-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
}
.message-div textarea::-ms-input-placeholder {
  padding: 0;
  font-weight: 400;
}
.message-div textarea::placeholder {
  padding: 0;
  font-weight: 400;
}
.message-initial {
  position: absolute;
  top: 13px;
  left: 13px;
  background-color: #ddf6fc;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  color: #11b4db;
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.searchtxt {
  background-color: #fff !important;
  border-radius: 5px 0px 0px 5px;
  margin-left: 15px;
}
.searchtxt-span {
  background-color: #fff !important;
  border-radius: 0px 5px 5px 0px !important;
}
*:focus {
  outline: none;
}
.product-card .card {
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0 rgb(219, 219, 219, 0.5);
}
.mobile-chat-popup .product-card .card:last-child {
  margin-bottom: 0px;
}
.searchtxt-new .search-customerAddSrch {
  background-color: #ecf2f4;
  width: 96%;
  height: 43px;
  padding: 10px;
  border: none;
  padding-left: 15px;
  margin-left: 0;
}
.bkcprdt {
  background-color: #f3f3f3;
  padding: 10px;
  width: 100%;
}
.chat-product-name {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.chat-product-code {
  font-size: 12px;
  font-weight: 700;
  color: #686767;
}
.chat-product-prize {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}
.chat-product-url {
  font-size: 12px;
  font-weight: 700;
  color: #2aa2fd;
  width: 100%;
  cursor: pointer;
  word-break: break-all;
}
.chat-product-img {
  width: 100%;
}

.selectdot {
  width: 25px;
  position: absolute;
  top: 0px;
  z-index: 1;
  right: 6px;
}
.storeUpbtn {
  background-color: #fff;
  margin: auto;
  margin-top: 10px;
  height: 30px;
  padding: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.product-card {
  height: calc(100vh - 622px);
  /* height: 138px; */
  overflow: auto;
}
.product-card::-webkit-scrollbar,
.schedule-left-cntr::-webkit-scrollbar {
  width: 5px;
}
.product-card::-webkit-scrollbar-track,
.schedule-left-cntr::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.product-card::-webkit-scrollbar-thumb,
.schedule-left-cntr::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.product-card::-webkit-scrollbar-thumb:hover,
.schedule-left-cntr::-webkit-scrollbar-thumb:hover {
  background: #2561a8;
}
.product-card .col-md-4 {
  align-items: flex-end;
}
.selectdot img {
  width: 100%;
}
.storeUpbtn img {
  width: 20px;
}
.schedule-btn-outer-cntr {
  display: flex;
}
.message-icon-cnt {
  position: absolute;
  background-color: #eb4d4b;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 10%;
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  top: -13px;
  right: -10px;
}
.ongoing-message-icon-cnt {
  position: absolute;
  background-color: #2561A8;
  width: 15px;
  height: 14px;
  line-height: 15px;
  text-align: center;
  border-radius: 10%;
  font-size: 9px;
  color: #fff;
  font-weight: 700;
  top: -2px;
  right: -4px;
}
.schedule-btn-cntr {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -5px; */
  flex-wrap: nowrap;
  overflow: auto;
}
.schedule-btn-cntr::-webkit-scrollbar {
  display: none;
}
.schedule-btn-cntr button {
  cursor: pointer;
}
.schedule-btn-cntr button,
.select-slot-cntr,
.schedule-right-cntr input {
  font-size: 10px;
  position: relative;
  padding: 6px 24px 6px 6px;
  margin: 0 5px;
  margin-top: 14px;
  /* flex: 0 1 22%; */
  /* flex: 0 1 29%; */
  border-radius: 3px;
  min-width: auto;
  white-space: nowrap;
}
.s-yellow-btn {
  color: #000;
  border-color: #f7b500;
  background-color: #fff;
  cursor: pointer;
}
.s-green-btn {
  color: #000;
  border-color: #30ba93;
  background-color: #fff;
  cursor: pointer;
}
.s-red-btn {
  color: #000;
  border-color: #bd3939;
  background-color: #fff;
  cursor: auto !important;
  cursor: initial !important;
}
.s-red-active {
  background-color: #bd3939;
  color: #fff;
  border-color: #bd3939;
}
.s-yellow-active {
  background-color: #f7b500;
  color: #fff;
  border-color: #f7b500;
}
.s-green-active {
  background-color: #30ba93;
  color: #fff;
  border-color: #30ba93;
}
.s-lable {
  font-weight: 700;
  margin-bottom: -8px;
  color: #000;
  display: block;
  font-size: 12px;
}
.s-img-select {
  width: 18px;
  right: 3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.selectdot-blue {
  height: 20px;
  line-height: 17px;
  flex: 0 0 20px;
  background-color: #2561a8;
  border-radius: 50%;
  margin-top: 19px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}
.selectdot-blue-left {
  margin-left: 0;
  margin-right: 10px;
}
.selectdot-blue img {
  width: 14px;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.selectdot-blue-left img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.schedule-left-cntr {
  max-height: 145px;
  /* max-height: 200px; */
  overflow: auto;
}
.schedule-left-cntr > div {
  margin-bottom: 15px;
}
.schedule-left-cntr > div:last-child {
  margin-bottom: 0px;
}
.schedule-right-outer-cntr {
  display: flex;
  flex-direction: column;
  height: 80%;
  /* height: 100%; */
  justify-content: space-between;
}
.schedule-right-cntr {
  display: flex;
  justify-content: space-evenly;
}
.schedule-right-cntr input {
  border: 1px solid #b6b6b6;
  margin-left: 0;
  margin-right: 0;
  width: 75px;
  padding: 5px 8px;
  font-size: 14px;
}
.schedule-right-cntr .s-lable {
  color: #4a4a4a;
  font-size: 13px;
}
.ant-tooltip-inner {
  font-size: 10px;
  max-width: 100px;
  min-height: auto;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1.4;
  padding-right: 13px;
  padding-left: 13px;
}
.ant-tooltip-arrow::before {
  width: 8px;
  height: 8px;
}
.schedule-btn-cntr *:focus {
  outline: none;
}
.pd-0 .card-body {
  padding: 0;
}
.isSendClick-dsle {
  pointer-events: none;
  cursor: no-drop;
}
.messagecount {
  background-color: darkseagreen;
  color: #fff;
  border: 2px solid #f1f1f1;
  font-size: 11px;
  border-radius: 50%;
  padding: 1px 6px 2px 5px;
  text-align: center;
}
.firstbox {
  width: 20%;
}
.secondbox {
  width: 53%;
}
.thirdbox {
  width: 27%;
  position: relative;
  border-left: 1px solid #e8e8e8;
}
.firstbox-full {
  width: 24%;
}
.secondbox-open {
  width: 69% !important;
}
.thirdbox-close {
  width: 7% !important;
}
.thirdbox-close .shoppingbag {
  display: none;
}
.thirdbox-close .uptabs .nav-tabs {
  display: none;
}
.thirdbox-close .nameplate span {
  display: none;
}
.thirdbox-close .contactbox {
  display: none;
}
.thirdbox-close .pointstable {
  display: none;
}
.thirdbox-close .prodtabl1 {
  display: none;
}
.thirdbox-close .updateprofilelinkbtn {
  display: none;
}
.thirdbox-close .pin {
  display: none;
}
.thirdbox-close .nameplate li:first-child {
  margin-right: 0;
}
.thirdbox-close .nameplate {
  margin: 0;
}
.thirdbox-close .tab-content {
  padding: 20px 15px 15px !important;
  align-items: center;
  display: block;
  margin: auto;
  text-align: center;
}
/* .uptabs {
  position: relative;
} */
.uptabs .nav-tabs {
  border-bottom: none;
  display: inline-block;
  background: #2561a8;
  color: #fff;
  border-radius: 10px;
  margin: 15px 30px;
  padding: 0 2px;
}
.uptabs .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: none;
  box-shadow: none;
  font-weight: 600;
  background: #fff;
  padding: 6px 12px;
  border-radius: 8px;
}
.uptabs .nav-tabs .nav-link {
  padding: 8px 12px;
  border: 0;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
}
.uptabs .nav-tabs .nav-item {
  margin-bottom: 0;
}
.uptabs .tab-content {
  padding: 0 15px 15px;
}
.nameplate li:first-child {
  border: 1px solid #64c5b4;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}
.nameplate li {
  vertical-align: middle;
}
.nameplate .namelabel {
  background: #ddf6fc;
  padding: 10px 17px;
  border-radius: 50%;
  font-weight: bold;
}
.nameplate h3 {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  white-space: normal;
  word-break: break-word;
}
.nameplate span {
  display: block;
  background: #f5c645;
  padding: 1px 10px;
  font-size: 11px;
  border-radius: 50px;
  text-align: center;
  width: 70px;
  margin-top: 4px;
}
.nameplate img {
  height: 13px;
  margin: 0 4px;
}
.contactbox {
  float: right;
}
.chatdivtitle li {
  margin-right: 10px;
}
.contactbox ul {
  text-align: right;
}
.contactbox label {
  font-size: 12px;
  color: #666;
  display: block;
}
/* .contactbox li:first-child {
  margin-right: 50px;
} */
.contactbox li:first-child {
  border: none;
  padding: 0;
  border-radius: 0;
  margin: 0;
  display: block;
}
.contactbox p {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}
/* .pointstable table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
} */
.nameplate {
  /* padding: 0 15px; */
  padding: 0 0px;
}
.pointstable table td {
  padding: 10px 0;
  font-size: 12px;
}
.pointstable table label {
  color: #333;
}
.pointstable table span {
  text-align: right;
  font-size: 16px;
  color: #2561a8;
}
.pointstable {
  margin-bottom: 10px;
  padding: 0 15px;
}
.pointstable td:nth-child(1) {
  float: left;
}
.pointstable td:nth-child(2) {
  float: none;
  text-align: center;
}
.pointstable td:nth-child(3) {
  float: right;
}
.pointstable td:nth-child(1) span {
  text-align: left;
}
.pointstable td:nth-child(2) span {
  text-align: center;
}
.pointstable td:nth-child(3) span {
  text-align: right;
}

.itemtable table thead tr {
  background-color: transparent !important;
  border-bottom: none;
}
.itemtable table th {
  padding: 0;
  color: #999;
  font-weight: normal;
}
.itemtable table td {
  padding: 2px 0;
  font-size: 12px;
}
.itemtable table label {
  color: #333;
}
.itemtable {
  margin-bottom: 10px;
}
.itemtable td:nth-child(1) {
  float: left;
}
.itemtable td:nth-child(2) {
  float: none;
  text-align: center;
}
.itemtable td:nth-child(3) {
  float: right;
}
.itemtable td:nth-child(1) label {
  text-align: left;
}
.itemtable td:nth-child(2) label {
  text-align: center;
}
.itemtable td:nth-child(3) label {
  text-align: right;
}
.itemtable th:nth-child(1) {
  float: left;
}
.itemtable th:nth-child(2) {
  float: none;
  text-align: center;
}
.itemtable th:nth-child(3) {
  float: right;
}
.itemtable table tbody tr {
  border-bottom: none;
}
.lasttransaction h3 {
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}
.lasttransaction label {
  font-size: 12px;
  color: #999;
  display: block;
}
.lasttransaction li:last-child {
  float: right;
}
.lasttransaction li {
  width: 50%;
}
.lasttransaction {
  padding-bottom: 4px;
}
.lasttransaction span {
  font-size: 11px;
  color: #333;
  font-weight: 600;
}
.lasttransaction {
  margin-bottom: 12px;
}
.lasttransaction ul {
  margin-bottom: 10px;
  display: flex;
}
.ordersbox ul {
  margin-bottom: 7px;
}
.ordersbox h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}
.ordersbox label {
  font-size: 12px;
  color: #999;
  display: block;
}
.ordersbox li {
  width: 100px;
}
.ordersbox li:last-child {
  float: right;
}
.ordersbox li:last-child {
  float: right;
}
.ordersbox span {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}
.ordersbox {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
  padding-bottom: 5px;
}
.DownArw {
  margin-left: 6px;
  height: 7px;
}
.insightsbox {
  margin-bottom: 12px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
.insightsbox h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.insightsbox p {
  font-size: 12px;
  color: #666;
  margin-bottom: 3px;
  font-weight: normal;
}
.updateprofilelinkbtn {
  background: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 12px;
  color: #2561a8;
  font-weight: 600;
  margin: auto;
  display: block;
}

.tabsbotbtn {
  outline: none !important;
  cursor: pointer;
  background: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 11px;
  color: #2561a8;
  font-weight: 600;
  margin: auto;
  margin-right: 6px;
  padding: 8px 6px;
}
.tabsbotbtn:last-child {
  margin-right: 0px;
}
/* .tabsbotbtn-box {
  text-align: center;
  margin-top: 2px;
} */
.tabsbotbtn-box {
  text-align: center;
  margin-top: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  background: #fff;
}
.pastchatmobbtn {
  display: none;
}
.pin {
  position: absolute;
  height: 20px;
  right: 20px;
  top: 20px;
}
.productsbox .nav-tabs {
  margin: 0;
  background: none;
  border-radius: 0;
  padding: 0;
}
.productsbox .nav-tabs .nav-link.active {
  background: none;
  border-radius: 0;
  padding: 8px 12px;
}
.productsbox .nav-tabs .nav-link {
  color: #333;
  text-transform: capitalize;
  padding: 8px 12px;
  font-size: 16px;
}
.productsbox .nav-tabs .nav-link:hover {
  color: #333;
}
.productsbox .nav-tabs .nav-link:first-child {
  padding-left: 0;
}
.productsbox .nav-tabs .nav-item:last-child .nav-link {
  padding-right: 0;
}
.productsbox .selectalllabel {
  color: #2561a8;
  font-size: 13px;
  margin-bottom: 12px;
}
.productsbox .tab-content {
  padding: 0 0 15px;
}
.productsbox .prodboxx {
  position: relative;
  margin-right: 7px;
  margin-bottom: 12px;
  display: inline-table;
}
.ladyimg {
  width: 100px;
  height: 150px;
  border: 1px solid #cac4c4;
}
.productsbox tr {
  border-bottom: none;
}
.productsbox td {
  padding: 0;
}
.prodboxx h3 {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85px;
  overflow: hidden;
}
.prodboxx h4 {
  font-size: 12px;
    color: #333;
    margin-top: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
}
.prodboxx span {
  font-size: 10px;
  color: #666;
  margin-top: 3px;
  display: block;
  padding: 0 !important;
}
.prodboxx .ant-checkbox {
  position: absolute;
  top: 3px;
  left: 6px;
  outline: none;
}
.prodboxx .ant-checkbox-inner {
  border-radius: 50%;
}
.cancelico {
  height: 18px;
  border: 2px solid;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  right: 2px;
  top: 0;
  /* bottom: -7px; */
  cursor: pointer;
}
.chatupperbtn {
  background: #fff;
  color: #2561a8;
  padding: 4px 0;
  font-size: 12px;
  font-weight: bold;
}
.prodtabl1 {
  max-height: 295px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.prodtabl1::-webkit-scrollbar {
  width: 5px;
}
.prodtabl1::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.prodtabl1::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.prodtabl1::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.prodtabl {
  width: 100%;
  max-height: 366px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.prodtabl::-webkit-scrollbar {
  width: 5px;
}
.prodtabl::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.prodtabl::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.prodtabl::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.search-customerChatSrch {
  width: 88%;
  height: 43px;
  padding: 10px;
  border: none;
  padding-left: 15px;
}
.seacrh-img-chatsearch {
  width: 12%;
  display: inline-block;
  height: 43px;
  padding: 13px 0 0 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background: #ecf2f4 !important;
}
.Chatw {
  height: 16px;
  margin-right: 6px;
}
.chatsearchtxt-span {
  background-color: #fff !important;
  border-radius: 0px 5px 5px 0px !important;
}
.chat-trail-img .chat-initial {
  background: #ddf6fc;
  display: block;
  height: 45px;
  line-height: 45px;
  color: #11b4db;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
}

.cls-charcount {
  float: right;
  font-weight: bold;
  color: #757575;
}

.cls-p-conf {
  margin: 7px;
  margin-top: 14px;
  position: relative;
  left: 32%;
}

.cls-p-sugg {
  padding: 15px;
}

#desktop-conf-popup {
  background: #ecf2f4;
  padding: 15px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 42%;
}

.chat-btn-conf {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 120px;
  padding-left: 116px;
}

#desktop-conf-mob-popup {
  background: #ecf2f4;
  padding: 8px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}

.cls-p-conf-mob {
  margin: 7px;
  margin-top: 14px;
  position: relative;
  left: 24%;
}

.chat-btn-conf-mob {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 64px;
  padding-left: 65px;
}

.slot-span {
  display: block;
  margin: 10px 10px 10px 10px;
  padding: 5px 10px;
  text-align: center;
  color: #757575;
}

.chat-hist {
  padding: 90px 0px 30px 0px;
  position: relative;
  margin: auto;
  display: block;
}
.chat-hist .nav-item {
  margin: auto;
  display: block;
  width: 150px;
  text-align: center;
}

.chat-hist .nav-item .nav-link {
  color: #000000;
  border: 1px solid #2561a8;
  box-shadow: none;
  border-radius: 21px;
  background-color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding: 7px 20px;
}

.chat-history .ant-table-body {
  padding: 8px 8px 8px 8px;
}

.chat-history .ant-table-pagination {
  padding: 8px 8px 8px 8px;
}

.chattabtitle {
  padding: 0 !important;
  width: 100% !important;
}

.chattitletab {
  background-color: #ecf2f4 !important;
  border: none !important;
  outline: 0 !important;
}

.storeaget-chat-ctn {
  background-color: #ebebeb;
  border-radius: 4px;
  width: 35px;
  height: 22px;
  color: #989898;
  font-family: Lato;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  padding: 1px;
  flex: 0 0 35px;
  text-align: center;
}
.agentchatdrop-down {
  margin-left: 0;
  width: 43%;
}
.histochat {
  max-height: 200px;
  overflow: auto;
}
.histochat::-webkit-scrollbar {
  width: 6px;
}
.histochat::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.histochat::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.histochat::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatactionbtn {
  float: right;
  display: inline-block;
  margin-right: 10px;
  margin-left: 0px;
  outline: none !important;
  padding: 5px 10px;
  border-radius: 10px;
}
#actionmodalId {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  /* margin-right: 105px; */
  margin-top: 83px;
  display: inline-block;
}
.actionmodalId-mr-add {
  margin-right: 388px;
}
.actionmodalId-mr-remove {
  margin-right: 102px;
}

.actionmodallbl {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 7px;
}

.endchatlbl {
  width: 100%;
  text-align: center;
  /* margin-bottom: 10px; */
  color: #fff;
  font-weight: bold;
  background-color: #eb7372;
}
.chat-cntr .ant-select-arrow {
  display: block !important;
}
.showArrow .ant-select-arrow {
  display: block !important;
}

.add-cursor .ant-table-row {
  cursor: pointer;
}

.chathistory-div {
  padding: 10px;
}
.mg-rm.ant-pagination .ant-table-pagination {
  /* margin-bottom: 10px !important;
  padding-right: 10px !important; */
  margin: 0px !important;
}

.chathistory-tbl {
  background-color: #f5f5f5 !important;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  height: 100vh;
}

.historychatcontnet {
  margin: 10px;
  background-color: #fcfcfc;
  overflow: auto;
  margin-top: 10px;
  margin-right: 15px;
  border-radius: 5px;
}
.historychatcontnet .add-bord {
  border-bottom: 1px solid #f3f3f3;
}

.chathistory-tbl .clickRowStyl {
  background-color: #f6f6f6;
}

.chathistory-tbl.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #f6f6f6;
}
.add-bord .chat-on-tuesday-jul {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.msg-text-overlap {
  display: inline-block;
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.now-rap-tbl-txt.chatabcus
  .custom-antd-table.antd-table-campaign
  .ant-table-tbody
  > tr
  > td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-pagination-item-ellipsis {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  width: 22px !important;
  overflow: visible !important;
}
.rm-header tbody {
  display: flex;
  flex-wrap: wrap;
}
.rm-header table tbody tr {
  border-bottom: 0;
}
.rm-header .ant-table-thead {
  display: none;
}

.rm-header.custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding: 0 !important;
  margin: 0 !important;
}

.rm-header .ant-table {
  width: 100% !important;
}

.rm-header.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.rm-header.ant-pagination-item-active {
  border-color: #2561a8 !important;
}
.Cancelicow {
  display: none;
}
.BackArw {
  display: none;
}
.loader {
  margin-right: 88%;
  margin-top: 5px;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.tagsbtn {
  background: #fff;
  color: #000000;
  font-weight: 400;
  outline: none !important;
  margin-right: 10px;
  margin-top: 15px;
  min-width: auto;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 8px;
}
.tagsbtn-active {
  text-transform: uppercase;
  font-size: 10px;
  padding: 8px 12px;
  background: #2561a8;
  color: #fff;
  font-weight: 400;
  outline: none !important;
  margin-right: 10px;
  margin-top: 15px;
  min-width: auto;
  cursor: pointer;
  border-radius: 8px;
}
.secondbox-open-new {
  width: 76% !important;
}
.Arwico {
  display: none;
}
.prodtabl1 .ant-collapse.ant-collapse-icon-position-left {
  border: none;
  background-color: #fff;
  border-radius: 0;
}
.prodtabl1 .ant-collapse-header {
  color: #333 !important;
  font-weight: 600;
}
.prodtabl1 .ant-collapse-content {
  border: none;
  color: #333;
}
.prodtabl1 .ant-collapse > .ant-collapse-item {
  border: none;
}
.prodtabl1 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 15px;
}
.prodtabl1 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
  padding-left: 36px;
}
.show-mobile {
  display: none;
}
.hide-message-title .ant-notification-notice-message {
  display: none;
}

/* Dropdown Button */
.chatdivtitle.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer !important;
}

/* Dropdown button on hover & focus */
.chatdivtitle .dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.chatdivtitle .dropdown {
  position: relative;
  display: inline-block;
  background-image: none !important;
  float: right;
  cursor: pointer !important;
  width: 200px;
}

/* Dropdown Content (Hidden by Default) */
.chatdivtitle .dropdown-content {
  width: 100%;
  display: none;
  position: absolute;
  background-color: #fff;
  margin-top: 35px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 6px;
}

/* Links inside the dropdown */
.chatdivtitle .dropdown-content label {
  color: black;
  padding: 25px;
  text-decoration: none;
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 0;
  cursor: pointer !important;
}

/* Change color of dropdown links on hover */
.chatdivtitle .dropdown-content label:hover {
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer !important;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.chatdivtitle .show {
  display: block;
}

.chat-initial-brd {
  border: 1px solid #9a9a9a;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.insightsbox .ant-empty .ant-empty-normal {
  margin: 16px 0;
}
.agentchatdrop-down .ant-select-selection--single {
  background: none;
  border: none;
  font-size: 14px;
  color: #4a4a4a !important;
}
.agentchatdrop-down i {
  color: blue;
  line-height: 0;
}
.selected-store-cancel {
  position: relative;
  top: 20px;
  right: 10px;
  height: 14px;
  cursor: pointer;
  width: 13px;
  margin: 0px 0px 0px auto;
}
.ant-notification-notice {
  border: 1px solid #ccc;
}
#message-tab::-webkit-scrollbar {
  width: 6px;
  margin-left: 10px;
}
#message-tab::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
#message-tab::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
#message-tab::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.setpagination .ant-spin-nested-loading, .setpagination .ant-spin-container {
width: 100%;
}
/* .styles_modal__gNwvD{
  padding: 15px !important;
} */
/* Responsive starts */
@media all and (max-width: 768px) {
  .firstbox {
    width: 40%;
  }
  .secondbox {
    width: 60%;
  }
  .thirdbox {
    display: none;
  }
  #chat-popup-overlay {
    top: 0;
  }
}

@media all and (max-width: 576px) {
  .Cancelicow {
    display: block;
  }
  .BackArw {
    display: block;
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .firstbox-hide {
    display: none;
  }
  .firstbox-show {
    display: block;
  }
  .secondbox-open-new-hide {
    display: none;
  }
  .secondbox-open-new-show {
    display: block;
  }
  .firstbox {
    width: 100%;
    height: 94vh;
  }
  .secondbox {
    width: 100%;
  }
  .secondbox-open {
    width: 100% !important;
  }
  .thirdbox-shutter {
    height: 560px !important;
    max-height: 560px !important;
  }
  #chat-popup-overlay {
    top: 0;
  }
  .oc-bg {
    background-color: #ecf2f4;
    height: 76vh;
  }
  .chat-hist {
    padding: 0;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 15px;
  }
  .agentchatdrop-down {
    margin-left: 0;
    width: 43%;
  }
  .agentchatdrop-down:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .chat-trail-chat {
    background: #fff;
    color: #333;
  }
  .chat-trail-cntr {
    margin-left: 0px;
    margin-right: 0;
    max-width: 100%;
  }
  .chat-trail-cntr-right .chat-trail-chat {
    background: #cee0e6;
    color: #333;
  }
  .thirdbox {
    display: block;
    width: 100% !important;
    position: absolute;
    background: #fff;
    max-height: 90px;
    overflow: inherit;
    border-radius: 0 0 10px 10px;
  }
  .Arwico {
    height: 18px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999999999999;
    bottom: -10px;
    display: block;
  }
  .chattabtitle {
    padding: 0 !important;
    width: 100% !important;
    margin-top: 28px;
  }
  .pin {
    display: none;
  }
  .loader {
    top: 13px;
    margin-left: 100px;
    margin-right: 0% !important;
    margin-top: 0px !important;
    font-size: 4px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
            animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }

  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  #chat-popup-overlay {
    padding: 0px;
  }
  #chat-popup-overlay .styles_modal__gNwvD {
    max-width: 100%;
    background: whitesmoke;
  }
  .rounded-cross,
  /* .chatbot-left, */
  .chatdivtitle,
  .chat-tabs-desktop {
    display: none;
  }
  .chatdivtitle {
    display: inline-block;
  }
  .chatdivtitle ul {
    width: 100% !important;
  }
  .chatactionbtn {
    display: none !important;
  }
  a.nav-link.active.chattitletab {
    color: #2561a8;
    font-weight: bold;
  }
  .store-chat-header h3 {
    font-size: 13px;
    display: inline;
    margin-right: 0 !important;
  }
  .search-customerChatSrch::-webkit-input-placeholder {
    font-size: 12px;
  }
  .search-customerChatSrch:-ms-input-placeholder {
    font-size: 12px;
  }
  .search-customerChatSrch::-ms-input-placeholder {
    font-size: 12px;
  }
  .search-customerChatSrch::placeholder {
    font-size: 12px;
  }
  .chats-heading {
    font-size: 12px;
  }
  .store-chat-header {
    justify-content: inherit;
    padding-left: 15px;
    display: block;
    padding-right: 15px;
  }
  .BackArw {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    float: right;
    margin-top: 3px;
    margin-right: 10px;
  }
  .Cancelicow {
    height: 17px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    float: right;
    margin-top: 3px;
  }
  .mobile-arrow,
  .mobile-chat-tabs,
  .chat-tabs-mobile {
    display: block;
    /* height: 12px; */
  }
  .mobile-ck-send {
    /* top: 25px; */
    top: 7px;
  }
  .mobile-ck-send-btn {
    top: 43px;
  }
  #chat-popup .butn,
  #chat-popup .butn-inv {
    min-width: 85px;
    height: 36px;
    font-size: 14px;
  }

  /* right side part starts */
  .chatcontentdivtab {
    padding: 0;
  }
  .chatcontentdivtab .nav-tabs .nav-link {
    font-size: 13px;
    border: 0;
    padding: 5px 20px;
    text-transform: lowercase;
    color: #404d52;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #2561a8;
  }
  .chatcontentdivtab .nav-tabs .nav-link::first-letter {
    text-transform: uppercase;
  }
  .chatcontentdivtab .nav-tabs {
    margin: 10px 0px 10px 15px;
  }
  /* .chatcontentdivtab .nav-item {
    margin-left: 0;
    padding-right: 10px;
  } */
  .chatcontentdivtab .nav-tabs .nav-item:last-child {
    padding-right: 15px;
  }
  .chatcontentdivtab .tab-content {
    padding: 0;
  }
  .message-div {
    /* margin: 0; */
    margin: 14px;
    margin-bottom: 10px;
  }
  .message-initial {
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 11px;
    border-radius: 3px;
    top: 9px;
    left: 9px;
  }
  .message-div textarea {
    border-color: transparent;
    border-radius: 4px;
    font-size: 12px;
    height: 120px;
    padding: 11px 55px 11px 40px;
  }
  .message-div textarea::-webkit-input-placeholder {
    font-size: 12px;
  }
  .message-div textarea:-ms-input-placeholder {
    font-size: 12px;
  }
  .message-div textarea::-ms-input-placeholder {
    font-size: 12px;
  }
  .message-div textarea::placeholder {
    font-size: 12px;
  }
  .chatcontentdivtab .cke_contents {
    height: 80px !important;
  }
  .chat-trail-chat {
    font-size: 12px;
    padding: 8px 15px;
  }
  .bot-mark {
    font-size: 8px;
    padding: 4px;
    top: 7px;
    left: -15px;
  }
  .chat-trail-chat .card-body {
    margin: 0 -7px;
  }
  .chat-trail-time {
    font-size: 10px;
  }
  .chat-trail-img {
    flex: 0 0 35px;
    display: none;
  }
  .chat-trail-chat-cntr {
    margin-top: 10px;
  }
  .chat-trail-time {
    margin-top: 4px;
  }
  .chat-trail-cntr {
    margin-bottom: 10px;
  }
  .chatcontentDiv {
    /* border-bottom: 1px solid #ccc; */
    /* margin-right: 5px; */
    margin-left: 5px;
    /* height: calc(100vh - 314px); */
    /* height: calc(100vh - 404px); */
    height: calc(100vh - 345px);
  }
  .pastcht {
    height: 215px;
  }
  .prodboxx h4 {
    max-width: 90px;
  }
  /* right side part ends */

  /* ck editor starts */
  /* .cke_top {
    padding: 0 !important;
    padding-right: 8px !important;
  }
  .cke_toolgroup {
    margin-bottom: 1px !important;
  } */
  /* ck editor ends */

  /* card modal starts */
  .send-up {
    width: 22px;
  }
  .bkcprdt label {
    display: block;
  }
  .storeUpbtn {
    display: none;
  }
  .product-card {
    max-height: calc(100vh - 171px);
    height: 100%;
    padding-right: 10px;
  }
  .searchtxt-new {
    margin-top: 0;
    padding-right: 10px;
  }
  .mobile-card-img {
    flex: 0 0 100px;
    margin-right: 15px;
  }
  .chat-product-name {
    font-size: 10px;
  }
  .chat-product-code,
  .chat-product-prize,
  .chat-product-url {
    font-size: 9px;
  }
  .chat-product-prize {
    margin-top: 6px;
  }
  .selectdot {
    width: 22px;
    top: -13px;
    right: -13px;
  }
  .product-card .card {
    margin: 0;
    margin-bottom: 10px;
  }
  /* card modal ends */

  /* schedule modal starts */
  .schedule-visit-popup > div {
    height: 100%;
  }
  .schedule-mobile-cntr {
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .selectdot-blue {
    display: none;
  }
  .s-lable {
    font-size: 12px;
    margin-bottom: -4px;
  }
  .schedule-btn-cntr button,
  .select-slot-cntr,
  .schedule-right-cntr input {
    margin-top: 11px;
    font-weight: 700;
    font-size: 10px;
    padding: 4px 24px 4px 8px;
    flex: 0 1 29%;
  }
  .schedule-left-cntr > div {
    margin-bottom: 20px;
  }
  .schedule-left-cntr::after {
    content: "";
    border-bottom: 1px solid #d7d7d7;
  }
  .schedule-visit-popup {
    padding: 0 !important;
    /* height: 100%; */
  }
  .schedule-left-outer-cntr {
    padding: 20px 0 20px 15px;
    border-bottom: 1px solid #d7d7d7;
  }
  .schedule-right-cntr {
    justify-content: initial;
    padding: 15px;
  }
  .schedule-right-cntr .s-lable {
    font-size: 12px;
    color: #000;
  }
  .schedule-right-cntr > div {
    margin-right: 20px;
  }
  .schedule-right-cntr > div:last-child {
    margin-right: 0px;
  }
  .schedule-right-cntr input {
    width: 80px;
    color: #000;
    padding: 4px 8px;
  }
  .schedule-visit-popup .chat-btn-cntr {
    padding: 0 15px 15px;
  }
  .schedule-left-cntr {
    max-height: calc(100vh - 230px);
  }
  .ant-tooltip-inner {
    padding-right: 9px;
    padding-left: 9px;
  }
  .schedule-btn-cntr {
    margin-left: -5px;
    flex-wrap: wrap;
    overflow: visible;
    overflow: initial;
  }
  /* schedule modal ends */

  /* payment link mobile starts */
  .payment-details label {
    font-size: 12px;
    margin-bottom: 0;
  }
  .payment-details span {
    font-size: 16px;
  }
  .payment-details {
    margin-left: 5px;
  }
  /* payment link mobile ends */

  /* message suggestions starts */
  .suggestions-cntr span {
    font-size: 11px;
    padding-right: 10px;
    padding-left: 10px;
    margin: 2px 4px;
    max-width: 190px;
  }
  .suggestions-cntr {
    margin: 8px -4px 4px;
  }
  .suggestions-tick span:before {
    content: "";
    width: 15px;
    height: 15px;
  }
  .suggestions-tick span:after {
    content: "";
    width: 4px;
    height: 8px;
    right: 5.5px;
  }
  /* message suggestions ends */
  .uptabs .nav-tabs {
    margin: 9px 9px;
    padding: 0 2px;
    float: right;
  }
  ul.nameplate {
    margin: 15px 0;
  }
  .thirdbox .uptabs {
    overflow: hidden;
    max-height: 89px;
  }
  .thirdbox-shutter .uptabs {
    max-height: -webkit-fit-content;
    max-height: fit-content;
  }
  .prodtabl1 {
    max-height: 345px;
  }
  .nameplate .namelabel {
    padding: 6px 11px;
  }
  .nameplate li:first-child {
    margin-right: 6px;
  }
  .nameplate h3 {
    font-size: 13px;
    white-space: nowrap;
    max-width: 90px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    margin-top: 5px;
  }
  .contactbox {
    float: right;
    width: 100%;
  }
  .updateprofilelinkbtn {
    margin: 10px 0;
    display: inline;
    float: right;
  }
  .pastchatmobbtn {
    display: inline;
    margin: 12px !important;
    float: left;
  }
  .productsbox .selectalllabel {
    margin-top: 12px;
  }
  .productsbox .prodboxx {
    margin-right: 5px;
  }
  .thirdbox .uptabs .tab-content {
    padding: 0 6px 15px;
  }
  .ladyimg {
    width: 90px;
    height: 125px;
    border: 1px solid #cac4c4;
  }
  .tabsbotbtn-box {
    text-align: inherit;
    margin: 10px;
  }
  .prodtabl {
    max-height: 350px;
  }
  .tabsbotbtn {
    min-width: 95px;
    width: 95px;
    margin-right: 5px;
    font-size: 10px;
    padding: 8px 3px;
  }
  .uptabs .nav-tabs .nav-link {
    padding: 8px 8px;
    font-size: 11px;
  }
  .chatbot-right {
    height: calc(100vh - 38px);
  }
  .chatcontentdivtab {
    max-height: 228px;
  }
  .tabsnme ul {
    width: 100%;
    padding: 0 5px !important;
    margin: 0 !important;
  }
  .tabsnme a {
    font-size: 14px !important;
    margin-right: 24px;
  }
  .productsbox .nav-tabs .nav-item:last-child .nav-link {
    margin-right: 0;
}
}

@media all and (max-width: 425px) {
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }
  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  .chat-face-cntr {
    width: 35px;
  }
  .chat-detail-cntr {
    margin-right: 20px;
  }
  .chatcontentDiv {
    /* height: calc(100vh - 299px); */
    /* height: calc(100vh - 389px); */
    /* height: calc(100vh - 285px); */
  }
  .chat-detail-outer-cntr {
    min-height: 66px;
  }
  .online {
    width: 8px;
    height: 8px;
  }
  .chat-initial {
    height: 35px;
    line-height: 35px;
    font-size: 12px;
  }
  /* .chat-detail-outer-cntr .chat-detail-middle-cntr:first-child {
    padding-left: 2px;
  } */

  /* card modal starts */
  .mobile-card-img {
    flex: 0 0 70px;
    margin-right: 10px;
  }
  .chat-product-name {
    font-size: 8px;
  }
  .chat-product-code,
  .chat-product-prize,
  .chat-product-url {
    font-size: 7px;
  }
  .selectdot {
    width: 20px;
  }
  /* card modal ends */
}

@media all and (max-width: 387px) {
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }
  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  /* schedule modal starts */
  .schedule-btn-cntr button,
  .select-slot-cntr,
  .schedule-right-cntr input {
    flex: 0 1 44%;
  }
  /* schedule modal ends */
}
/* Responsive ends */

/* chetan css starts */

/* order tabs starts */
.order-mobile-dropdown,
.order-mobile {
  display: none;
}
.orders-tabs-outer .nav-tabs .nav-link {
  font-weight: 400;
  padding: 0;
  margin-right: 30px;
}
.orders-tabs-outer .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}
.orders-tabs-outer {
  padding: 7px 16px;
}
.order-search {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 11px 14px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-search input {
  border: 0;
  padding: 0;
  width: 185px;
  margin-right: 10px;
}
.order-search input::-webkit-input-placeholder {
  padding: 0;
}
.order-search input:-ms-input-placeholder {
  padding: 0;
}
.order-search input::-ms-input-placeholder {
  padding: 0;
}
.order-search input::placeholder {
  padding: 0;
}
.order-search img {
  width: 16px;
  cursor: pointer;
}
.orders-tab-cont {
  padding: 12px 16px;
}
.orddropdown {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #ecf2f4
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAcElEQVR4nJXPMQrCUBBF0TNGhTTRbVho4f43IQlkGyEIsVIL/w/ykQRvM/DgzpsJVNjgZZ3Ac4sdTqhXxMCEPlJwxAX7BemBDkN8hQ3OqbFkwg13Pr9kRrRpY9nQZqGUfolZGBfOnjngmuZflFfMvAHCTRZc4oUWEgAAAABJRU5ErkJggg==)
    no-repeat right 12px center;
  border: none;
  cursor: pointer;
}
/* order tabs ends */

/* shopping bag starts */
.order-hamb {
  width: 20px;
  margin-left: 4px;
  cursor: pointer;
}
.order-info {
  width: 16px;
  margin-left: 6px;
  cursor: pointer;
}
.antd-table-order .ant-table-tbody > tr > td {
  color: #000;
  font-weight: 400 !important;
  white-space: nowrap;
  height: 60px;
}
.order-popover-table .antd-table-order .ant-table-tbody > tr > td {
  white-space: normal;
  white-space: initial;
}
.custom-antd-table.antd-table-campaign.antd-table-order
  .ant-table-thead
  > tr
  > th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: 50px;
}
.order-small-font {
  font-size: 11px;
}
.order-more-small-font {
  font-size: 10px;
}
.order-grid-butn {
  font-size: 13px;
  white-space: nowrap;
  padding: 4px 10px !important;
  border-radius: 3px;
  min-width: 121px;
  text-transform: none;
  text-transform: initial;
  cursor: pointer;
}
.order-del-butn img {
  width: 13px;
}
.order-del-butn {
  padding-right: 9px !important;
  padding-left: 9px !important;
  background: #e55050;
  align-items: center;
  display: flex;
  margin-left: 7px;
  border: 0;
  min-width: auto !important;
}
.white-space-init {
  white-space: normal !important;
  white-space: initial !important;
}
.order-clr-green {
  color: #07a287;
}
.order-clr-blue {
  color: #2561a8;
}
.order-clr-pink {
  color: #e74f4c;
}
.pick-up-date .ant-table-column-title {
  line-height: 1.3;
  white-space: normal !important;
  white-space: initial !important;
  display: inline-block;
  width: 70px;
}
.order-popover-table .ant-popover-inner-content {
  padding: 0;
  width: 425px;
}
.order-popover-table .ant-popover-inner {
  overflow: hidden;
}
.order-popover-table .ant-table-hide-scrollbar {
  background: #e5e9ed;
  min-width: 10px !important;
}
.order-popover-table
  .ant-table-hide-scrollbar
  + .ant-table-body::-webkit-scrollbar,
.order-popover-address .ant-popover-message::-webkit-scrollbar,
.shopping-popover-cancel-info::-webkit-scrollbar {
  width: 7px;
}
.order-popover-address .react-datepicker button {
  min-width: auto;
}
.order-popover-table
  .ant-table-hide-scrollbar
  + .ant-table-body::-webkit-scrollbar-track,
.order-popover-address .ant-popover-message::-webkit-scrollbar-track,
.shopping-popover-cancel-info::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.order-popover-table
  .ant-table-hide-scrollbar
  + .ant-table-body::-webkit-scrollbar-thumb,
.order-popover-address .ant-popover-message::-webkit-scrollbar-thumb,
.shopping-popover-cancel-info::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.order-popover-table
  .ant-table-hide-scrollbar
  + .ant-table-body::-webkit-scrollbar-thumb:hover,
.order-popover-address .ant-popover-message::-webkit-scrollbar-thumb:hover,
.shopping-popover-cancel-info::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.order-popover .ant-popover-arrow {
  border-width: 7px;
  width: 14px !important;
  height: 14px !important;
  margin-top: 0 !important;
}
.order-popover.ant-popover-placement-top
  > .ant-popover-content
  > .ant-popover-arrow,
.order-popover.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow,
.order-popover.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow {
  bottom: 3px;
}
.order-popover.ant-popover-placement-bottom
  > .ant-popover-content
  > .ant-popover-arrow,
.order-popover.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow,
.order-popover.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow {
  top: 3px;
}

.order-popover-overlay {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.shopping-popover-delete .ant-popover-inner {
  position: relative;
  left: 10px;
}
.shopping-popover-delete.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow,
.shopping-popover-delete.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow {
  right: 9px;
}
.shopping-delivery-header .ant-table-filter-icon {
  left: 98px !important;
}
.partner-shipment-header .ant-table-filter-icon {
  left: 68px !important;
}
.courier-shipment-header .ant-table-filter-icon {
  left: 111px !important;
}
.shopping-popover-cancel-info {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 7px;
  max-height: 200px;
  overflow: auto;
  padding-right: 10px;
}
.shopping-popover-cancel {
  width: 280px;
}
.shopping-popover-cancel .ant-popover-inner-content {
  padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6px;
}
.shopping-popover-cancel .ant-popover-inner {
  border-radius: 8px;
}
.username-mar {
  margin-right: 10px;
}
.ordDisply{
  display: contents;
}
.ordDisply select {
  font-size: 12px !important;
  color: #4a4a4a !important;
  width: 17% !important;
  cursor: pointer;
  margin-right: 15px !important;
}
.ordDisply label {
  margin-right: 7px;
}
/* shopping bag ends */

/* chetan css ends */

/* Delivered Start */

.deliv-grid-butn {
  border-radius: 3px;
  width: 142px;
  height: 31px;
  color: #fff;
}
.delibutn {
  background-color: #25a874;
  border: 1px solid #25a874;
}
.markasbutn {
  background-color: #2561a8;
  border: 1px solid #2561a8;
}
.pickedbutn {
  background-color: #61a9fd;
  border: 1px solid #61a9fd;
}
.deliv-status {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  width: 58px;
  text-align: left;
}
.btn-apply-status {
  background-color: #2561a8;
  border-radius: 2px;
  min-width: 73px;
  height: 29px;
  color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  margin-right: 9px;
  cursor: pointer;
}
.btn-cancel-status {
  background-color: #ffffff;
  border: 0.7px solid #2561a8;
  border-radius: 2px;
  min-width: 73px;
  height: 29px;
  color: #2561a8;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  cursor: pointer;
  text-align: center;
}
.btn-shipment-popup {
  background-color: #ffffff;
  border: 1px solid #2561a8;
  border-radius: 3px;
  min-width: 83px;
  height: 35px;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  text-transform: none;
  cursor: pointer;
}
.btn-shipment-saveNext {
  background-color: #2561a8;
  border-radius: 3px;
  min-width: 165px;
  height: 36px;
  color: #ffffff;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  margin-right: 10px;
  text-transform: none;
  cursor: pointer;
}
.dv-status {
  margin-top: 15px;
  text-align: right;
}
.shipment-table-dropdown {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAA/klEQVR4nL3UzUrDQBTF8V9LQEE3iuIbCL7/wygu/AAXorYu1GIgZFzMHYhK0sTW3tXAzJx/7j1nwo5qhgp7sf6PSqirWJzgKIDbrBqveCigDxzgEPtbgnziLbRThRbL2Ew4k8e5STVY4Dm02yLYxsZ7QE4x/yOkaN3Ko0v8DkAJx4Xs21RYixdcyl2lsvFTKMWBK9HyRMgy7n6D0B/pmZzAcxxb71kTkGudcXWrTyDFhZv40qHolwjf9UEY9iBhhUfZ3LoHsogzqz4I60fSjf5cDki508idPBnh55j3UuJad2BC/F5+Er2dlJryf+sGhAHjNwWV88XXdixkp/UFw/tTyABncXQAAAAASUVORK5CYII=) no-repeat right 8px center !important;
  background-size: 9px !important;
  margin: 0 !important;
  border: 1px solid #d6d3d3 !important;
  border-radius: 4px !important;
  height: auto !important;
  padding: 8px 25px 8px 10px !important;
  font-weight: 700;
}
.arti-check {
  margin-right: 7px;
}
.no-focus .nav-link:focus {
  outline: 0;
}
.step-progress .nav-line {
  position: relative;
  color: #2561a8;
}
/* .step-progress .nav-line::before {
  width: 30px;
  background: #3a91f8;
  height: 2px;
  right: -16px;
  bottom: 20px;
  position: absolute;
  content: "";
} */
.step-progress .nav-tabs .nav-link.active {
  border-bottom: none;
}
.step-progress .nav-tabs .nav-item {
  margin-bottom: 0;
  outline: none;
}

.btn-ref {
  background-color: #ffffff;
  border: 1px solid #2561a8;
  color: #2561a8;
}

.btn-proc {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  color: #ffffff;
  cursor: pointer;
}

.dv-table-paging .react-pagination-js-bootstrap ul {
  margin: auto;
  display: table;
  padding: 10px 0;
  box-shadow: none;
}

.dv-table-paging .react-pagination-js-bootstrap ul li {
  border: none;
}

.dv-table-paging .react-pagination-js-bootstrap .page,
.dv-table-paging .react-pagination-js-bootstrap .pageElli {
  line-height: 25px !important;
  color: #333;
}

.dv-table-paging .react-pagination-js-bootstrap .is-active {
  float: left;
  padding: 0px 10px;
  line-height: 25px;
  color: #fff;
  background-color: #2561a8;
  cursor: default;
  border-radius: 5px;
}

/* Delivered End */

/* order starts */
.invoice-icon-cntr {
  width: 20px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-shoping {
  width: 20px;
}
.order-bag {
  width: 15px;
}
.order-popover-invoice .ant-popover-content {
  margin-left: -15px;
}
.order-popover-invoice.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow,
.order-popover-invoice.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow {
  left: 1px;
}
.shopping-num-invoice {
  color: #4a4a4a;
  font-weight: 700;
  padding: 13px;
  text-align: center;
}
.shopping-num-invoice span {
  font-weight: 400;
  margin-left: 5px;
}
.order-popover-invoice .ant-popover-inner {
  border-radius: 8px 8px 4px 4px;
}
.order-popover-table-big .ant-popover-inner-content {
  width: 560px;
}
.order-popover-table-big .antd-table-order .ant-table-thead > tr > th,
.order-popover-table-big .antd-table-order .ant-table-tbody > tr > td {
  width: 100%;
}
.order-clr-orange {
  color: #ef6b00;
}
.order-grid-butn-green {
  background: #84c242;
  border-color: #84c242;
}
.order-grid-butn-yellow {
  background: #f3bc17;
  border-color: #f3bc17;
}
.order-grid-butn-orange {
  background: #ef6b00;
  border-color: #ef6b00;
}
.order-grid-btn-disable {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.order-status-drpdwn {
  padding: 15px 25px 25px;
}
.order-status-header .ant-table-filter-icon::before {
  width: 0;
  height: 0;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-top: 4px solid #4a4a4a;
  -webkit-transform: none;
          transform: none;
}
.order-status-header .ant-table-filter-icon {
  width: 8px !important;
  left: 60px;
}
.order-popover-butns .ant-popover-inner-content {
  padding: 20px 30px;
}
.order-popover-butns .ant-popover-inner {
  border-radius: 8px;
}
.order-tab-popover p {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 700;
}
.order-tab-popover span {
  font-size: 12px;
  color: #4a4a4a;
}
.pay-done {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  padding: 11px 0;
}
.pay-done p {
  min-width: 170px;
}
.order-tab-popover .pay-done:first-child {
  padding-top: 0;
}
.order-tab-popover .pay-done:last-child {
  border: 0;
  padding-bottom: 0;
}
.credit-card-icon {
  width: 20px;
  margin-right: 7px;
}
.ant-popover-message-title,
.ant-popover-message {
  padding: 0;
}
.no-pay {
  width: 50px;
  margin-bottom: 10px;
  display: inline-block;
}
.no-pay img {
  width: 100%;
}
.order-popover-no-pay .ant-popover-inner-content {
  padding-right: 15px;
  padding-left: 15px;
  width: 270px;
}
.order-popover-no-pay .order-tab-popover {
  text-align: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 5px;
}
.order-popover-no-pay .ant-popover-buttons button:first-child {
  display: none;
}
.order-popover-butns .ant-popover-buttons button:first-child {
  width: 90px;
  border-radius: 5px;
  height: 35px;
  font-size: 13px;
  border: 1px solid #2561a8;
  text-shadow: none;
  box-shadow: none;
  color: #2561a8;
  margin-left: 0;
  text-transform: none;
}
.order-popover-butns .ant-popover-buttons button:last-child {
  width: 150px;
  background-color: #2561a8;
  border-radius: 5px;
  height: 35px;
  font-size: 13px;
  border: 0;
  text-shadow: none;
  box-shadow: none;
  text-transform: none;
}
.order-popover-butns .ant-popover-buttons button[disabled]:last-child {
  opacity: 0.5;
  color: #fff;
}
.order-popover-butns .ant-popover-buttons {
  margin-top: 25px;
}
.order-popover-no-pay .ant-popover-buttons {
  text-align: center;
}
.order-popover-no-pay .ant-popover-buttons button:last-child {
  margin-left: 0;
}
.order-popover-butns p {
  color: #4a4a4a;
  font-weight: 700;
}
.order-popover-butns textarea,
.order-popover-butns input {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 700;
  padding: 12px 16px;
  background: #ecf2f4;
  border-radius: 4px;
  border: 0;
  width: 100%;
  margin: 10px 0 15px;
}
.customaddpop textarea,
.customaddpop input {
  margin: 5px 0 5px;
}
.order-popover-butns textarea {
  height: 130px;
  resize: none;
  display: block;
}
.order-popover-address textarea {
  height: 80px;
}
.order-popover-address .ant-popover-message {
  max-height: 200px;
  overflow: hidden auto;
  padding-right: 10px;
}
.customaddpop .ant-popover-message {
  max-height: inherit;
  overflow: hidden;
}
.order-popover-address .ant-popover-inner-content {
  padding-right: 20px;
}
.order-popover-butns textarea::-webkit-input-placeholder,
.order-popover-butns input::-webkit-input-placeholder {
  padding: 0;
  font-size: 12px;
  color: #4a4a4a;
}
.order-popover-butns textarea:-ms-input-placeholder,
.order-popover-butns input:-ms-input-placeholder {
  padding: 0;
  font-size: 12px;
  color: #4a4a4a;
}
.order-popover-butns textarea::-ms-input-placeholder,
.order-popover-butns input::-ms-input-placeholder {
  padding: 0;
  font-size: 12px;
  color: #4a4a4a;
}
.order-popover-butns textarea::placeholder,
.order-popover-butns input::placeholder {
  padding: 0;
  font-size: 12px;
  color: #4a4a4a;
}
.popover-input-cntr > div:last-child textarea,
.popover-input-cntr > div:last-child input {
  margin-bottom: 0;
}
/* .order-popover-address .ant-popover-inner {
  width: 400px;
} */
.non-deliverable {
  color: red !important;
  font-weight: 400 !important;
  margin-bottom: 0px;
  line-height: 1;
  margin-top: 15px;
  text-align: center;
}
.popover-radio-cntr label {
  color: #4a4a4a;
}
.popover-radio-cntr input + label::before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAAXNSR0IArs4c6QAAAF1QTFRFAAAAgID/ImKqI2GrIWCrIl+qIl+qQGC/I1+rIl+rIV+qIl+sImCqImGrImKtImWuK2qq////I2CrIl+qI2GqIWCqIl+rIV+sIV+qI2KtIWCqJ2KxImCrIV+rIWGqiVQkZwAAAB90Uk5TAAI8jL/Z8wh26f9T4olEJgwBhcxCqPtr7kHYDfKLbKh9o7wAAADNSURBVCiRhZPZFoMgDETjAuoIKO5L2///zNJarVVp8gaXkwzJhGiNIIxiIaWIozCgYyRphi2yNPmluQKENkVZFkYLQOU7aCugbr7npgYqu9EWXW9p/77v0K43FYbxqGUcUH3qojtRxzu86ycK/ZkS9VAv/Slqe4VtjdR1I0NzRZ1+ZAGFmK4pkUBIEbQPa0QUw/iwQewyFD5cQJBE6cMlpMOzD8+4cckZaX8/dufawjSVGYl3oI9loIwdODNxVmSMTMsaTL41IG6JyLOCT1YgDYEquiYWAAAAAElFTkSuQmCC) no-repeat center left;
  background-size: 15px;
  width: 15px;
  display: inline-block;
  height: 16px;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  margin-right: 7px;
}
.popover-radio-cntr input:checked + label::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAAXNSR0IArs4c6QAAAHhQTFRFAAAAgID/ImKqI2GrIWCrIl+qIl+qQGC/I1+rIl+rIV+qIl+sImCqImGrImKtImWuK2qq////I2CrIl+qI2GqIWCqIl+rIV+sIV+qI2KtJmOrJmKpJmKpJmKpKWesJWKpJWGoJmSrJmKoIWCqJ2KxImCrIV+rIWGqOKJDuAAAACh0Uk5TAAI8jL/Z8wh26f9T4olEJgwBhcxCqPtr7kFDmtjzJcD/Pe7YDfKLbBluuFUAAAD+SURBVCiRhZPXtoJADEVDGcqRDoJKtd37/39oIoo4a+GcF2ayIQkpRG9ZtuMqz1OuY1ukyw9CLAoD/5vuIkDFSZplaRIrINqtYF4AZfW5VyVQ5Avdo25yWr/f1Ni/LQUORz2X4wHFKy7qmZ7aru+79jTzGs/4foRGnsM4vTQOcm8QSf4BSolyvkyLLmeJXyLgaoSQnIcVZS7fVwgtsnEVV+P0pVFsCjY5iCWrSZPkF8MhFwmfWh23bEzgsoeUT52OOzamUOQh41Ov456NGTzGty18w93k3JDazx/7M5XFUFRDSzYb+j831DAOpmEyjaJhkGleg+vWGpBpiWhjBR9enS+1n5NROQAAAABJRU5ErkJggg==);
}
.popover-radio-cntr {
  /* display: flex;
  align-items: center; */
  /* margin-top: 10px; */
  margin-top: 15px;
  margin-bottom: 10px;
}
.popover-radio-cntr > div {
  /* margin-right: 25px; */
  margin-bottom: 5px;
}
.popover-radio-cntr > div:last-child {
  /* margin-right: 0px; */
  margin-bottom: 0;
}
.antd-table-order .ant-table-row-expand-icon {
  display: none !important;
}
.order-popover-table .ant-table-thead > tr > th:nth-child(2),
.order-popover-table .ant-table-tbody > tr > td:nth-child(2) {
  width: 150px !important;
}
.order-bill-no {
  max-width: 100px;
}
/* order ends */

.shipment-table-popup .ant-table-row-cell-last {
  width: 165px !important;
}
.shipment-table-popup .ant-table-row-cell-last .ant-table-header-column {
  width: 100%;
}
.shipment-status-popover .username-mar {
  flex: 0 0 40%;
}
.shipment-status-popover div {
  margin-bottom: 5px;
}
.shipment-status-popover div:last-child {
  margin-bottom: 0px;
}

.setting-txt {
  float: right;
  margin-left: auto !important;
  width: 200px !important;
}

/* chetan css ends */

/* responsive starts */
.assign-grid-btn {
  margin-right: 25px;
}

@media all and (max-width: 576px) {
  /* chetan css starts */

  .shipment-table-popup .ant-table-row-cell-last {
    width: 155px !important;
  }

  /* order starts */
  .order-search,
  .orders-tabs-outer ul,
  .order-desktop {
    display: none;
  }
  .order-mobile {
    display: table-cell;
  }
  .order-mobile-dropdown {
    width: 160px;
    display: block;
  }
  .order-mobile-dropdown-menu .nav-link {
    font-size: 13px;
    color: #000;
    padding: 9px 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
    font-weight: 400;
  }
  .order-mobile-dropdown-menu .nav-link.active {
    color: #2561a8;
  }
  .order-mobile-dropdown-menu .ant-select-dropdown-menu-item-selected,
  .order-mobile-dropdown-menu
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .order-mobile-dropdown-menu
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #fff;
  }
  .order-mobile-dropdown-menu
    .ant-select-dropdown-menu-item:last-child
    .nav-link {
    border-bottom: 0;
  }
  .order-mobile-dropdown-menu .ant-select-dropdown-menu-item:last-child {
    background: #fff !important;
  }
  .order-mobile-dropdown-menu .ant-select-dropdown-menu-item {
    padding: 0 13px !important;
  }
  .order-mobile-dropdown .nav-link {
    font-weight: 700;
    font-size: 14px;
    color: #2561a8;
    padding: 0;
    padding-right: 2px;
    margin: 10px 0;
  }
  .order-mobile-dropdown .nav-link::after {
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-left: 10px;
    margin-bottom: 2px;
  }
  .order-mobile-dropdown .ant-select-selection__rendered {
    margin: 0 !important;
  }
  .order-mobile-dropdown .ant-select-selection {
    border: 0 !important;
    box-shadow: none;
    background: #f5f8f9;
  }
  .order-mobile-dropdown-menu .ant-select-dropdown-menu {
    /* padding: 0; */
    border-radius: 5px;
    max-height: 300px;
  }
  .order-mobile-dropdown-menu {
    border-radius: 5px;
  }
  .order-mobile-dropdown-menu::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    bottom: 100%;
    left: 25px;
  }
  .orders-tabs-outer {
    padding: 4px 15px;
    background: #f5f8f9;
  }
  .order-mobile-dropdown-menu .ant-select-dropdown-menu-item-active .nav-link {
    color: #2561a8;
  }
  .orders-tab-cont {
    padding: 0;
    padding-bottom: 15px;
  }
  .custom-antd-table.antd-table-campaign.antd-table-order
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
    width: 100px;
  }
  #delivered-tab
    .custom-antd-table.antd-table-campaign.antd-table-order
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
    width: auto;
  }
  #shipment-assigned-tab
    .custom-antd-table.antd-table-campaign.antd-table-order
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
    width: auto;
  }
  #delivered-tab .antd-table-order .ant-table-tbody > tr > td {
    width: auto;
  }
  #shipment-assigned-tab .antd-table-order .ant-table-tbody > tr > td {
    width: auto;
  }
  #delivered-tab .antd-table-order .ant-table-row-expand-icon {
    margin-left: 10px;
  }
  .antd-table-order .ant-table-tbody > tr > td {
    padding: 15px 5px !important;
    width: 90px;
    font-size: 12px;
    color: #797979;
  }
  .antd-table-order .ant-table-row-expand-icon {
    display: block !important;
  }
  .antd-table-order .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px;
    padding-left: 15px !important;
  }
  .antd-table-order .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0px;
    padding-right: 15px !important;
  }
  .orders-tab-cont .table-cntr,
  .antd-table-order .ant-table table {
    border-radius: 0;
  }
  .antd-table-order-mobile .ant-table-tbody > tr > td:last-child {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    /* justify-content: flex-end; */
    padding-right: 15px !important;
  }
  .antd-table-order .ant-table-tbody > tr > td:first-child {
    padding-left: 15px !important;
    color: #797979;
  }
  .antd-table-order .ant-table-row-collapsed::after,
  .antd-table-order .ant-table-row-expanded::after {
    position: static;
    -webkit-transform: none;
            transform: none;
    display: inline-block;
    margin-top: 0px;
    margin-left: 5px;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }
  .order-expanded-title {
    font-weight: 700;
    margin-bottom: 4px;
  }
  .order-expanded-cntr {
    padding: 12px 15px;
    color: #4a4a4a;
    background: #fff;
    white-space: normal;
    white-space: initial;
  }
  .order-expanded-cntr .row div {
    margin-bottom: 17px;
  }
  .order-expanded-cntr .row div:last-child,
  .order-expanded-cntr .row div:nth-last-child(2) {
    margin-bottom: 5px;
  }
  .order-small-font {
    font-size: 12px;
  }
  .order-popover-table .ant-popover-inner,
  .order-popover-table .ant-popover-inner-content {
    width: 100%;
  }
  .order-popover-invoice .ant-popover-content {
    margin-left: 0;
  }
  .order-popover-table .ant-popover-content {
    margin: 0 10px;
  }
  .order-popover-invoice.ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow,
  .order-popover-invoice.ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow {
    left: 109px;
  }
  .shopping-num-invoice span {
    margin-left: 0;
  }
  .order-popover-table .ant-table-thead > tr > th {
    height: 50px !important;
  }
  .order-popover-table
    .custom-antd-table.antd-table-order
    .ant-table-tbody
    > tr
    > td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .order-popover-address
    .popover-input-cntr
    > div:last-child
    div:first-child
    textarea,
  .order-popover-address
    .popover-input-cntr
    > div:last-child
    div:first-child
    input {
    margin-bottom: 15px;
  }
  .order-popover-address .ant-popover-inner {
    width: 340px;
  }
  .order-popover-table-big.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -17px;
  }
  .order-bill-no {
    max-width: 100px;
  }
  /* order ends */

  /* shopping bag starts */
  .order-popover-butns .ant-popover-inner-content {
    padding: 15px 20px;
  }
  .shopping-bag-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -45px;
  }
  /* shopping bag ends */

  /* returns starts */
  .retry-butn {
    min-width: 80px;
  }
  .return-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -75px;
  }
  /* returns ends */

  /* chetan css ends */
  .deliv-grid-butn {
    width: auto;
    height: 22px;
    font-size: 10px;
    min-width: auto;
    padding: 0 5px !important;
  }
  .assign-grid-btn {
    margin-right: 5px;
  }
  .order-grid-butn {
    padding: 0px 5px !important;
    height: 22px;
    font-size: 10px;
    min-width: auto !important;
  }

  .ship-mar-bot {
    margin-bottom: 15px;
  }
}

@media all and (max-width: 400px) {
  /* chetan css starts */

  /* order starts */
  .order-popover-table .ant-table-thead > tr > th:nth-child(2),
  .order-popover-table .ant-table-tbody > tr > td:nth-child(2) {
    width: auto !important;
  }
  .pay-done p {
    min-width: 120px;
  }
  .order-popover-address .ant-popover-inner,
  .order-popover-address {
    width: 100%;
  }
  .order-popover-address .ant-popover-content {
    margin: 0 10px;
  }
  .order-popover-address.ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow,
  .order-popover-address.ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow {
    left: 100px;
  }
  .order-popover-table.order-popover-table-big
    .ant-table-thead
    > tr
    > th:nth-child(2),
  .order-popover-table.order-popover-table-big
    .ant-table-tbody
    > tr
    > td:nth-child(2) {
    width: 120px !important;
  }
  .order-popover-table-big.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -14px;
  }
  /* order ends */

  /* shopping bag starts  */
  .order-popover-butns .ant-popover-buttons button:first-child {
    min-width: auto;
    width: 80px;
  }
  .order-popover-butns .ant-popover-buttons button:last-child {
    width: 120px;
  }
  .shopping-bag-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -40px;
  }
  /* shopping bag ends  */

  /* returns starts */
  .retry-butn {
    min-width: 44px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .return-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -60px;
  }
  /* returns ends */

  /* chetan css ends */
}

@media all and (max-width: 359px) {
  /* chetan css starts */

  /* order starts */
  .antd-table-order .ant-table-thead > tr:first-child > th:first-child,
  .antd-table-order .ant-table-tbody > tr > td:first-child {
    padding-left: 10px !important;
  }
  .antd-table-order .ant-table-thead > tr:first-child > th:last-child,
  .antd-table-order .ant-table-tbody > tr > td:last-child {
    padding-right: 10px !important;
  }
  .antd-table-order .ant-table-tbody > tr > td {
    font-size: 12px;
    color: #797979;
  }
  .order-grid-butn {
    min-width: 95px;
    padding-right: 5px !important;
    padding-left: 5px !important;
    font-size: 11px;
  }
  .invoice-icon-cntr {
    width: 17px;
  }
  .order-shoping {
    width: 17px;
  }
  .order-bag {
    width: 13px;
  }
  .order-info {
    width: 14px;
  }
  .order-hamb {
    width: 23px;
  }
  .antd-table-order .ant-table-row-collapsed::after,
  .antd-table-order .ant-table-row-expanded::after {
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
    margin-top: -2px;
  }
  .orders-tabs-outer {
    padding: 4px 10px;
  }
  .order-small-font {
    font-size: 11px;
  }
  .order-expanded-title {
    margin-bottom: 2px;
  }
  .order-expanded-cntr .row div {
    margin-bottom: 12px;
  }
  .order-expanded-cntr .row div:last-child,
  .order-expanded-cntr .row div:nth-last-child(2) {
    margin-bottom: 0px;
  }
  .order-expanded-cntr {
    padding-right: 10px;
    padding-left: 10px;
  }
  .order-popover-invoice.ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow,
  .order-popover-invoice.ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow {
    left: 96px;
  }
  .order-popover-address .ant-popover-message {
    max-height: 180px;
  }
  .order-popover-address .ant-popover-inner-content {
    padding-right: 15px;
    padding-left: 22px;
  }
  .order-popover-table.order-popover-table-big
    .ant-table-thead
    > tr
    > th:nth-child(2),
  .order-popover-table.order-popover-table-big
    .ant-table-tbody
    > tr
    > td:nth-child(2) {
    width: 100px !important;
  }
  /* order ends */

  /* shopping bag starts */
  .order-del-butn {
    padding-right: 6px !important;
    padding-left: 6px !important;
    margin-left: 5px;
  }
  .order-del-butn img {
    width: 11px;
  }
  .shopping-popover-delete.ant-popover-placement-bottomRight
    > .ant-popover-content
    > .ant-popover-arrow,
  .shopping-popover-delete.ant-popover-placement-topRight
    > .ant-popover-content
    > .ant-popover-arrow {
    right: 6px;
  }
  .shopping-popover-cancel {
    width: 250px;
  }
  .shipment-popover-cancel {
    width: 280px;
  }
  .shopping-bag-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -27px;
  }
  /* shopping bag ends */

  /* returns starts */
  .return-popover-item.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow {
    margin-left: -50px;
  }
  /* returns ends */

  /* chetan css ends */
}

/* responsive ends */
.ordMainhtml{
  background: #ffff;
  border-radius: 0px;
  border: 0.5px solid #1c1a1a;
  margin-top: 5Px;
  padding: 15px;
  width: 80%;
  margin-left: 80px;
}
.ord2tbl{
  background: #ffff;
  border-radius: 0px;
  border: 0.5px solid #1c1a1a;
  padding: 20px 0px 20px 25px;
}
.ord3tbl{
  background: #ffff;
  border-radius: 0px;
  border: 0.5px solid #1c1a1a;
  padding: 0px 0px 0px 25px;
  margin-top: 18px;
}
.ord4tbl {
  background: #ffff;
  border-radius: 0px;
  border: 0.5px solid #1c1a1a;
  margin-top: 18px;
}
.ordWidth{
  width: 100%;
}
.ordRow{
  display: flex;
  flex-wrap: wrap;
}
.ordMl{
  margin-left: 3px;
}
.ordShipdt{
  display: contents;
  font-weight: 700;
}
.ordMtb{
  margin-top: 15px;
  margin-bottom: 15px;
}
.ordMtb label{
  font-weight: 700;
}
label p {
  display: contents;
}
.ordLeftMb{
  margin-bottom: 8px;
}
.ord3tbl label {
  display: block;
  font-weight: 500;
}
.ordFw{
  font-weight: 800 !important;
}
.ord4tbl table thead tr {
  background-color: #fff !important;
}
.ord4tbl table tr th:last-child {
  float: none;
  text-align: center;
}
.ord4tbl table tbody tr {
  border-bottom: 1px solid #000;
}
.ord4tbl .table-bordered td, .table-bordered th {
  border: 1px solid rgb(19, 3, 3);
}
.ord4tbl .table-bordered th {
  text-align: center;
  font-weight: bold;
  color: #000;
}
.ord4tbl .table-bordered td {
  color: #000;
  font-weight: 500;
}
.ord4tbl .table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 0;
}
.ord4tbl table td,
.ord4tbl table th {
  padding: 10px 15px;
}
.ord4tbl table tbody tr td:nth-child(3) {
  text-align: right;
}
.ord4tbl table tbody tr td:nth-child(4) {
  text-align: center;
}
.ordShipLabel{
  display: block;
}
