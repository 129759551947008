p {
  margin-bottom: 0;
}
.cr-pnt {
  cursor: pointer;
}
a {
  cursor: pointer;
}
.card-Nav {
  border-radius: 0;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  /* margin-bottom: 30px; */
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: white;
}
.rectangle-nav-header {
  background-color: #ffffff;
  width: 1260px;
  height: 57px;
}
.dashboards {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.btnClickDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.cls-hide {
  display: none;
}
.my-tickets {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.claim-space {
  margin-top: -2.9%;
  margin-left: 41%;
}
.claim-label {
  margin-left: 333%;
}
.claim-logo {
  width: 16px;
}
.knowledge-base {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.bitmap {
  background-color: #4a4a4a;
  width: 26px;
  height: 26px;
}
.bitmap {
  width: 25px;
  height: 25px;
}
.oval-4 {
  background-color: #ffb629;
  border-radius: 100%;
  width: 19px;
  height: 19px;
}
.card .card-header1 {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
  /* padding: 3px 0px; */
}
.main-header {
  width: 5%;
  background: "#2561A8";
  color: "#FFFFFF";
  /* padding-left: '1.5%' */
}
.rectangle-2 {
  background-color: #2561a8;
  width: 63px;
  height: 57px;
}
.rectangle-1 {
  background-color: #ffffff;
  width: auto;
  height: 57px;
  margin: 20px;
}
.card-row {
  height: 55px;
}
.er {
  height: 58px; /* 60px; */
  flex: 0 0 60px;
  background: #2561a8;
  color: #fff;
  line-height: 58px;
  text-align: center;
}
.bell-icon {
  flex: 0 0 85px;
  background: #fff;
  border-right: 1px solid #e9e9e9;
  line-height: 53px;
}
.bell-icon img {
  width: 50px;
  cursor: pointer;
}
.hamb-menu {
  width: 20px;
  margin-top: 17px;
  float: none;
  display: none;
}
.hamb-menu img {
  width: 100%;
}
.er-label {
  font-size: 17px;
  font-weight: 700;
}
.headers-menu .active {
  font-weight: 700;
  color: #2561a8;
}
.header-icons-cntr img:first-child {
  display: block;
}
.header-icons-cntr img:last-child {
  display: none;
}
.headers-menu .active .header-icons-cntr img:first-child {
  display: none;
}
.headers-menu .active .header-icons-cntr img:last-child {
  display: block;
}
.headers-menu {
  display: flex;
  align-items: center;
  max-width: calc(100vw - 324px);
  overflow: auto;
  white-space: nowrap;
  flex: 1 0 100%;
}
/* .ordermainrow .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  width: auto !important;
}
.ordermainrow .ReactTable .rt-tbody .rt-td {
  width: auto !important;
} */

.ordermainrow .rt-table::-webkit-scrollbar {
  height: 5px;
}
.ordermainrow .rt-table::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.ordermainrow .rt-table::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.ordermainrow .rt-table::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.headers-menu::-webkit-scrollbar {
  height: 5px;
}
.headers-menu::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.headers-menu::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.headers-menu::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.headers-menu a {
  color: #4a4a4a;
  line-height: 1;
  font-size: 16px;
}
.header-icons-cntr {
  line-height: 1;
  margin-right: 8px;
}
.single-menu {
  display: flex;
  align-items: center;
  margin-left: 45px;
}
.headers-menu .single-menu:first-child {
  margin-left: 30px;
}
.rectangle-2 {
  background-color: #2561a8;
  width: 63px;
  height: 57px;
}
.dashboards-header {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  width: 11%;
}
.campaign-logo {
  width: 18px;
}
.my-tickets {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  width: 10%;
}
.knowledge-base {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.header-right-icons {
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.header-right-icons a {
  display: inline-block;
  color: #4a4a4a;
  margin-right: 20px;
  line-height: 1;
}
.icon-fullname {
  margin-left: 6px;
  margin-right: 25px;
}
.bitmap {
  background-color: #2561a8;
  width: 21px;
  height: 21px;
}
.bitmap1 {
  width: 26px;
  height: 26px;
  margin-top: 15px;
}
.bitmap2 {
  width: 26px;
  height: 26px;
  margin-top: -23px;
  margin-left: 40px;
}
.bitmap3 {
  width: 24px;
  height: 24px;
  margin-top: -29px;
  margin-left: 80px;
}
.bitmap4 {
  width: 24px;
  height: 24px;
  margin-top: -23px;
  margin-left: 116px;
}
.bitmap5 {
  width: 30px;
  height: 30px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 10px;
  line-height: 27px !important;
  text-align: center;
  font-weight: 600;
}
.main-nav {
  margin-left: 17.5%;
}
.sign-in {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  padding-top: 10px;
  display: block;
  margin-bottom: 15px;
  /* margin-bottom: 30px; */
}
.dashboardImg1 {
  width: 21px;
}
.stores-icon {
  width: 24px;
}
.myTicket {
  width: 19px;
}
.order-blue {
  width: 18px;
}
.knowledgeNav {
  width: 19px;
}
.calImg {
  width: 19px;
}
.chatImg {
  width: 26px;
  cursor: pointer;
}
.notifi {
  width: 18px;
  cursor: pointer;
}
.setting {
  width: 21px;
  cursor: pointer;
}
.dashboards-label1 {
  font-size: 16px;
  vertical-align: middle;
  padding-left: 3px;
  /* margin-top: -117%;
  margin-left: 100px; */
}
.myTiket-label {
  font-size: 16px;
}
.nav-tab1 {
  margin-top: -3%;
  margin-left: 25%;
  position: relative;
  z-index: 1;
}
.nav-tab2 {
  margin-top: -3.5%;
  margin-left: 455px;
}
.nav-tab3 {
  margin-top: -41px;
  margin-left: 68px;
  position: relative;
  z-index: 1;
}
.ticket-label {
  font-size: 18px;
  vertical-align: -webkit-baseline-middle;
  margin-top: -47px;
  margin-left: 23px;
}
.knowledge-label {
  font-size: 18px;
  vertical-align: -webkit-baseline-middle;
  margin-top: -5px;
}
/***** For Model *****/
.Model {
  position: absolute;
  top: 30%;
  left: 65%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 536px;
  height: 220px;
}
.new-tickets-assigned {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 50px;
  width: 243px;
  text-align: left;
  padding-left: 15px;
}
.view-tickets {
  color: #2561a8;
  font-family: Lato;
  /* font-size: 16px; */
  font-size: 14px;
  /* font-weight: 600; */
  line-height: 50px;
  width: 121px;
  text-align: left;
  cursor: pointer;
}
.upper-noti-count {
  position: absolute;
  background-color: #ffb629;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  top: -13px;
  right: -10px;
}
.rectangle-2 {
  background-color: #2561a8;
  width: 33px;
  height: 27px;
}
.lable05 {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-top: 12px;
}
.rowpadding {
  padding-left: 25px;
}
.viewticketspeadding {
  padding-left: 30px;
}
.labledata {
  padding-left: 9px;
  padding-top: 5px;
}
/************My Ticket Header******************/
.headphone {
  width: 25px;
  height: 27px;
  margin-right: 9px;
  vertical-align: initial;
}
.headphonered {
  width: 23px;
  height: 23px;
  margin-right: 9px;
  cursor: pointer;
}
.loading-rectangle {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 6px;
  width: 23px;
  height: 25px;
  padding: 5px;
  margin-top: 7px;
  margin-left: 7px;
  vertical-align: top;
  cursor: pointer;
}
.loading-rectangle-cntr {
  display: inline-block;
  position: relative;
  top: -15px;
  margin-left: 10px;
  cursor: pointer;
}
.oval-55 {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-top: -3px;
}
.naman {
  margin-top: 8px;
}
.oval-head {
  margin-top: -7px;
  margin-left: 700px;
}
.naman-r {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
}
.down-header {
  width: 20px;
  height: 7px;
  padding-left: 8px;
  cursor: pointer;
}
.head-header {
  background-color: #ecf2f4;
  width: auto;
  height: auto;
}
.head-header-1 {
  padding: 7px 15px;
}
.id-abc-1234 {
  color: #4a4a4a;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
}
.updated-2-d-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
}
.lable-name {
  font-weight: bold;
  color: black;
  padding-left: 4px;
}
#HeadePhone-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 10px;
  margin-top: 45px;
  /* margin-bottom: 4px; */
  display: inline-block;
}
#labelmodel-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 150px;
  margin-top: 45px;
  width: 450px;
  /* width: 400px; */
  /* margin-bottom: 4px; */
  display: inline-block;
}
.myTicket-table table td {
  font-size: 16px;
  color: #5f5d5d;
  padding: 10px 15px;
}
.myTicket-table table th {
  font-size: 13px;
  padding: 10px 15px;
  color: #9b9b9b;
}
.remov table th {
  background-color: white !important;
}
.myTicket-table table thead tr {
  background-color: #ffffff;
  border-bottom: none;
}
.myTicket-table table tbody tr {
  border-bottom: 1px solid #eeeeee;
  border: none;
}
.btn-outline-primary {
  color: #2561a8;
  background-color: transparent;
  background-image: none;
  border-color: #2561a8;
  width: 190px;
}
.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: -25px;
  transition: all 0.3s ease-in-out;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.button-margin {
  /* margin-left: 32px; */
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
.myTicket-table table tr td:last-child,
table tr th:last-child {
  text-align: right;
  /* float: left; */
}
/****************My Ticket********************/

.right-icon {
  width: 19px;
  height: 19px;
  margin-left: 5px;
  margin-top: 3px;
}
.new-top-bottom-margin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.myticket-submit-solve-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 222px;
  height: 43px;
  margin-left: 35px;
  cursor: pointer;
  color: #ffff;
}

.myticket-submit-solve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 12px;
  cursor: pointer;
}
.rectangle-3 .top-margin {
  margin-top: 20px;
}

.rectangle-3 .message-header {
  margin-top: 15px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.main-content1 {
  padding: 0px 20px;
}
.rectangle-box {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: auto;
  height: auto;
  margin: 15px;
}
.rectangle2 {
  background-color: #ecf2f4;
  width: 1280px;
  height: 57px;
}
.rectangle-3 {
  background-color: #ffffff;
  border-radius: 4px;
  width: auto;
  height: auto;
  /* margin: 32px;
  padding: 30px; */
}
.text-editor .row {
  margin: 20px 0 0 0;
}
.card-rectangle {
  background: #f5f8f9;
  padding: 1px;
}
.ticket-title-where {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 3px;
}
.ticket-details-wher {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: 1227px;
  text-align: left;
}
.card .card-header-1 {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}
.messages-04 {
  color: #2561a8;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  cursor: pointer;
}
.msg-row {
  padding-top: 23px;
}
.notes-00 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -46px;
  cursor: pointer;
}
.task-03 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -122px;
  cursor: pointer;
}
.claim-00 {
  color: #4a4a4a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
  cursor: pointer;
}

.claim-00-1 {
  color: #2561a8;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
  cursor: pointer;
}
.line-4 {
  text-align: center;
  display: block;
}
/***************ToDAY 02*******************/
.oval-5 {
  background-color: #7ed321;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.solved-by-naman-r {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;

  margin-top: 5px;
}
.i-have-solved-this-i {
  color: #4a4a4a;
  font-family: unset;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.smg-Img1 {
  width: 22px;
  height: 16px;
  /* margin: 0 0 0 88px; */
  margin-left: auto;
  margin-right: 20px !important;
}
.up {
  width: 18px;
  height: 18px;
}
.up-1 {
  width: 22px;
  height: 18px;
  margin: 0px;
  margin-left: 11%;
  padding: 4px;
  cursor: pointer;
}
.comment {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.rectangle-4 {
  background-color: #fafafa;
  width: 693px;
  height: 253px;
}
.rectangle-44 {
  background-color: #ffffff;
  width: 563px;
  height: 206px;
}
.btn-submit {
  margin-top: -6px;
  padding-left: 8px;
}
.btn-primary1 {
  color: #fff;
  background-color: #2561a8;
  border-color: #2561a8;
  margin-left: 3.7px;
}
.dropdown-menu.show {
  display: block;
}
.mobile-number {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 2px;
  text-align: left;
}
.mobile-no {
  color: #000000;
  font-family: inherit;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  padding-right: 10px;
}
.eyeImg {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: auto;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.eyeImg1 {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: 22px;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.searchImg-2 {
  width: 20px;
  height: 20px;
  margin-left: -32px;
}
.billImg {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: auto;
  height: 20px;
  padding: 4px;
  cursor: pointer;
}
.bill-1 {
  margin-top: -25px;
  padding-left: 155px;
}
.target-closure-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.Date-target {
  color: #4a4a4a;
  font-family: unset;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.vl {
  border-left: 2px solid #f1f1f1;
  height: 158px;
  margin-top: -1.3%;
  margin-left: 10px;
}
.v2 {
  border-left: 2px solid #f1f1f1;
  height: 158px;
  margin-top: -137px;
  margin-left: 556px;
}
.v3 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
}
.v4 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
  margin-left: -8.5%;
}
.v5 {
  border: 1px solid #dfdfdf;
  width: 104%;
  margin-top: 2.6%;
}
.v6 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 2.6%;
  margin-left: -8.5%;
}
.oval-loading {
  background-color: #f5a623;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  vertical-align: top;
  padding: 7px;
}
.reassign-to-naman-r {
  color: #4a4a4a;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.today-02 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 70px; */
}
.yesterday-02 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 67px; */
}
.rashmi-c {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 7px;
}
.label-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.card-space-1 {
  padding-top: 20px;
  padding-bottom: 5px;
}
.hi-naman-please-hel {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.hi-diwakar-i-really2 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 650px;
  text-align: left;
  display: contents;
}
.comment-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.comment-text1 {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  float: right;
  cursor: pointer;
}
.label-2 {
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 15px;
  padding-left: 3px;
  color: #5c5c5c;
}
.label-3 {
  margin-top: 0;
  font-size: 16px;
  padding-left: 3px;
  line-height: initial;
  padding-bottom: 22px;
}
.mail-card {
  background-color: #ecf2f4;
  width: auto;
  height: 54px;
}
.mask1 {
  background-color: #ecf2f4;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  height: auto;
}
.mail-mask {
  border-radius: 5px;
  height: auto;
  margin: 4px;
  position: relative;
  width: 100%;
  display: inline-block;
}
.email {
  color: #000000;
  font-family: inherit;
  font-size: 16px;
  padding-right: 46px;
}
.down-1 {
  width: 16px;
  height: 22px;
}
.arrow-img {
  width: 15px;
  height: 15px;
  margin: 5px 15px 0;
}
.plush-img {
  width: 15px;
  height: 15px;
  margin: 5px 15px 0;
  cursor: pointer;
}
.minus-img {
  width: 15px;
  height: 2px;
  margin: 5px 15px 0;
  cursor: pointer;
}
.line-1 {
  border: 1px solid #cad4d7;
  width: 1px;
  height: 40px;
  position: absolute;
  top: -4px;
  right: 42px;
}
.label-4 {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: inherit;
  margin-bottom: 5px;
  text-align: left;
}
.rectangle-9 {
  border-radius: 4px;
  width: 100%;
  height: 31px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url("../Images/dropDown.png") no-repeat right 12px center;
  background-size: 13px;
  cursor: pointer;
  border: none;
}
.mid-sec {
  padding: 30px;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  height: 100%;
}
.mid-secnew {
  padding: 15px 30px 0px 30px;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  height: 100%;
}
.drop-card {
  margin-top: -15px;
  padding-left: 20px;
}
.data-store {
  margin-top: -24.4%;
  margin-left: 97%;
}
.bata-rajouri-garden {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  white-space: normal;
  cursor: pointer;
}
.storeSpacing {
  padding-left: 0;
  padding-top: 0;
  display: inherit;
}
.pencilImg {
  width: 13px;
  height: 13px;
  margin-top: -10px;
  cursor: pointer;
}
.card-details {
  background-color: #fafafa;
  width: auto;
  /* height: 253px; */
}
.card-details-1 {
  background-color: #ffffff;
  width: auto;
  /* height: 195px; */
  margin: 29px;
  margin-left: 58px;
  padding: 23px;
  padding-left: 47px;
  padding-right: 39px;
}
.card-op-out .card .card-block,
.card-op-out .card .card-body {
  padding: 0;
}
.card-op-out .collapse {
  width: 100%;
}
.card-op-out .card-details {
  overflow: auto;
}
.label-5 {
  color: #a5a49b;
  font-size: 14px;
}
.oval-56 {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 6px;
}
.row-spacing {
  margin-top: 22px;
}
.facebook {
  width: 25px;
  height: 25px;
  float: right;
}
.clip {
  width: 22px;
  height: 22px;
}
.oval-6 {
  background-color: #50e3c2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 4px;
}
.oval-7 {
  background-color: #e63939;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 4px;
}
.headphone1 {
  width: 25px;
  height: 28px;
  float: right;
}
.need-to-change-my-sh {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.reply-comment {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
  /* float: right; */
  display: block;
  cursor: pointer;
}
.inlineGridTicket {
  display: inline-grid;
  float: right;
}
.btn-primary1 {
  background-color: #2561a8;
  margin-left: 15px;
  margin-top: -9px;
}
.btn-primary2 {
  background-color: #2561a8;
  margin-left: 119px;
  margin-top: -48px;
  color: white;
}
.down-white {
  width: 20px;
  height: 14px;
  padding: 3px;
  margin-left: 15px;
}
/*****************Ticket System (Deepak)************************/
.rectanglesystem {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.tablepadding {
  margin-top: 15px;
}

.mask {
  background-color: #ffffff;
  border-radius: 4.5px;
  width: auto;
  margin: 20px;
}
.bitmapback {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.bitmapheadpone {
  width: 30px;
  height: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.source {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 12px;
}

.a91-9873470074 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 10px;
}
.b91-9873470074 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.save-as-a-draft {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-right: 22px;
  cursor: pointer;
  border: none;
}

.rectanglecreateticket {
  background-color: #2561a8;
  border-radius: 4px;
  width: 145px;
  height: 43px;
  cursor: pointer;
}

.create-ticket {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: auto;
  min-width: 160px;
  text-align: center;
}

.btnMdlSubmit {
  background: #fff;
  border: none;
  height: 0px;
  padding: 0px;
  cursor: pointer;
}

.tdtext {
  padding-top: 15px;
  padding-left: 10px;
}
.tdicon {
  padding-top: 8px;
  padding-left: 10px;
}

.tdtextnew {
  padding: 5px;
  float: right;
  text-align: right;
}

table {
  width: auto;
}
.rectangletd {
  background-color: #fbfbfb;
  width: 591px;
  height: 792px;
}
.ticket-tittle {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 15px;
}

.ticket-tittle-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 43px;
  border: none;
  padding: 10px;
}

.rowmargin {
  margin-left: 10px;
  margin-bottom: 10px;
}

.ticket-details {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
}

.ticket-details-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}

.category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.sub-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 20px;
}

.category-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 245px;
  height: 43px;
  padding-left: 10px;
  border: none;
}
.sub-category-select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 245px;
  height: 43px;
  padding-left: 10px;
  border: none;
  margin-left: 10px;
}

.select-category-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.select-sub-category-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

/* .high-button {
  background-color: #ffffff;
  border: 1px solid #d02139;
  border-radius: 4px;
  width: 58px;
  height: 43px;
} */

/* .high-button-text {
  color: #ba505d;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
} */

/* .medium-button {
  background-color: #f5a623;
  border: 1px solid #f5a623;
  border-radius: 4px;
  width: 78px;
  height: 43px;
} */

/* .medium-button-text {
  color: #f5a623;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */

/* .low-button {
  background-color: #ffffff;
  border: 1px solid #5aa805;
  border-radius: 4px;
  width: 58px;
  height: 43px;
} */

/* .low-button-text {
  color: #61ad0f;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */

.screen-shot-2018-04-17-at-4-39-16-pm {
  width: 18px;
  height: 23px;
}

.sort-column {
  color: #0066cc !important;
  font-weight: bold;
  text-decoration-line: underline;
}
.add-note {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.email-id {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px !important;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.phone-number {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.customer-name {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.alankrit-kumar {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.alankrit-easyrewardz {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.edit-button {
  background-color: #fbfbfb;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 93px;
  height: 31.32px;
  margin-left: 13px;
  margin-top: 10px;
  cursor: pointer;
}

.rectangletd .row button {
  padding: 0px;
}

.mask-ticket-system {
  background-color: #ffffff;
  border-radius: 4.5px;
  width: auto;
  height: auto;
  margin: 20px;
}

.mask-ticket-system .table td {
  border: none;
}

.margin-ticket-system-td {
  margin-top: 15px;
}
.edit-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  line-height: 12px;
  /* width: 23.07px; */
  text-align: left;
  padding-bottom: 5px;
  cursor: pointer;
}
.ticketsystem-line {
  margin-top: 30px;
  border: 1px solid #ededed;
}
.right-rectangle {
  background-color: #ecf2f4;
  width: 77px;
  height: 792px;
}

.customer-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.customer-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  padding-top: 10px;
  cursor: pointer;
}
.order-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.order-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}
.store-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.store-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}

.task-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.task-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}

:-ms-input-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
ticket-details-textarea ::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 5px;
}
.addNote-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 520px;
  height: 123px;
  border: none;
  resize: none;
}

.addNote-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.colmarigin-tab {
  margin-left: 20px;
  margin-right: 40px;
}
.colmarigin-tab .row {
  margin-bottom: 5px;
}

/********************************************/

/* chetan css starts */

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");

p,
label,
h4,
h3 {
  margin: 0;
  padding: 0;
}
ul,
li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
  display: inline-block;
}
a:hover {
  text-decoration: none;
}
input[type="radio"] {
  display: none;
}
/* input:focus {
  outline: none;
} */
/* input[type="text"]:focus {
  border: 1px solid #1890ff;
} */
/* button:focus {
  outline: none;
} */
/* button:focus,
select:focus,
textarea:focus {
  outline: none;
} */
a:focus,
a:hover:focus,
button:focus {
  outline: 1.5px #7aacfe solid;
  /* outline: 1.5px #e5efff solid; */
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
body {
  font-family: "Lato", sans-serif !important;
  background-color: #f5f8f9 !important;
  color: #4a4a4a !important;
}
.chat-left-height::-webkit-scrollbar,
.chat-user-det::-webkit-scrollbar {
  width: 6px;
}
.chat-left-height::-webkit-scrollbar-track,
.chat-user-det::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chat-left-height::-webkit-scrollbar-thumb,
.chat-user-det::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chat-left-height::-webkit-scrollbar-thumb:hover,
.chat-user-det::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.red-clr {
  color: #f2726f;
}
.blue-clr {
  color: #2561a8;
  cursor: pointer;
}
.black-clr {
  color: #4a4a4a;
}
.blak-clr {
  color: #000;
}
.green-clr {
  color: #46780b;
}
.pink-bg {
  background-color: #ffdfdf;
}
.orange-bg {
  background-color: #fff3df;
}
.blue-bg {
  background-color: #def3ff;
}
.fs-14 {
  font-size: 14px;
  font-weight: 500;
}
.fs-12 {
  font-size: 12px;
}
button,
.csv-button {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  color: #2561a8;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 15px;
  min-width: 100px;
  transition: all 0.3s ease-in-out;
}
.butn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.butn-inv {
  background-color: #fff;
  color: #2561a8;
  font-size: 12px;
  padding: 8px 25px;
  border-radius: 50px;
  cursor: pointer;
}

/* dashboard starts */

.dash-cntr .card {
  position: static;
  display: initial;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0;
  border-radius: 0;
}
.dash-cntr .card .card-block,
.dash-cntr .card .card-body {
  padding: 0;
}
.dashboard-collapse-icon {
  width: 36px;
  height: 36px;
  background-color: #c8d8dd;
  border-radius: 50%;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 2px;
  z-index: 1;
}
.dashboard-collapse-icon-inv {
  background-color: #2561a8;
}
.dash-popup {
  position: absolute;
  background-color: #fff;
  right: -12px;
  top: 28px;
  padding: 25px 30px 35px;
  color: #4a4a4a;
  z-index: 1;
  display: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}
.dash-creation-popup {
  width: 370px;
}
.dash-creation-popup-cntr {
  position: relative;
  display: inline-block;
}
.dash-creation-popup-cntr:hover .dash-popup {
  display: block;
}
.dash-creation-popup li {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15px; */
  margin-top: 20px;
}
.dash-creation-popup li.title {
  /* font-size: 12px; */
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  padding-bottom: 5px;
}
.dash-creation-popup li p:first-child {
  flex: 0 0 70%;
}
.dash-creation-popup li p:last-child {
  flex: 0 0 30%;
}
.dash-creation-popup li p {
  /* font-size: 13px; */
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  line-height: 0.8;
}
.dash-category-popup {
  padding: 20px 30px;
  font-size: 14px;
  border-radius: 8px;
  white-space: initial;
}
.dash-category-popup li p:first-child {
  color: #9b9b9b;
}
.dash-category-popup li p {
  white-space: nowrap;
}
.dash-category-popup li {
  margin-bottom: 15px;
  display: block;
}
.dash-category-popup li:last-child {
  margin-bottom: 0px;
}
.dash-task-popup {
  width: 220px;
  padding: 20px 20px 25px;
  transform: translateX(-50%);
  left: 50%;
  top: initial !important;
  bottom: 34px;
}
.dash-task-popup span {
  display: inline;
  font-size: 14px;
}
.task-progress {
  margin-top: 12px;
  background-color: #e6e6e6;
  border-radius: 5px;
  height: 10px;
  overflow: hidden;
}
.task-progress .progress-bar {
  background-color: #b0d288;
}
.task-red-clr {
  color: #d20c24;
}
.nw-chat {
  width: 18px;
  margin-left: 9px;
}
.nw-chat img {
  width: 100%;
}
.dash-top-cards {
  padding: 12px 0px 15px; /*15px 0px 15px;*/
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  min-height: 140px;
}
.dash-top-cards small {
  white-space: nowrap;
}
.card-head {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 20px;
}
.card-value {
  font-size: 40px;
  font-weight: 300;
  display: block;
}
small {
  font-size: 12px;
  font-weight: 700;
  margin-top: -5px;
  display: block;
}
big {
  font-size: 56px;
}
.dash-cntr {
  padding: 15px 0;
  position: relative;
}
.resp-success .card-head {
  text-align: center;
  font-weight: 300;
  margin-bottom: 0px;
}
.aside-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 63px); /* 24 */
}
.aside-cont > div {
  margin: 0 25px;
}
.btm-mar > .row > div {
  margin-bottom: 30px;
}
.table-cntr {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}
table {
  width: 100%;
  font-weight: 700;
  overflow: hidden;
  border-radius: 5px 5px 0px 0;
}
table th {
  font-size: 13px;
  padding: 15px 25px; /*20px 30px;*/
}
table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 18px 23px;
}
table thead img {
  width: 12px;
  margin-left: 3px;
}
table tbody tr {
  border-bottom: 1px solid #eeeeee;
}
table thead tr {
  background-color: #e5e9ed;
  border-bottom: 1px solid #eeeeee;
}
table span {
  font-size: 10px;
}
table td span {
  display: block;
}
.scroll-table {
  overflow: auto;
}
.mobile-table {
  /* border-radius: 5px 5px 0 0;
  overflow: hidden; */
  display: none;
}
.table-cont {
  display: flex;
  font-size: 13px;
  color: #9b9b9b;
  font-weight: 700;
  padding-bottom: 15px;
}
.table-row .table-cont:last-child {
  padding-bottom: 0;
}
.tab-subhead {
  display: block;
  font-size: 10px;
}
.table-cont > p:first-child {
  font-size: 12px;
  color: #4a4a4a;
  flex: 0 0 100px;
}
.table-row {
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
}
.scroll-table table {
  white-space: nowrap;
}
.scroll-table::-webkit-scrollbar {
  height: 7px;
}
.scroll-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.scroll-table::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.scroll-table::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.one-liner label {
  max-width: calc(100% - 20px);
}
.one-liner {
  display: flex;
  align-items: flex-start;
}
.one-liner .info-icon {
  margin-top: 4px;
}
.one-liner .info-icon-cp {
  margin-top: 5px;
}
.priority-one-liner span {
  display: flex;
  align-items: flex-start;
}
.priority-one-liner .info-icon-cp {
  margin-top: 4px;
}
.store-one-liner .info-icon-cp {
  margin-top: 3px;
}
.info-icon {
  width: 17px;
  position: relative;
  left: 2px;
  bottom: 1px;
  margin-left: 3px;
  cursor: pointer;
}
.info-iconcus {
  float: right;
  margin-top: 3px;
}
.dashboard-popover .ant-popover-arrow {
  display: none;
}
.dashboard-popover .ant-popover-inner {
  position: relative;
  left: -30px;
}
.table-btn {
  border-radius: 15px;
  min-width: 100px;
  width: auto;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.table-blue-btn {
  background-color: #cbe0f1;
  border: 1px solid #0076ff;
  color: #1775e2;
  font-size: 10px !important;
}
.table-yellow-btn {
  background-color: #ffe4b2;
  border: 1px solid #ffa400;
  color: #dc6530;
  font-size: 10px !important;
}
.table-green-btn {
  background-color: #cbf1e9;
  border: 1px solid #2d8f7b;
  color: #2d8f7b;
  font-size: 10px !important;
}
.task-icon-1 {
  width: 20px;
}
.claim-icon {
  width: 16px;
  margin-right: 6px;
}
.claim-icon-1 {
  width: 17px;
}
.table-img-cntr {
  width: 44px;
}
.table-img-cntr {
  width: 44px;
  padding: 0;
  text-align: right;
}
table tr td:last-child,
table tr th:last-child {
  text-align: none; /*text-align: right;*/
}
.pagi {
  text-align: center;
  padding: 25px;
}
.pagi ul {
  border: 1px solid #eceff0;
  display: inline-block;
}
.pagi ul li {
  font-size: 16px;
  width: 37px;
  height: 37px;
  line-height: 35px;
  border: 1px solid #eceff0;
  text-align: center;
}
.pagi ul li a {
  color: #747779;
}
.pagi ul li.active {
  border-color: #d3d3d3;
  box-shadow: inset 0px 0px 5px 0px #d3d3d3;
}
.float-search small {
  display: inline-block;
}
.float-search {
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  top: -17px;
  left: 50%;
  font-size: 16px;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 4px 17px 6px;
  cursor: pointer;
  color: #4a4a4a;
  z-index: 1;
}
.float-search:hover {
  color: #4a4a4a;
}
.search-icon {
  width: 15px;
  margin-left: 7px;
}
.dash-dropdowns {
  background-color: #ecf2f4;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
}
.dash-dropdowns div div:last-child select {
  margin-right: 0;
}
.dash-dropdowns span {
  font-weight: 300;
  font-size: 16px;
  color: lightslategray;
}
.dash-dropdowns select {
  color: #4a4a4a;
  background: transparent url("../Images//table-arr.png") no-repeat right 55%;
  border: 0;
  padding-right: 20px;
  background-size: 13px;
  padding-left: 5px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.assign-collapse-btn {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 121px;
  height: 43px;
}
.table-expandable-sctn {
  background: #ecf2f4;
}
.table-expandable-sctn button {
  cursor: pointer;
}
.table-expandable-sctn .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff url("./../Images/calendar-black.svg") no-repeat right 15px
    center;
  color: #4a4a4a;
}
.table-expandable-sctn input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.table-expandable-sctn .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.save-view-search button {
  padding-right: 25px;
  padding-left: 25px;
}
.save-view-search button.btn-inv {
  padding-right: 45px;
  padding-left: 45px;
}
.table-expandable-sctn button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
  border-color: rgba(37, 97, 168, 0.4);
  color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn .tab-content button:last-child {
  margin-right: 0;
}
.save-view-search {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-51%);
}
.table-expandable-sctn .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn .tab-content .row.all-row div {
  margin-bottom: 25px;
}
.mar-comp {
  margin-right: 20px;
}

/* status dropdown starts */
.status-drop-down {
  position: absolute;
  background-color: #fff;
  right: -25px;
  top: 25px;
  width: 250px;
}
.sort-sctn {
  padding: 13px 35px;
  border-bottom: 1px solid #eeeeee;
}
.sort-sctn div {
  padding: 5px 0 0 0;
}
.sort-sctn p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
}
.sorting-icon {
  width: 11px;
  margin-right: 14px;
}
.sorting-icon img {
  width: 100%;
  margin: 0;
}
.filter-type {
  padding: 5px 25px 20px 25px;
  border-bottom: 1px solid #eeeeee;
}
.filter-type p {
  margin: 0;
  padding: -2px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
}
.filter-type input {
  display: none;
}
.filter-checkbox input + label {
  position: relative;
  padding-left: 35px;
}
.filter-checkbox input + label::before {
  content: "";
  width: 17px;
  height: 17px;
  position: absolute;
  background-color: #fefefe;
  border: 1px solid #e0ddd9;
  left: 0;
  top: 50%;
  transform: translateY(-51%);
  border-radius: 3px;
}
.filter-checkbox input + label::after {
  content: "";
  position: absolute;
  left: 6px;
  width: 5px;
  height: 7px;
  border: solid #fefefe;
  border-width: 0 1.5px 1.5px 0;
  top: 50%;
  transform: translateY(-65%) rotate(45deg);
}
.filter-checkbox input:checked + label:before {
  background-color: #2d8f7b;
}
.filter-type .filter-checkbox {
  margin-top: 18px;
}
.filter-color {
  border: 0;
}
.fil-color-bg {
  width: 100px;
  height: 25px;
  display: block;
  border: 1px solid #e8e8e8;
}
.fil-color-red {
  background-color: #ffdfdf;
}
.fil-color-orange {
  background-color: #fff3df;
}
.fil-color-white {
  background-color: #fff;
}
.fil-color-green {
  background-color: #def3ff;
}
.filter-color .filter-checkbox {
  margin-top: 10px;
}
/* status dropdown ends */

/* tabs starts */
.nav-tabs {
  border-bottom: 1px solid #ececec;
}
.nav-tabs .nav-link {
  color: #4a4a4a;
  font-weight: 700;
  padding: 12px 20px;
  border: 0;
}
.nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  /*background: #e3e9eb;*/
}
.tab-content {
  padding: 12px 0px;
  box-shadow: none;
  background: initial;
}
.alert-p1 {
  padding: 35px !important;
}
/* tabs ends */

/* logout block starts */
.logout-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 10px;
}
.logout-block {
  color: #000;
  /* width: 380px; */
  min-width: 380px;
  background-color: #fff;
  border-radius: 8px;
}
.logout-block > div {
  padding: 22px 22px 20px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e4e4e4;
}
.logout-block > div:first-child {
  border-top: none;
}
#logout-popup .logout {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 5px;
  width: 100px;
  height: 38px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #2561a8;
  cursor: pointer;
  margin-left: 10px;
}
.mail-id {
  color: #6e6e6e;
  font-size: 12px;
  margin-top: 0px;
  word-break: break-all;
}
.user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.user-img a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout-label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.logout-block p {
  margin-bottom: 0;
}
.logout-status {
  width: 19px;
}
.logout-status img {
  width: 100%;
}
.status-options input + label {
  position: relative;
  padding-left: 15px;
}
.status-options input + label::before {
  content: "";
  position: absolute;
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  left: 0;
  top: 3px;
}
.status-options input:checked + label::before {
  background-color: #2561a8;
}
.status-sctn {
  padding-top: 0 !important;
  border-top: none !important;
  padding-bottom: 10px !important;
  margin-top: -12px;
}
.chat-status {
  margin-left: 5px;
  position: relative;
  top: 7px;
}
.logout-progress {
  background-color: #d8d8d8;
  border-radius: 5px;
  height: 10px;
  margin-top: 10px;
  overflow: hidden;
}
.logout-progress .progress-bar {
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  box-shadow: none;
}
.prog-indi {
  text-align: right;
  /* width: 60%; */
  position: relative;
  left: 22px;
  margin-top: 5px;
  white-space: nowrap;
}
#logout-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  max-width: 500px;
}
#logout-ovrly {
  padding: 70px 10px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#Pincode-ovrly {
  padding: 70px 10px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
  z-index: 10000;
}
#logout-popup .styles_closeButton__20ID4 {
  display: none;
}
.dis-btn {
  background-color: #2561a8 !important;
  color: #fff;
  opacity: 0.4;
}
/* logout block ends */

/* save search popup starts */
#save-search-ovrly {
  background-color: rgba(77, 92, 116, 0.2);
  padding: 0;
}
#save-search-popup {
  margin: 0 auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 0;
  width: 335px;
}
#save-search-popup .save-search {
  font-size: 16px;
  font-weight: 600;
  color: #2561a8;
  padding: 20px 25px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #eeeeee;
}
.search-name {
  padding: 20px 15px;
  border-bottom: 1px solid #eeeeee;
}
.search-name input {
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  padding: 10px 18px;
  width: 100%;
}
.search-name input::placeholder {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
}
.search-name .butn {
  padding: 11px 25px;
  font-size: 14px;
  letter-spacing: 0.5px;
  width: 100%;
  margin-top: 15px;
}
.search-names {
  padding: 15px 20px;
  padding-right: 7px;
}
.names-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.names-title p {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 700;
}
.search-names ul p {
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 700;
  flex: 0 0 45%;
  line-height: 1.3;
}
.search-names ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f2f2f2;
  margin-right: 11px;
}
.search-names ul li:first-child {
  padding-top: 0px;
}
.search-names ul li:last-child {
  border-bottom: 0;
}
.search-names ul a {
  font-size: 14px;
  font-weight: 700;
  color: #2561a8;
  margin-right: 12px;
}
.search-names ul {
  max-height: 190px;
  overflow: auto;
}
.search-names ul::-webkit-scrollbar {
  width: 7px;
}
.search-names ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.search-names ul::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.search-names ul::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
#save-search-popup .styles_closeButton__20ID4 {
  display: block;
  top: 19px;
  right: 25px;
  min-width: auto;
  cursor: pointer;
}
#save-search-popup .styles_closeButton__20ID4 .styles_closeIcon__1QwbI {
  width: 24px;
  height: 24px;
}
/* save search popup ends */

.prio-pie-chart .nv-legend {
  display: none;
}
.prio-pie-chart {
  display: inline-block;
  margin-top: -10px;
}
.pie-chart-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  margin-left: 0px;
  padding: 0 !important;
  margin-top: -2px;
}
.pie-chart-count span {
  font-weight: 700;
  font-size: 16px;
  display: block;
}
.prio-pie-chart .nv-label text {
  fill: #4a4a4a !important;
  font-size: 12px;
}
.prio-pie-cntr {
  padding-right: 0;
  padding-left: 0;
}
.prio-pie-cntr p {
  padding-right: 20px;
  padding-left: 20px;
}
.dash-timepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box,
.dash-timepicker .react-datepicker__time-container {
  width: 185px !important;
}
.dash-timepicker .react-datepicker-wrapper,
.dash-timepicker input {
  width: 100% !important;
}
.dash-timepicker {
  margin-bottom: 12px;
}
.collapse-icon {
  width: 15px;
}
.black-twitter {
  filter: brightness(0);
}
.dash-res {
  display: block;
  font-size: 11px;
  margin-top: 3px;
  opacity: 0;
  font-weight: 300;
}
.dash-res-opac {
  opacity: 1;
}
/* dashboard ends */

/* chatbot starts */

/* chatbot left starts */
.chatbot-left {
  background-color: #fffef7;
  height: calc(100vh - 55px);
  position: relative;
}
.chatbot-right {
  background-color: #ecf2f4;
  height: calc(100vh - 55px);
  position: relative;
}
.chat-left-height {
  max-height: 200px;
  overflow: auto;
}
.chat-cntr {
  padding: 15px 0px;
  border-bottom: 1px solid #e8e8e8;
}
.chat-cntr .chat-info:last-child {
  border-bottom: none;
}
.chat-cntr .ongng {
  font-size: 16px;
  font-weight: 600;
}
.chat-info {
  /* padding: 17px; 
  border-bottom: 1px solid #e4e4e4;*/
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.chat-info.active {
  /* background-color: #ecf2f4; */
  background-color: #cde4ff;
}
.dark-blue-ini {
  background-color: #ece8fd;
  color: #5b35ff;
}
.light-blue-ini {
  background-color: #ddf6fc;
  color: #11b4db;
}
.yellow-ini {
  background-color: #ffefcb;
  color: #ffb40a;
}
.green-ini {
  background-color: #daf3c0;
  color: #65ac13;
}
.orange-ini {
  background-color: #ffdee2;
  color: #ff631d;
}
.initial {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  margin-top: -10px;
  background-color: #ddf6fc;
  border: 1px solid #64c5b4;
}
.name-num p {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  line-height: 0.7;
}
.name-num .num {
  font-size: 11px;
  font-weight: 400;
  color: #4a4a4a;
  margin-top: -2px;
}
.mess-time p {
  font-size: 11px;
  line-height: 0.7;
  font-weight: 600;
}
.chatbot-left button {
  font-weight: 700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50px;
}
.hist-btn {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
/* chatbot left ends */

/* chatbot right ends */
.top-tab-act {
  background-color: #ecf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}
.top-tab-act .nav-tabs,
.top-tab-act .nav-tabs .nav-link.active {
  border-bottom: none;
  background-color: transparent;
}
.action-part select {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 42px;
  cursor: pointer;
  background: #2561a8 url("../Images/down-white.png") no-repeat right 20px
    center;
  background-size: 13px;
}
/* .sel-arr::after {
    content: '';
    position: absolute;
    display: inline-block;
    font-size: 12px;
    width: 11px;
    height: 11px;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    transform: rotate(-144deg) skew(-20deg);
  } */
.action-part .cross {
  min-width: auto;
  width: 43px;
  height: 43px;
  padding: 0;
  margin-left: 8px;
  font-size: 30px;
}
.top-tab-act .nav-tabs .nav-link {
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
}
.chat-user-det {
  background-color: #fff;
  padding: 10px 25px;
  height: calc(100vh - 118px);
  overflow: auto;
}
.chat-user-det > div {
  padding: 15px 0;
  border-bottom: 1px solid #e4e4e4;
}
.chat-user-det > div:last-child {
  border-bottom: none;
}
.chat-user-num a,
.chat-user-num span {
  font-size: 11px;
  color: #4a4a4a;
}
.chat-user-num span {
  margin: 0 5px;
}
.chat-user-name {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.chat-user-det textarea {
  background-color: #ecf2f4;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 100px;
  width: 100%;
  resize: none;
  font-size: 11px;
  color: #4a4a4a;
  padding: 8px 12px;
  margin-top: 10px;
}
.chat-user-det textarea::placeholder {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0px;
}
.price-tic div {
  flex: 0 0 50%;
}
.chat-extra-info {
  color: #000;
  font-size: 11px;
}
.chat-extra-info div {
  margin-top: 15px;
}
.chat-extra-info label {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 15px;
}
/* chatbot right ends */

/* chatbot ends */

/* store dashboard starts */
.store-dash-top small {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 0;
}
.store-dash-top .card-value {
  font-size: 28px;
}
.store-dash-top .aside-cont > div {
  margin: 0 20px;
  display: inline-block;
}
.store-dash-top .aside-cont {
  height: auto;
  display: block;
}
.store-table-cntr table {
  border-radius: 0;
}
.store-table-cntr {
  position: relative;
}
.store-dash-tabs a {
  color: #4a4a4a;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 10px;
}
.store-dash-tabs li:last-child a {
  margin-right: 0;
}
.store-dash-tabs a span {
  /* width: 30px; */
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  position: relative;
  top: 1px;
}
.store-dash-tabs a.active {
  background-color: #e3ebed;
  color: #2561a8;
  font-weight: 700;
}
.store-dash-tabs a.active span {
  background-color: #2561a8;
  color: #fff;
  font-weight: 400;
  width: 30px;
}
.store-dash-tabs {
  background-color: #ecf2f4;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
/* store dashboard ends */

/* login and password extra css starts */
.custom-select,
.form-control {
  background-color: #ecf2f4;
  padding: 12px;
}
.auth-content .card .card-block,
.auth-content .card .card-body {
  padding: 25px 60px 25px;
  /* padding: 35px 60px 50px; */
  /*box-shadow: 0 0 4px 0px #ddd;*/
}
.auth-wrapper .auth-content {
  padding: 0;
}
.auth-wrapper {
  display: block;
}
/* .box-center {
  display: flex;
  height: 100%;
} */
.settingtable .ReactTable .rt-thead {
  text-align: left;
}
.settingtable .ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.auth-wrapper .auth-content {
  /* margin: 20px auto; */
  margin: 50px auto;
}
.change-password-auth-wrapper .auth-content {
  margin: 20px auto;
}
.auth-wrapper input {
  border: 0;
}
/* login and password extra css ends */

/* extra css starts */
.my-tic-email {
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  padding-right: 35px;
  width: 151px;
  font-size: 14px;
  background: #dbe4e7 url("../Images/down-1.png") no-repeat right 12px center;
  background-size: 16px;
  text-align: left;
}
.my-tic-email::after {
  display: none;
}
.my-tic-email1 {
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  padding-right: 35px;
  width: 151px;
  font-size: 14px;
  background: #fff url("../Images/down-1.png") no-repeat right 12px center;
  background-size: 16px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ecf2f4;
}
.my-tic-email1::after {
  display: none;
}
.mail-mask .dropdown-menu.show:before {
  display: none;
}
.mail-mask svg {
  text-align: left;
  color: #212529 !important;
  float: left;
  margin: 1px 5px 1px 0 !important;
}
.mail-mask ul li {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.mail-mask ul {
  padding: 15px 0;
}
/* extra css end */

/* my ticket list starts */
.normal-dropdown .css-yk16xz-control,
.normal-dropdown .css-1pahdxg-control {
  min-height: 41px;
  border-color: transparent;
  box-shadow: none;
}
.normal-dropdown .css-1pahdxg-control {
  border-color: #7aacfe;
}
.normal-dropdown .css-yk16xz-control:hover,
.normal-dropdown .css-1pahdxg-control:hover {
  border-color: transparent;
  box-shadow: none;
}
.normal-dropdown .css-1pahdxg-control:hover {
  border-color: #7aacfe;
}
.normal-dropdown .css-1n7v3ny-option,
.normal-dropdown .css-yt9ioa-option,
.normal-dropdown .css-9gakcf-option {
  padding: 6px 19px;
}
.normal-dropdown .css-w8afj7-Input {
  margin: 0;
  padding: 0;
}
.normal-dropdown .css-26l3qy-menu {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #7b9cd3;
}
.normal-dropdown .css-1ml51p6-MenuList {
  padding: 0;
}
.normal-dropdown .css-yt9ioa-option,
.normal-dropdown .css-1uccc91-singleValue,
.normal-dropdown .css-1wa3eu0-placeholder {
  color: #a7a7a7;
}
.normal-dropdown .css-1wa3eu0-placeholder {
  margin: 0;
}
.normal-dropdown .css-1n7v3ny-option {
  color: #fff;
  background-color: #2684ff;
}
.normal-dropdown .css-1g48xl4-IndicatorsContainer,
.normal-dropdown .css-1wy0on6 {
  display: none;
}
.normal-dropdown .css-yt9ioa-option:active {
  background-color: #fff;
}
.normal-dropdown .css-1n7v3ny-option:active {
  background-color: #2684ff;
}
.normal-dropdown .css-1hwfws3 {
  padding-right: 44px;
  padding-left: 19px;
  background: url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
}
.store-report-add .normal-dropdown .css-1hwfws3 {
  background-color: #ecf2f4;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
  padding-left: 14px;
  padding-right: 40px;
}
.store-report-add .normal-dropdown .css-1wa3eu0-placeholder {
  color: #9b9b9b;
  font-weight: 700;
}
.store-report-add .normal-dropdown .css-yk16xz-control,
.store-report-add .normal-dropdown .css-1pahdxg-control {
  min-height: auto;
}
.store-report-add .normal-dropdown .css-1n7v3ny-option,
.store-report-add .normal-dropdown .css-yt9ioa-option,
.store-report-add .normal-dropdown .css-9gakcf-option {
  padding: 3px 14px;
}
.store-report-add .issuetype-cusdrp .category-box {
  margin-bottom: 0;
}
.common-adv-padd {
  padding: 20px 0;
}
.schedule-multi {
  padding: 0;
  border-radius: 5px;
  margin-top: 15px;
}
.schedule-multi .css-yk16xz-control,
.schedule-multi .css-1pahdxg-control {
  min-height: 43px;
  background: #ecf2f4;
}
.schedule-multi .css-1hwfws3 {
  padding-right: 25px;
  padding-left: 11px;
  background-position: right 4px center;
}
.schedule-multi .css-1n7v3ny-option,
.schedule-multi .css-yt9ioa-option,
.schedule-multi .css-9gakcf-option {
  padding: 6px 12px;
}
.schedule-multi .css-yt9ioa-option,
.schedule-multi .css-1uccc91-singleValue {
  color: #495057;
}
.schedule-multi .css-1ml51p6-MenuList {
  font-weight: 400;
}
.schedule-multi .css-1wa3eu0-placeholder {
  color: #9b9b9b;
}
.schedule-width {
  /* max-width: 283px; */
  max-width: 500px;
  width: 100%;
}
.last-to {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.one-line-outer {
  display: flex;
  align-items: flex-end;
}
.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.my-tic-ck-height iframe {
  height: calc(100% - 50px) !important;
}
.a-mar {
  margin-bottom: 14px;
}
.ReactTable .-pagination input:focus,
.ReactTable .-pagination select:focus,
.ReactTable .-pagination .-btn:focus {
  outline: 1.5px #7aacfe solid;
}
/* my ticket list ends */

/* ticket system starts */
.ticket-title-select .css-yk16xz-control,
.ticket-title-select .css-1pahdxg-control {
  background-color: #ecf2f4;
  min-height: 42px;
  border-color: transparent;
  box-shadow: none;
}
.ticket-title-select .css-yk16xz-control:hover,
.ticket-title-select .css-1pahdxg-control:hover {
  border-color: transparent;
  box-shadow: none;
}
.ticket-title-select .css-1g48xl4-IndicatorsContainer {
  display: none;
}
.ticket-title-select .css-1wa3eu0-placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-weight: 700;
}
.ticket-title-select .css-1uccc91-singleValue,
.ticket-title-select .css-w8afj7-Input input {
  color: #000 !important;
  font-family: Lato;
  font-weight: 700;
}
.ticket-title-select .css-1ml51p6-MenuList {
  padding: 12px;
}
.ticket-title-select .css-26l3qy-menu {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  margin: 0;
}
.ticket-title-select .css-1n7v3ny-option,
.ticket-title-select .css-yt9ioa-option {
  color: #9b9b9b;
  font-family: Lato;
}
.ticket-title-select .css-9gakcf-option {
  font-family: Lato;
  background-color: #2561a8;
}
.ticket-title-select .css-1ml51p6-MenuList div {
  display: inline-block;
  width: auto;
  border: 1px solid #2561a8;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 7px;
  cursor: pointer;
}
.priority-butns label,
.action-type-butns label {
  border-radius: 4px;
  font-weight: 700;
  font-family: Lato;
  padding: 10px 15px;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid;
  margin-bottom: 7px;
}
.action-type-butns label {
  border-color: #f4f4f4;
}
.action-type-butns-cntr .action-type-butns input:checked + label {
  border-color: #2561a8;
  color: #2561a8;
}
.priority-butns,
.action-type-butns {
  display: inline-block;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 1) label {
  border-color: #d02139;
  color: #d02139;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 1) input:checked + label {
  background: #d02139;
  color: #fff;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 2) label {
  border-color: #f5a623;
  color: #f5a623;
}
.priority-butns-cntr .priority-butns:nth-child(3n + 2) input:checked + label {
  background: #f5a623;
  color: #fff;
}
.priority-butns-cntr .priority-butns:nth-child(3n) label {
  border-color: #5aa805;
  color: #5aa805;
}
.priority-butns-cntr .priority-butns:nth-child(3n) input:checked + label {
  background: #5aa805;
  color: #fff;
}
.priority-butns-cntr .priority-butns:last-child label,
.action-type-butns-cntr .action-type-butns:last-child label {
  margin-right: 0;
}
.kb-acc-cntr {
  border-bottom: 1px solid #ecf2f4;
}
.kb-acc-cntr .table-details-data-modal {
  cursor: pointer;
  width: 100%;
  background: url("./../Images//down-1.png") no-repeat right top 6px;
  background-size: 13px;
  padding-right: 25px;
}
.kb-acc-cntr .table-details-data-modal[aria-expanded="true"] {
  background-image: url("./../Images//up-1.png");
}
.kb-acc-cntr .copyblue-kbtext {
  display: inline-block;
  margin: 10px 0 12px;
}

.custom-ticket-title {
  position: relative;
}
.custom-ticket-title input {
  background-color: #ecf2f4;
  border: 0;
  width: 100%;
  padding: 11px;
  border-radius: 4px;
}
.custom-ticket-title-suggestions span {
  display: inline-block;
  width: auto;
  border: 1px solid #2561a8;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 7px;
  cursor: pointer;
  color: #9b9b9b;
  transition: all 0.2s linear;
}
.custom-ticket-title-suggestions span:hover {
  background: #deebff;
}
.custom-ticket-title-suggestions {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}
.custom-ticket-title-suggestions::-webkit-scrollbar {
  width: 5px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-thumb {
  background: #5c5252;
  border-radius: 10px;
}
.custom-ticket-title-suggestions::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}

.custom-antd-table .ant-table-content {
  overflow: auto;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar {
  height: 5px;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar-track {
  background: #f3f0f0;
  border-radius: 10px;
}
.custom-antd-table .ant-table-content::-webkit-scrollbar-thumb {
  background: #a5a3a3;
  border-radius: 10px;
}
.order-input {
  border: 1px solid #eaeaea;
  padding: 4px 10px;
  border-radius: 5px;
  width: 100%;
}
.custom-antd-table .ant-table-thead > tr > th,
.custom-react-table .rt-thead .rt-th span {
  font-weight: 700;
  color: #4a4a4a;
  white-space: nowrap;
  padding: 10px 20px 4px !important;
  background: transparent !important;
  border-bottom: 0;
}
.custom-antd-table.antd-table-campaign .ant-table-thead > tr > th {
  background: #e5e9ed !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.custom-antd-table.antd-table-campaign .ant-table-thead > tr > th:last-child {
  text-align: left;
  float: none;
}
.custom-antd-table.antd-table-campaign .ant-spin-nested-loading,
.custom-antd-table.antd-table-campaign .ant-spin-container,
.custom-antd-table.antd-table-campaign .ant-table {
  position: static;
}
.custom-antd-table.antd-table-campaign .react-datepicker__time-list {
  overflow-x: hidden;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-thead
  > tr
  > th {
  color: #989898;
  background: #f0f0f0 !important;
}
.custom-table-bg
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-thead
  > tr
  > th {
  background: #f5f8f9 !important;
}
.custom-table-bg
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  background: rgb(250, 254, 255) !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  background: #fcfcfc !important;
}
.custom-antd-table.antd-table-campaign.antd-table-campaign-padd
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:first-child
  > td {
  padding-top: 15px !important;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:last-child
  > td {
  padding-bottom: 15px !important;
}
.antd-table-campaign tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}
.custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.custom-antd-table.antd-table-campaign table {
  padding: 0;
}
.antd-table-campaign .ant-table-row-collapsed::after {
  content: "";
  background: url("./../Images/collapsedown.png") no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}
.antd-table-campaign .ant-table-row-expanded::after {
  content: "";
  background: url("./../Images/collapseUp.png") no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}
.antd-table-campaign
  .ant-table
  .ant-table-row-indent
  + .ant-table-row-expand-icon {
  margin-right: 0;
  position: relative;
  border: 0;
}
.antd-table-campaign .ant-table-tbody > tr > td {
  border-color: #f1f1f1;
}
.custom-react-table .rt-thead .rt-th span {
  padding: 0 !important;
  font-size: 12px;
}
.custom-antd-table .ant-table-thead > tr {
  background: transparent !important;
}
.custom-antd-table .ant-table-tbody > tr > td,
.custom-react-table .rt-tbody .rt-td {
  /* white-space: nowrap !important; */
  padding: 20px !important;
  font-weight: 700 !important;
  font-size: 13px;
}
.custom-antd-table
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}
.custom-antd-table .ant-table-thead > tr > th .ant-table-column-title {
  font-size: 12px !important;
}
.custom-antd-table .ant-table-expanded-row > td:first-child {
  display: none;
}
.custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  > td:first-child {
  display: table-cell;
  padding: 0 !important;
}
.custom-antd-table .ant-table-expanded-row,
.inner-custom-react-table .ReactTable {
  background: #fff;
}
.inner-custom-react-table .ReactTable {
  border-radius: 0 !important;
  border-top: 1px solid #e8e8e8 !important;
}
.inner-custom-react-table .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0;
}
.custom-antd-table .ant-table-expanded-row .ant-table-tbody > tr > td {
  border-bottom: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.custom-antd-table .filter-checkbox input + label,
.custom-react-table .filter-checkbox input + label {
  padding-left: 0;
  top: -5px;
}
.custom-antd-table table,
.custom-react-table .ReactTable {
  padding: 0 20px;
}
.custom-antd-table .ant-table-expanded-row table {
  padding: 0;
}
.date-picker-arrows .react-datepicker button,
.antd-table-campaign .react-datepicker button {
  min-width: auto;
}
.date-picker-arrows .react-datepicker span,
.antd-table-campaign .react-datepicker span {
  display: inline-block;
}
.datepicker-overflow table,
.datepicker-overflow .ant-table-content {
  overflow: visible;
}
.datepicker-overflow .react-datepicker__month-read-view span,
.datepicker-overflow .react-datepicker__year-read-view span {
  display: inline-block;
}
.ck-expand {
  width: 15px;
  position: absolute;
  top: 15px;
  right: 25px;
}
.ck-exp-top {
  top: 6px;
}
.ck-exp-width {
  width: 100% !important;
  max-width: 90%;
}
.ck-exp-width .placeholder-dropdown-tktSys {
  top: 45px;
  right: -375px;
  left: inherit;
}
.ck-exp-width .ck-det-cntr .cke_top {
  height: 70px !important;
}
.ck-exp-width .ck-expand {
  top: 25px;
  right: 30px;
}
.ck-exp-width .cke_bottom {
  display: none;
}
.ck-exp-width .ck-det-cntr .cke_inner {
  min-height: auto;
}
.ck-exp-width .ck-det-cntr .cke_contents {
  height: 300px !important;
}
.ck-exp-width iframe {
  height: calc(100% - 60px) !important;
}
.store-date-width {
  width: 115px;
}
/* ticket system ends */

/* add search my ticket starts */
.srv-validation-message {
  color: #f00;
  margin-top: 5px;
}
.circular-loader {
  width: 15px !important;
  margin-right: 8px !important;
  color: #fff !important;
}
.add-cust-butn:disabled {
  opacity: 0.7;
  pointer-events: none;
}
/* add search my ticket ends */

/* my ticket starts */
.my-ticket-notes .my-ticket-notes-row:first-child {
  margin-top: 0;
}
.my-ticket-notes-created {
  margin-top: 7px !important;
}
.my-ticket-notes {
  max-height: 245px;
  overflow: auto;
}
.my-ticket-notes::-webkit-scrollbar {
  width: 5px;
}
.my-ticket-notes::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.my-ticket-notes::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.my-ticket-notes::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.ReactTable.limit-react-table-body .rt-tbody {
  max-height: 300px;
}
.ReactTable.limit-react-table-body .rt-thead {
  margin-right: 4px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar {
  width: 4px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.ReactTable.limit-react-table-body .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ReactTable.limit-react-table-body .rt-thead .rt-tr {
  text-align: left;
}
.cancel-assign {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
}
.cancel-assign img {
  width: 100%;
}
.my-ticket-temp .kblink {
  top: 90px;
  left: 145px;
  right: initial;
  border: none;
}
.my-ticket-temp .collapbtn {
  top: 90px;
  left: 0px;
  right: initial;
  border: none;
}
.my-tic-ckeditor iframe {
  padding-top: 35px;
  height: calc(100% - 70px) !important;
}
.my-tic-ckeditor .cke_inner {
  min-height: 300px;
}
.tic-det-progress .progress {
  height: 8px;
  margin-top: 8px;
}
.ck-edit-mar {
  margin-bottom: 14px !important;
}
.tic-det-ck-user {
  position: absolute;
  right: 230px;
  z-index: 1;
  background: #fff !important;
  top: 5px;
}
.tic-det-ck-user-rply {
  right: 10px;
  top: 42px;
  width: 120px;
}
.tic-det-ck-user select {
  background-color: #ecf2f4 !important;
  height: auto;
  padding: 5px 25px 5px 15px !important;
  background-position: right 10px center !important;
}
.placeholder-dropdown {
  right: 410px;
  width: 180px;
}
.placeholder-dropdown-tktSys {
  top: 45px;
  right: -245px;
  width: 135px;
}
.placeholder-dropdown {
  right: 410px;
}
.placeholder-alert {
  right: 250px !important;
}
.big-modal-placeholder .modal-dialog {
  max-width: 1000px;
}
.tic-det-Freecmd {
  right: 65px;
  top: 15px;
}
.tic-createTic {
  right: -100px;
  top: 45px;
  width: 100px;
}
.template-user {
  right: 80px;
  top: 38px;
  width: 160px;
}

/* my ticket ends */

/* .cke_editable {
  margin: 0px !important;
} */

/* settings starts */
.module-switch .switch input[type="checkbox"]:checked + .cr {
  border: 1px solid #50d462;
}
.module-switch .switch input[type="checkbox"] + .cr:before {
  background: #c9c9c9;
}
.module-switch .switch input[type="checkbox"]:checked + .cr:before {
  background: #50d462 !important;
}
.temp-sub {
  padding: 0 40px 30px 40px;
}
.temp-multi.schedule-multi .css-1hwfws3 {
  padding-right: 35px;
  background-position: right 12px center;
}
.settings-created-by-popover .title {
  color: #000;
}
.settings-created-by-popover .sub-title {
  font-size: 13px;
  margin-top: 2px;
}
.settings-created-by-popover div {
  margin-bottom: 18px;
}
.settings-created-by-popover div:last-child {
  margin-bottom: 0px;
}
.settings-created-by-popover {
  padding: 10px 20px;
}
/* settings ends */

/* sla starts */
.category-box input:checked + label img {
  display: block;
}
.category-box input + label img {
  display: none;
}
.category-box input + label div {
  float: right;
  border: 2px solid #2561a8;
  width: 16px;
  height: 16px;
  margin-top: 5px;
}
.category-box input {
  display: none;
}
#overlaySla,
#overlayDepartment,
#overlayFunction,
#overlayPriority,
#overlayClaimStatus,
#overlayClaimCategory,
#overlayClaimSubCategory,
#overlayClaimIssueType,
#overlayCampaignName,
#overlayCampaignStatus {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
}
#overlaySla.show,
#overlayDepartment.show,
#overlayFunction.show,
#overlayPriority.show,
#overlayClaimStatus.show,
#overlayClaimCategory.show,
#overlayClaimSubCategory.show,
#overlayClaimIssueType.show,
#overlayCampaignName.show,
#overlayCampaignStatus.show {
  display: block;
}
.dropdown-menu-sla {
  z-index: 1001;
}
.sla-cancel-search {
  position: relative;
}
.sla-cancel-search img {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 12px;
}
/* sla ends */

.kb-search-cntr {
  display: inline-block;
  cursor: pointer;
}

.alert-proper {
  margin-top: 0 !important;
  margin-right: 35px;
}

.notification-container {
  text-align: left;
}

/* chetan css end */
/******************************************************/

/*Shlok css start*/

/*******************Claim start *********************/
.claim-header-card {
  background: #ecf2f4;
}
.claim-ticket {
  font-size: 15px;
  padding: 15px;
  padding-left: 0;
  margin-bottom: 0px;
  color: #5e6061;
}
.claim-A22345 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  margin-right: 40px;
}
.claim-title {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding: 14px;
}
.btn-claim {
  background-color: #2561a8;
  border-radius: 4px;
  border-color: #2561a8;
  width: 145px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: white;
}
.btn-margin {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 30px;
}
.submit-claim {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.btn-btn-claim {
  color: #2561a8;
  background-color: transparent;
  background-image: none;
  border-color: #2561a8;
  border-radius: 0.25rem;
  height: 40px;
  line-height: 10px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}
.col-md-9-card {
  margin-left: -5px;
  flex: 0 0 75%;
  max-width: 75%;
}
.oderDetails-table {
  padding-top: 15px;
}
.span-color {
  color: red;
}
.search-customer-padding {
  padding: 30px;
}
.cusrow .row {
  margin: 0 !important;
}
.back-color {
  background-color: #f5f8f9;
  padding-top: 20px;
}
.card-radius {
  border-radius: 6px;
}
.label-color {
  color: #6b6767;
  margin-right: 5px;
}
.add-customer-color {
  color: #4275b1;
  float: right;
  cursor: pointer;
}
.search-mobile-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  border: none;
}
.searchImg {
  width: 16px;
  height: 16px;
}
.raiseclaim .searchImg {
  position: absolute;
  top: 25px;
  right: 15px;
}
.claim-status-card {
  background-color: #ecf2f4;
  width: auto;
  height: 40px;
  border-radius: 4px;
  padding: 9px 15px;
}
.line-2 {
  border: 1px solid #cad4d7;
  height: 53px;
  width: 0px;
  margin-top: -36px;
  margin-left: 753px;
}
.line-3 {
  border: 1px solid #cad4d7;
  height: 53px;
  width: 0px;
  margin-top: -53px;
  margin-left: 810px;
}
.arrow-img-1 {
  width: 18px;
  height: 18px;
  /* float: right; */
  margin-right: 10px;
  margin-top: -5px;
  margin-left: 10px;
}
.plush-img-1 {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  /* margin-top: -9%; */
  float: right;
}
.first-margin {
  margin-left: -13px;
  font-size: 15px;
}
.search-order {
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-left: 27px;
  padding-left: 6px;
}
.searchImg-1 {
  width: 20px;
  height: 20px;
  margin-top: -70px;
  margin-left: 833px;
}
.batashoes {
  width: 150px;
}
.label-6 {
  color: #4a4a4a;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  opacity: 0.5;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 10px;
}
/* .raiseclaim .row {
  margin: 0;
} */
.ticket-comments-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  min-height: 125px;
  border: none;
  height: auto;
  resize: none;
}
.hr-ago {
  color: #9b9b9b;
  font-family: inherit;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  padding-top: 15px;
}
.naman-R {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-top: 15px;
  margin-left: -6px;
}
.hr-ago-margin {
  margin-left: 43px;
  padding-left: 70px;
}
.margin-claim {
  margin-left: -28px;
  padding-top: 10px;
}
.comment-02-margin {
  margin-bottom: 9px;
}
.card-radius2 {
  border-radius: 6px;
  padding-top: 35px;
  padding-left: 25px;
  height: auto;
}
.displayNn {
  display: none;
}
.displayShw {
  display: block;
}
/**************************************/

/* -------------Deepka Savani-------------- */

.addNote-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.card-header1 .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
}

/* ----------------My Ticket List Page--------------------- */

.myticketlist-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.escalation-04-new {
  color: #2561a8;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.Escalation04-box1 {
  background-color: #e3ebed;
  color: #2561a8;
  border-radius: 4px;
  width: 137px;
  height: 43px;
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
}

.Add-ticket-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 148px;
  height: 40px;
  cursor: pointer;
}

.myticketlist-header table td {
  padding: 15px 15px 5px 15px;
  color: #727474;
}
.add-tickets {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
/* .oval04 {
  background-color: #2561a8;
  border-radius: 100%;
  width: 30px;
  height: 30px;
} */

/* ---------------------------------------------------------- */

/**************************************/

/********Shlok css for Historical task popup********/
/**Historical popup css start**/
.historical-model {
  border-radius: 10px;
}
.table-scrolling {
  min-height: 300px;
  overflow: auto;
  max-height: 350px;
  margin-left: 0;
}
#Historical-popup .rt-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#Historical-popup .rt-table::-webkit-scrollbar-track {
  background: yellow;
  border-radius: 10px;
}
#Historical-popup .rt-table::-webkit-scrollbar-thumb {
  background: yellow;
  border-radius: 10px;
}
#Historical-popup .rt-table::-webkit-scrollbar-thumb:hover {
  background: yellow;
}
/**Historical popup css edn**/

/*Progress bar in my tickeyt page*/
progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 290px;
  height: 8px;
  border-radius: 20px;
  /* background-color: #777; */
  color: #ff6d33;
}
.responseProgress {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
}
progress::-webkit-progress-bar {
  background-color: #777;
  border-radius: 20px;
}

.ticket-progress::-webkit-progress-value {
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  border-radius: 20px;
}
.ticket-progress::-webkit-progress-bar {
  background-color: #d8d8d8;
  border-radius: 20px;
}

.ticket-progress::-moz-progress-bar {
  background: linear-gradient(to right, #ff8363 42%, #ff631d 50%);
  border-radius: 20px;
}
.prog-indi-1 {
  width: 100%;
  text-align: center;
  position: relative;
  margin: 0;
  text-transform: capitalize;
}
.dropdown-label {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #ecf2f4 url("../Images/dropDown.png") no-repeat right 20px center;
  background-size: 15px;
  border: none;
  cursor: pointer;
}
#Historical-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 360px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.historical-popup {
  margin-right: auto !important;
  min-width: 60%;
}
/* ---------------------------Deepak Savani Task:Claim Tab Ticket View-----------------------------   */

.claim-00-1 {
  color: #2561a8;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-right: 5%;
  margin-left: -202px;
}

.messages-04-1 {
  color: #4a4a4a;
  font-family: unset;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}
.rectanglesystem table tr {
  border-bottom: none !important;
}
.custom-select:focus,
.form-control:focus {
  background-color: #ecf2f4 !important;
  box-shadow: none !important;
}
/* ------------------------------------------------------------------------------------------------- */
/* model close button css */
.styles_closeButton__20ID4 {
  display: none;
}
.ReactModal__Overlay {
  background-color: rgba(77, 92, 116, 0.2) !important;
}

/* .styles_modal__gNwvD
{
  top: 7%;
  left: 14%;
  border-radius: 8px;
  width: 500px;
} */
/* ------------------------------- */

.CliamDetailsModal {
  background-color: #ffffff;
  width: 945px;
  height: 929px;
  float: right;
  position: relative;
}

.black-left-arrow {
  width: 20px;
  height: 18px;
  margin-top: -5px;
  cursor: pointer;
}

.claim-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  padding-top: 3px;
  text-align: left;
  margin-left: 20px;
  text-transform: uppercase;
}

.id-a-22134 {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 70px;
  text-align: left;
}

.claimrowmargin {
  margin-left: 25px;
}
.claimline {
  margin-left: -25px;
  margin-right: -24px;
}
.claim-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.sub-category {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  padding: 0;
}
.claim-type {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.claim-asked-for {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.attached-image {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.refund {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  display: block;
}

.recent-orders {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.recent {
  margin-bottom: 20px;
}
.customer-name {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.email-id {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}
.phone-number {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.gender {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.Add-Comments-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 359px;
  height: 198px;
  border: none;
  resize: none;
  padding: 10px;
}

/* ----- */

.table-header {
  background-color: white;
}
.claim-status-table {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 876px;
  min-height: 350px;
  height: auto;
}
.my-tic-mob-float {
  position: absolute;
  top: -20px;
  right: -20px;
}
.my-tic-mob-float img {
  width: 12px;
}
.claim-status-table-header {
  background-color: #ecf2f4;
  width: 876px;
  height: 57px;
  border: 1px solid #ecf2f4;
  border-radius: 8px;
}

.claim-status-open {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding: 20px;
}

.order-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 34px;
  padding-top: 20px;
}

.invoice-number {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.invoice-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.item-count {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.item-price {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.price-paid {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-code {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-address {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.claim-SKU-details-table {
  background-color: #f8f8f8;
  width: 802px;
  height: 105px;
  margin-left: 10px;
}
.bb-332398 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.sku {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

/* table.borderless td,table.borderless th{
  border: none !important;
} */

/* ------ */
.oval-5-1 {
  background-color: #7ed321;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* margin-left: 15px; */
}

.varun-nagpal {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.hi-diwakar-i-really {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  width: 343px;
  text-align: left;
}

.add-comment-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 359px;
  height: 43px;
}

.add-comment-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.claim-status-table .table td {
  padding: 0 15px;
}

.claim-status-row {
  background-color: #f3f0f0;
  border-radius: 4.5px;
  width: 851px;
  height: 10px;
  margin: 10px;
}

.ticket-details-textarea::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-tittle-textbox::placeholder {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.batashoes-icon {
  width: 135px;
  height: 76px;
}
.batashoes-icon img {
  width: 100%;
}
.reacttableclaimdrawer .ReactTable {
  border: none;
}
.reacttableclaimdrawer .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 7px !important;
}
.reacttableclaimdrawer .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.reacttableclaimdrawe .ReactTable {
  background-color: #f8f8f8;
}
.reacttableclaimdrawe .ReactTable .rt-thead.-header {
  background-color: #f8f8f8;
}
.borderless table tr {
  border-bottom: none !important;
  border: none !important;
  height: 0px;
  padding: 1px;
}

.borderless table td {
  border: none !important;
}

.borderless table tr td:last-child {
  text-align: left;
}

.claim-SKU-details-table table tbody tr {
  height: 28px;
  border: none !important;
}
.claim-SKU-details-table table tbody tr td {
  border: none !important;
}

#Notification-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 25%;
  top: -20px;
}
#Status-popup {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 80px;
  margin: 0 auto;
  padding: 0px;
}
#ScheduleModel {
  border-radius: 10px;
}
.textBox {
  border: none;
}
/* #endregion */
.disabled-input {
  cursor: not-allowed !important;
}
/* Deepak Savani for Program Code Page add CSS  */

.program-code-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  /* margin-right: 10px; */
  cursor: pointer;
  color: #fff;
}
.chpass {
  font-weight: bold;
  color: #a5a5a5;
  /* margin-bottom: 5px; */
}
.program-code-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}

.program-code-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding: 10px;
}

.card {
  border: none !important;
}
.headPhone3 {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 0;
}

.ScheduleDate-to {
  /* width: 245px; */
  margin-bottom: 17px;
  margin-top: 20px;
  width: 100%;
}
.txt1Place {
  padding-left: 10px;
  width: 245px !important;
  border: none;
}
.scheduleBtn {
  background-color: #2561a8;
  border-radius: 4px;
  /* width: 245px; */
  height: 43px;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
}

.obpml {
  margin-left: 0px;
}
.textbhead {
  display: inline;
  font-size: 14px;
  padding: 0 15px;
  font-weight: bold;
}
.textbhead span {
  font-weight: normal;
}
.collapbtn {
  position: absolute;
  right: 100px;
  top: 41px;
  z-index: 1;
  border: 1px solid #d1d1d1;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}
.collapbtn1 {
  margin: 45px 0 0 18px;
  z-index: 1;
  /* border: 1px solid #d1d1d1; */
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}
.collapbtn button {
  background: none;
  width: auto;
}
.kblink {
  position: absolute;
  top: 41px;
  right: 5px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.kblink1 {
  z-index: 1;
  padding: 5px 8px 5px 5px;
  /* border: 1px solid #d1d1d1; */
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
  margin: 45px 0 0 0;
}
.floatingtabs {
  position: absolute;
  bottom: 0;
  right: 0;
}
.action-label {
  float: right;
}

#slaEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  max-width: 30%;
  min-width: 30%;
}

/*------------------------------Knowledge Base start-------------------------*/

.kb-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.header-new-submissions {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 20px;
  padding-top: 17px; */
  cursor: pointer;
}

.header-new-submissions-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 24px;
  padding-top: 17px; */
  cursor: pointer;
}

.kb-Header-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 185px;
  height: 43px;
  float: right;
  margin-right: 20px;
  margin-top: 7px;
  cursor: pointer;
  color: #ffff;
}

.add-new-kb {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
}

.main-content-kb {
  background-color: #ffffff;
  border-radius: 4px;
  width: 97%;
  margin: 20px;
}

.main-content-margin {
  margin: 10px;
}
.main-conenet-point {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.clear-search {
  color: #cd3245;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-left: 20px;
  cursor: pointer;
  display: inline;
}

.searchicon {
  width: 20px;
  height: 20px;
  margin-left: 7px;
  cursor: pointer;
}

.search-KB {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.table-id-data {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.table-details-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: auto;
  text-align: left;
  white-space: normal;
  display: inline-block;
}
.table-type-return {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.table-category {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.table-subcategory {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.kb-table table thead th {
  border: 0;
  background-color: white;
}

.reject-button {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
}

.reject-button-1 {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
}
.reject-button-text {
  color: #d0021b;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  padding-bottom: 5px;
}

.aprove-button {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  padding: 0px;
}

.approve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}

.down-icon-kb {
  width: 21px;
  height: 21px;
  vertical-align: top;
}

/* ------------------------------------end---------------------------------- */

.ReactModal__Overlay {
  overflow: auto;
}
.oval-5-1-new {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

/*------------------------------Knowledge Base start-------------------------*/

.kb-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}

.header-new-submissions {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 20px;
  padding-top: 17px; */
  cursor: pointer;
}

.header-new-submissions-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  /* padding-left: 24px;
  padding-top: 17px; */
  cursor: pointer;
}
.kb-header span {
  margin-left: 20px;
  margin-top: 17px;
  display: inline-block;
}

.kb-Header-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 185px;
  height: 43px;
  float: right;
  margin-right: 20px;
  margin-top: 7px;
}

.add-new-kb {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: uppercase;
}

.main-content-kb {
  background-color: #ffffff;
  border-radius: 4px;
  width: 97%;
  margin: 20px;
}

.main-content-margin {
  margin: 10px;
}
.main-conenet-point {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.search-KB {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
}

.table-id-data {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.table-details-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: auto;
  text-align: left;
  white-space: normal;
  display: inline-block;
}

.table-details-data-1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 383px;
  text-align: left;
  white-space: normal;
  display: inline-block;
}
.table-type-return {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.table-category {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.table-subcategory {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.kb-table table thead th {
  border: 0;
  background-color: white;
}
.kb-table table thead th.pad {
  padding-right: 65px;
  float: inherit;
}

.reject-button {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
  min-width: 0;
  cursor: pointer;
}

.reject-button-1 {
  border: 1px solid #d0021b;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  background-color: white;
  padding: 0px;
  margin-right: 10px;
  min-width: 0;
}
.reject-button-text {
  color: #d0021b;
  font-family: Lato;
  font-size: 11px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  padding-bottom: 5px;
  cursor: pointer;
}

.aprove-button {
  background-color: #2561a8;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 74px;
  height: 27px;
  padding: 0px;
  min-width: 0;
  cursor: pointer;
}

.approve-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  cursor: pointer;
}

.down-icon-kb {
  width: 20px;
  height: 15px;
  padding: 3px;
  vertical-align: top;
  cursor: pointer;
}
.ReactModal__Overlay {
  overflow: auto;
}

/* --------------------------------Search Modal------------------------------- */
#kb-search-popup {
  border-radius: 0px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  height: 100%;
  width: 422px;
}
#kb-search-ovrly {
  padding: 0px 0px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#kb-search-popup .styles_closeButton__20ID4 {
  display: none;
}

.search-modal-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
  margin-left: 8px;
}
.search-button-modal {
  background-color: #2561a8;
  border-radius: 4px;
  width: 105px;
  height: 43px;
  float: right;
  margin-right: 40px;
  cursor: pointer;
}
.search-button-modal-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}

.kb-Model-mp {
  margin: 40px 40px 0px 40px;
}

.back-button-left {
  width: 24px;
  height: 24px;
  margin-top: -6px;
  cursor: pointer;
}

.kb-modal-type-select {
  border-radius: 4px;
  width: 340px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url("../Images//dropDown.png") no-repeat right 12% center;
  margin-left: 20px;
  background-size: 17px;
  cursor: pointer;
}
.claimTab-drawerModal .ant-drawer-content-wrapper {
  width: auto !important;
}

/* ----------------------------------------------------------- */

/* ---------------------------Add new kb modal-------------------------------- */

#addkb-modal-popup {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-top: 4.5%;
  display: inline-block;
  height: auto;
  width: 600px;
}
#addkb-modal-ovrly {
  padding: 0px 0px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
}
#addkb-modal-popup .styles_closeButton__20ID4 {
  display: none;
}

.add-select-category {
  background: #ecf2f4 url("../Images//table-arr.png") no-repeat right 7% center;
  background-size: 20px;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding-left: 10px;

  cursor: pointer;
}

.add-select-subcategory {
  background: #ecf2f4 url("../Images//table-arr.png") no-repeat right 7% center;
  border-radius: 4px;
  background-size: 20px;
  width: 258px;
  height: 43px;
  border: none;
  padding-left: 10px;
  margin-left: 11px;
  cursor: pointer;
}

.addkb-subject {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  /* margin-left: 17px; */
  margin-top: 15px;
  padding: 10px;
}

.addkb-subject::placeholder {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 0;
}

.rich-text-areas {
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  width: 530px;
  height: 178px;
  resize: none;
  margin-left: 17px;
}

.add-kb-button-modal {
  background-color: #2561a8;
  border-radius: 4px;
  width: 128px;
  height: 43px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}
.add-kb-button-text-modal {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.cancel-button-modalk {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  width: 128px;
  height: 43px;
  cursor: pointer;
  margin-right: 0;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.cancel-button-modal {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.cancel-button-modal-icon {
  width: 17px;
  height: 16px;
  float: right;
  margin-right: 20px;
  margin-top: -20px;
  cursor: pointer;
}
.main-content-kb .pagi {
  padding-top: 43%;
}

.kb-table .card-body {
  padding: 0;
}

/* ----------------------------------------------------------- */
/* ------------------------------------end---------------------------------- */

/* ---------------------------------myticket list----------------- */

.header-list-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.claim-modal-scroll .row {
  margin-right: 0;
}

.forgotpass-card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 406px;
  /* height: 438px; */
}
.forgot-pass-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  /* margin-top: 28px; */
  margin-top: 10px;
}

.recovery-pass-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 282px;
  height: 43px;
}

.programcode-card-new {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 406px;
  height: auto;
}
.mainDivPadding .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: -15px;
}
.lblHistorical {
  margin-left: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 22px;
}
.historyTable {
  font-size: 12px;
  padding: 10px 0px;
  margin-right: 0;
}
.historyTable table thead tr {
  background-color: #ffffff !important;
  border-bottom: none;
}

/* --------------------------------------------------------- */
#profile-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 548px;
  height: 494px;
}
#BillInvoice-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0 16px 0 28px;
  margin: 0 auto;
  display: inline-block;
  min-width: 83%;
  /* height: 550px; */
}
.profilemodal-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  display: block;
}

.profilemodal-textval {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 20px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  display: block;
}

.openticketbox {
  background-color: #43c3a7;
  border-radius: 8px;
  width: 206px;
  height: 138px;
}

.profilemodalrow {
  margin-top: 9.13%;
  margin-left: 3.85%;
}

.profilemodalrow-1 {
  margin-top: 7.13%;
  margin-left: 3.85%;
}

.open-tickets-box-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 38px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  display: block;
  padding-top: 33px;
}
.open-tickets-box-textval {
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  display: block;
  text-align: center;
  margin-top: -15px;
}
.openticketbox-2 {
  background-color: #4a90e2;
  border-radius: 8px;
  width: 206px;
  height: 138px;
  margin-left: 26px;
}
.alternumber {
  border: 0;
  border-radius: 5px;
  padding: 10px;
  width: 171px;
  height: 40px;
  font-size: 14px;
  background: #dbe4e7;
}

.full-profile-view-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  float: right;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 12px;
}

.profilemodal-row-3 {
  float: right;
  margin-right: 25px;
  margin-top: 60px;
}
.profilemodalmaindiv {
  clear: both;
  overflow: hidden;
}
.pro-cross-icn {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

#ticket-store-modal {
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  width: 439px;
  height: auto;
}
#layout-ticket-store-modal {
  padding: 70px 10px 70px 0px;
  background-color: rgba(77, 92, 116, 0.2);
}

.storemodal-row {
  margin-top: 10px;
}
.storemodal-select {
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url("../Images/dropDown.png") no-repeat right 12px center;
  cursor: pointer;
}

.storemodal-text {
  background-color: #edf2f4;
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding: 10px;
}
.storemodal-row-1 {
  margin-top: 20px;
}

.storemodal-more-filter {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.storemodal-search-btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 365px;
  height: 43px;
  text-align: center;
  cursor: pointer;
}

.storemodal-search-btn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  /* padding-top: 10px; */
}

.collstore-head-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  float: left;
}
.storemd-icon {
  width: 30px;
  height: 30px;
  float: left;
}

.profilemodalmaindiv .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 25px 25px 15px;
  position: relative;
  padding-left: 25px;
}

.profilemodalmaindiv .card .card-header button {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  background: url("../Images/down-1.png") no-repeat right 12px center;
}
.profilemodalmaindiv .card .card-body {
  padding: 0px 40px;
}

.collstore-head-text-black {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  float: left;
}

.pro-cross-icn-1 {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 92%;
  cursor: pointer;
}
.historyTable table th {
  font-size: 13px;
  padding: 12px 30px;
}
.historyTable table tbody tr {
  border-bottom: none;
}
.historyTable table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 10px 30px;
}

.cancalImg {
  width: 18px;
  height: 18px;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

.datePicketArrow {
  width: 15px;
  height: 10px;
  margin-left: 5px;
  cursor: pointer;
}
.storemodal-row-1 button {
  padding: 0;
}

.profilemodalmaindiv-1 {
  clear: both;
  overflow: hidden;
}

.profilemodalmaindiv-1 .card {
  padding-top: 20px;
}

.profilemodalmaindiv-1 .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 0px;
  position: relative;
}

.profilemodalmaindiv-1 .card-body {
  padding: 0px 23px;
}

.profilemodalmaindiv-1 .card .card-header button {
  margin-left: 10px;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  background: url(../Images/down-1.png) no-repeat right 24px center;
}

.storeproduct-tab-head-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.storeheadsearchdiv {
  margin-left: 20px;
}

.tabhead1 {
  width: 30%;
}

.tabhead2 {
  width: 15%;
  text-align: right;
}

.tabhead3 {
  width: 25%;
  text-align: right;
}

.tabhead4 {
  width: 25%;
  text-align: right;
}

.tabdatadeactive {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: right;
}
.tabdatadeactive-1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.addselected-button {
  background-color: #edf2f4;
  border: 1px solid #3069ac;
  border-radius: 4px;
  width: 175px;
  height: 43px;
  margin-right: 20px;
  cursor: pointer;
}
.addselected-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.bigblue {
  color: DodgerBlue;
  padding: 40px;
  font-family: Arial;
  text-align: center;
}

/* 
.storemodal-select{  
  border-radius: 4px;
  width: 365px;
  height: 43px;
  border: none;
  padding-left: 10px;
  background: #ecf2f4 url("../Images/dropDown.png") no-repeat right 12px center;
} */
/* ----------------------------store and product modal search modal----------------------- */
#storeproductsearchmodal {
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  min-width: 800px;
  width: 1002px;
  height: 694px;
  padding: 0px 5px 0px 31px;
  max-width: 1100px;
}
#layout-storeproductsearchmodal {
  padding: 70px 10px 70px 0px;
  background-color: rgba(77, 92, 116, 0.2);
}

.storeproductdetailsdiv {
  background-color: #edf2f4;
  border-radius: 4px;
  width: 571px;
  height: 694px;
}
.disabled-link {
  pointer-events: none;
}
.text-disabled {
  pointer-events: none;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  line-height: 50px;
  width: 100px;
  text-align: left;
  cursor: pointer;
}

/* ---------------------------------------------- */
/* Css Store_CRM Roles Shlok */
/**  =====================
      Switches css start
     =====================  **/
.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.switch input[type="checkbox"] + .cr {
  position: relative;
  display: inline-block;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  /* top: 10px; */
  top: 2px;
  margin-left: 115px;
}
.cr-tick {
  top: 10px !important;
}
.switch input[type="checkbox"] + .cr:after,
.switch input[type="checkbox"] + .cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
.switch input[type="checkbox"] + .cr:before {
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
  background: #50d462;
}
.switch input[type="checkbox"] + .cr:after {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13),
    0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}
.switch input[type="checkbox"]:checked + .cr:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.switch input[type="checkbox"]:checked + .cr:after {
  left: 16px;
}
.switch input[type="checkbox"]:disabled + label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
  cursor: not-allowed;
}
.switch.switch-primary input[type="checkbox"]:checked + .cr:before {
  background: #c9c9c9;
}
.switch.switch-danger input[type="checkbox"]:checked + .cr:before {
  background: #f44236;
}
.switch.switch-success input[type="checkbox"]:checked + .cr:before {
  background: #1de9b6;
}
.switch.switch-warning input[type="checkbox"]:checked + .cr:before {
  background: #f4c22b;
}
.switch.switch-info input[type="checkbox"]:checked + .cr:before {
  background: #3ebfea;
}
.switch.switch-alternative input[type="checkbox"]:checked + .cr:before {
  background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}

/**====== Switches css end ======**/

/* *************New react table start css(SHLOK BAROT)************** */
.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: #f8f8f8;
  /* background-color: #E5E9ED; */
  border-bottom: 1px solid #eeeeee;
}
.ReactTable {
  border-radius: 5px !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}
.rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  color: #9e9e9e !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  float: left !important;
  color: #4a4a4a !important;
  font-weight: 500;
  white-space: normal;
}
.ChatElementReact
  .ReactTable
  .rt-thead
  .rt-resizable-header:last-child
  .rt-resizable-header-content {
  float: right !important;
}
.ChatElementReact .react-tabel-button {
  float: right;
}
.chatRules
  .ReactTable
  .rt-thead
  .rt-resizable-header:last-child
  .rt-resizable-header-content {
  float: right !important;
}
.chatRules .react-tabel-button {
  float: right;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: none !important;
  padding: 20px 25px !important;
  /* color: #393a3a !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px; */
}
.ReactTable .rt-tbody .rt-td {
  padding: 18px 20px !important;
  border-right: none !important;
  font-weight: 500;
}
.settings-align .ReactTable .rt-tbody .rt-td,
.settings-align .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.settings-align-actions {
  display: flex;
  align-items: center;
}
.settings-align-actions .react-tabel-button {
  flex: 0 0 57px;
}
.settings-align .del-btn,
.settings-align .downloadaction {
  margin-right: 20px;
}
.inner-custom-react-table .ReactTable .rt-tbody .rt-td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
#inner-custom-react-table .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding: 15px 20px 9px !important;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 15px !important;
  /* color: #393a3a !important;
  font-weight: 600;
  text-align: left;
  font-size: 13px; */
}
#tic-det-assign .ReactTable .rt-td {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
#tic-det-assign .ReactTable .rt-th {
  font-weight: 700 !important;
  color: #9b9b9b !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.react-tabel-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: 57px;
  height: 27px;
  min-width: auto;
  padding: 0px !important;
  color: #ffffff;
}
.rt-tableMoveImge {
  flex: 0 0 auto !important;
  width: 0px !important;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -2px;
}
.btn-padding-rTbale {
  padding-top: 20px !important;
}
/* .rt-resizable-header {
  width: 0px !important;
} */
.headerAdminStyle {
  background: aqua !important;
}
.store-settings-cntr > .rt-td:nth-child(1) {
  width: 50%;
}
/* .rt-th{
  display: none !important;
} */

/* Drag and drop table By Shlok  */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 20px 25px !important;
}
table span {
  font-size: 13px !important;
}
.ant-table-thead > tr > th {
  background: #e5e9ed !important;
}
table thead tr {
  background-color: #e5e9ed !important;
}
/* End css  */
/* Multi-Select Drop Down css start by SHLOK */
.dropDown-multiselect {
  width: 295px !important;
  height: calc(2.55rem + 2px);
}
.add-valueLogo-Dd {
  padding: 4px 8px;
  cursor: pointer;
  float: right;
  color: rgb(42, 112, 193);
}
.sweetAlert-inCategory {
  color: #2561a8;
  float: right;
}
/* Scrolling css start By Shlok*/
.ant-select-dropdown-menu-vertical::-webkit-scrollbar {
  width: 5px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}

.ant-select-dropdown-menu-vertical::-webkit-scrollbar-thumb:hover {
  background: #d2ddeb;
  border-radius: 10px;
}
/* Scrolling css End */
.ant-select-dropdown ul .ant-select-dropdown-menu-item:last-child {
  background: #f9f9f9;
}
/* Multi select drop down css End */
/*********8 Alert page css Start *********/
.alert-tableImge {
  width: 20px;
  height: 18px;
  margin-left: 15px;
}
.communication-labelHeader {
  color: #393a3a !important;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
}
.right-sect-div h4 {
  color: #2561a8;
  font-size: 17px;
  font-weight: 600;
  padding-top: 20px;
}
.ant-checkbox + span {
  padding-right: 18px !important;
  font-weight: 500 !important;
}
.cre-str-alrt .ant-checkbox + span {
  padding-right: 9px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2561a8 !important;
  border-color: #2561a8 !important;
}
.text-areaModel {
  background-color: #ffff;
  padding: 12px;
  height: auto;
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #f1f0f0;
  resize: none;
}
.notification-placeholder {
  position: static;
}
.noti-plchldr-cntr {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
/* Alert page css End */
/* Next pop-up in Alert Page start css */
#Alert-next-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 250px;
  margin-top: -54px;
  display: inline-block;
  width: 780px;
  height: auto;
}
.alert-nav-tabs3 {
  border-bottom: 1px solid #ececec;
  border: none;
  margin-left: 270px;
}
.alert-nav-tabs3 .nav-link {
  color: #4a4a4a;
  font-weight: 700;
  padding: 12px 20px;
  border: 0;
}
.alert-nav-tabs3 .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  /* box-shadow: none; */
}
.container-fluid-alert {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}
.position-relative-alert {
  position: inherit !important;
  background: #f3f3f3;
  padding-bottom: 15px;
  margin-top: -25px;
}
.alert-tabs {
  padding: 15px 20px;
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
}
.margin-Alerttab {
  margin-left: 250px;
}
.cancelImg-alert {
  height: 20px;
  float: right;
  margin-top: -42px;
  margin-right: -13px;
}
.alert-sec-div h3 {
  color: #2561a8;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 7px;
}
.alert-sec-div {
  padding: 25px 45px;
}
.alert-main-popuplbl {
  font-size: 22px;
  font-weight: 500;
  color: #2561a8;
  padding-bottom: 20px;
  margin-top: -10px;
}
/* pop-up End css */
/* Email-test editor */
#cke_32 {
  display: none;
}
#cke_47 {
  display: none;
}
#cke_1_bottom {
  display: none;
}
#cke_editor1 {
  border-radius: 3px;
}
/* #cke_editor1 {
  margin-left: 0 !important;
  width: 100% !important;
} */
#cke_24 {
  display: none;
}
.textbox-email-editor {
  border: 1px #f4f4f4 solid;
  width: 240px;
  margin-left: 60px;
  height: 34px;
  border-radius: 3px;
  padding-left: 10px;
}
.text-box2 {
  margin-left: 57px;
}
.text-box3 {
  margin-left: 49px;
}
.text-box4 {
  margin-left: 28px;
}
.div-padding-alert {
  padding-left: 15px;
}
.m-t1 {
  margin-bottom: -10px;
}
.label-color-alert {
  color: #807e7e;
  padding-top: 5px;
}
.butn-2 {
  background-color: #2561a8;
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}
.div-button1 {
  float: right;
  margin-top: 25px;
  margin-bottom: 20px;
}
/* .sms-mainLabel{
  padding: 25px 40px;
  margin-top: -70px;
  color: #2561a8;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 7px;
} */
/* Template page css Start by Shlok */
.template-table .ReactTable .rt-tbody .rt-td {
  padding: 18px 25px !important;
  border-right: none !important;
  font-weight: 500;
}
.template-table .rt-tr .rt-th:last-child {
  margin-left: 20px !important;
}
.template-table .rt-tr .rt-th:last-child div {
  margin-left: 20px !important;
}
.modal-header {
  border: none !important;
  padding: 0;
}
.modal-body {
  padding: 0 !important;
}
/* Template css End */
/* Add-Search My Ticket page  start css*/
.addSearch-header {
  background-color: #ecf2f4;
  width: auto;
  height: 55px;
  padding: 12px 20px;
}
.mobile-noAddsearch {
  font-weight: 700;
  font-size: 16px;
}
.arrowImg-addSearch {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.label-addsearch {
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}
.paste-addSearch {
  width: 18px;
  margin-left: 12px;
  cursor: pointer;
}
.bck-AddSearch {
  background: #f5f8f9;
  height: auto;
}
.addsearch-div {
  padding: 15px;
}
.addsearch-div .card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.addSearchCard {
  padding: 50px 270px;
  height: 100vh;
}
.label1-AddSearch {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.label2-AddSearch {
  font-size: 16px;
  font-weight: 500;
}
.search-customerAddSrch {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 90%;
  height: 43px;
  padding: 10px;
  border: none;
}
.notFound-addSrch {
  width: 35px;
  height: 30px;
}
.div-notFound {
  margin-top: 60px;
  text-align: center;
}
.div-notFoundaddseacr {
  margin-top: 60px;
  text-align: center;
  width: 90%;
}
.lbl-count-foundData {
  font-size: 24px;
  font-weight: 600;
  margin-top: 15px;
}
.btn-addCustomer {
  color: #2662a9;
  background-color: #fbfbfb;
  border-color: #2662a9;
  font-weight: 600;
  height: 34px;
  padding-top: 5px !important;
  margin-top: 15px;
}

.lbl-popup-title {
  font-size: 18px;
  font-weight: 500;
}
#AddSearchModel {
  position: relative;
  padding: 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: -60px auto;
  width: 670px;
  border-radius: 10px;
}
.pop-upAddSearchPD {
  padding: 0px 15px;
}
.row-margin1 {
  margin-top: 20px;
  margin-bottom: 15px;
}
.radio-btn-margin {
  margin-top: 10px;
}
.datePicker-modal {
  background: #ecf2f4 !important;
  border: none !important;
  background: #ecf2f4 url("../../assets/Images/calender.png") no-repeat right 7%
    center !important;
  background-size: 15px !important;
  height: 40px !important;
  padding: 10px;
}
.btn-float {
  float: right;
  margin-top: 15px;
}
.cancel-btn-A {
  margin-right: 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #2561a8;
  font-family: lato;
  font-weight: 400;
}
/* css End */
/* .KBase .cke_browser_webkit {
  margin-left: 0px !important;
  width: 530px !important;
} */
.collapbtn1 svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -2px 3px;
}
/* .ck-det-cntr iframe {
  padding-top: 15px;
} */
.ck-det-cntr .cke_top {
  height: 120px !important;
}
.ck-det-cntr-custom .cke_top {
  height: 80px !important;
  background: #fff;
  border-color: #f3f3f3;
}
.ck-det-cntr-custom .cke {
  border-color: #f3f3f3;
}
.ck-det-cntr-custom .colladrow label {
  font-size: 14px;
}
.ck-det-cntr-custom .file-upload1 + label {
  margin-right: 0;
}
.ck-det-cntr-custom .inputcc,
.ck-det-cntr-custom .inputcc-one,
.ck-det-cntr-custom .CCdi1 {
  border-color: #f3f3f3;
  font-weight: 400;
}
.ck-det-cntr-custom .CCdi1 {
  border-left: 0;
  padding-right: 0;
  padding-left: 0;
}
.ck-det-cntr .cke_contents {
  height: auto !important;
}
.ck-det-cntr .cke_inner {
  min-height: 380px;
  height: auto;
}
.KBas .cke_contents {
  height: 160px !important;
  width: auto !important;
}
.KBas .cke_bottom {
  display: none !important;
}
.KBase .cke_combo_text {
  padding: 0px !important;
}
.KBase .cke_top {
  padding: 0px !important;
}
/* css start Knowledge base pop-up modal by Shlok */
.myTicket-btn-A {
  min-width: 0px;
  /* padding: 12px; */
  margin-top: 0px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
  height: 40px;
  font-size: 14px;
}
.assign-modal-KBase .modal-lg {
  max-width: 435px;
  margin-right: -20px !important;
  margin-top: 115px !important;
}
.assign-modal-div {
  /* padding: 0px 40px 0px 30px; */
  padding: 0px 30px;
}
.assign-modal-header {
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eae9e9;
}
.assign-modal-body {
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-top: 1px solid #eae9e9;
  margin-top: 18px;
}
.txt-btmSpace {
  margin-bottom: 15px;
}
.srch-mleft-spc {
  /* margin-left: 120px; */
  float: right;
  width: auto;
  margin-top: 5px;
}
.assign-btn {
  height: 43px;
}
.anchorTag-clear {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 15px;
}
.assign-modal-body table thead tr {
  background-color: white !important;
  border-bottom: none !important;
}
.assign-modal-body table th {
  font-size: 14px;
  padding: 10px 5px;
  color: #bbb8b8;
}
.assign-modal-body table tbody tr {
  border-bottom: none;
}
.assign-modal-body table td {
  font-size: 14px;
  color: #9b9b9b;
  padding: 10px 5px;
}
.assign-hdphone {
  margin-right: 8px;
}
.assign-modal-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  /* width: 359px; */
  width: 100%;
  height: 236px;
  border: none;
  padding: 15px;
  resize: none;
  margin-top: 15px;
}
.btn-assign-tikcet {
  height: 43px;
  width: 360px;
  margin-top: 12px;
}
.assign-butn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.width {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Css End Knowledge base */
/* Dashboard Assign pop up modal css start*/
#AssignPop-up {
  /* max-width: 450px; */
  max-width: 520px;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  /* margin-left: 800px; */
  /* margin-left: 815px; */
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
.assign-modal-headerDashboard {
  padding: 0px 20px 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eae9e9;
}
.assign-modal-body-dash {
  padding: 25px 32px 10px 15px;
  margin-bottom: 15px;
  border-top: 1px solid #eae9e9;
  margin-top: 18px;
}
/* Dashboard Assign pop up Css End */
.fo a {
  font-size: 17px;
}
.Add-Notes-textarea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 198px;
  border: none;
  resize: none;
  padding: 10px;
}
.tab {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  text-align: left;
}
.notesbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  cursor: pointer;
}
.notesbtn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  /* width: 100%; */
  cursor: pointer;
}
.commentbt {
  margin-top: -50px;
  text-align: right;
  margin-right: 15px;
}
.commentbtn {
  background: #2561a8;
  border: none;
  cursor: pointer;
}
.txt {
  cursor: pointer;
  color: white;
  font-size: 14px;
}
.plusminus {
  margin-top: -32px;
  float: right;
}
.li-1 {
  border: 1px solid #cad4d7;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.d-block {
  padding: 10px 30px !important;
}
.logou {
  padding-bottom: 10px !important;
}
.pink1 {
  padding: 7px 10px;
  border: 0px;
}
.pink1 .pink2 {
  margin-top: 0px;
}
.pink2 input + label {
  padding-left: 0;
}
.draft td {
  padding: 20px 15px !important;
  color: #9b9b9b !important;
}
.claimplus {
  float: right;
  cursor: pointer;
  height: 35px;
}
.plusline12 {
  border-left: 1px solid #9b9b9b;
  padding-top: 10px;
  padding-bottom: 24px;
}
.plusline1 {
  border-right: 1px solid #9b9b9b;
  padding-top: 17px;
  padding-bottom: 18px;
}
.plusline13 {
  border-right: 1px solid #9b9b9b;
  padding-top: 8px;
  padding-bottom: 27px;
}
.plusline2 {
  border: 1px solid #9b9b9b;
  padding-top: 9px;
  padding-bottom: 9px;
}
/* My Ticket page email Editor css start by Shlok */
.myTicketEmail .cke_chrome {
  display: block;
  padding: 0;
}
.myTicketEmail #cke_editor1 {
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  border: 1px solid #d1d1d1;
  border-top: none;
}
.myTicketEmail .cke_top {
  border-bottom: 1px solid #e6e6e6;
  background: #fffefe;
  padding: 6px 8px 2px;
  white-space: normal;
}
.sendEmail-btn {
  margin-top: -10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
  font-size: 15px;
  width: 128px;
  height: 43px;
}
.sendmail-Img {
  width: 20px;
  height: 17px;
  margin-top: -5px;
  margin-right: 8px;
}
.myTicketEmail #cke_editor1 {
  margin-left: 0;
  width: 100%;
}
/* Email Editor css End*/
/* myTicket css start for comment pop up by Shlok*/
.commentsDwarer .ant-drawer-content-wrapper {
  width: 580px !important;
}
.btn-addMoreTask {
  color: black;
  background-color: #fff;
  font-weight: 600;
  float: right;
  margin-top: -10px;
}
.lblHeader-drawer {
  font-weight: 700;
  font-size: 16px;
}
.commentsDwarer .ant-drawer-body {
  padding: 0px !important;
}
.drawer-header-1 {
  padding: 15px 20px 10px;
  background: #ecf2f4;
}
.drawer-hr {
  margin-top: 0px;
}
.deleteImg {
  height: 16px;
  width: 13px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
  margin-right: 3px;
}
.knowledgeTbl table tr td:last-child,
table tr th:last-child {
  /* text-align: right; */
  float: left;
}
.active .myTciket-tab-span {
  background-color: #2561a8;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  padding: 3px 6px 4px 6px;
}
.comment-padding {
  padding: 30px;
}
.table-column {
  float: left;
}
.cmt-lbl {
  font-weight: 700;
  font-size: 15px;
}
.cmdtxt-2 {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 8px;
  border: 1px solid;
  border-color: #949090;
}
.cmt-textarea {
  background-color: #ffff;
  padding: 8px;
  /* height: auto; */
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-color: #949090;
  resize: none;
  border: 1px solid;
}
.frm-margin {
  padding-top: 15px;
  padding-bottom: 15px;
}
.frm-margin1 {
  padding-bottom: 15px;
}
.cmt-regtangleDDL {
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding-left: 14px;
  padding-right: 40px;
  border-color: #949090;
  background: #ffffff url("../Images/down-1.png") no-repeat right 12px center;
  background-size: 16px;
  cursor: pointer;
  border: 1px solid;
}
.select-CmtDDl {
  color: #585454;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.cmdtxt-2::placeholder {
  color: black;
}
.cmt-textarea::placeholder {
  color: black;
}
/* myTicket css end */
/* Dashboard datepicker css start by kalpesh  */
.react-datepicker button {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
  margin-right: 0;
}
.table-expandable-sctn .react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.55rem solid transparent;
  z-index: 1;
}
.table-expandable-sctn .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.table-expandable-sctn .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.table-expandable-sctn .react-datepicker-wrapper {
  width: 100%;
}
.allspc {
  margin-bottom: 25px;
}
/* dashboard datepicker css end */
/* My Ticket/Eye Button css Start By Kalpesh */
.customerBill {
  padding: 20px 0 0 20px;
  margin-bottom: 20px;
}
#BillInvoice-popup .customer-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
#BillInvoice-popup .cross {
  float: right;
  margin-top: 15px;
  cursor: pointer;
}
#BillInvoice-popup .down-header {
  width: 24px;
  height: 10px;
  padding-left: 10px;
  cursor: pointer;
  transform: rotate(90deg);
  margin-top: -15px;
}
#BillInvoice-popup
  .switch.switch-primary
  input[type="checkbox"]:checked
  + .cr:before {
  background: #1ab394;
}
#BillInvoice-popup .usericon {
  width: 22px;
  height: 22px;
  margin-top: -10px;
}
.customer-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 9px !important;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.namepad {
  padding: 0;
  /* padding: 0 0 0 35px; */
  margin-bottom: 20px;
  display: grid;
}
.fullna {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}
.namedi {
  color: #000000;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  word-break: break-all;
}
.xyz {
  background-color: #edf2f4;
}
.calnex {
  float: right;
  padding: 0px 28px 18px 0px;
}
.calnexbtn {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  width: 134px;
  height: 43px;
}
.calnexbtn1 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 134px;
  height: 43px;
  margin-left: 10px;
  cursor: pointer;
}
.calnexbtn1-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.calnexbtn-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.search-orderhis {
  border-radius: 4px;
  width: 300px;
  height: 43px;
  margin-left: 2px;
  border: none;
  cursor: pointer;
  padding: 10px;
}
.histo {
  padding: 20px 0 25px 15px;
}
.tablehistrical {
  margin-top: 25px;
}
.tablehistrical .ReactTable {
  border: none;
  background: #edf2f4;
}
.tablehistrical .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 0px !important;
  background-color: #edf2f4;
  border: none;
}
.historyTable-header {
  float: left;
  padding: 1px 0px 0px 18px;
}
.tablehistrical .rt-thead {
  border: none !important;
  color: #4a4a4a;
  font-size: 13px;
}
.tablehistrical .ReactTable .rt-tbody .rt-td {
  border-bottom: 1px solid #edf2f4;
}
.tablehistricaldetail .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.tablehistrical .rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  color: #000 !important;
}
#hispop-upBck {
  background-color: rgba(77, 92, 116, 0.2);
}
.skipmar {
  margin-top: 38px;
}
.done {
  margin-top: 218px;
}
.skiptext {
  color: #2561a8;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.histOrderHide {
  display: none;
}
.orderswitch {
  margin-top: -27px;
  margin-left: -25px;
}
.switch input[type="checkbox"] + .ord {
  margin-left: 70 !important;
}
.customerOrder-text {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  /*  padding-left: 15px;  */
  /*  padding-right: 15px;  */
}
.customerItem-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-top: 5px;
  padding-left: 75px;
  padding-right: 15px;
}
.order1checkbox {
  padding: 0;
}
.order1checkbox .order2checkbox {
  margin-top: 0;
}
.order1checkbox input + label {
  padding-left: 22px;
}
/* MyTicket/Eye Css End */
/* MyTicket ProgressBar Response Css Kalpesh Start */
.mobilenumber-resp {
  font-size: 10px;
}
.mobilenumber-resp .respo {
  float: left;
  margin-left: 35px;
}
.mobilenumber-resp .resol {
  float: right;
  color: green;
  margin-right: 35px;
}
.mobilenumber-resp .line-respo {
  border-right: 2px solid orange;
  padding: 3px;
  top: 20px;
  left: -26px;
  position: relative;
}
.mobilenumber-resp .line-resol {
  border-right: 2px solid red;
  padding: 3px;
  top: 20px;
  position: relative;
  right: -20px;
}
/* MyTicket Css End */
/* Tooltip css start by Shlok In Edit Button */
.edtpadding {
  padding: 15px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  margin-top: -5px;
  background: white !important;
}
/* Tooltip css End by Shlok In Edit Button */
/* MyTicketNew Css Start Kalpesh */
.newReact .ReactTable .rt-thead .rt-tr {
  font-size: 13px;
  color: #4a4a4a;
  padding: 0 0 0 13px;
  font-weight: 600;
}
.newReact .table-b {
  border-radius: 15px;
  width: 76px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.newReact .ReactTable .rt-tbody .rt-td {
  padding: 12px 20px !important;
  color: #9b9b9b !important;
  border-bottom: 1px solid #eeee !important;
}
/* MyTicketNew Css end*/
/* React table body color css start by shlok */
.alertsTable .rt-tbody .ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 14px;
  color: #a0a0a0 !important;
}
.assign-ticket-table .rt-tr[style="background: rgb(236, 242, 244);"] .rt-td {
  color: #000 !important;
}
.assign-ticket-table .rt-tbody .rt-tr {
  cursor: pointer;
}
.assign-ticket-table .rt-tbody .rt-tr.-padRow {
  cursor: initial;
}
.assign-ticket-table .rt-tbody .rt-tr:hover {
  background: #ecf2f4;
}
.assign-ticket-table .rt-tbody .rt-tr.-padRow:hover {
  background: #fff;
}
.assign-ticket-table .rt-tbody .rt-tr:hover .rt-td {
  color: #000 !important;
}
/* React table body color css end */
/* StoreUser page css start by shlok */
.storeUsers table th {
  font-size: 13px;
  padding: 20px 16px;
}
/* StoreUser page css end by shlok */
/* Reports Css Start Kalpesh */
.reportbutton {
  float: right;
  margin-top: -10px;
  margin-right: 16px;
}
.addplusbtn {
  width: 120px;
  height: 40px;
  background-color: #2561a8;
  cursor: pointer;
}
.addplusbtntext {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.addplusbtnReport {
  width: 120px;
  height: 40px;
  background-color: #2561a8;
  cursor: pointer;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.downloadaction {
  width: 17px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
  /* padding: 6px 8px; */
  line-height: 1;
  box-sizing: content-box;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.reactreport .ReactTable .rt-tbody .rt-td {
  color: #9b9b9b !important;
}
.ticketreport {
  margin-bottom: 10px;
}
.reportpad {
  padding: 30px 40px !important;
}
.ticketreport input {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding: 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  /* background-size: 13px; */
  cursor: pointer;
  border: none;
}
.ticketreport select {
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding-left: 8px;
  padding-right: 25px;
  border-color: #ecf2f4;
  background: #ecf2f4 url("./../Images//table-arr.png") no-repeat right 4px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  height: 39px;
}
.ticketreport label {
  font-size: 13px;
  font-weight: 500;
  font-family: lato;
  text-align: left;
  line-height: 25px;
  display: block;
}
#addreport-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  max-width: 1150px;
}
.entercenter {
  padding: 15px 20px;
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  text-align: center;
}
.nextbutton-text {
  background-color: #2561a8;
  color: #fff;
  font-size: 13px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  width: 150px;
}
.nextbutton1 {
  float: right;
  margin-bottom: 20px;
  margin-right: -1px;
  margin-top: 15px;
}
#nextbuttonpopup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 730px;
  height: 415px;
}
.cancelnextpopup {
  height: 20px;
  float: right;
}
.reportdetail {
  font-size: 20px;
  font-family: lato;
  font-weight: 600;
  text-align: left;
  color: #2561a8;
}
.contpaddre {
  padding: 0;
}
.totalresultcircle {
  background-color: #f3f3f3;
  font-size: 24px;
  width: 190px;
  height: 190px;
  border-radius: 100%;
  text-align: center;
  margin: 65px 0 0 75px;
}
.totalresult {
  display: block;
  font-size: 20px;
  font-weight: 500;
  font-family: lato;
  padding: 65px 0 0 0;
  line-height: 0;
}
.totalresultnumber {
  font-size: 2.5em;
  font-weight: 600;
  color: #2561a8;
  font-family: lato;
}
.Schedulenext {
  width: 270px;
  height: 40px;
  background: #fff;
  color: #2561a8;
}
.Schedulenext1 {
  width: 270px;
  height: 40px;
  background-color: #2561a8;
  color: #fff;
  margin-bottom: 40px;
  cursor: pointer;
}
.buttonschdulesave {
  margin-bottom: 10px;
  margin-top: 75px;
}
.rname input {
  border-radius: 4px;
  width: 270px;
  height: 35px;
  padding: 0 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  cursor: pointer;
  border: none;
}
.ranmetext {
  margin-top: 65px;
  display: grid;
}
.renametext {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  text-align: left;
}
.margin-report {
  margin: auto;
  display: block;
  float: none;
  text-align: center;
}
.reactreport .ReactTable .rt-tbody {
  background: #fff;
}
.report-action {
  /* width: 115px; */
  width: 170px;
  display: flex;
  justify-content: flex-end;
}
.report-action div:first-child,
.report-action div:nth-child(2) {
  flex: 0 0 20%;
}
.report-action div:last-child {
  flex: 0 0 60%;
}
.down-tic-rep input {
  height: 43px;
}
.total-tic-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
/* Reports Css end Kalpesh */
/* storeReports css start by Shlok */
#addStorereport-modal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  /* margin-right: 360px; */
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  min-width: 950px;
}
.ticketstrReport label {
  font-size: 13px;
  font-weight: 500;
  font-family: lato;
  text-align: left;
  line-height: 25px;
  display: block;
}
.ticketstrReport input {
  border-radius: 4px;
  width: 100%;
  height: 37px;
  padding: 10px;
  border-color: #ecf2f4;
  background: #ecf2f4;
  /* background-size: 13px; */
  cursor: pointer;
  border: none;
}
.ticketstrReport select {
  border-radius: 4px;
  width: 100%;
  height: 37px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url("./../Images//table-arr.png") no-repeat right 20px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
}
.mdl-row {
  margin-bottom: 10px;
}
.store-totalresultcircle {
  background-color: #f3f3f3;
  font-size: 24px;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  text-align: center;
  margin: 65px 0 0 75px;
}
/* storeReports css End by Shlok */
/* My Ticket Claim:03 add tadk modal css start by Shlok */
.claim-addTask-btn {
  float: right;
  margin-top: -55px;
}
#ClaimAdd-taskPopup {
  /* border-radius: 8px; */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 360px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  width: 588px;
}
.ClaimAddTadk-modal-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  border: none;
  padding: 15px;
  resize: none;
  margin-top: 15px;
  margin-bottom: 10px;
}
.claim-AddTask-Mdl {
  background: #ecf2f4;
  height: 50px;
  padding: 12px 28px 0px 16px;
  margin: 12px 0px 0px 0xp;
  margin-top: -19px;
  width: 588px;
  margin-left: -19px;
}
#ClaimAdd-taskPopup .cancel {
  background-color: #fff;
  color: #2561a8;
  font-size: 14px;
  font-family: lato;
  margin-right: 1opx;
  cursor: pointer;
  border: none;
}
.claim-hdrMdl {
  font-weight: 700;
  font-size: 16px;
}
.task-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  padding-top: 3px;
  text-align: left;
  margin-left: 20px;
  text-transform: uppercase;
}
.wifiLbl-drawer {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 15px;
}
.addTasklbl-name {
  font-weight: bold;
  color: black;
  display: flex;
}
.addTask-2-d-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: block;
  padding-left: 7px;
}
.status-opn {
  background-color: #f9f9f9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 14px;
  padding: 6px;
}
.taskTab-drawerModal .ant-drawer-content {
  height: 100%;
  width: 435px !important;
}
.taskTab-drawerModal .ant-drawer-content-wrapper {
  width: auto !important;
}
.tasktasb-para {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  /* width: 356px; */
  text-align: left;
}
.task-drawerv-textArea {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 359px;
  height: 166px;
  border: none;
  resize: none;
  padding: 15px;
}
.varunoverflow {
  max-height: 190px;
  overflow-x: hidden;
}
.varunoverflow::-webkit-scrollbar {
  width: 4px;
}
.varunoverflow::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.varunoverflow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.varunoverflow::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar {
  height: 7px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.assign-modal-body-mytick .rt-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.assign-modal-body-mytick .item-selection {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  text-align: center;
}
.varun-taskDrawer {
  font-weight: bold;
  color: black;
  display: inline-block;
  line-height: 30px;
  padding: 0px 5px;
}
.addTask-time-ago {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  padding-left: 7px;
}
.task-drawer-lnl {
  color: #505050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  width: 343px;
  padding: 0;
  margin-left: 35px;
}
.storeImg-drawer {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* margin-left: 14px; */
  padding: 6px;
}
.storeImg {
  width: 20px;
  height: 18px;
  margin-top: -5px;
}
/* My Ticket Claim:03 add tadk modal css end */
/* TicketSystem Css Start Kalpesh */
.marginsystem {
  padding: 0;
}
.column {
  width: 46%;
}
.column1 {
  width: 7%;
  height: auto;
  background-color: #f5f8f9;
}
.paddingsystem {
  padding: 40px 30px 40px 40px;
  position: relative;
  height: auto;
}
.ticket-details-system {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.ticket-details-textarea-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}
.category-select-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  padding: 10px;
  border: none;
}
.High button {
  background-color: #ffffff;
  border: 1px solid #d02139;
  border-radius: 4px;
  height: 43px;
  min-width: 60px;
  /* margin-left: 22px; */
  cursor: pointer;
  color: #d02139;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.High button:focus {
  background-color: #d02139;
  border: 1px solid #d02139;
  border-radius: 4px;
  height: 43px;
  min-width: 60px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.high-button-text:focus {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Low button {
  background-color: #ffffff;
  border: 1px solid #5aa805;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  margin-left: -8px;
  cursor: pointer;
  color: #5aa805;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Low button:focus {
  background-color: #5aa805;
  border: 1px solid #5aa805;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  margin-left: -8px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.low-button-text:focus {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Medium button {
  background-color: #fff;
  border: 1px solid #f5a623;
  border-radius: 4px;
  min-width: 68px;
  height: 43px;
  margin-left: -15px;
  cursor: pointer;
  color: #f5a623;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Medium button:focus {
  background-color: #f5a623;
  border: 1px solid #f5a623;
  border-radius: 4px;
  min-width: 68px;
  height: 43px;
  margin-left: -15px;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.medium-button-text:focus {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Qc button {
  /* background-color: #ffffff;
  border: 1px solid #2561a8; */
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  min-width: 60px;
  height: 43px;
  cursor: pointer;
  /* color: #2561a8; */
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Qc button:focus {
  border: 1px solid #2561a8;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.Etc button {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  min-width: 60px;
  height: 43px;
  margin-left: -50px;
  cursor: pointer;
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.Etc button:focus {
  border: 1px solid #2561a8;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

/* .Qc-button-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */
/* .Etb-button-text {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
} */
.addNote-textarea-system {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 123px;
  border: none;
  resize: none;
  padding: 10px;
}
.myticketlist-header-system {
  background-color: #f5f8f9;
  width: auto;
  /* height: 620px; */
}
.system .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  padding: 20px 0;
  font-size: 16px;
}
.system .nav-tabs .nav-link {
  font-weight: 400;
  padding: 20px 0px;
  border-radius: 3px;
}
.system ul {
  display: grid;
}
.system {
  padding: 15px 10px;
  text-align: center;
}
.system .nav-tabs .nav-item {
  margin-right: 0;
}
.active .system-tab-span {
  background: transparent;
  color: #2561a8;
  display: block;
  font-weight: 600;
  font-family: lato;
  font-size: 10px;
}
.system-tab-span {
  display: block;
  font-size: 10px;
  font-weight: 600;
  font-family: lato;
}
.tabpaddingsystem {
  padding: 0;
  box-shadow: none;
  background: initial;
  height: 100%;
}
.category2 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}
.category1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  word-break: break-all;
}
.systemeditbutton {
  background-color: #fbfbfb;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 93px;
  line-height: 0;
  height: 31.32px;
  cursor: pointer;
  text-align: center;
}
.systemeditbutton-text {
  color: #2561a8;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  /* line-height: 12px;
  width: 23.07px;
  text-align: left;
  cursor: pointer; */
}
.collapsetask {
  padding: 0;
  margin-bottom: 0;
  background-color: #ecf2f4;
  border: none;
}
.btn {
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.btn-link {
  font-weight: 500;
  color: #000;
}
.btn-link:hover {
  color: #000;
  text-decoration: none;
}
.createtasksystem {
  background-color: #2561a8;
  border-radius: 4px;
  width: 254px;
  height: 45px;
  cursor: pointer;
}
.createtasksystem-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}
.systemtaskreact {
  padding: 0 10px;
}
.systemtaskreact .ReactTable {
  border: none;
}
.systemtaskreact .ReactTable .rt-thead.-header {
  background-color: transparent;
}
.systemtaskreact .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px !important;
}
.systemtaskreact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 15px !important;
}
.systemorder {
  background-color: #ecf2f4;
}
.arrow-imgtask-1 {
  width: 18px;
  height: 18px;
  /* float: right; */
  margin-right: 10px;
  margin-top: 0px;
  margin-left: 15px;
  cursor: pointer;
}
.systemordersearch {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  float: right;
  padding: 10px;
}
.systemorder-imgsearch {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
}
.addmanualbtn {
  text-align: center;
  margin-top: 20px;
}
.addmanual {
  width: 142px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #2561a8;
  color: #2561a8;
  line-height: 0;
  cursor: pointer;
}
.drpdwn-order > div > div {
  position: absolute !important;
  left: 0 !important;
  top: 100% !important;
  z-index: 1;
  max-height: 100px !important;
}
.addmanuallytext1 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  border: none;
  padding: 10px;
}
.addmanuallytext1::placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}
.addmanuallytext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
#addOrderTableModal {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  min-width: 80%;
  border-radius: 10px;
}
.reacttableordermodal .ReactTable {
  border: none;
}
.reacttableordermodal .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 12px 15px !important;
}
.reacttableordermodal .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: #fff;
  border: none;
  min-width: max-content !important;
}
.searchtextpopup {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 100%;
  height: 43px;
  float: right;
  padding: 10px;
}
.searchtextimgpopup {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
}
.orderdetailpopup {
  font-size: 16px;
  font-weight: 600;
  font-family: lato;
  color: #4a4a4a;
  margin-top: 8px;
}
.minus-imgorder {
  width: 21px;
  height: 2px;
  /* float: right; */
  margin-right: 5px;
  margin-top: 12px;
  margin-left: 15px;
  cursor: pointer;
}
.orderswitchitem {
  margin-top: 7px;
  margin-left: -106px;
  margin-right: 8px;
}
.storemainrow {
  margin-left: 0;
  margin-right: 0;
  background-color: #ecf2f4;
  border-radius: 5px;
  height: 54px;
}
.storeplusline {
  cursor: pointer;
  float: right;
  padding: 15px 0 0 0;
  height: 54px;
}
.storeplusline13 {
  cursor: pointer;
  float: right;
  padding: 0 0 0 0;
  height: 54px;
}
.systemstoredropdown {
  background-color: #dbe4e7;
  width: 70%;
  height: 30px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
  background: #dbe4e7 url("../Images/dropDown.png") no-repeat right 12px center;
}
.systemstoredropdown1 {
  background-color: #dbe4e7;
  width: 35%;
  height: 30px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
  background: #dbe4e7 url("../Images/dropDown.png") no-repeat right 12px center;
}
.systemstordercustomer {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  font-family: lato;
  border: none;
  padding: 5px;
  border-radius: 5px;
  line-height: 14px;
}
#addStoreTableModal {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  min-width: 83%;
  border-radius: 10px;
}
.newtabstore {
  background: none;
  padding-left: 15px;
  padding-right: 0;
}
.store-nav-tabs {
  border-bottom: 1px solid gray;
  border: none;
  margin-left: 0px;
}
.store-nav-tabs .nav-link.active {
  color: #2561a8 !important;
  border: none;
}
.store-nav-tabs .nav-link {
  color: #4a4a4a !important;
  font-weight: 700;
  padding: 0;
  margin-top: 3px;
  border: 0;
  margin-left: 15px;
}
.tabcontentstore {
  padding: 0px;
}
.linestore1 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
}
.linestore2 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
  margin-bottom: 10px;
}
.linestore3 {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 0px;
  display: block;
  border-bottom: thick;
  /* margin-bottom: 10px; */
}
.reactstoreselect .ReactTable {
  border: none;
}
.reactstoreselect .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 20px 4px !important;
  /* padding: 12px 15px !important; */
}
.reactstoreselect .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: transparent;
  border: none;
}
.switchmargin {
  margin-bottom: 8px;
  margin-left: -102px;
}
.ticktSycard {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.reacttableordermodal .ReactTable .rt-tbody {
  overflow: hidden;
  min-width: max-content !important;
}
.storedetailtabsbutton {
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}
.addstoretabsbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 145px;
  height: 43px;
  color: #fff;
  cursor: pointer;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
#CreateTicket1-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 10px;
  margin-top: 45px;
  margin-bottom: 4px;
  width: 184px;
  display: inline-block;
}
/* TicketSystem Css End Kalpesh */
/* UserProfile CSS Start By Kalpesh */
.profile-settings-cntr {
  padding: 35px 300px;
}
.centerprofile {
  padding: 100px 0px 20px;
  margin: auto;
}
.profilemain {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  padding-bottom: 20px;
  overflow: hidden;
}
.forwardpasstext {
  color: #2561a8;
  font-family: lato;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.half-circle {
  position: relative;
}
.half-circle::before {
  position: absolute;
  content: "";
  height: 210px;
  width: 100%;
  background-color: #2561a8;
  border-left: 0;
  box-sizing: border-box;
  border-radius: 50%;
  top: -100px;
}
.profimg {
  width: 60px;
  height: 60px;
  margin-top: 30px;
}
.profimg1 {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}
.uploadtextprofile1 {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: lato;
  color: #9b9b9b;
  padding: 5px 15px;
  cursor: pointer;
  background-color: #ecf2f4;
}
.imguserupload {
  text-align: center;
  width: 150px;
  height: auto;
  background: #fff;
  border-radius: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  position: relative;
  top: 35px;
  box-shadow: 0px 5px 4px 0px #ddd;
  z-index: 1;
}
#ProfileForgot-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.modalforgotpadding {
  padding: 25px 80px;
}
.Changepasswordheader {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  text-align: left;
  padding-bottom: 20px;
}
.file-uploadprofile + label {
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
/* UserProfile CSS End By Kalpesh */
/* MyTicket Comment Collapse Css start Kalpesh */

.commentcollapseTicket .card-body {
  padding: 0px;
}
.commentcollapseTicket .Commentlabel {
  background-color: #ecf2f4;
  padding: 10px 0 10px 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.comment-disp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commentcollapseTicket .commenttextmessage {
  padding: 25px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.Commentlabel1 {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.SendCommentBtn {
  text-align: end;
  /* margin-right: 25px; */
  margin-bottom: 20px;
}
.SendCommentBtn1 {
  background-color: #2561a8;
  border-radius: 4px;
  width: 128px;
  height: 43px;
  color: #fff;
  cursor: pointer;
}
.comment-line {
  border: 1px solid #cad4d7;
  height: 42px;
  margin-top: -42px;
  margin-right: 40px;
  float: right;
}
.CommentMinus-img {
  width: 15px;
  height: 2px;
  margin-left: 0;
  margin-top: 12px;
  cursor: pointer;
  float: right;
  margin-right: 14px;
}
.myTicketCommentCollapse #cke_editor2 {
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
  border: 1px solid #d1d1d1;
}
.myTicketCommentCollapse .card-body {
  padding: 0;
}
.myTicketCommentCollapse #cke_2_bottom {
  display: none;
}
/* MyTicket Comment Collapse Css start Kalpesh */
/* Header Headphone Img Pop-up CSS Start Kalpesh */
.headphoneheader {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
#HeadPhoneHeader-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 300px;
  display: inline-block;
  height: 300px;
}
.logincenter {
  text-align: center;
  background-color: #f3f3f3;
  margin-left: 0;
  margin-right: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 14px 0;
}
.logincenterimg {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  margin-top: 45px;
}
.logintoivr {
  font-family: lato;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.agenttextrow {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  text-align: center;
}
.agenttextrow1 {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 3px;
}
.agenttext {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 85%;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
.agentidtext {
  font-size: 14px;
  font-weight: 600;
  font-family: lato;
  color: #4a4a4a;
  margin-left: 10px;
}
.loginbtnagent {
  width: 228px;
  height: 35px;
  background-color: #2561a8;
  color: #fff;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.easyrewardlogo {
  width: 100px;
  height: 25px;
}
.poweredby {
  font-size: 11px;
  font-family: lato;
  margin-right: 5px;
  color: lightgray;
}
#Login-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 350px;
  display: inline-block;
  height: 370px;
}
.amitsinghlogo {
  width: 60px;
  height: 60px;
}
.amitsinghcenter {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 25px;
}
.amitsinghcenter2 {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 10px;
}
.amitsinghcenter1 {
  margin-left: 15px;
  margin-right: 0;
  text-align: center;
  margin-top: 25px;
}
.amitsinghtext {
  font-size: 15px;
  font-weight: 700;
  font-family: lato;
}
.amitsinghtextonline {
  font-size: 14px;
  font-weight: 600;
  font-family: lato;
  border: 1px solid greenyellow;
  margin-right: 5px;
  padding: 5px;
}
.amitnextrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}
.logouttamitsingh {
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
  color: #9b9b9b;
}
.logoutImg {
  margin-right: 4px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.status1 input:checked + label::before {
  background-color: #6dd400;
  border: double 3px #fff;
}
.status1 input:checked + label {
  border: 1px solid #6dd400;
  border-radius: 5px;
}

.status1 input + label::before {
  content: "";
  position: absolute;
  background-color: #fff;
  border: 1px solid #6dd400;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 2px;
  top: 9px;
}
.status1 input + label {
  position: relative;
  padding-left: 20px;
}
.logout-label {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
}
.logout-label1 {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px;
}
#NextButton-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 4px;
  width: 410px;
  display: inline-block;
  height: auto;
}
.CallwrapBtn {
  width: 228px;
  height: 35px;
  background-color: #44d7b6;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.SwitchToProgBtn {
  width: 228px;
  height: 35px;
  background-color: #32c5ff;
  color: #fff;
  border: none;
  font-family: lato;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.amitsinghcallrow {
  text-align: center;
  background-color: #f3f3f3;
  margin-left: -10px;
  margin-right: -10px;
  padding: 41px 0;
  margin-top: 25px;
}
.amitsinghwaiting {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 228px;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
.idletimeamit {
  font-size: 14px;
  font-weight: 400;
  font-family: lato;
  color: #44d7b6;
  margin-top: 3px;
  margin-left: 10px;
}
.amitnextbuttonrow1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: 45px;
  text-align: center;
}
.amitnextbuttonrow3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.amitnextbuttonrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 0px;
  text-align: center;
}
.takeabreak {
  border: 1px solid #e5e5e5;
  width: 120px;
  margin: 0 auto;
  padding: 5px;
}
.takebreaktext {
  font-size: 13px;
  font-family: lato;
  font-weight: 400;
  color: #795548;
  cursor: pointer;
}
.backtohomerow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.backtohomelogoutrow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
}
.logoutbacktohome {
  font-size: 13px;
  font-family: lato;
  font-weight: 400;
  color: #9b9b9b;
}
.backtohometext {
  font-size: 12px;
  font-family: lato;
  font-weight: 400;
  color: #2561a8;
  cursor: pointer;
}
.CallwrapBtnwaiting {
  width: 228px;
  height: 35px;
  background-color: #e5e5e5;
  border: none;
  color: #9b9b9b;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.holdbtn {
  width: 228px;
  height: 35px;
  background-color: #1cd188;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.tranferbtn {
  width: 228px;
  height: 35px;
  background-color: #3d7aff;
  border: none;
  color: #fff;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 1px;
}
.incomingcalltext {
  font-size: 12px;
  font-weight: 400;
  font-family: lato;
  color: #4a4a4a;
  margin-top: 3px;
  margin-left: 77px;
}
.incomingcallrow {
  margin-left: 0;
  margin-right: 0;
}
.tooltiptransfercallrow {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 3px;
}
.agenttext-tooltip {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  background-color: #fff;
  padding: 10px;
}
/* Header Headphone Img Pop-up CSS End Kalpesh */
.addcustdate .react-datepicker-wrapper {
  width: 100%;
}
.addcustdate .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.addcustdate .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.colladrow label {
  font-family: lato;
  font-size: 15px;
  color: #9b9b9b;
  margin-right: 10px;
}
.CCdi {
  border: 1px solid #9b9b9b;
  padding: 5px 5px;
  width: 145px;
  height: 30px;
}
.CCdi1 {
  border: 1px solid #9b9b9b;
  padding: 5px 5px;
  width: 145px;
  height: 30px;
  border-right: none;
}
.colladrow .fileup {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  cursor: pointer;
}
.colladrow .send,
.my-tic-send {
  background: #2561a8;
  color: #fff;
  font-size: 14px;
  height: 40px;
  width: 115px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.my-tic-send {
  float: right;
  margin-top: 20px;
  margin-right: 15px;
}
/* .my-tic-ckeditor {
  margin-top: 20px;
} */
.my-tic-ckeditor .cke_bottom {
  display: none;
}
.colladrow .sav {
  background: #fff;
  color: #2561a8;
  font-size: 14px;
  height: 40px;
  width: 115px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.colladrow .send1 {
  background: #2561a8;
  color: #fff;
  font-size: 13px;
  height: 30px;
  padding: 0;
  line-height: 0;
  min-width: 65px;
  border-radius: 5px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.colladrowa .ticsys {
  margin: 0 18px;
}
.colladrow .sav1 {
  background: #fff;
  color: #2561a8;
  font-size: 13px;
  padding: 0;
  line-height: 0;
  height: 30px;
  width: 75px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #2561a8;
  cursor: pointer;
}
.EMFCImg {
  width: 20px;
  height: 15px;
}
.EMFCText {
  margin: 0 15px;
  color: #000;
  text-transform: capitalize;
}
.kblink-auto1 {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.kblink-auto2 {
  margin: 45px 0 0 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.myTicketCommentCollapse .cke_bottom {
  display: none;
}
.myTicketEmail .cke_bottom {
  display: none;
}
.file-upload1 + label {
  font-weight: 400;
  cursor: pointer;
}
.colladrow .one {
  color: #2561a8;
  margin-left: -20px;
}
.myTicketEmail {
  position: relative;
}
.colladrowa {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.DashBoarReact .table-b {
  border-radius: 15px;
  width: 76px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.marginimg {
  margin-left: -20px;
}
.DashBoarReact .ReactTable .rt-thead.-header .pinkmargin {
  margin-left: 5px;
}

.pinkmargin input + label {
  padding-left: 25px;
}
.DashBoarReact .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 12px;
}
.MyTicketListReact .ReactTable .rt-thead.-header .pinkmargin {
  margin-left: 5px;
}
.MyTicketListReact .rt-th {
  text-align: left;
}
.newReact .rt-tbody .pinkmargin {
  margin-left: 5px;
}
.newReact .pink1 {
  padding: 0;
}
/* MyTicket KbLink Css Start Kalpesh */
.knoim {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.knoim1 {
  width: 17px;
  height: 17px;
  margin-top: -5px;
  margin-right: 10px;
}
#KbLink-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  min-width: 85%;
  height: 565px;
}
#logout-ovrlykb {
  background-color: rgba(77, 92, 116, 0.2);
}
.kblinkright {
  background-color: #ecf2f4;
  padding: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 565px;
}
.thumbtick {
  border: 1px solid #ecf2f4;
  border-radius: 5px;
  margin-right: 5px;
  height: 45px;
}
.thumbtick-plus {
  border: 1px solid #ecf2f4;
  border-radius: 5px;
  margin-right: 5px;
  margin: auto 0;
  width: 32px;
  height: 35px;
  cursor: pointer;
}
.cancalImg-kb {
  width: 15px;
  height: 15px;
  float: right;
  margin-top: -15px;
  cursor: pointer;
  margin-right: -25px;
}
.knokb {
  padding: 25px 60px;
}
.knokb-a {
  padding: 25px 40px;
}
.table-details-data-modal {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  white-space: normal;
  display: inline-block;
}
.knokb-a h5 {
  margin-bottom: 25px;
  color: #2561a8;
}
.kblink-search {
  background: #2561a8;
  color: #fff;
  font-size: 15px;
  height: 40px;
  width: 125px;
  border: 1px solid #2561a8;
  line-height: 11px;
  float: right;
  cursor: pointer;
}
.down-icon-kb1 {
  width: 20px;
  height: 15px;
  padding: 3px;
  vertical-align: top;
  cursor: pointer;
  float: right;
  margin: 10px 0;
}
.textkb {
  border-bottom: 1px solid #ecf2f4;
}
.select-category-placeholderkblink {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
}
.kblinkrectangle-9 {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #fff;
  background: #fff url("../Images/dropDown.png") no-repeat right 12px center;
  background-size: 13px;
  cursor: pointer;
  border: none;
}
.copyblue-kb {
  width: 20px;
  height: 22px;
  margin-right: 3px;
}
.copyblue-kbtext {
  font-weight: 600;
  color: #2561a8;
  font-size: 15px;
  font-family: lato;
}
.viewpolicy-kb {
  margin-left: 5px;
  width: 22px;
  height: 25px;
  margin-top: -5px;
}
.kblink-auto {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.autosavekb {
  width: 15px;
  height: 16px;
  margin-top: -4px;
}
/* MyTicket KbLink Css end Kalpesh */
/* Dashboard ReactTable Css Start Kalpesh */
.dash-task-popup-new {
  width: 220px;
  padding: 20px 20px 25px;
  transform: translateX(0%);
  left: 50%;
  top: initial !important;
  bottom: 34px;
}
.DashBoarReact .pink1 {
  padding: 0;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(1) {
  background-color: #ffdfdf;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(2) {
  background-color: #fff3df;
}
.DashBoarReact .ReactTable .rt-tbody .rt-tr-group:nth-child(3) {
  background-color: #def3ff;
}

.modal-content {
  border: 0;
}

.broembox {
  background-color: #f3f3f3;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.broembox p {
  float: left;
}
.broembox img {
  float: right;
  height: 20px;
  margin-top: 13px;
  cursor: pointer;
}
.broembox label {
  font-size: 16px;
  font-weight: bolder;
}
.broembox span {
  font-size: 12px;
  font-style: italic;
  color: #666;
}
#totalconcount-popup {
  border-radius: 5px;
  position: relative;
  padding: 30px;
  padding-bottom: 5px;
  width: 400px;
}
.totalconcount-popup {
  padding: 15px !important;
}
.counttab td {
  padding: 0 0 25px 0;
  text-align: center;
  font-size: 12px;
}
.counttab label {
  /* font-size: 17px; */
  font-size: 16px;
  color: #2561a8;
}
.counttab span {
  font-size: 12px !important;
  color: #333;
  font-weight: 400;
}

.mob-header {
  display: none !important;
}
.cardasscus .store-task-tabs .nav-tabs .nav-link {
  padding: 0px 30px 5px;
}
.cardasscus .ant-table-row-cell-last {
  text-align: right !important;
}
.cardasscus .card-img {
  width: 140px;
  box-shadow: 0 0 10px 0px #ddd;
  border-radius: 10px;
}
.cardasscus .card-img img {
  height: auto;
  width: 115px;
  margin: auto;
  display: block;
  padding: 20px 10px;
}
.cardasscus .btngreen {
  background-color: #00b894;
  color: #fff;
  padding: 8px 15px !important;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}
.cardasscus .btnred {
  background-color: #ff7163;
  color: #fff;
  padding: 8px 15px !important;
  border: none;
  cursor: pointer;
}
.cardasscus .statusbtnadd {
  background-color: #e5f7f4;
  color: #0ebc9a;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: 600;
}
.cardasscus .statusbtnrej {
  background-color: #ffe3e0;
  color: #ff7171;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: 600;
}
.cardassetspop {
  width: 300px;
}
.cardsresbtn {
  text-align: right !important;
}
.addimg input {
  width: 100%;
}
.pickuppending-table td {
  padding: 10px 10px;
}
.pickuppending-table tr {
  border-bottom: none;
}
.pickuppending-table label {
  display: block;
  margin-bottom: 10px;
}
.pickuppendingcustom .ant-popover-inner-content {
  padding: 10px;
  width: 200px;
}
.pickuppending-table .popbtn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.pickuppending-table .popbtnno {
  background-color: #fff;
  color: #2561a8;
  border: 1px solid #2561a8;
  font-size: 14px;
  padding: 8px 25px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.pickuppending-table .form-control {
  margin: 0 !important;
}
#addressPincode-popup {
  width: auto;
  border-radius: 10px;
}
#article-popup {
  width: 800px;
  border-radius: 10px;
}
#article-popup .heading {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.article-body span {
  text-align: center;
  display: block;
  font-size: 16px;
}
.staffdetailspopup {
  padding: 20px 30px;
}
.staffdetailspopup .popbtn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.staffdetailspopup .popbtnno {
  background-color: #fff;
  color: #2561a8;
  border: 1px solid #2561a8;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.staffdetailspopup label {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 15px;
}
.staffdetailspopup .form-control {
  border: none;
  margin-bottom: 15px;
}
.enterpod {
  border: none;
  width: 125px;
}
.enterpod::placeholder {
  color: #2561a8;
  text-align: center;
  font-weight: normal;
}
#article-popup .tabs-content {
  padding: 0px;
  margin: 0;
}
#article-popup .step-progress {
  padding: 0;
}
#article-popup .footer-buttons {
  padding: 0 10px;
}
#article-popup .btn-prev {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  border-color: #2561a8;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
#article-popup .btn-next {
  background-color: #fff;
  color: #2561a8;
  font-size: 14px;
  border-color: #2561a8;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.airwaybox {
  background-color: #f6f9fb;
  border-radius: 10px;
  border: 1px solid #ddd;
  min-height: 300px;
}
.airwaycontent {
  margin-top: 8%;
}
.airwaycontent .cardtick {
  height: 50px;
  margin-bottom: 15px;
}
.airwaycontent h2 {
  font-size: 28px;
  margin-bottom: 5px;
}
.airwaycontent p {
  font-size: 17px;
  color: #999;
  margin-bottom: 5px;
}
.airwaycontent ul {
  border-top: 1px solid #ddd;
  display: inline-block;
}
.airwaycontent ul li {
  font-size: 18px;
  color: #666;
  padding-top: 5px;
}
.airwaycontent ul li:first-child {
  margin-right: 30px;
}
#article-popup em {
  display: none;
}
.progtrckr li {
  margin-right: 50px;
  padding-left: 20px;
}
#article-popup .progtrckr-done span {
  color: #2561a8;
  font-weight: bold;
}
#article-popup .progtrckr-doing span {
  color: #2561a8;
  font-weight: bold;
}
#article-popup .progtrckr {
  margin: 20px;
  border-bottom: 1px solid #ddd;
  padding: 0;
  padding-bottom: 12px;
  margin-top: 0;
}
#article-popup .multi-step {
  position: relative;
}
.progtrckr li:first-child {
  position: relative;
}
.progtrckr li:first-child:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  background: #2561a8;
  right: -46px;
  top: 12px;
}
.innertabcollapse label {
  display: block;
}
.innertabcollapse table td {
  border-top: none;
  vertical-align: top;
}
.innertabcollapse label b {
  font-weight: bold;
  color: #333;
  font-size: 12px;
}
#shipment-tab table td span {
  display: inline;
}
#shipment-tab .antd-table-order .ant-table-row-expand-icon {
  display: inline;
  margin-left: 10px;
}
#delivered-tab .antd-table-order .ant-table-row-expand-icon {
  display: inline;
}
.pickuppendingcustom {
  display: inline;
  vertical-align: super;
  float: left;
}
.action-w {
  min-width: 150px;
}

.initial-logo {
  width: 210px;
}

@media (min-width: 768px) {
  .storecards .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .storecards .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/* Dashboard ReactTable Css End Kalpesh */
/* MyTicketList ReactTable Start Kalpesh */
.MyTicketListReact .table-b {
  border-radius: 15px;
  width: 85px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.MyTicketListReact .ticketid {
  font-size: 12px;
}
.MyTicketListReact .pinkmyticket {
  padding: 0 0;
}
.tabpadtick {
  padding-bottom: 60px !important;
}
.slatemp-textpopup-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 25%;
}
.newReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 10px !important;
}
/* MyTicket Bottom Header Css Start Kalpesh */
.TicketTabs {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
  padding: 0 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.TicketTabs li {
  padding: 15px 0;
}
.TicketTabs .active {
  border-top: 3px solid #2561a8;
}
.TicketTabs .Sub {
  margin: 0 20px;
}
.TicketTabs .SubR {
  margin: 0 12px;
}
.TicketTabs .SubL {
  margin: 0 12px;
}
.TicketTabs .Ticket {
  width: 22px;
  height: 22px;
}
.TicketTabs .cancel {
  width: 16px;
  height: 16px;
}
.TicketTabs .Subject {
  margin: 0 15px;
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  opacity: 0.501983;
  text-align: left;
}
.TicketTabs .More {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.TicketTabs .MoreUp {
  width: 14px;
  height: 11px;
}
/* MyTicket Bottom Header Css End Kalpesh */
.dashnewpopup li p {
  margin-bottom: 0;
}
.dashnewpopup {
  padding: 0px 10px;
  font-size: 14px;
  border-radius: 8px;
  white-space: initial;
}
.myticlist-expand-sect .nav-tabs .nav-link {
  padding: 20px;
  background: transparent;
  color: #4a4a4a;
  font-weight: 700;
  border: 0;
  margin-right: 0;
}
.myticlist-expand-sect .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  font-weight: 700;
  background-color: transparent;
  padding: 20px;
  border-radius: 0;
}
.myticlist-expand-sect {
  background: #ecf2f4;
}
.myticlist-expand-sect .nav-tabs {
  border-color: #dae2e5;
  position: relative;
  border-bottom: 1px solid #dae2e5;
}
.myticlist-expand-sect .nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 0;
}
.myticlist-expand-sect .save-view-search {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-51%);
}
.myticlist-expand-sect .save-view-search button {
  padding-right: 25px;
  padding-left: 25px;
}
.myticlist-expand-sect button,
.csv-button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}
.csv-button {
  padding-top: 15px;
  padding-bottom: 15px;
}
.csv-dis-btn {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}
.myticlist-expand-sect .save-view-search button.btn-inv {
  padding-right: 45px;
  padding-left: 45px;
}
.myticlist-expand-sect button.btn-inv {
  color: #fff;
  background-color: #2561a8;
  cursor: pointer;
}
.myticlist-expand-sect .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
  margin-bottom: 0 !important;
}
.myticlist-expand-sect input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff url("./../Images/calendar-black.svg") no-repeat right 15px
    center;
  color: #4a4a4a;
}
.myticlist-expand-sect input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.myticlist-expand-sect input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.myticlist-expand-sect select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.myticlist-expand-sect .react-datepicker button {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
  margin-right: 0;
}
.myticlist-expand-sect .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.myticlist-expand-sect .react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.55rem solid transparent;
  z-index: 1;
}
.myticlist-expand-sect .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.myticlist-expand-sect .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.myticlist-expand-sect .myticket-text input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  background: #fff;
  color: #4a4a4a;
}
.dapic .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.dapic .react-datepicker__navigation--previous {
  min-width: auto;
  border-right-color: #ccc;
}
.dapic .react-datepicker__navigation--next {
  min-width: auto;
  border-left-color: #ccc;
}
.diwamargin {
  /* margin-bottom: 8px; */
  margin-bottom: 15px;
}
.ticketsystemeditor .cke_bottom {
  display: none !important;
}
.ticketsystemeditor .kblink {
  position: absolute;
  top: 7px;
  right: 113px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.ticketsystemeditor .kblink-auto1 {
  position: absolute;
  top: 7px;
  right: 16px;
  z-index: 1;
  padding: 5px 8px 5px 5px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  color: #333;
  border-right: 0;
  border-bottom: 0;
}
.dashallbrand {
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
  cursor: pointer;
  font-weight: 500;
  margin: 0 7px;
  min-width: 65px;
  height: 30px;
  line-height: 1px;
  font-size: 14px;
  background: #dbe4e7 url("../Images//table-arr.png") no-repeat right 12px
    center;
  background-size: 14px;
  text-align: left;
}
.dashallbrand::after {
  display: none;
}
.dashallbrand1 .dropdown-menu.show:before {
  display: none;
}
.dashallbrand1 .dropdown {
  background: transparent !important;
  display: inherit;
}
.ticketSycard {
  padding: 0 0 0 10px;
  background-color: #f5f8f9;
  height: 100%;
}
.ticketSycard1 {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}
.marginsystem1 {
  border-radius: 8px;
  height: auto;
}
.addNote-textarea-system-new {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  /* position: absolute; */
  height: 120px;
  border: none;
  resize: none;
  padding: 10px;
}
.paddingsystem .cke_bottom {
  display: none !important;
}
.collapsetask .drop {
  background: url("./../Images//down-1.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
  cursor: pointer;
}
.drop .drop1 {
  width: 100%;
  text-align: left;
}
.DashTimeRange .form-control {
  padding: 3px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  /* width: 120px; */
}
.DashTimeRange .form-control:focus {
  background-color: none !important;
  box-shadow: none !important;
}
.DashTimeRange #DateTimeInput_end {
  width: 120px;
  margin-left: -135px;
  color: #666 !important;
  border: none;
}
.DashTimeRange #DateTimeInput_start {
  width: 120px;
  text-align: right;
  border: none;
  color: #666 !important;
}
.DashTimeRange .timeContainer {
  display: none;
}
.DashTimeRange .dateTimeLabel {
  display: none;
}
.DashTimeRange .activeNotifier {
  display: none;
}
.DashTimeRange .fromDateHourContainer {
  position: absolute;
  bottom: 5px;
  border: none;
}
.DashTimeRange .fromDateTimeContainer {
  height: 295px;
}
.DashTimeRange .buttonContainer {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.DashTimeRange .input-group-addon {
  display: none;
}
.DashTimeRange .applyButton {
  background-color: #2561a8 !important;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  padding: 5px 10px;
  width: 88px;
  cursor: pointer;
  margin-right: 4px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #2561a8;
  text-transform: uppercase;
}
.DashTimeRange .applyButton:hover {
  background-color: #fff !important;
  color: #2561a8 !important;
}
.DashTimeRange .cancelButton:hover {
  background-color: #2561a8 !important;
  color: #fff !important;
}
.DashTimeRange .cancelButton {
  background-color: #fff;
  color: #2561a8;
  font-size: 12px;
  width: 88px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #2561a8;
  text-transform: uppercase;
}
.DashTimeRange .rangecontainer {
  border-right: 1px solid #eeeeee;
}
.DashTimeRange .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.DashTimeRange .glyphicon-chevron-left {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 10px;
  transform: rotate(270deg);
}
.DashTimeRange .glyphicon-chevron-right {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 10px;
  transform: rotate(90deg);
}
.dashallbrand1 .ch1 {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}
.dashallbrand1 .ch1-text {
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
  font-family: lato;
  margin: 0 5px;
}
.dashallbrand1 .dropdown-menu.show {
  min-width: 155px;
  display: grid;
}
.DashTimeRange .inputDate form-control {
  margin: 0 0 0 100px;
}
.centerprofile .txt-1 {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 47px;
  border: none;
  padding: 15px;
}
.centerprofile .CreateADDBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 47px;
  cursor: pointer;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.im {
  flex: 20 0 auto !important;
  width: 20px !important;
}
#ScheduleModel .scheduleBtncancel {
  background-color: #fff;
  border-radius: 4px;
  /* width: 245px; */
  width: 100%;
  height: 43px;
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  border: none;
  cursor: pointer;
}
#ScheduleModel .txt1Time {
  background: #ecf2f4 url("../Images/Time.png") no-repeat right 12px center;
  background-size: 17px;
}
.Every {
  width: 35px;
  height: 40px;
  background-color: #ecf2f4;
  border: none;
  padding: 0 8px;
  margin: 0 8px;
  border-radius: 5px;
  color: #6e6e6e;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
}
.ScheduleDate-to .every1 {
  color: #a7a7a7;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
#ScheduleModel .ant-checkbox + span {
  padding: 0 9px 0 5px !important;
  color: #a7a7a7;
}
#ScheduleModel .ant-checkbox-wrapper {
  width: 60px;
}
#ScheduleModel .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
.pop-over-cancle:hover {
  color: #2561a8 !important;
}
#thumb-modal-popup {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: auto;
  display: inline-block;
  height: auto;
  width: 634px;
}
#thumb-modal-popup .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  margin: -20px 5px 0 0;
}

/* ****************End css ************************/

.autocomp div {
  background: red;
}

.loader-icon-cntr {
  position: relative;
  min-height: 150px;
}
.loader-icon-cntr-above {
  background: #fff;
  margin: 0 15px;
  border-radius: 5px;
  margin-bottom: 40px;
}
.loader-icon {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* height: 100vh; */
  bottom: 0;
  background: transparent url("../Images/loader.gif") no-repeat center center;
  background-size: 60px;
  z-index: 5;
}
.loader-icon-cntr-ovrlay .loader-icon {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
}
.loader-icon-height {
  height: 200px;
}
.tic-history .ReactTable {
  border: none;
}
.tic-history .ReactTable .rt-thead.-header {
  background-color: transparent;
  border: none;
}
.tic-history .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.tic-history .ReactTable .rt-th {
  font-weight: bold;
  text-align: left;
  color: #888;
}
.tic-history .ReactTable .rt-tbody .rt-td {
  padding: 10px 15px !important;
}
.tic-history .ReactTable .rt-thead .rt-th.-cursor-pointer,
.tic-history .ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 10px 15px !important;
}

.bckHide .rt-th {
  display: none;
}
.tableSrolling {
  max-height: 300px;
  overflow: auto;
}

.datePickertable .ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: initial;
}

.datePickertable .ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
}

.datePickertable .ReactTable .rt-tr .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: initial;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.datePickertable .ReactTable .rt-tbody {
  overflow: initial;
}
.circleCancle {
  height: 18px;
  position: absolute;
  top: -7px;
  right: -2px;
  cursor: pointer;
}

.datePickertable .react-datepicker__input-container input,
.date-picker-arrows .react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #dcd4d4;
  padding: 1px 5px;
  border-radius: 3px;
  font-weight: 500;
}
.date-picker-arrows .react-datepicker__input-container input {
  width: auto;
}
.datePickertable button {
  min-width: initial;
}
#Modalorderitemtable .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}
#Modalorderitemtable .ReactTable {
  border: none;
}
#Modalordertable {
  max-height: 400px;
  overflow: auto;
}
#Modalordertable .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}
#Modalordertable .ReactTable {
  border: none;
}

.reactstoreselect .ReactTable .rt-thead.-headerGroups {
  background: none;
  border-bottom: none;
  display: none;
}

.applySearch {
  font-size: 14px;
  font-weight: 700;
  color: #2561a8 !important;
  margin-right: 12px;
  cursor: pointer;
}

.tic-noti label {
  line-height: 1.5;
}
.noti-count {
  width: 50px;
  text-align: center;
}
.noti-count label {
  padding-right: 9px;
}
.notification-popover {
  max-width: 150px;
  max-height: 200px;
  overflow: auto;
  padding-right: 10px;
}
.notification-popover p {
  margin-bottom: 0;
  padding: 5px 0;
  font-weight: 700;
}
.notification-popover a {
  margin-left: 10px;
  font-weight: 400;
  color: #2561a8;
}
.notification-popover::-webkit-scrollbar {
  width: 6px;
}
.notification-popover::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}
.notification-popover::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}
.notification-popover::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cus-drp .css-yk16xz-control,
.cus-drp .css-1fhf3k1-control {
  border: none;
  color: #000;
  /* display: block; */
  margin-top: 5px;
}
.cus-drp .css-14jk2my-container {
  pointer-events: initial;
}
.cus-drp .css-14jk2my-container .css-1hwfws3 {
  cursor: not-allowed;
}
.cus-drp .css-14jk2my-container .css-1rhbuit-multiValue {
  pointer-events: none;
}
.cus-drp .css-1hwfws3 {
  background: #ecf2f4 url("./../Images/table-arr.png") no-repeat right 25px
    center;
  background-size: 13px;
  min-height: 43px;
  border-radius: 4px;
  padding-right: 45px;
  padding-top: 4px;
}
.cus-drp .css-1g48xl4-IndicatorsContainer,
.cus-drp .css-1wy0on6 {
  display: none;
}
.cus-drp .css-1pahdxg-control {
  border-color: transparent;
  margin-top: 5px;
  border: none;
  box-shadow: none;
  background-color: #ecf2f4;
}
.cus-drp .css-1pahdxg-control:hover,
.cus-drp .css-1pahdxg-control:focus {
  border-color: transparent;
}
.cus-scroll {
  max-height: 300px;
  overflow: auto;
}
.agentlist .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: #ecf2f4;
  cursor: pointer;
}
.agentlist .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  background-color: transparent;
  border-bottom: none;
}
.agentlist .ReactTable {
  border: none;
}
.agentlist .ReactTable .rt-thead .rt-resizable-header-content {
  color: #a0a0a0 !important;
}
.agentlist .ReactTable .rt-td {
  color: #4a4a4a !important;
  padding: 12px 20px !important;
}
.agentlist .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.order-det .ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal !important;
  text-overflow: inherit !important;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
}
.order-det .ReactTable .rt-thead .rt-th,
.order-det .ReactTable .rt-thead .rt-td {
  width: 150px !important;
  text-align: left;
}
.order-det .ReactTable .rt-tbody .rt-td {
  width: 150px !important;
  padding: 12px 15px !important;
}
.order-det .ReactTable .rt-thead .rt-th,
.order-det .ReactTable .rt-thead .rt-td {
  padding: 12px 15px !important;
}
.order-det .ReactTable .rt-tbody .rt-td:first-child {
  width: 50px !important;
}
.order-det .ReactTable .rt-thead .rt-th:first-child,
.order-det .ReactTable .rt-thead .rt-td:first-child {
  width: 50px !important;
}
.order-detc2 .ReactTable .rt-tbody .rt-td:nth-child(2) {
  width: 50px !important;
}
.order-detc2 .ReactTable .rt-thead .rt-th:nth-child(2),
.order-detc2 .ReactTable .rt-thead .rt-td:nth-child(2) {
  width: 50px !important;
}
#daterangepicker .daterangepicker:after {
  top: -6px;
  border-right: 6px solid #fff0;
  border-bottom: 6px solid #fff;
  border-left: 6px solid #fff0;
}
.tic-his {
  max-height: 300px;
  overflow: auto;
}
.cus-head span {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.cus-head .ReactTable .rt-thead.-header {
  background-color: #e5e9ed;
}
.issuetype-cusdrp .btn {
  background-color: #ecf2f4;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  color: #333;
  text-align: left;
  padding: 10px 15px;
}
.store-report-add .ticketstrReport select {
  padding-left: 15px;
}
.store-report-add .issuetype-cusdrp .btn {
  padding: 7px 40px 7px 15px;
  font-size: 14px;
}
.issuetype-cusdrp .dropdown-menu.show {
  margin: 0;
  width: 100%;
  padding: 0px;
}
.cat-mainbox {
  padding: 10px;
  border-bottom: 1px solid #d2ddeb;
}
.issuetype-cusdrp .dropdown-menu.show:before {
  content: "";
}
.issuetype-cusdrp .searchf {
  background-color: #f9f9f9;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  padding: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
}
.issuetype-cusdrp .searchf::placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.issuetype-cusdrp .filter-checkbox {
  max-height: 70px;
  overflow: auto;
  margin-bottom: 10px;
}
.issuetype-cusdrp .filter-checkbox ul {
  margin-bottom: 0px;
}
.issuetype-cusdrp .filter-checkbox ul li {
  display: block;
}
.issuetype-cusdrp .category-button ul {
  margin-bottom: 0px;
}
.issuetype-cusdrp .category-button li:nth-child(1) {
  float: left;
}
.issuetype-cusdrp .category-button li:nth-child(2) {
  float: right;
}
.issuetype-cusdrp .category-button ul label {
  color: #2561a8;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.5px;
}
.issuetype-cusdrp .category-button {
  margin-bottom: 35px;
  padding: 6px 6px 0 6px;
}
.issuetype-cusdrp .category-box {
  margin-bottom: 15px;
  max-height: 110px;
  overflow: auto;
}
.issuetype-cusdrp .category-box {
  clear: both;
  padding: 0 6px;
}
.issuetype-cusdrp .category-box label {
  display: block;
}
.issuetype-cusdrp .category-box label {
  display: block;
  margin-bottom: 6px;
  color: #666;
  font-size: 16px;
}
.issuetype-cusdrp .category-box span {
  background-color: #f3f3f3;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 12px;
  margin: 2px 0;
  margin-right: 5px;
  display: inline-block;
}
.issuetype-cusdrp .category-box li {
  display: block;
  margin-bottom: 13px;
}
.store-report-add .issuetype-cusdrp .category-box li:last-child {
  margin-bottom: 0;
}
.category-scroll {
  max-height: 190px;
  overflow-x: hidden;
}
.category-scroll::-webkit-scrollbar {
  width: 6px;
}
.category-scroll::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}
.category-scroll::-webkit-scrollbar-thumb {
  background: #d2ddeb;
  border-radius: 10px;
}
.category-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.category-buttonbtm .cancel {
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}
.category-buttonbtm .done {
  margin-top: 0;
  background: #2561a8;
  border: none;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 40px;
  font-size: 14px;
  cursor: pointer;
}
.category-buttonbtm {
  padding: 12px 15px 15px;
}
.category-buttonbtm ul {
  margin-bottom: 0;
}
.issuesladrop {
  background: #fff url("./../Images//table-arr.png") no-repeat right 12px center;
  background-size: 12px;
}
.store-report-add .issuesladrop {
  background-position: right 19px center;
}
.issuetype-cusdrp .category-box label img {
  height: auto;
  /* width: 15px; */
  width: 10px;
  /* float: right; */
  /* margin: 5px 10px 0; */
  margin: 2px 1px 0;
}
#storeEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  /* max-width: 50%;
  min-width: 50%; */
}
.commentImg {
  width: 25px;
  height: 25px;
  padding: 5px;
}
.editstorepopover {
  min-width: 700px;
  max-width: 700px;
}
.emshl {
  background: #fff url("./../Images/facebook.png") no-repeat right 15px center;
}
.fileAttchImg {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}

#categoryEditModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px;
  margin: 100px auto;
  display: inline-block;
  max-width: 30%;
  min-width: 30%;
}

#tampleteEditModal {
  width: 300px;
  top: 16%;
}

.editcatcnl {
  float: right;
  margin: 0;
  color: #2561a8 !important;
}

.clrFlwUp {
  color: #2561a8;
  width: 18px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  display: inline-table;
}
.dropdown-settings {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  width: 100%;
  border: none;
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.notifi-container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.notifi-container::-webkit-scrollbar {
  width: 5px;
}
.notifi-container::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.notifi-container::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 10px;
}
.notifi-container::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}

#BlockEmailModel {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  width: 670px;
  border-radius: 10px;
}

.btn-block {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.block-cancelImg {
  height: 20px;
  float: right;
  margin-top: -11px;
  margin-right: -13px;
}

/* Responsive starts */
@media only screen and (max-width: 1300px) {
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
  .placeholder-dropdown-tktSys {
    right: -275px;
  }
}
@media only screen and (max-width: 1200px) {
  .colladrow label {
    font-size: 12px !important;
  }
  #slaEditModal {
    max-width: 50%;
    min-width: 50%;
  }
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
}
@media only screen and (max-width: 1024px) {
  /* Qa Dashboard */
  .QAtable table th {
    padding: 15px 10px;
  }
  .QAtable table td {
    padding: 18px 10px;
  }
  /* MyTicketClaim */
  .paddcla table th {
    padding: 15px 5px;
  }
  .paddcla table td {
    padding: 15px 5px;
  }
  /*MyTicketList  */
  .esc1 .nav-tabs .nav-link {
    margin-right: -15px;
  }
  .setting-tabs .es .nav-link.active {
    padding: 7px 7px;
    font-weight: 500;
    font-size: 13px;
  }
  .mtictab table th {
    padding: 16px 5px;
    font-size: 13px;
  }
  .myticketlist-header table td {
    padding: 15px 5px 15px 5px;
  }
  /* Setting>Store>Storemaster */
  .store-master table td {
    padding: 18px 5px;
  }
  .store-master table th {
    padding: 20px 5px;
  }
  /* Qa>QaAuditView */
  .removelinespace .mobiotw {
    margin-left: 32px !important;
  }
  /*Store/StoreTask */
  .store-task-tab-cont table th {
    padding: 15px 5px;
  }
  .store-task-tab-cont table td {
    padding: 15px 5px;
  }
  /* Setting/Brand */
  .brandtable table th {
    padding: 15px 10px;
  }
  .brandtable table td {
    padding: 15px 10px;
  }
  /* Setting/CategoryMaster */
  .category-master table td {
    padding: 18px 5px;
  }
  .category-master table th {
    padding: 18px 5px;
  }
  /* HierarchyMaster */
  .hiermas table th {
    padding: 20px 10px;
  }
  .hiermas table td {
    padding: 20px 10px;
  }
  /* Setting/Template */
  .template-table .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    padding: 20px 15px !important;
  }
  .template-table .ReactTable .rt-tbody .rt-td {
    padding: 18px 10px !important;
  }
  /*Store/Claim  */
  .store-task-tab-cont table th {
    padding: 15px 10px;
  }
  .store-task-tab-cont table td {
    padding: 15px 10px;
  }
  /* Setting/Store/CrmRole */
  .crm-padding-div {
    padding-right: 32px;
  }
  /*  */
  .ChatElementReact .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    padding: 20px 5px !important;
  }
  .ChatElementReact .ReactTable .rt-tbody .rt-td {
    padding: 10px 5px !important;
  }
  .my-tic-email1 {
    width: 130px;
    background-size: 10px;
  }
  .kblink1 {
    font-size: 12px;
  }
  .tic-createTic {
    right: -135px;
    top: 45px;
    width: 130px;
  }
  .setting-tabs .nav-tabs .nav-item {
    margin-right: 25px;
  }
  .Add-ticket-button {
    width: 130px;
  }
  .seacrh-img-addsearch {
    width: 8%;
  }
  .placeholder-dropdown-tktSys {
    top: 83px;
    left: 20px;
    width: 128px;
  }
  .CCdi1 {
    width: 95px;
  }
}
@media only screen and (max-width: 992px) {
  /* dashboard starts */
  body {
    font-size: 14px;
  }
  .dash-dropdowns select {
    background-size: 11px;
    padding-right: 20px;
    margin-right: 25px;
  }
  .card-value {
    font-size: 32px;
  }
  .card-head {
    margin-bottom: 10px;
  }
  .dash-top-cards {
    min-height: 125px;
  }
  .btm-mar > .row > div {
    margin-bottom: 25px;
  }
  big {
    font-size: 42px;
  }
  table th {
    padding: 20px;
  }
  table td {
    padding: 18px 20px;
  }
  .info-icon {
    width: 15px;
  }
  .claim-icon {
    width: 13px;
  }
  .task-icon-1 {
    width: 16px;
  }
  .dash-creation-popup li p {
    font-size: 14px;
  }
  .dash-creation-popup li {
    margin-top: 12px;
  }
  .dash-creation-popup li.title {
    padding-bottom: 0;
  }
  .dash-popup {
    right: -8px;
    top: 24px;
  }
  .dash-task-popup {
    bottom: 30px;
    font-size: 12px;
  }
  .dash-task-popup span {
    font-size: 12px;
  }
  .nw-chat {
    width: 16px;
    margin-left: 7px;
  }
  .task-progress {
    margin-top: 10px;
    height: 8px;
  }
  .pagi ul li {
    font-size: 14px;
    width: 32px;
    height: 32px;
    line-height: 30px;
  }
  .table-expandable-sctn button {
    font-size: 12px;
    padding-top: 7px;
    padding-bottom: 8px;
  }
  .save-view-search button.btn-inv {
    padding-right: 30px;
    padding-left: 30px;
  }
  .save-view-search button {
    padding-right: 20px;
    padding-left: 20px;
  }
  .table-expandable-sctn .nav-tabs .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .table-expandable-sctn .tab-content button img {
    top: -2px;
  }
  .sch-icon {
    width: 17px;
  }
  .csv-icon {
    width: 14px;
  }
  .assign-icon {
    width: 16px;
  }
  .table-expandable-sctn .tab-content .row {
    padding: 15px 0;
  }
  .table-expandable-sctn input[type="text"] {
    background-size: 16px;
  }
  .table-expandable-sctn input[type="text"]::placeholder {
    font-size: 14px;
  }
  .setting-tabs ul {
    border: none;
    margin-bottom: 25px;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 991px) {
  .target-closure-date {
    font-size: 11px;
  }
  .Date-target {
    font-size: 12px;
  }
  .bata-rajouri-garden {
    line-height: 22px;
  }
  #slaEditModal {
    max-width: 50%;
    min-width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  /* dashboard starts */
  .save-view-search {
    position: static;
    transform: none;
    padding: 15px;
    padding-bottom: 0;
  }
  .save-view-search button {
    width: 48%;
    margin-right: 0;
  }
  .save-view-search {
    display: flex;
    justify-content: space-between;
  }
  /* dashboard ends */
}
@media only screen and (max-width: 768px) {
  .mobilenumber-resp .respo {
    margin-left: 0 !important;
  }
  .mobilenumber-resp .resol {
    margin-right: 0 !important;
  }
  .card-details-1 {
    height: auto;
  }

  #slaEditModal {
    max-width: 70%;
    min-width: 70%;
  }
  #categoryEditModal {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
    padding: 10px;
    margin: auto;
    display: inline-block;
    max-width: 70%;
    min-width: 70%;
  }
  .EditModa .modal-dialog {
    width: 70%;
  }
}
@media only screen and (max-width: 767px) {
  .storebox {
    padding: 0 15px;
  }
  .v3,
  .v4 {
    display: none;
  }
  /* dashboard starts */
  .ticketToTask-chart .apexcharts-canvas {
    width: 100% !important;
    overflow: auto;
  }

  .ticketToTask-chart .apexcharts-canvas svg {
    width: auto !important;
    overflow: auto;
  }
  #bill-graph-tab .col-md-3 {
    order: 1;
  }
  .bill-graph-list {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .bill-graph-list li {
    padding: 0px 15px;
  }
  .table-expandable-sctn .tab-content .row > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .table-expandable-sctn .tab-content .row {
    padding: 10px 0;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .table-expandable-sctn .tab-content .row.all-row div {
    margin-bottom: 0px;
  }
  .table-expandable-sctn .tab-content .row.all-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ticketToTask-chart-1 .apexcharts-canvas {
    width: 100% !important;
    overflow: auto;
  }

  .ticketToTask-chart-1 .apexcharts-canvas svg {
    width: auto !important;
    overflow: auto;
  }
  .sectiontenant select {
    margin-bottom: 10px;
  }
  #Notification-popup {
    left: 5px;
  }
  /* dashboard ends */

  .storeRole-name-text {
    width: 105px;
  }
  .module-switch {
    padding: 0;
  }
  .d-nonemob {
    display: none !important;
  }
  .mob-header {
    display: block !important;
    background-color: #2561a8;
    color: #fff;
    padding: 10px;
  }
  .message-icon-cnt {
    position: absolute;
    background-color: #eb4d4b;
    width: 13px !important;
    height: 13px !important;
    line-height: 11px !important;
    text-align: center;
    border-radius: 10%;
    font-size: 10px !important;
    color: #fff;
    font-weight: 700;
    top: -5px !important;
    right: -5px !important;
  }
  .verticaldots {
    margin: 0 0 0 8px !important;
  }
  .verticaldots img {
    height: 17px;
  }
  .header-right-icons {
    padding: 0;
    float: right;
  }
  .hamb-menu {
    display: inline-block;
    margin: 0 10px 0;
  }
}

@media only screen and (max-width: 580px) {
  /* dashboard starts */
  .rem-table {
    display: none;
  }
  .mobile-table {
    display: block;
  }
  .dash-creation-popup {
    right: -70px;
    width: 300px;
  }
  .dash-popup {
    padding: 20px 15px;
  }
  /* dashboard ends */
  .myticket-submit-solve-button {
    width: 200px;
    height: auto;
    margin-left: 5px;
  }
  .myticket-submit-solve-button-text {
    font-size: 12px;
    margin-left: 0;
  }
  #slaEditModal {
    max-width: 70%;
    min-width: 70%;
  }
  .rolle-name-text {
    width: 90px;
  }

  /* program code starts */
  .auth-wrapper .auth-content {
    width: 360px;
  }
  .sign-in {
    font-size: 24px;
  }
  .programcode-card-new img,
  .main-logo {
    width: 160px !important;
  }
  #totalconcount-popup {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
  .counttab span {
    font-size: 11px !important;
  }
  .cardassetspop {
    width: 90%;
    left: 15px !important;
  }
  .cardasscus .card-img img {
    height: auto;
    width: 90px;
    margin: auto;
    display: block;
    padding: 8px 10px;
  }
  .cardasscus .card-img {
    width: 100px;
    margin: 7px 0;
  }
  .cardasscus td:nth-child(3) {
    white-space: nowrap;
  }
  .cardasscus .statusbtnadd {
    padding: 5px 20px;
  }
  .cardsresbtn {
    white-space: nowrap;
  }
  .cardasscus .btngreen {
    padding: 6px 10px !important;
  }
  .cardasscus .btnred {
    padding: 6px 10px !important;
  }
  .addimg input {
    height: 53px;
  }
  .pickuppendingcustom .deliv-grid-butn {
    width: 110px;
    height: 25px;
    font-size: 11px;
  }
  #article-popup {
    width: 95%;
    border-radius: 5px;
    padding: 0;
  }
  #article-popup .cancalImg {
    display: none;
  }
  .progtrckr li:first-child:before {
    display: none;
  }
  .article-body span {
    font-size: 11px;
    margin: 0;
    padding: 2px;
    margin-bottom: 5px !important;
    white-space: normal;
  }
  #article-popup .footer-buttons #next-button {
    float: right;
    margin: 10px 10px 10px 0;
  }
  #article-popup .footer-buttons #prev-button {
    float: right;
    margin: 10px 10px 10px 0;
  }
  .cus-checkbox {
    display: none;
  }
  #article-popup .progtrckr {
    margin: 0;
  }
  #article-popup .progtrckr li {
    margin-right: 0;
    padding-left: 0;
    font-size: 12px;
  }
  #article-popup .progtrckr li:first-child {
    margin-right: 10px;
  }
  #article-popup .step-progress {
    padding: 0;
  }
  #article-popup .progtrckr {
    padding: 15px;
    margin: 0;
    background: #2561a8;
    border-radius: 5px 5px 0 0;
  }

  #article-popup .progtrckr-done span {
    color: #fff;
    font-weight: normal;
  }
  #article-popup .progtrckr-doing span {
    color: #fff;
    font-weight: normal;
    position: relative;
  }
  #article-popup .progtrckr-todo span {
    color: #7fa9db;
    font-weight: normal;
  }
  #article-popup .progtrckr-doing span::before {
    width: 35px;
    background: #3a91f8;
    height: 2px;
    left: 0;
    bottom: -7px;
    position: absolute;
    content: "";
  }
  #article-popup .tabs-content {
    padding: 0;
    margin: 0;
  }
  .airwaybox {
    border-radius: 5px;
    border: none;
    margin: 10px;
  }
  .airwaycontent .cardtick {
    height: 40px;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .airwaycontent h2 {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .airwaycontent p {
    font-size: 13px;
    color: #999;
    margin-bottom: 5px;
  }
  .airwaycontent ul li:first-child {
    margin-right: 0;
  }
  .airwaycontent ul li {
    font-size: 14px;
    color: #666;
    padding-top: 5px;
  }
  .innertabcollapse label b {
    color: #797979;
  }
  .deliv-grid-butn {
    width: auto;
    height: 22px;
  }
  .assign-grid-btn {
    margin-right: 5px;
  }
  .order-grid-butn {
    min-width: 80px;
  }
  .step-progress .nav-tabs .nav-item {
    background: #2561a8;
    color: #fff;
    width: 100%;
  }
  .nav-tabs .nav-link.active {
    color: #fff;
    background: #2561a8;
    font-weight: normal;
  }
  .step-progress .nav-tabs .nav-link {
    font-size: 12px;
  }
  .step-progress .nav-line::before {
    display: none;
  }
  .step-progress .nav-line {
    font-weight: normal;
    color: #fff !important;
  }
  .airwaycontent ul {
    border-top: 1px solid #ddd;
    display: inline-block;
    margin: 0 30px 0;
  }
  .dv-status {
    margin-top: 15px;
    text-align: right;
    padding: 0 10px;
  }
  .cus-strecth {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  /* program code ends */

  .order-popover .antd-table-order .ant-table-tbody > tr > td {
    padding: 15px 5px !important;
    width: 70px !important;
  }
  .order-popover
    .custom-antd-table.antd-table-campaign.antd-table-order
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
    width: 70px;
  }
  .order-popover .ant-table-thead > tr > th:nth-child(2),
  .order-popover-table .ant-table-tbody > tr > td:nth-child(2) {
    width: 75px !important;
  }
}

@media only screen and (max-width: 480px) {
  .hamb-menu li {
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }
  .hamb-menu .dropdown-menu.show:before {
    display: none;
  }
  .hamb-menu ul {
    padding: 0;
    font-size: 12px;
  }
  /* .active a {
    color: #fff !important;
  } */
  .header-right-icons a:last-child {
    margin-right: 0px;
  }

  .vl {
    border: 0;
    height: auto;
    margin: 15px 0 20px;
    border-bottom: 2px solid #f1f1f1;
    width: 100%;
  }
  .v2 {
    border: 0;
    height: auto;
    margin: 5px 0 15px -15px;
    border-bottom: 2px solid #f1f1f1;
    width: 110%;
  }
  .data-store .col-md-12 {
    padding: 0;
  }
  .rectangle-box {
    height: auto;
  }
  .dropdrown {
    margin-left: 0;
  }
  .mob-pad {
    padding: 0 !important;
  }
  .drop-card {
    padding: 0 !important;
    margin-top: 0;
  }
  .label-4 {
    width: 100px;
  }
  .data-store {
    margin: 0;
  }
  .storeSpacing {
    padding-left: 0;
  }
  .data-store .label-4 {
    width: 45px;
  }
  .rectangle-box .select-category-placeholder {
    width: 100%;
  }
  .notes-00,
  .task-03,
  .claim-00 {
    margin-left: 0;
  }
  .arrow-img,
  .plush-img,
  .line-1 {
    margin-left: 0;
    margin-top: 0;
  }
  .mail-mask {
    width: 100%;
    background-color: transparent;
  }
  .mob-float {
    float: right;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .line-1 {
    display: inline-block;
    margin: 0 15px;
  }
  .label-2 {
    margin-top: 0px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .label-3 {
    margin-top: 2px;
    padding-bottom: 20px;
  }
  .v3,
  .v4 {
    width: 100%;
    margin-left: 0;
  }
  .v4 {
    margin-top: 0;
  }
  .today-02 {
    text-align: center;
    display: block;
    padding: 0;
    margin: 10px 0;
  }
  .rectangle-3 .message-header {
    display: none;
  }
  .i-have-solved-this-i {
    margin-left: 0;
  }
  .mob-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }
  .card-details {
    width: auto;
    height: auto;
    margin: 0;
  }
  .card-details-1 {
    width: auto;
    height: auto;
    margin: 20px;
    padding: 20px 30px;
  }
  .v6 {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
  .yesterday-02 {
    display: block;
    padding-left: 0;
    text-align: center;
    margin: 10px 0;
  }
  .hi-naman-please-hel {
    padding-left: 0;
  }
  .comment {
    margin-left: 0 !important;
    padding-left: 0;
    order: 1;
  }
  .up-1 {
    margin: 0;
    order: 2;
  }
  .card-op-out .card-details {
    margin-top: 15px;
  }
  .comment-text {
    padding-left: 0;
  }
  .hi-diwakar-i-really1 {
    width: 80%;
    display: inline-block;
    padding-left: 10px;
  }
  .comment-text1 {
    padding-left: 15px;
    margin-top: 5px;
  }
  .clip {
    margin-left: 30px;
    vertical-align: top;
    width: 17px;
    height: 17px;
  }
  .reply-comment {
    padding-left: 13px;
    text-align: left;
  }
  .need-to-change-my-sh {
    margin-left: 37px;
  }
  .head-header {
    height: auto;
  }
  .oval-head {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    width: 100%;
  }
  .myticket-submit-solve-button {
    margin-left: 9px;
    width: auto;
    float: right;
    padding: 5px 10px;
  }
  .myticket-submit-solve-button-text {
    margin-left: 0;
  }
  .loading-rectangle {
    margin-top: -183px;
  }
  .head-header-1 {
    padding-bottom: 0;
  }
  .nav-tab3,
  .nav-tab2,
  .nav-tab1,
  .claim-space,
  .main-nav {
    display: none;
  }
  .er {
    padding-left: 0;
    text-align: center;
    width: 60px;
    height: 55px;
    display: inline-block;
  }
  .er-label {
    font-size: 16px;
    margin-top: 0;
  }

  /* dashboard starts */
  .dash-dropdowns {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dash-dropdowns > div {
    padding: 5px 0;
  }
  .btm-mar > .row > div {
    margin-bottom: 20px;
  }
  .task-claim-cont {
    padding-right: 0;
    padding-left: 0;
  }
  .mob-mar-btm button {
    margin-bottom: 15px;
  }
  .mob-mar-btm button:last-child {
    margin-bottom: 0px;
  }

  .grapwid {
    max-width: 100% !important;
    overflow: auto;
  }
  .dash-top-cards1 {
    padding: 12px 20px !important;
  }
  .mid-sec {
    padding: 15px;
  }
  .arrow-img {
    margin: 5px 30px 0;
  }
  #Notification-popup .rowpadding {
    padding-left: 5px;
  }
  #Notification-popup .viewticketspeadding {
    padding-left: 5px;
  }
  #slaEditModal {
    max-width: 90%;
    min-width: 90%;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 400px) {
  /* dashboard starts */
  .pagi {
    padding-right: 15px;
    padding-left: 15px;
  }
  .pagi ul li {
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 28px;
  }
  .save-view-search {
    display: block;
  }
  .save-view-search button {
    width: 100%;
    margin-bottom: 15px;
  }
  .save-view-search button:last-child {
    margin-bottom: 0px;
  }
  .btnSearchQa {
    width: 100%;
    margin-top: 15px;
  }
  #categoryEditModal {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
    padding: 10px;
    margin: auto;
    display: inline-block;
    max-width: 100%;
    min-width: 100%;
  }
  .EditModa .modal-dialog {
    width: 100%;
  }
  /* dashboard ends */
}

@media only screen and (max-width: 360px) {
  /* dashboard starts */
  .dash-tp-card .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .obpml {
    margin-left: 0;
  }
  .mlistbtn {
    float: none;
    text-align: center;
  }
  .setting-tabs ul {
    border: none;
    display: flex !important;
    margin-bottom: 25px;
  }
  #slaEditModal {
    max-width: 98%;
    min-width: 98%;
  }
  #logout-ovrly {
    padding: 70px 0px 0 0;
  }
  /* dashboard ends */

  /* program code starts */
  .auth-wrapper .auth-content {
    width: 100%;
  }
  /* program code ends */
}
#customer-tab {
  position: relative;
}
.alertckuser {
  /* top: 132px !important; */
  top: 82px !important;
}
.alertckuserinter {
  right: 75px;
  top: 37% !important;
}
.placeholder-alert-2 {
  /* top: 37% !important; */
  top: 32% !important;
}

/* Responsive ends */

#DateTimeRangeContainerNoMobileMode .ant-calendar-range-picker-input {
  width: 41%;
  /* width: 40%; */
  height: 99%;
  text-align: left;
  float: left;
  background-color: transparent;
  border: 0;
  outline: 0;
}
#DateTimeRangeContainerNoMobileMode .ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: left;
  float: left;
  vertical-align: top;
  pointer-events: none;
}
#DateTimeRangeContainerNoMobileMode .ant-calendar-picker-clear {
  top: 45%;
}
.seacrh-img-addsearch {
  width: 4%;
  display: inline-block;
  height: 43px;
  padding: 13px 0 0 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background: #ecf2f4 !important;
}
.srch-imge {
  margin-top: -5px;
  cursor: pointer;
}
.csv-button img {
  margin-right: 5px;
}
.myticlist-expand-sect img {
  margin-right: 5px;
}
.dis-btn {
  margin: 0;
}
.DashTimeRange {
  padding: 0 15px 0 0;
}

#BlockEmailModel {
  position: relative;
  padding: 0;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  width: 670px;
  border-radius: 10px;
}

.btn-block {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.block-cancelImg {
  height: 20px;
  float: right;
  margin-top: -11px;
  margin-right: -13px;
}

.myticket-knowpopup {
  margin-right: auto !important;
  min-width: 30%;
}

.knowticketID {
  color: #2561a8 !important;
  cursor: pointer;
}
.iskbticket {
  pointer-events: none;
}
.storeTdetail .ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.autopriority {
  display: none;
}
.plusline1new {
  border-right: 1px solid #9b9b9b;
  padding-top: 12px;
  padding-bottom: 14px;
}

.campaign-end-date .ant-calendar-picker-input {
  height: 37px;
  background: #ecf2f4 url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
  border-color: transparent;
}
.campaign-end-date
  .ant-calendar-picker:hover
  .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: transparent;
}
.campaign-end-date .ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 0;
  pointer-events: none;
}
.campaign-end-date .ant-calendar-picker-icon {
  display: none;
}
.campaign-end-date .ant-calendar-range-picker-input {
  font-weight: 700;
  color: #333;
}
.campaign-end-date .ant-calendar-range-picker-separator {
  color: #333;
  font-weight: 700;
  vertical-align: middle;
}
.tasksearchdiv {
  text-align: right;
  width: 100%;
}
.rejectmodal-popup {
  margin-right: auto !important;
  min-width: 40%;
}
.cusinput {
  position: absolute;
  width: 135px;
  right: -135px;
  top: 50px;
}
.ord-m-t20 {
  margin-top: 20px !important;
}
.ordPayment1 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 148px;
}
.ordcusinput {
  position: absolute;
  width: 135px;
  right: -135px;
  top: 325px;
}
.ordcusinput1 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 52px;
}
.ordcusinput2 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 100px;
}
.ordcusinputStore2 {
  position: absolute;
  width: 250px;
  right: -515px;
  top: 100px;
}
.ordcusinput3 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 147px;
}
.ordcusinput4 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 194px;
}
.ordcusinput5 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 241px;
}
.ordcusinput6 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 287px;
}
.ordcusinput7 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 333px;
}
.ordcusinput8 {
  position: absolute;
  width: 250px;
  right: -249px;
  top: 379px;
}
.chatabcus table td {
  color: #333;
}
.chatabcus .custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.chatabcus table tbody tr {
  border-bottom: none;
}
.chatabcus .ant-table-tbody > tr > td {
  border-bottom: none;
}
.chattxtdivcus {
  display: flex;
  width: 100%;
}
.languagebox {
  margin-bottom: 15px;
}
.languagebox .langbtn {
  background-color: #eee;
  border-color: #ccc;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  height: 42px;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}
.languagebox .langbtn.active {
  background-color: #2561a8;
  color: #fff;
}
.skip {
  margin-top: 10px;
  text-transform: uppercase;
  color: rgb(36, 110, 195);
  cursor: pointer;
}
.ant-drawer-content-wrapper {
  width: 230px !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.mobsidebar {
  height: 100vh;
}
.uppersec {
  background-color: #2561a8;
  padding: 50px 20px;
}
.uppersec ul {
  margin-bottom: 0;
}
.uppersec ul li:first-child {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
}
.uppersec ul li:last-child {
  vertical-align: -webkit-baseline-middle;
}
.uppersec ul li img {
  height: 10px;
}
.uppersec h3 {
  font-size: 14px;
  color: #fff;
}
.uppersec p {
  font-size: 12px;
  color: #fff;
}
.lowersec ul {
  padding: 20px;
  margin-bottom: 0;
}
.lowersec ul li {
  display: block;
  margin-bottom: 25px;
}
.lowersec img {
  height: 15px;
  margin-right: 10px;
  max-width: 20px;
}
.lowersec span {
  width: 30px;
  display: inline-block;
}
.logoutbox {
  padding: 20px;
  border-top: 1px solid #ccc;
}
.logoutbox ul {
  margin-bottom: 0;
}
.logoutbox img {
  height: 15px;
  margin-right: 10px;
  max-width: 20px;
}
.headers-menu a[disabled] {
  color: #4a4a4a !important;
}
.lowersec a[disabled] {
  color: #4a4a4a !important;
}

.myticlist-expand-sect .tab-content .row.all-row > div {
  margin-bottom: 25px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1 > span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search > input {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search > input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search > button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search > button > span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}

.notfound .notfound-search > button > span:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  border: 4px solid #c5c5c5;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound-search > button > span:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: 7px;
  top: 17px;
  border-radius: 2px;
  background: #c5c5c5;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search > button:hover > span:after {
  border-color: #00b7ff;
}

.notfound .notfound-search > button:hover > span:before {
  background-color: #00b7ff;
}

@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}
.tktTaskDrw .ant-drawer-body {
  padding: 24px !important;
}